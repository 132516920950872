import { Box, Typography,Button, Link } from '@mui/material'
import React from 'react'
import Projectcolors from '../../Utils/Colors'

const SubNavbar = (props) => {

    const {handleOpen,handleOpenc,home} = props

  return (
    <div>
        <Box
        component="div"
        sx={{ display: "inline", float: "right", padding: 5 }}
      >
        {home ? (  <Typography
            sx={{ display: "inline", padding: 1, fontSize: 16, marginRight: 2 }}
          >
            <Link href="/" sx={{ textDecoration: "none", color: "#80e5ff" }}>
              <b>HOME</b>
            </Link>
          </Typography>) : null}
        <Typography
          sx={{ display: "inline", padding: 1, fontSize: 16, marginRight: 2 }}
        >
          <Button onClick={handleOpen} sx={{ color: "#80e5ff", fontSize: 16 }}>
            <b>ABOUT</b>
          </Button>{" "}
        </Typography>
        <Typography
          sx={{ display: "inline", padding: 1, fontSize: 16, marginRight: 2 }}
        >
          <Button onClick={handleOpenc} sx={{ color: "#80e5ff", fontSize: 16 }}>
            <b>CONTACT</b>
          </Button>{" "}
        </Typography>

        <Typography
          sx={{
            display: "inline",
            padding: 1,
            fontSize: 16,
            backgroundColor: "white",
            borderRadius: "10%",
            marginRight: 7,
          }}
        >
          <Link
            href="/login"
            sx={{ textDecoration: "none", color: Projectcolors.primary }}
          >
            <b>SIGN IN </b>
          </Link>
        </Typography>
      </Box>
    </div>
  )
}

export default SubNavbar