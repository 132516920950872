import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@mui/material/Input";
// Icons
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import CancelIcon from "@mui/icons-material/Cancel";
import Projectcolors from "../Utils/Colors";
import { Alert } from "@mui/material";
import { baseURL } from "../Config/config";
import Checkbox from "@mui/material/Checkbox";
import GlobalLoader from "./CommonC/GlobalLoader";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  table: {
    minWidth: 650,
    height: 15,
  },
  selectTableCell: {
    width: 60,
  },
  tableCell: {
    width: 180,
    height: 2,
    fontSize: 13,
  },
  input: {
    width: 130,
    height: 40,
    fontSize: 13,
  },
}));

const createData = (tagname, lower, upper) => ({
  tagname: tagname,
  lower,
  upper,
  isEditMode: false,
});

const CustomTableCell = ({ row, name, onChange }) => {
  const classes = useStyles();
  const { isEditMode } = row;
  return (
    <TableCell
      sx={{ fontSize: "12px" }}
      align="left"
      className={classes.tableCell}
    >
      {isEditMode ? (
        <Input
          value={row[name]}
          name={name}
          onChange={(e) => onChange(e, row)}
          className={classes.input}
          sx={{ fontSize: "12px" }}
        />
      ) : (
        row[name]
      )}
    </TableCell>
  );
};

function Limit(props) {
  const [rows, setRows] = React.useState([]);
  const [previous, setPrevious] = React.useState({});
  const [saved, setsaved] = React.useState(false);
  const [getLoader, setGetLoader] = React.useState(false);

  const classes = useStyles();

  React.useEffect(() => {
    getLimitData();
  }, []);
  const getLimitData = () => {
    setGetLoader(true);
    const ProjectID = localStorage.getItem("projectID");
    fetch(
      `${baseURL}/getlimitdata?projectID=${ProjectID}&loaded=${props.loaded}`
    )
      .then((res) => res.json())
      .then((myresponse) => {
        setRows(myresponse.data);
        setGetLoader(false);
      })
      .catch((err) => {
        setGetLoader(false);
      });
  };

  const onToggleEditMode = (tagname) => {
    setRows((state) => {
      return rows.map((row) => {
        if (row.tagname === tagname) {
          return { ...row, isEditMode: !row.isEditMode };
        }
        return row;
      });
    });
  };

  const onChange = (e, row) => {
    if (!previous[row.tagname]) {
      setPrevious((state) => ({ ...state, [row.tagname]: row }));
    }
    const value = e.target.value;
    const name = e.target.name;
    const { tagname } = row;
    const newRows = rows.map((row) => {
      if (row.tagname === tagname) {
        return { ...row, [name]: value };
      }
      return row;
    });
    setRows(newRows);
  };

  const onRevert = (tagname) => {
    const newRows = rows.map((row) => {
      if (row.tagname === tagname) {
        return previous[tagname] ? previous[tagname] : row;
      }
      return row;
    });
    setRows(newRows);
    setPrevious((state) => {
      delete state[tagname];
      return state;
    });
    onToggleEditMode(tagname);
  };

  const handlestatuslimit = (e) => {
    const newData = rows.map((row, i) => {
      if (i === parseInt(e.target.value)) {
        const newstatus = row.status === false ? true : false;

        return { ...row, status: newstatus };
      }
      return row;
    });
    setRows(newData);
  };

  const savelimits = () => {
    setGetLoader(true);
    const ProjectID = localStorage.getItem("projectID");

    const body = {
      rows: rows,
      ProjectID: ProjectID,
    };

    fetch(`${baseURL}/savelimits`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((result) => {
        setGetLoader(false);
        setsaved(true);
      })
      .catch((error) => {
        setGetLoader(false);
        console.error("Error:", error);
      });
  };

  return (
    <div>
      <TableContainer
        component={Paper}
        style={{
          width: "90%",
          height: "450px",
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: 40,
        }}
      >
        <Table sx={{ minWidth: 150 }} aria-label="sticky table" stickyHeader>
          <TableHead
            sx={{ backgroundColor: Projectcolors.primary, color: "blue" }}
          >
            <TableRow>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  fontSize: "18px",
                  color: "#fff",
                  backgroundColor: Projectcolors.primary,
                }}
                align="left"
              >
                {" "}
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  fontSize: "14px",
                  color: "#fff",
                  backgroundColor: Projectcolors.primary,
                }}
                align="left"
              >
                Tag Name
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  fontSize: "14px",
                  color: "#fff",
                  backgroundColor: Projectcolors.primary,
                }}
                align="left"
              >
                Description
              </TableCell>

              <TableCell
                sx={{
                  fontWeight: "bold",
                  fontSize: "14px",
                  color: "#fff",
                  backgroundColor: Projectcolors.primary,
                }}
                align="left"
              >
                {" "}
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  fontSize: "14px",
                  color: "#fff",
                  backgroundColor: Projectcolors.primary,
                }}
                align="left"
              >
                Lower limit
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  fontSize: "14px",
                  color: "#fff",
                  backgroundColor: Projectcolors.primary,
                }}
                align="left"
              >
                Upper limit
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <TableRow key={i} sx={{ height: 1 }}>
                <TableCell>
                  <Checkbox
                    color="primary"
                    inputProps={{
                      "aria-label": "select all desserts",
                    }}
                    size="medium"
                    checked={row.status}
                    value={i}
                    onChange={handlestatuslimit}
                  />
                </TableCell>
                <TableCell sx={{ fontSize: "12px" }} key={row.tagname}>
                  {row.tagname}
                </TableCell>
                <TableCell sx={{ fontSize: "12px" }} key={row.desc}>
                  {row.desc}
                </TableCell>

                <TableCell className={classes.selectTableCell}>
                  {row.isEditMode ? (
                    <>
                      <IconButton
                        aria-label="done"
                        onClick={() => onToggleEditMode(row.tagname)}
                      >
                        <DoneIcon />
                      </IconButton>
                      <IconButton
                        aria-label="revert"
                        onClick={() => onRevert(row.tagname)}
                      >
                        <CancelIcon />
                      </IconButton>
                    </>
                  ) : (
                    <IconButton
                      aria-label="delete"
                      onClick={() => onToggleEditMode(row.tagname)}
                    >
                      <EditIcon />
                    </IconButton>
                  )}
                </TableCell>
                <CustomTableCell
                  sx={{ fontSize: "20px" }}
                  {...{ row, name: "lower", onChange }}
                />
                <CustomTableCell
                  sx={{ fontSize: "20px" }}
                  {...{ row, name: "upper", onChange }}
                />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <div style={{ width: 480 }}>
          {saved ? (
            <div sx={{ marginBottom: 1, marginLeft: 10 }}>
              <Alert
                sx={{
                  marginTop: 2,
                  fontSize: "14px",
                  color: Projectcolors.primary,
                  marginLeft: 10,
                }}
                variant="outlined"
                severity="success"
              >
                Set Limits Successfull
              </Alert>
            </div>
          ) : (
            <p></p>
          )}
        </div>
        <div
          sx={{
            width: 100,
            display: "flex",
            flex: 1,
            justifyContent: "flex-end",
            margin: "auto",
          }}
        >
          <Button
            disabled={!props.editAccess}
            onClick={savelimits}
            sx={{
              marginLeft: 45,
              marginTop: 3,
              width: 30,
              height: 40,
              fontSize: 16,
              padding: 2,
              backgroundColor: Projectcolors.primary,
              color: "white",
              fontStyle: "bold",
              marginBottom: 3,
            }}
            variant="contained"
          >
            {" "}
            Save
          </Button>
        </div>
      </Box>

      {getLoader ? <GlobalLoader /> : null}
    </div>
  );
}

export default Limit;
