import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import SliceData from "../SliceData";
import Limit from "../Limit";
import NoiseRemoval from "../NoiseRemoval";
import { Typography } from "@mui/material";
import Projectcolors from "../../Utils/Colors";
import { Button } from "@mui/material";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import Paper from "@mui/material/Paper";
import LinearProgress from "@mui/material/LinearProgress";
import Modal from "@mui/material/Modal";
import LinePlotRaw from "../LinePlotRaw";
import Tab from "@mui/material/Tab";
import Heatmap from "../Heatmap";
import Scatterplot from "../Scatterplot";
import CloseIcon from "@mui/icons-material/Close";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import GlobalLoader from "../CommonC/GlobalLoader";
import { baseURL } from "../../Config/config";
import Spearman from "../Spearman";

function Cleandata(props) {
  const [value, setValue] = React.useState("1");
  const [openrawdata, setOpenrawdata] = React.useState(false);
  const [openslicedata, setOpenslicedata] = React.useState(false);
  const [openlimitdata, setOpenlimitdata] = React.useState(false);
  const [opennoisedata, setOpennoisedata] = React.useState(false);
  const [percentage, setPercentage] = React.useState(70);
  const [getLoader, setGetLoader] = React.useState(false);
  const [timeseries, setTimeseries] = React.useState(0);

  useEffect(() => {
    getPercentage();
  }, []);
  // const CustomTab = withStyles({
  //   root: {
  //     textTransform: "none"
  //   }
  // })(Tab);

  const getPercentage = () => {
    setGetLoader(true);
    const ProjectID = localStorage.getItem("projectID");
    fetch(
      `${baseURL}/getpercentage?projectID=${ProjectID}&loaded=${props.loaded}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())

      .then((data) => {
        setPercentage(data.percentage);
        setTimeseries(data.timeseries);
        setGetLoader(false);
      })
      .catch((err) => {
        setGetLoader(false);
        console.log("caught it!", err);
      });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handlerawdataOpen = () => setOpenrawdata(true);
  const handlerawdataClose = () => {
    setOpenrawdata(false);
    getPercentage();
  };
  const handleslicedataOpen = () => setOpenslicedata(true);
  const handleslicedataClose = () => {
    setOpenslicedata(false);
    getPercentage();
  };
  const handlelimitdataClose = () => setOpenlimitdata(false);
  const handlelimitdataOpen = () => setOpenlimitdata(true);
  const handlenoisedataClose = () => {
    setOpennoisedata(false);
    getPercentage();
  };
  const handlenoisedataOpen = () => setOpennoisedata(true);

  const NextStep = () => {
    props.handleNextStep();
  };

  return (
    <div>
      <Card
        sx={{
          width: "90%",
          marginLeft: 6,
          marginTop: 5,
          height: 300,
          marginTop: "100px",
        }}
        elevation={3}
      >
        <CardHeader
          title="Data Conditioning"
          sx={{ color: Projectcolors.primary, fontWeight: "bold" }}
          titleTypographyProps={{ fontSize: "16px", fontWeight: "bold" }}
        ></CardHeader>
        <CardContent>
          <TableContainer
            component={Paper}
            style={{
              width: "100%",
              height: 210,
              padding: 10,
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: 10,
            }}
          >
            <Table sx={{ minWidth: 150 }} aria-label="simple table">
              <TableBody>
                <TableRow sx={{ width: 100, fontSize: 20 }}>
                  <TableCell sx={{ fontSize: 13 }} padding="checkbox">
                    <EditIcon
                      onClick={handlerawdataOpen}
                      sx={{ fontSize: "12px" }}
                    />
                  </TableCell>

                  <TableCell
                    sx={{ fontSize: "12px", width: 180, fontWeight: "bold" }}
                  >
                    Raw Data Visualization
                  </TableCell>

                  <TableCell>
                    <Box
                      sx={{
                        width: "100%",
                        height: 30,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Box sx={{ width: "100%", mr: 1 }}>
                        <LinearProgress
                          variant="determinate"
                          value={percentage}
                          sx={{ height: 14, color: "green" }}
                        />
                      </Box>
                      <Box sx={{ minWidth: 35 }}>
                        <Typography variant="h6" color="text.secondary">
                          {percentage}%
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>

                <TableRow sx={{ width: 100 }}>
                  <TableCell sx={{ fontSize: 12 }} padding="checkbox">
                    <EditIcon
                      onClick={handleslicedataOpen}
                      sx={{ fontSize: "12px" }}
                    />
                  </TableCell>

                  <TableCell sx={{ fontSize: "12px", fontWeight: "bold" }}>
                    Data Slicing
                  </TableCell>

                  <TableCell sx={{ fontSize: 15 }}>
                    <Box
                      sx={{
                        width: "100%",
                        height: 30,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Box sx={{ width: "100%", mr: 1 }}>
                        <LinearProgress
                          variant="determinate"
                          value={percentage}
                          sx={{ height: 14, color: "green" }}
                        />
                      </Box>
                      <Box sx={{ minWidth: 35 }}>
                        <Typography variant="h6" color="text.secondary">
                          {percentage}%
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
                <TableRow sx={{ width: 100 }}>
                  <TableCell sx={{ fontSize: 15 }} padding="checkbox">
                    <EditIcon
                      onClick={handlelimitdataOpen}
                      sx={{ fontSize: "12px" }}
                    />
                  </TableCell>

                  <TableCell sx={{ fontSize: "12px", fontWeight: "bold" }}>
                    Engineering Constraint
                  </TableCell>

                  <TableCell sx={{ fontSize: 15 }}>
                    <Box
                      sx={{
                        width: "100%",
                        height: 30,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Box sx={{ width: "100%", mr: 1 }}>
                        <LinearProgress
                          variant="determinate"
                          value={percentage}
                          sx={{ height: 14, color: "green" }}
                        />
                      </Box>
                      <Box sx={{ minWidth: 35 }}>
                        <Typography variant="h6" color="text.secondary">
                          {percentage}%
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
                <TableRow sx={{ width: 100 }}>
                  <TableCell sx={{ fontSize: 13 }} padding="checkbox">
                    <EditIcon
                      onClick={handlenoisedataOpen}
                      sx={{ fontSize: "12px" }}
                    />
                  </TableCell>

                  <TableCell sx={{ fontSize: "12px", fontWeight: "bold" }}>
                    Data Noise Removal
                  </TableCell>

                  <TableCell sx={{ fontSize: 15 }}>
                    <Box
                      sx={{
                        width: "100%",
                        height: 30,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Box sx={{ width: "100%", mr: 1 }}>
                        <LinearProgress
                          variant="determinate"
                          value={percentage}
                          sx={{ height: 14, color: "green" }}
                        />
                      </Box>
                      <Box sx={{ minWidth: 35 }}>
                        <Typography variant="h6" color="text.secondary">
                          {percentage}%
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>

      <Paper elevation={15}>
        <Modal open={openrawdata} onClose={handlerawdataClose} elevation={15}>
          <Box
            sx={{
              width: 950,
              height: 650,
              backgroundColor: "white",
              display: "flex",
              margin: "auto",
              justifyContent: "center",
              marginTop: 10,
              flexDirection: "column",
            }}
          >
            <div>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  backgroundColor: Projectcolors.primary,
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    height: 35,
                    fontSize: "15px",
                    color: "white",
                    padding: 1,
                  }}
                >
                  Raw Data Visualization
                </Typography>
                <Button
                  onClick={handlerawdataClose}
                  sx={{
                    display: "inline-flex",
                    color: "white",
                    fontSize: "20px",
                  }}
                >
                  <CloseIcon sx={{ fontSize: "25px" }} />
                </Button>
              </Box>
            </div>

            <TabContext value={value} sx={{ position: "fixed" }}>
              <Box
                sx={{
                  width: "50%",
                  marginTop: 5,
                  borderBottom: 1,
                  borderColor: Projectcolors.primary,
                  display: "flex",
                  margin: "auto",
                  justifyContent: "center",
                  marginLeft: 30,
                }}
              >
                <TabList
                  sx={{ fontSize: 15 }}
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab
                    sx={{ fontSize: "13px", textTransform: "none" }}
                    label="Data"
                    value="1"
                  />
                  <Tab
                    sx={{ fontSize: "13px", textTransform: "none" }}
                    label="Correlation"
                    value="2"
                  />
                  <Tab
                    sx={{ fontSize: "13px", textTransform: "none" }}
                    label="Plot Controls"
                    value="3"
                  />
                  <Tab
                    sx={{ fontSize: "13px", textTransform: "none" }}
                    label="MB Ranking"
                    value="4"
                  />
                </TabList>
              </Box>
              <TabPanel value="1">
                {" "}
                <LinePlotRaw />
              </TabPanel>
              <TabPanel value="2">
                <Heatmap />
              </TabPanel>
              <TabPanel value="3">
                <Scatterplot />
              </TabPanel>
              <TabPanel value="4">
                <Spearman />
              </TabPanel>
            </TabContext>
          </Box>
        </Modal>
      </Paper>

      <Paper elevation={3}>
        <Modal open={openslicedata} onClose={handleslicedataClose}>
          <Box
            sx={{
              boxShadow: 5,
              width: 950,
              height: 650,
              padding: 0,
              backgroundColor: "white",
              display: "flex",
              margin: "auto",
              justifyContent: "center",
              marginTop: 10,
              flexDirection: "column",
            }}
          >
            <div>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  backgroundColor: Projectcolors.primary,
                  justifyContent: "space-between",
                  marginTop: 0,
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    height: 35,
                    fontSize: "15px",
                    color: "white",
                    padding: 1,
                  }}
                >
                  Data Slicing
                </Typography>
                <Button
                  onClick={handleslicedataClose}
                  sx={{
                    display: "inline-flex",
                    color: "white",
                    fontSize: "20px",
                  }}
                >
                  <CloseIcon sx={{ fontSize: "25px" }} />
                </Button>
              </Box>
            </div>

            <SliceData editAccess={props.editAccess} />
          </Box>
        </Modal>
      </Paper>

      <Paper elevation={3}>
        <Modal open={openlimitdata} onClose={handlelimitdataClose}>
          <Box
            sx={{
              width: 950,
              height: 620,
              backgroundColor: "white",
              display: "flex",
              margin: "auto",
              justifyContent: "center",
              marginTop: 10,
              flexDirection: "column",
            }}
          >
            <div>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  backgroundColor: Projectcolors.primary,
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    height: 45,
                    fontSize: "15px",
                    color: "white",
                    padding: 1,
                  }}
                >
                  Data Check Limit
                </Typography>
                <Button
                  onClick={handlelimitdataClose}
                  sx={{
                    display: "inline-flex",
                    color: "white",
                    fontSize: "20px",
                  }}
                >
                  <CloseIcon sx={{ fontSize: "25px" }} />
                </Button>
              </Box>
            </div>

            <Limit
              editAccess={props.editAccess}
              loaded={props.loaded}
              ProjectID={props.ProjectID}
            />
          </Box>
        </Modal>
      </Paper>

      <Paper elevation={3}>
        <Modal open={opennoisedata} onClose={handlenoisedataClose}>
          <Box
            sx={{
              width: 950,
              height: 650,
              backgroundColor: "white",
              display: "flex",
              margin: "auto",
              justifyContent: "center",
              marginTop: 10,
              flexDirection: "column",
            }}
          >
            <div>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  backgroundColor: Projectcolors.primary,
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    height: 35,
                    fontSize: "15px",
                    color: "white",
                    padding: 1,
                  }}
                >
                  Data Noise Removal
                </Typography>
                <Button
                  onClick={handlenoisedataClose}
                  sx={{
                    display: "inline-flex",
                    color: "white",
                    fontSize: "20px",
                  }}
                >
                  <CloseIcon sx={{ fontSize: "25px" }} />
                </Button>
              </Box>
            </div>

            <NoiseRemoval editAccess={props.editAccess} />
          </Box>
        </Modal>
      </Paper>

      <Button
        onClick={NextStep}
        mt={5}
        sx={{
          float: "right",
          marginRight: 15,
          width: 10,
          height: 35,
          fontSize: 16,
          marginTop: 5,
          backgroundColor: Projectcolors.primary,
          color: "black",
          fontStyle: "bold",
        }}
        variant="contained"
        size="large"
      >
        {" "}
        <ChevronRightIcon sx={{ fontSize: "25px", color: "white" }} />
      </Button>

      <Box
        sx={{
          margin: "auto",
          width: "80%",
          typography: "h5",
          backgroundColor: "grey",
        }}
      >
        {/*<TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider',fontSize:35 }}>
          <TabList onChange={handleChange} sx={{width:'50%',textAlign:'center',marginTop:3,fontSize:35}} aria-label="lab API tabs example">
            <Tab sx={{fontSize:15}} label="Raw Data" value="1" />
            <Tab sx={{fontSize:15}} label="Slicing" value="2" />
            <Tab  sx={{fontSize:15}} label="Check Limit" value="3" />

            <Tab sx={{fontSize:15}} label="Noise Removal" value="4" />
          </TabList>
        </Box>
        <TabPanel value="1" ><RawData /></TabPanel>
        <TabPanel value="2"><SliceData /></TabPanel>
        <TabPanel value="3"><Limit /></TabPanel>
        <TabPanel value="4"><NoiseRemoval /></TabPanel>

</TabContext>*/}
      </Box>
      {getLoader ? <GlobalLoader /> : null}
    </div>
  );
}

export default Cleandata;
