import React from 'react';
import './css/GettingStarted.css'; // Import your CSS file for styling
import image1 from "../images/3kpi.png";
import image2 from "../images/4evaluate.png";

function KeyIndicatorsStep() {
  return (
    <div className="project-details">
      <h2>Key Indicators Step</h2>
      <p>
        In the "Key Indicators" step, you can define and evaluate key performance indicators (KPIs) for your machine learning model. This step is crucial for building and assessing the effectiveness of your model. Below are instructions for both options:
      </p>

      <h4>Define Indicators Definition</h4>
      <p>
        <strong>Key Indicators Table:</strong> In the table displayed, you will see a list of key indicators loaded from your CSV file. This table provides valuable information about each indicator.
      </p>
      <p>
        <strong>Select Independent Variables:</strong> Identify the indicators that represent the independent variables in your model. To do this, click the checkboxes next to the indicators you want to designate as independent variables.
      </p>
      <p>
        <strong>Select Dependent Variables:</strong> Indicate which indicators represent the dependent variables that your model aims to predict. Click the checkboxes next to the indicators you want to designate as dependent variables.
      </p>
      <p>
        <strong>Save Your Indicators:</strong> After selecting the appropriate variables, click the "Save" button to confirm your choices.
      </p>

      <img src={image1} className='imagestyling'/>


      <h4>Evaluate Indicators</h4>
      <p>
        <strong>Click "Evaluate Indicators":</strong> To assess the relationships between your independent and dependent variables, click the "Evaluate Indicators" button.
      </p>
      <p>
        <strong>Variable Rankings Plot:</strong> The software will generate a plot that illustrates the ranking or importance of each dependent variable concerning the independent variables. This visualization can help you understand which variables have the most significant impact on your outcomes.
      </p>
      <p>
        <strong>Interpret the Plot:</strong> Analyze the plot to gain insights into your data. Variables positioned higher in the ranking are more influential, while those lower in the ranking have less impact.
      </p>
      <p>
        <strong>Refine Your Model:</strong> Use the insights from the variable rankings to make informed decisions about refining your machine learning model or optimizing your processes.
      </p>
      <img src={image2} className='imagestyling'/>

    </div>
  );
}

export default KeyIndicatorsStep;
