import React, { useState } from "react";
import { Checkbox, Typography } from "@mui/material";
import Plot from "react-plotly.js";
import { baseURL } from "../Config/config";
import GlobalLoader from "./CommonC/GlobalLoader";
import Box from "@mui/material/Box";
import Projectcolors from "../Utils/Colors";
import { Grid, Paper, TextField } from "@mui/material";
import Radio from "@mui/material/Radio";


function Spearman() {
  const [ranks, setRanks] = React.useState([]);
  const [independent, setIndependent] = React.useState([]);
  const [dependent, setdependent] = React.useState([]);
  const [getLoader , setGetLoader] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(0);

  

  const handleChange = (item,position) => {
    setSelectedValue(position);
  };




    React.useEffect(() => {
        getSpearman()
      }, []);

      const getSpearman = () => { 
        setGetLoader(true);
        const ProjectID = localStorage.getItem("projectID")
        fetch(`${baseURL}/getSpearman?projectID=${ProjectID}`)
          .then((res) => res.json())
          .then((myresponse) => {
           setRanks(myresponse.allranks);
           setIndependent(myresponse.independent);
           setdependent(myresponse.dependent);
           setGetLoader(false);
           
           console.log(myresponse);

          })
          .catch((err)=> {
            setGetLoader(false);
            console.log("error",err)})
       }

        
       
  return (
    <div style={{display:"flex", flexDirection:"row",paddingRight:"10px",justifyContent:"space-between"}}>
    <Box component ={Paper} elevation={5} sx={{display:"flex", flexDirection:"column",marginTop:0,border:"1px solid #0078C9",overflowY:"scroll",height:"500px",width:"240px"}}>
      {dependent
        ? dependent.map((item, i) => {
            return (
              <div style={{display:"flex",alignItems:"center",marginLeft:10}}>
                 <Box
                        sx={{
                          width: 260,
                          maxWidth: 240,
                          display: "flex",
                          flexDirection: "row",
                          padding: 1,
                        }}
                      >
                        <div>
                          <Radio
                            key={i}
                            onChange={() => handleChange(item, i)}
                            checked={selectedValue == i}
                            value={i}
                            size="medium"
                          />
                        </div>
                        <div style={{ padding: "5.5px" }}>
                          <Typography
                            sx={{
                              width: 90,
                              display: "inline",
                              fontSize: 12,
                            }}
                          >
                            {item}
                          </Typography>
                        </div>
                      </Box>
              </div>
            );
          })
        : null}
 </Box>
 <Box component ={Paper} elevation={5} sx={{marginLeft:"30px",border:"1px solid #0078C9",height:"500px",overflowY:"scroll",width:"710px",padding:"3px"}}>
      <Plot
        data={[
          {
            
              x: independent,
              y: ranks[selectedValue],
              name: 'Trace1',
              type: 'bar',
              width:0.30,
            
          },
                ]}
        layout={{
          width: 600,
          height: 500,
          title: "MB Ranking Plot",
          barmode: 'relative',
          name: "",
          borderradius: 5,
          yaxis: {
            automargin: true
          },
          xaxis: {
            automargin: true
          }
                }}
                config = {{
                  displaylogo:false
                }}
        
      />
      </Box>
   {
            getLoader ? <GlobalLoader /> : null
          }
    </div>
  )
}

export default Spearman