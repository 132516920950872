import React, { useState ,useEffect} from 'react';
import { Container, TextField, Button, Paper, Typography } from '@material-ui/core';
import Navbar from './CommonC/Navbar';
import Projectcolors from "../Utils/Colors";
import Input from '@material-ui/core/Input';
import GlobalLoader from "./CommonC/GlobalLoader";
import { baseURL } from "../Config/config";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import SelexSolNavbar from './CommonC/SelexSolNavbar';





function GPT() {
  const [userInput, setUserInput] = useState('');
  const [chat, setChat] = useState([]);
  const [getLoader, setGetLoader] = React.useState(false);
   const [falsewarning, setfalsewarning] = React.useState(false);


  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  
  const postQuestion = () => {
    setGetLoader(true);
    try {
      fetch(`${baseURL}/postQuestion`, {
        method: "POST",
        body: JSON.stringify({
          question: userInput,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((result) => {
          console.log(result)
          if(result.iserror == 1){
            setfalsewarning(true)
            setGetLoader(false);

          }
          //props.handleNextStep();
          else{
          const userMessage = { text: [userInput], isUser: true };
          setChat((prevChat) => [...prevChat, userMessage]);
          setUserInput('');
          const botMessage = { text: result.answer.split('\n'), isUser: false };
          setChat((prevChat) => [...prevChat, botMessage]);
          setGetLoader(false);
          console.log(botMessage);

          }

        })
        .catch((error) => {
          setGetLoader(false);
          console.error("Error:", error);
        });
    } catch (error) {
      setGetLoader(false);
      console.log("Error", error);
    }

  }
  
  const handleSendMessage = () => {
    if (userInput.trim() === '') return;

    // Add user's question to the chat
    const userMessage = { text: userInput, isUser: true };
    setChat((prevChat) => [...prevChat, userMessage]);
    setUserInput('');

    // Simulate ChatGPT response
    setTimeout(() => {
      const response = 'Sample response from ChatGPT';
      const botMessage = { text: response, isUser: false };
      setChat((prevChat) => [...prevChat, botMessage]);
    }, 1000);
  };
  console.log(chat)

  const handlefalsewarningClose = () => {
    setfalsewarning(false);
  };

  const getMessages = () => {
    axios.get(
      `${baseURL}/getMessages`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {console.log(res)})
    }

  useEffect(() => {
    getMessages();
  }, []);

  return (
    <Container maxWidth="md">
       <Dialog
        open={falsewarning}
        onClose={handlefalsewarningClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ color: "red", fontSize: "13px" }}
        >
          {"Error while getting response."}
        </DialogTitle>
      </Dialog>

        <SelexSolNavbar />
      <Typography variant="h3" align="center" gutterBottom style={{marginTop:'30px',fontWeight:'bold'}}>
        SelexGPT
        </Typography>
      <Paper elevation={3} style={{ padding: '10px', minHeight: '500px', maxHeight: '500px', overflowY: 'auto' , marginTop:'10px'  }}>
        {chat.map((message, index) => (
          <div key={index} style={{ textAlign: message.isUser ? 'right' : 'left', marginBottom: '10px', color: message.isUser ? 'white' : 'black' }}>
            <div style={{ display: 'inline-block', padding: '10px', borderRadius: '10px', backgroundColor: message.isUser ? Projectcolors.primary : '#f0f0f0' , fontSize : '14px' , maxWidth:'650px' }}>
              
              {message.text.map((line, index) => (
        <Typography key={index} style={{padding:'2px',fontSize : '14px'}}>
          {/* You can apply formatting here */}
          {line}
        </Typography>
      ))}
            </div>
          </div>
        ))}
      </Paper>
      <div style={{ marginTop: '10px' }}>
      <Typography variant="h4" gutterBottom style={{marginTop:'14px' , color:'black' , fontSize:'16px'}}>
        Type your Question 
        </Typography>
        <TextField
          variant="outlined"
          fullWidth
          multiline
          minRows={3}
          value={userInput}
          onChange={handleInputChange}
          placeholder=""
          InputProps={{ style: { fontSize: '14px' } }} // Adjust the font size as needed

        />
        <Button
        onClick={() => postQuestion()}
          variant="contained"
          color="primary"
          fullWidth
          //onClick={handleSendMessage}
          style={{ marginTop: '10px'  , fontSize:'14px'}}
        >
          Send
        </Button>
      </div>
      {getLoader ? <GlobalLoader /> : null}
    </Container>
  );
}

export default GPT;
