import React from 'react'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Projectcolors from "../../Utils/Colors";
import HomeIcon from '@mui/icons-material/Home';
import HelpIcon from '@mui/icons-material/Help';
import Selexlogo from '../../images/SelexMath-2.png';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { create } from '../../Store/projectPermission/permssionReducer';
import Tooltip from "@mui/material/Tooltip";
import gpticon from "../../images/gpticon.png";






function SelexMathNavbar(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch()


  function logout(){
    localStorage.removeItem("usertoken");
    localStorage.removeItem("projectID");
    localStorage.removeItem("projectName");
    dispatch(create([{empty:true}]))

    if (props.admin){
      navigate("/admin");

    }
    else{
      navigate("/login");
    }
  }
  return (
    <div style={{paddingBottom:"60px"}}>    
    <Box>
    <AppBar  sx={{backgroundColor:Projectcolors.primary,height:60}} >
      <Toolbar sx={{position:"sticky"}}>
      <a href="/" style={{padding:'10px'}}><img alt="logo" style = {{ height:'45px',width:'120px',marginTop:'0px',marginleft:'3px',background:'white'}} src = {Selexlogo}/></a>


        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          href="/selexmath"
          
          
        >
          <HomeIcon  sx={{fontSize:33,borderLeft: '0.01em solid white',marginLeft:0.5,paddingLeft:1}} />
        </IconButton>
      
              <div style={{display: "block",
          marginLeft: "35%",
          marginRight: "auto"}}
 >      
        <Typography sx={{fontSize:22,color:Projectcolors.white,display:'inline',marginTop:2}}>SelexMath™</Typography>
      </div>

      <Tooltip
                                title={
                                  <Typography fontSize={15}>
                                    SelexGPT
                                  </Typography>
                                }
                                sx={{ fontSize: 15 }}
                              >
                                 <IconButton
          edge="end"
          color="inherit"
          aria-label="menu"
          href="/gpt"
          target="_blank"
        >
          <img
                alt="model"
                src={gpticon}
                style={{
                  marginRight: 10,
                  height:34,
                  width:34
                }}
              />
        </IconButton>
                                
                              </Tooltip>
     
                              <Tooltip
                                title={
                                  <Typography fontSize={15}>
                                    Help
                                  </Typography>
                                }
                                sx={{ fontSize: 15 }}
                              >
       
      <IconButton
          edge="end"
          color="inherit"
          aria-label="menu"
          href="/selexmath-help"
          target="_blank"
        >

          <HelpIcon  sx={{fontSize:"24px",marginLeft:"20px",marginRight:"10px"}} />
        </IconButton>
        </Tooltip>

        <Tooltip
                                title={
                                  <Typography fontSize={15}>
                                    Logout
                                  </Typography>
                                }
                                sx={{ fontSize: 15 }}
                              >
        <IconButton
          edge="end"
          color="inherit"
          aria-label="menu"
        >
          <LogoutIcon onClick={logout}  sx={{fontSize:"24px",marginLeft:"20px",marginRight:"10px"}} />
        </IconButton>
        </Tooltip>


      </Toolbar>
    </AppBar>
  </Box>
</div>
  )
}

export default SelexMathNavbar