import React from 'react';
import './css/GettingStarted.css'; // Import your CSS file for styling
import image1 from "../images/2download.png";

function ImportData() {
  return (
    <div className="project-details">
      <h2>Uploading CSV Files</h2>
      <p>
        In this step, you can easily upload your data in CSV (Comma-Separated Values) format. CSV files are commonly used for importing structured data, such as time series data. Here's how to upload your CSV file:
      </p>

      <ol>
        <li>
          <strong>Click the "Choose File" Button:</strong> To upload your CSV file, click the "Choose File" button. This will open a file selection dialog on your computer.
        </li>
        <li>
          <strong>Select Your CSV File:</strong> Navigate to the location of your CSV file on your computer and select it.
        </li>
        <li>
          <strong>Confirm Your Selection:</strong> Once you've selected the file, confirm your choice by clicking "Open" (or an equivalent option on your operating system).
        </li>
      </ol>
      <img src={image1} className='imagestyling'/>


      <p>
        <strong>To help you get started, we provide example templates that illustrate the acceptable CSV file format for time series data. You can use these templates as a reference to ensure your data is properly formatted. Click on the links below to download the templates:</strong>
      </p>

      <h4>X's and Y's Prediction</h4>
      <p>
        <strong>Structure of X's and Y's Prediction CSV:</strong> X's and Y's prediction datasets are commonly used in supervised machine learning, where "X" represents the independent variables (features), and "Y" represents the dependent variable (the target or prediction).
      </p>
      <p>
        <strong>Independent Variables (X):</strong> The CSV file will contain one or more columns representing the independent variables or features used for prediction. Each column typically represents a different feature or attribute of the data.
      </p>
      <p>
        <strong>Dependent Variable (Y):</strong> There is usually a single or multiple columns, labeled as the dependent variable (or target), which contains the values you want to predict. This column holds the ground truth or actual values that your machine learning model will aim to predict.
      </p>
      <p>
        <strong>Example Template 1:</strong> This template showcases a basic structure for CSV format.
      </p>

      <h4>Time Series Prediction</h4>
      <p>
        <strong>Structure of Time Series Data CSV:</strong> Time series data is a sequence of data points collected or recorded at regular time intervals. Time series data CSV files are structured to represent this temporal nature of data.
      </p>
      <p>
        <strong>Timestamp:</strong> Usually, the first column contains timestamps or time periods when data was recorded. These timestamps can be in various formats, such as dates, hours, minutes, or seconds, depending on the frequency of data collection.
      </p>
      <p>
        <strong>Value:</strong> The second column typically contains the actual data values corresponding to each timestamp. These values can represent various types of data, such as stock prices, temperature readings, sales figures, or any other variable that changes over time.
      </p>
      <p>
        <strong>Additional Columns (Optional):</strong> Depending on the specific use case, there may be additional columns that provide context or metadata for the time series data. For example, you might have columns for location, product ID, or other relevant information.
      </p>
      <p>
        <strong>Example Template 2 available for download:</strong> This template showcases a basic structure for time series data in CSV format.
      </p>

      <p>
        <strong>Proceed to the Next Step</strong>
      </p>
      <p>
        After you have successfully uploaded your CSV file and ensured its format is correct, click the "Next Step" button to proceed to the next stage of data processing. The next step will allow you to define key performance indicators (KPIs), clean the data, and build AI models.
      </p>
    </div>
  );
}

export default ImportData;
