import React, { useEffect } from "react";
import Paper from "@mui/material/Paper";
import Plot from "react-plotly.js";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Projectcolors from "../Utils/Colors";
import { ListItemAvatar, Typography } from "@mui/material";
import LinePlotRaw from "./LinePlotRaw";
import { baseURL } from "../Config/config";
import GlobalLoader from "./CommonC/GlobalLoader";
import { Checkbox } from "@mui/material";
import { Button } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import axios from "axios";

const menuprops = {
  PaperProps: {
    style: {
      maxHeight: 250,
    },
  },
};

function ScatterPlotMethod(props) {
  const [rawdata, setRawData] = React.useState([]);

  const [lineplotdata, setLineplotdata] = React.useState([]);

  const [lineplotx, setLineplotx] = React.useState([]);
  const [x, setX] = React.useState("");
  const [y, setY] = React.useState("");
  const [ylabel, setYLabel] = React.useState("Y axis");
  const [xlabelscatter, setXscatter] = React.useState();
  const [ylabelscatter, setYscatter] = React.useState();
  const [alltagname, setTagname] = React.useState([]);
  const [getLoader, setGetLoader] = React.useState(false);
  const [timeseries, setTimeseries] = React.useState(0);
  const [alllinear_ys, setalllinear_ys] = React.useState([]);
  const [linear_ab, setlinear_ab] = React.useState([]);
  const [allexp_ys, setallexp_ys] = React.useState([]);
  const [exp_ab, setexp_ab] = React.useState([]);
  const [alllogsy, setalllogsy] = React.useState([]);
  const [logsab, setlogsab] = React.useState([]);
  const [linear, setlinear] = React.useState(false);
  const [exp, setexp] = React.useState(false);
  const [log, setlog] = React.useState(false);
  const [quad, setquad] = React.useState(false);
  const [hyper, sethyper] = React.useState(false);
  const [linearequation, setlinearequation] = React.useState("");
  const [expequation, setexpequation] = React.useState("");
  const [logarithmicequation, setlogarithmicequation] = React.useState("");
  const [hyperequation, sethyperequation] = React.useState("");
  const [quadraticequation, setquadraticequation] = React.useState("");

  const [allhyperbolic, setallhyperbolic] = React.useState([]);
  const [hyperbolicab, sethyperbolicab] = React.useState([]);
  const [allquadratic, setallquadratic] = React.useState([]);
  const [quadab, setquadab] = React.useState([]);

  const [checkedState, setCheckedState] = React.useState([
    false,
    false,
    false,
    false,
    false,
  ]);
  const [plotlydata, setplotlydata] = React.useState([
    {
      x: lineplotdata[x],
      y: lineplotdata[y],
      type: "scatter",
      mode: "markers",
      marker: { color: Projectcolors.primary },
      name: "Real Data",
    },
  ]);

  const handledropdownx = (e) => {
    
    setX(e.target.value);
    var xlabel = rawdata[e.target.value][1].length > 35? rawdata[e.target.value][1].substring(0, 25) + "...": rawdata[e.target.value][1]
    setXscatter(xlabel);
    if (plotlydata.length === 1) {
      const myFirstList = [...plotlydata];
      const artwork = myFirstList.find((a) => a.name === "Real Data");
      artwork.x = lineplotdata[e.target.value];
      artwork.y = lineplotdata[y];
      setplotlydata(myFirstList);
    }
    if (plotlydata.length > 1) {
      const myFirstList = [...plotlydata];
      const artwork = myFirstList.find((a) => a.name === "Real Data");
      artwork.x = lineplotdata[e.target.value];
      artwork.y = lineplotdata[y];
      setplotlydata(myFirstList);
      if (linear) {
        const mysecondList = [...plotlydata];
        const artwork = mysecondList.find((a) => a.name === "Linear");
        artwork.x = lineplotdata[e.target.value];
        artwork.y = alllinear_ys[e.target.value][y];
        setplotlydata(mysecondList);
        setlinearequation(linear_ab[e.target.value][y]);
      }
      if (exp) {
        const mysecondList = [...plotlydata];
        const artwork = mysecondList.find((a) => a.name === "Exponential");
        artwork.x = lineplotdata[e.target.value];
        artwork.y = allexp_ys[e.target.value][y];
        setplotlydata(mysecondList);
        setexpequation(exp_ab[e.target.value][y]);
      }
      if (log) {
        const mysecondList = [...plotlydata];
        const artwork = mysecondList.find((a) => a.name === "Logarithmic");
        artwork.x = lineplotdata[e.target.value];
        artwork.y = alllogsy[e.target.value][y];
        setplotlydata(mysecondList);
        setlogarithmicequation(logsab[e.target.value][y]);
      }
      if (hyper) {
        const mysecondList = [...plotlydata];
        const artwork = mysecondList.find((a) => a.name === "Hyperbolic");
        artwork.x = lineplotdata[e.target.value];
        artwork.y = allhyperbolic[e.target.value][y];
        setplotlydata(mysecondList);
        sethyperequation(hyperbolicab[e.target.value][y]);
      }
      if (quad) {
        const mysecondList = [...plotlydata];
        const artwork = mysecondList.find((a) => a.name === "Quadratic");
        artwork.x = lineplotdata[e.target.value];
        artwork.y = allquadratic[e.target.value][y];
        setplotlydata(mysecondList);
        setquadraticequation(quadab[e.target.value][y]);
      }
    }
  };
  const handledropdowny = (e) => {
    setY(e.target.value);
    var ylabel = rawdata[e.target.value][1].length > 35? rawdata[e.target.value][1].substring(0, 25) + "...": rawdata[e.target.value][1]
    setYscatter(ylabel);
    if (plotlydata.length === 1) {
      const myFirstList = [...plotlydata];
      const artwork = myFirstList.find((a) => a.name === "Real Data");
      artwork.x = lineplotdata[x];
      artwork.y = lineplotdata[e.target.value];
      setplotlydata(myFirstList);
    } else if (plotlydata.length > 1) {
      const myFirstList = [...plotlydata];
      const artwork = myFirstList.find((a) => a.name === "Real Data");
      artwork.x = lineplotdata[x];
      artwork.y = lineplotdata[e.target.value];
      setplotlydata(myFirstList);
      if (linear) {
        const mysecondList = [...plotlydata];
        const artwork = mysecondList.find((a) => a.name === "Linear");
        artwork.x = lineplotdata[x];
        artwork.y = alllinear_ys[x][e.target.value];
        setplotlydata(mysecondList);
        setlinearequation(linear_ab[x][e.target.value]);
      }
      if (exp) {
        const mysecondList = [...plotlydata];
        const artwork = mysecondList.find((a) => a.name === "Exponential");
        artwork.x = lineplotdata[x];
        artwork.y = allexp_ys[x][e.target.value];
        setplotlydata(mysecondList);
        setexpequation(exp_ab[x][e.target.value]);
      }
      if (log) {
        const mysecondList = [...plotlydata];
        const artwork = mysecondList.find((a) => a.name === "Logarithmic");
        artwork.x = lineplotdata[x];
        artwork.y = alllogsy[x][e.target.value];
        setplotlydata(mysecondList);
        setlogarithmicequation(logsab[x][e.target.value]);
      }
      if (hyper) {
        const mysecondList = [...plotlydata];
        const artwork = mysecondList.find((a) => a.name === "Hyperbolic");
        artwork.x = lineplotdata[e.target.value];
        artwork.y = allhyperbolic[x][e.target.value];
        setplotlydata(mysecondList);
        sethyperequation(hyperbolicab[x][e.target.value]);
      }
      if (quad) {
        const mysecondList = [...plotlydata];
        const artwork = mysecondList.find((a) => a.name === "Quadratic");
        artwork.x = lineplotdata[e.target.value];
        artwork.y = allquadratic[x][e.target.value];
        setplotlydata(mysecondList);
        setquadraticequation(quadab[x][e.target.value]);
      }
    }
  };

  useEffect(() => {
    getRawData();
  }, []);

  const getRawData = () => {
    setGetLoader(true);

    const ProjectID = localStorage.getItem("projectID");
    axios.get(
      `${baseURL}/getscatterplotdata?projectID=${ProjectID}&scatterplot=${1}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (res.data.code === 0){
          setGetLoader(false);
          console.log(res.data);
        }
        else{
        if (res.data.timeseries === 1) {
          setTimeseries(res.data.timeseries);
        } else {
          setTimeseries(res.data.timeseries);
          setRawData(res.data.raw_data);
          setalllinear_ys(res.data.alllinear_ys);
          setlinear_ab(res.data.linear_ab);
          setLineplotdata(res.data.lineplotdata);
          setLineplotx(res.data.lineplotdata[0]);
          setTagname(res.data.tag_name);
          setX(0);
          setY(1);
          setXscatter(alltagname[0]);
          setYscatter(alltagname[1]);
          setGetLoader(false);
          setTimeseries(res.data.timeseries);
          setallexp_ys(res.data.allexp_ys);
          setexp_ab(res.data.exp_ab);
          setalllogsy(res.data.alllogsy);
          setlogsab(res.data.logsab);
          setallhyperbolic(res.data.allhyperbolic);
          sethyperbolicab(res.data.hyperbolicab);
          setallquadratic(res.data.allquadratic);
          setquadab(res.data.quadab);
        }
      }
        
      
      })
      .catch((err) => {
        setGetLoader(false);
        console.log("caught it!", err);
      });
  };

  const NextStep = () => {
    props.handleNextStep();
  };
  const handleSelect = () => {
    if (xlabelscatter) {
      return <span style={{ color: "black" }}>{xlabelscatter}</span>;
    }
    return <span style={{ color: "#888888" }}>Select</span>;
  };

  const handleSelectY = () => {
    if (ylabelscatter) {
      return <span style={{ color: "black" }}>{ylabelscatter}</span>;
    }
    return <span style={{ color: "#888888" }}>Select</span>;
  };

  const handleOnChange = (value, check) => {
    //setchoosen(value)
    if (plotlydata.length === 1) {
      const myFirstList = [...plotlydata];
      const artwork = myFirstList.find((a) => a.name === "Real Data");
      artwork.x = lineplotdata[x];
      artwork.y = lineplotdata[y];
      setplotlydata(myFirstList);
    }
    if (value === 0) {
      if (check === false) {
        const checkedprev = [...checkedState];
        checkedprev[0] = true; // Problem: mutates list[0]
        setCheckedState(checkedprev);
        setlinear(true);

        const updateUsers = [
          ...plotlydata,
          {
            x: lineplotdata[x],
            y: alllinear_ys[x][y],
            type: "line",
            mode: "lines+text",
            marker: { color: "red" },
            name: "Linear",
            line: {
              dash: "dot",
            },
          },
        ];
        setplotlydata(updateUsers);
        setlinearequation(linear_ab[x][y]);
      } else {
        const checkedprev = [...checkedState];
        checkedprev[0] = false; // Problem: mutates list[0]
        setCheckedState(checkedprev);
        setlinear(false);

        setplotlydata(plotlydata.filter((a) => a.name !== "Linear"));
        setlinearequation("");
      }
    }

    if (value === 1) {
      if (check === false) {
        const checkedprev = [...checkedState];
        checkedprev[1] = true; // Problem: mutates list[0]
        setCheckedState(checkedprev);
        setexp(true);

        const updateUsers = [
          ...plotlydata,
          {
            x: lineplotdata[x],
            y: allexp_ys[x][y],
            type: "line",
            mode: "lines",
            marker: { color: "green" },
            name: "Exponential",
            line: {
              dash: "dot",
            },
          },
        ];
        setplotlydata(updateUsers);
        setexpequation(exp_ab[x][y]);
      } else {
        const checkedprev = [...checkedState];
        checkedprev[1] = false; // Problem: mutates list[0]
        setCheckedState(checkedprev);
        setexp(false);
        setplotlydata(plotlydata.filter((a) => a.name !== "Exponential"));
        setexpequation("");
      }
    }

    if (value === 2) {
      if (check === false) {
        const checkedprev = [...checkedState];
        checkedprev[2] = true; // Problem: mutates list[0]
        setCheckedState(checkedprev);
        setlog(true);

        const updateUsers = [
          ...plotlydata,
          {
            x: lineplotdata[x],
            y: alllogsy[x][y],
            type: "line",
            mode: "lines",
            marker: { color: "yellow" },
            name: "Logarithmic",
            line: {
              dash: "dot",
            },
          },
        ];
        setplotlydata(updateUsers);
        setlogarithmicequation(logsab[x][y]);
      } else {
        const checkedprev = [...checkedState];
        checkedprev[2] = false; // Problem: mutates list[0]
        setCheckedState(checkedprev);
        setlog(false);
        setplotlydata(plotlydata.filter((a) => a.name !== "Logarithmic"));
        setlogarithmicequation("");
      }
    }

    if (value === 3) {
      if (check === false) {
        const checkedprev = [...checkedState];
        checkedprev[3] = true; // Problem: mutates list[0]
        setCheckedState(checkedprev);
        sethyper(true);

        const updateUsers = [
          ...plotlydata,
          {
            x: lineplotdata[x],
            y: allhyperbolic[x][y],
            type: "line",
            mode: "lines",
            marker: { color: "lightblue" },
            name: "Hyperbolic",
            line: {
              dash: "dot",
            },
          },
        ];
        setplotlydata(updateUsers);
        sethyperequation(hyperbolicab[x][y]);
      } else {
        const checkedprev = [...checkedState];
        checkedprev[3] = false; // Problem: mutates list[0]
        setCheckedState(checkedprev);
        sethyper(false);
        setplotlydata(plotlydata.filter((a) => a.name !== "Hyperbolic"));
        sethyperequation("");
      }
    }

    if (value === 4) {
      if (check === false) {
        const checkedprev = [...checkedState];
        checkedprev[4] = true; // Problem: mutates list[0]
        setCheckedState(checkedprev);
        setquad(true);

        const updateUsers = [
          ...plotlydata,
          {
            x: lineplotdata[x],
            y: allquadratic[x][y],
            type: "line",
            mode: "lines",
            marker: { color: "orange" },
            name: "Quadratic",
            line: {
              dash: "dot",
            },
          },
        ];
        setplotlydata(updateUsers);
        setquadraticequation(quadab[x][y]);
      } else {
        const checkedprev = [...checkedState];
        checkedprev[4] = false; // Problem: mutates list[0]
        setCheckedState(checkedprev);
        setquad(false);
        setplotlydata(plotlydata.filter((a) => a.name !== "Quadratic"));
        setquadraticequation("");
      }
    }

   
  };

  return (
    <div>
      {timeseries ? (
        <div>
          <Box
            component={Paper}
            elevation={10}
            m="auto"
            sx={{
              height: 600,
              width: 1350,
              marginTop: "20px",
              padding: 4,
            }}
          >
            <div style={{ margin: "auto", width: "100%", textAlign: "center" }}>
              <Typography
                sx={{
                  fontSize: 17,
                  color: Projectcolors.primary,
                  marginTop: 2,
                }}
              >
                Data Includes Time Series.
              </Typography>{" "}
            </div>
          </Box>
        </div>
      ) : (
        <div>
          <Box
            component={Paper}
            elevation={10}
            m="auto"
            sx={{
              height: 600,
              width: 1350,
              marginTop: "20px",
              padding: 4,
            }}
          >
            <div>
              <Box
                m="auto"
                sx={{
                  display: "flex",
                  flexDirection: "row",

                  justifyContent: "center",
                }}
              >
                <Box component={Paper} elevation={4} sx={{ marginLeft: 1 }}>
                  {plotlydata.length > 1 ? (
                    <Plot
                      data={plotlydata}
                      /** {
                      x: lineplotdata[x],
                      y: alllinear_ys[x][y],
                      type: "line",
                      mode: "lines",
                      marker: { color: "red" },
                      name: "Linear",
                    }, */

                      layout={{
                        margin: { b: 50 },
                        width: 700,
                        height: 320,
                        title: {
                          font: {
                            size: 18,
                          },
                          xref: "paper",
                          x: 0.05,
                        },
                        xaxis: {
                          title: {
                            text: xlabelscatter,
                            font: {
                              size: 14,
                              color: "#000",
                            },
                          },
                          linewidth: 1,
                          ticklen: 10,
                        },
                        yaxis: {
                          title: {
                            text: ylabelscatter,
                            font: {
                              size: 14,
                              color: "#000",
                            },
                          },
                          linewidth: 1,
                          ticklen: 10,
                        },
                      }}
                      config={{
                        displaylogo: false,
                      }}
                    />
                  ) : (
                    <Plot
                      data={[
                        {
                          x: lineplotdata[x],
                          y: lineplotdata[y],
                          type: "scatter",
                          mode: "markers",
                          marker: { color: Projectcolors.primary },
                          name: "Real Data",
                        },
                      ]}
                      /** {
                    x: lineplotdata[x],
                    y: alllinear_ys[x][y],
                    type: "line",
                    mode: "lines",
                    marker: { color: "red" },
                    name: "Linear",
                  }, */

                      layout={{
                        margin: { b: 50 },
                        width: 700,
                        height: 350,
                        title: {
                          font: {
                            size: 18,
                          },
                          xref: "paper",
                          x: 0.05,
                        },
                        xaxis: {
                          title: {
                            text: xlabelscatter,
                            font: {
                              size: 14,
                              color: "#000",
                            },
                          },
                          linewidth: 1,
                          ticklen: 10,
                        },
                        yaxis: {
                          title: {
                            text: ylabelscatter,
                            font: {
                              size: 14,
                              color: "#000",
                            },
                          },
                          linewidth: 1,
                          ticklen: 10,
                        },
                      }}
                      config={{
                        displaylogo: false,
                      }}
                    />
                  )}
                  <Box sx={{ padding: 4 }}>
                    {linear ? (
                      <Typography sx={{ fontSize: 14, mt: 1 , fontWeight:'bold' }}>
                        Linear Equation: {linearequation}{" "}
                      </Typography>
                    ) : (
                      <Typography></Typography>
                    )}
                    {exp ? (
                      <Typography sx={{ fontSize: 14, mt: 1 ,fontWeight:'bold'}}>
                        Exponential Equation: {expequation}{" "}
                      </Typography>
                    ) : (
                      <Typography></Typography>
                    )}
                    {log ? (
                      <Typography sx={{ fontSize: 14, mt: 1 ,fontWeight:'bold' }}>
                        Logarithmic Equation: {logarithmicequation}{" "}
                      </Typography>
                    ) : (
                      <Typography></Typography>
                    )}
                    {hyper ? (
                      <Typography sx={{ fontSize: 14, mt: 1 ,fontWeight:'bold'}}>
                        Hyperbolic Equation: {hyperequation}{" "}
                      </Typography>
                    ) : (
                      <Typography></Typography>
                    )}
                    {quad ? (
                      <Typography sx={{ fontSize: 14, mt: 1 , fontWeight:'bold' }}>
                        Quadratic Equation: {quadraticequation}{" "}
                      </Typography>
                    ) : (
                      <Typography></Typography>
                    )}
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: 450,
                    width: 210,
                    marginLeft: 4,
                    padding: 2,
                  }}
                  component={Paper}
                  elevation={10}
                >
                  <Typography
                    sx={{ ml: 1.2, fontSize: "13px", fontWeight: 500 }}
                  >
                    X axis
                  </Typography>
                  <FormControl
                    sx={{ m: 1, mt: 0.7, minWidth: 150, height: 40 }}
                  >
                    <Select
                      defaultValue="AAAA"
                      // labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      // label="Age"
                      label=""
                      placeholder="Placeholder"
                      onChange={handledropdownx}
                      sx={{ fontSize: 11, color: "black" }}
                      MenuProps={menuprops}
                      renderValue={handleSelect}
                    >
                      {rawdata
                        ? rawdata.map((item, i) => {
                            return (
                              <MenuItem
                                value={i}
                                key={i}
                                sx={{ fontSize: "10px", color: "black" }}
                              >
                                {item[1]}
                              </MenuItem>
                            );
                          })
                        : null}
                    </Select>
                  </FormControl>
                  <Typography
                    sx={{ ml: 1.2, mt: 1.5, fontSize: "13px", fontWeight: 500 }}
                  >
                    Y axis
                  </Typography>
                  <FormControl
                    sx={{ m: 1, mt: 0.7, minWidth: 150, maxHeight: 40 }}
                  >
                    {/* <InputLabel
                sx={{ fontSize: 12 }}
                id="demo-simple-select-helper-label"
              >
                Y axis
              </InputLabel> */}
                    <Select
                      defaultValue="AAA"
                      id="demo-simple-select-helper"
                      onChange={handledropdowny}
                      sx={{ fontSize: 11 }}
                      MenuProps={menuprops}
                      renderValue={handleSelectY}
                    >
                      {rawdata
                        ? rawdata.map((item, i) => {
                            return (
                              <MenuItem
                                value={i}
                                key={i}
                                sx={{ fontSize: "10px" }}
                              >
                                {item[1]}
                              </MenuItem>
                            );
                          })
                        : null}
                    </Select>
                  </FormControl>
                  <Box sx={{ mt: 2 }}>
                    <Checkbox
                      color="primary"
                      inputProps={{
                        "aria-label": "select all desserts",
                      }}
                      sx={{ display: "inline" }}
                      size="medium"
                      key={0}
                      value={"linear"}
                      onChange={() => handleOnChange(0, checkedState[0])}
                    />
                    <Typography sx={{ fontSize: "12px", display: "inline" }}>
                      Linear
                    </Typography>
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <Checkbox
                      color="primary"
                      inputProps={{
                        "aria-label": "select all desserts",
                      }}
                      sx={{ display: "inline" }}
                      size="medium"
                      key={1}
                      value={"exponential"}
                      onChange={() => handleOnChange(1, checkedState[1])}
                    />
                    <Typography sx={{ fontSize: "12px", display: "inline" }}>
                      Exponential
                    </Typography>
                  </Box>

                  <Box sx={{ mt: 2 }}>
                    <Checkbox
                      color="primary"
                      inputProps={{
                        "aria-label": "select all desserts",
                      }}
                      sx={{ display: "inline" }}
                      size="medium"
                      key={2}
                      value={"exponential"}
                      onChange={() => handleOnChange(2, checkedState[2])}
                    />
                    <Typography sx={{ fontSize: "12px", display: "inline" }}>
                      Logarithmic
                    </Typography>
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <Checkbox
                      color="primary"
                      inputProps={{
                        "aria-label": "select all desserts",
                      }}
                      sx={{ display: "inline" }}
                      size="medium"
                      key={3}
                      value={"exponential"}
                      onChange={() => handleOnChange(3, checkedState[3])}
                    />
                    <Typography sx={{ fontSize: "12px", display: "inline" }}>
                      Hyperbolic
                    </Typography>
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <Checkbox
                      color="primary"
                      inputProps={{
                        "aria-label": "select all desserts",
                      }}
                      sx={{ display: "inline" }}
                      size="medium"
                      key={4}
                      value={"exponential"}
                      onChange={() => handleOnChange(4, checkedState[4])}
                    />
                    <Typography sx={{ fontSize: "12px", display: "inline" }}>
                      Quadratic
                    </Typography>
                  </Box>
                  <Button
                    onClick={NextStep}
                    mt={5}
                    sx={{
                      float: "left",
                      width: 20,
                      minWidth: 50,
                      height: "32px",
                      fontSize: "19px",
                      // marginTop: 2,
                      backgroundColor: Projectcolors.primary,
                      color: "black",
                      fontStyle: "bold",
                      padding: 0,
                      mt: 3,
                      marginLeft: 1,
                    }}
                    variant="contained"
                    size="large"
                  >
                    {" "}
                    <ChevronRightIcon
                      sx={{ fontSize: "19px", color: "white" }}
                    />
                  </Button>
                </Box>
              </Box>

              <Box
                sx={{ display: "flex", flex: 1, justifyContent: "center" }}
              ></Box>

              {getLoader ? <GlobalLoader /> : null}
            </div>
          </Box>
        </div>
      )}
    </div>
  );
}

export default ScatterPlotMethod;
