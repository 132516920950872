import React, {  useState } from "react";
import Box from "@mui/material/Box";
import Projectcolors from "../../Utils/Colors";
import { baseURL } from "../../Config/config";
import {
  
  TableBody,
  TableContainer,
  TableHead,
  Typography,
} from "@mui/material";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

import Divider from "@mui/material/Divider";
import { Button } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TableViewIcon from "@mui/icons-material/TableView";
import ScatterPlotIcon from "@mui/icons-material/ScatterPlot";
import Plot from "react-plotly.js";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import { Link } from "@mui/material";
import Radio from "@mui/material/Radio";
import DownloadIcon from "@mui/icons-material/Download";
import SsidChartOutlinedIcon from "@mui/icons-material/SsidChartOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import GlobalLoader from "../CommonC/GlobalLoader";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ShowChartIcon from '@mui/icons-material/ShowChart';

import "../../Styles/draggable.css";

function Validate(props) {
  const [validationdata, setValidationdata] = React.useState([]);

  const [showtable, setshowtable] = React.useState(true);
  const [status, setStatus] = React.useState([]);
  const [linestatus, setlineStatus] = React.useState([]);
  const [index, setIndex] = React.useState();
  const [parity, setParity] = React.useState(true);
  const [labels, setlabels] = React.useState([]);
  const [r2, setr2] = React.useState([]);
  const [q2, setq2] = React.useState([]);
  const [allr2, setallr2] = React.useState([]);
  const [allq2, setallq2] = React.useState([]);
  const [allmodel, setAllmodel] = React.useState([]);
  const [cat, setcat] = React.useState([]);
  const [selectedsnapshot, setselectedsnapshot] = React.useState(null);
  const [logistic, setLogistic] = React.useState(false);
  const [ranking, setRanking] = React.useState(false);
  const [r2q2, setr2q2] = React.useState(false);
  const initialState = new Array(50).fill(false);
  const [checkedState, setCheckedState] = useState(initialState);
  const [annstatus, setannstatus] = useState(false);
  const [getLoader, setGetLoader] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(0);
  const [ranks, setRanks] = React.useState([]);
  const [independent, setIndependent] = React.useState([]);
  const [dependent, setdependent] = React.useState([]);
  const [timeseries, setTimeseries] = React.useState([]);
  const [opengraph, setOpengraph] = React.useState(false);
  const [lineopengraph, setlineOpengraph] = React.useState(false);
  const [date_train, setDateTrain] = React.useState([]);
  const [date_test, setDateTest] = React.useState([]);
  const [ytrain, setytrain] = React.useState([]);
  const [ypred, setypred] = React.useState([]);
  const [forecasting, setforecasting] = React.useState([]);
  const [title, setTitle] = React.useState("");
  const [lineobserved, setlineobserved] = React.useState([]);
  const [linepredicted, setlinepredicted] = React.useState([]);
  const [lineplottitle, setlineplottitle] = React.useState([]);

  const handleChange = (item, position) => {
    setSelectedValue(position);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlegraphOpen = (title, dates, predictions) => {
    setDateTrain(dates[0]);
    setDateTest(dates[1]);
    setytrain(predictions[0]);
    setypred(predictions[1]);
    setforecasting(predictions[2]);
    setTitle(title);
    setOpengraph(true);
  };


  const handlelineOpen = (observed,predicted,title) => {
    setlineobserved(observed);
    setlinepredicted(predicted);
    setlineOpengraph(true);
    setlineplottitle(title);
  };

  const handlelineClose = () => {
    setlineOpengraph(false);
  };

  const handlegraphClose = () => {
    setOpengraph(false);
  };

  // Minimum resizable area
  var minWidth = 60;
  var minHeight = 40;

  // Thresholds
  var FULLSCREEN_MARGINS = -10;
  var MARGINS = 4;

  // End of what's configurable.
  var clicked = null;
  var onRightEdge, onBottomEdge, onLeftEdge, onTopEdge;

  var rightScreenEdge, bottomScreenEdge;

  var preSnapped;

  var b, x, y;

  var redraw = false;

  var pane = document.getElementById("pane");
  var ghostpane = document.getElementById("ghostpane");

  function setBounds(element, x, y, w, h) {
    element.style.left = x + "px";
    element.style.top = y + "px";
    element.style.width = w + "px";
    element.style.height = h + "px";
  }

  function hintHide() {
    setBounds(ghostpane, b.left, b.top, b.width, b.height);
    ghostpane.style.opacity = 0;
  }

  // Mouse events
  if (pane) {
    pane.addEventListener("mousedown", onMouseDown);
    document.addEventListener("mousemove", onMove);
    document.addEventListener("mouseup", onUp);

    // Touch events
    pane.addEventListener("touchstart", onTouchDown);
    document.addEventListener("touchmove", onTouchMove);
    document.addEventListener("touchend", onTouchEnd);
  }
  function onTouchDown(e) {
    onDown(e.touches[0]);
    e.preventDefault();
  }

  function onTouchMove(e) {
    onMove(e.touches[0]);
  }

  function onTouchEnd(e) {
    if (e.touches.length === 0) onUp(e.changedTouches[0]);
  }

  function onMouseDown(e) {
    onDown(e);
    e.preventDefault();
  }

  function onDown(e) {
    calc(e);

    var isResizing = onRightEdge || onBottomEdge || onTopEdge || onLeftEdge;

    clicked = {
      x: x,
      y: y,
      cx: e.clientX,
      cy: e.clientY,
      w: b.width,
      h: b.height,
      isResizing: isResizing,
      isMoving: !isResizing && canMove(),
      onTopEdge: onTopEdge,
      onLeftEdge: onLeftEdge,
      onRightEdge: onRightEdge,
      onBottomEdge: onBottomEdge,
    };
  }

  function canMove() {
    return x > 0 && x < b.width && y > 0 && y < b.height && y < 30;
  }

  function calc(e) {
    b = pane.getBoundingClientRect();
    x = e.clientX - b.left;
    y = e.clientY - b.top;

    onTopEdge = y < MARGINS;
    onLeftEdge = x < MARGINS;
    onRightEdge = x >= b.width - MARGINS;
    onBottomEdge = y >= b.height - MARGINS;

    rightScreenEdge = window.innerWidth - MARGINS;
    bottomScreenEdge = window.innerHeight - MARGINS;
  }

  var e;

  function onMove(ee) {
    calc(ee);

    e = ee;

    redraw = true;
  }

  function animate() {
    requestAnimationFrame(animate);

    if (!redraw) return;

    redraw = false;

    if (clicked && clicked.isResizing) {
      if (clicked.onRightEdge) pane.style.width = Math.max(x, minWidth) + "px";
      if (clicked.onBottomEdge)
        pane.style.height = Math.max(y, minHeight) + "px";

      if (clicked.onLeftEdge) {
        var currentWidth = Math.max(
          clicked.cx - e.clientX + clicked.w,
          minWidth
        );
        if (currentWidth > minWidth) {
          pane.style.width = currentWidth + "px";
          pane.style.left = e.clientX + "px";
        }
      }

      if (clicked.onTopEdge) {
        var currentHeight = Math.max(
          clicked.cy - e.clientY + clicked.h,
          minHeight
        );
        if (currentHeight > minHeight) {
          pane.style.height = currentHeight + "px";
          pane.style.top = e.clientY + "px";
        }
      }

      hintHide();

      return;
    }

    if (clicked && clicked.isMoving) {
      if (
        b.top < FULLSCREEN_MARGINS ||
        b.left < FULLSCREEN_MARGINS ||
        b.right > window.innerWidth - FULLSCREEN_MARGINS ||
        b.bottom > window.innerHeight - FULLSCREEN_MARGINS
      ) {
        // hintFull();
        setBounds(ghostpane, 0, 0, window.innerWidth, window.innerHeight);
        ghostpane.style.opacity = 0.2;
      } else if (b.top < MARGINS) {
        // hintTop();
        setBounds(ghostpane, 0, 0, window.innerWidth, window.innerHeight / 2);
        ghostpane.style.opacity = 0.2;
      } else if (b.left < MARGINS) {
        // hintLeft();
        setBounds(ghostpane, 0, 0, window.innerWidth / 2, window.innerHeight);
        ghostpane.style.opacity = 0.2;
      } else if (b.right > rightScreenEdge) {
        // hintRight();
        setBounds(
          ghostpane,
          window.innerWidth / 2,
          0,
          window.innerWidth / 2,
          window.innerHeight
        );
        ghostpane.style.opacity = 0.2;
      } else if (b.bottom > bottomScreenEdge) {
        // hintBottom();
        setBounds(
          ghostpane,
          0,
          window.innerHeight / 2,
          window.innerWidth,
          window.innerWidth / 2
        );
        ghostpane.style.opacity = 0.2;
      } else {
        hintHide();
      }

      if (preSnapped) {
        setBounds(
          pane,
          e.clientX - preSnapped.width / 2,
          e.clientY - Math.min(clicked.y, preSnapped.height),
          preSnapped.width,
          preSnapped.height
        );
        return;
      }

      // moving
      pane.style.top = e.clientY - clicked.y + "px";
      pane.style.left = e.clientX - clicked.x + "px";

      return;
    }

    // This code executes when mouse moves without clicking

    // style cursor
    if ((onRightEdge && onBottomEdge) || (onLeftEdge && onTopEdge)) {
      pane.style.cursor = "nwse-resize";
    } else if ((onRightEdge && onTopEdge) || (onBottomEdge && onLeftEdge)) {
      pane.style.cursor = "nesw-resize";
    } else if (onRightEdge || onLeftEdge) {
      pane.style.cursor = "ew-resize";
    } else if (onBottomEdge || onTopEdge) {
      pane.style.cursor = "ns-resize";
    } else if (canMove()) {
      pane.style.cursor = "move";
    } else {
      pane.style.cursor = "default";
    }
  }

  animate();

  function onUp(e) {
    calc(e);

    if (clicked && clicked.isMoving) {
      // Snap
      var snapped = {
        width: b.width,
        height: b.height,
      };

      if (
        b.top < FULLSCREEN_MARGINS ||
        b.left < FULLSCREEN_MARGINS ||
        b.right > window.innerWidth - FULLSCREEN_MARGINS ||
        b.bottom > window.innerHeight - FULLSCREEN_MARGINS
      ) {
        // hintFull();
        setBounds(pane, 0, 0, window.innerWidth, window.innerHeight);
        preSnapped = snapped;
      } else if (b.top < MARGINS) {
        // hintTop();
        setBounds(pane, 0, 0, window.innerWidth, window.innerHeight / 2);
        preSnapped = snapped;
      } else if (b.left < MARGINS) {
        // hintLeft();
        setBounds(pane, 0, 0, window.innerWidth / 2, window.innerHeight);
        preSnapped = snapped;
      } else if (b.right > rightScreenEdge) {
        // hintRight();
        setBounds(
          pane,
          window.innerWidth / 2,
          0,
          window.innerWidth / 2,
          window.innerHeight
        );
        preSnapped = snapped;
      } else if (b.bottom > bottomScreenEdge) {
        // hintBottom();
        setBounds(
          pane,
          0,
          window.innerHeight / 2,
          window.innerWidth,
          window.innerWidth / 2
        );
        preSnapped = snapped;
      } else {
        preSnapped = null;
      }

      hintHide();
    }

    clicked = null;
  }

  let newArray = [];
  let lineArray = [];

  const initializestatus = (data) => {
    data.allmodel[0].map((item, i) => {
      newArray.push(true);
      lineArray.push(false);
    });
    setStatus(newArray);
    setlineStatus(lineArray);
   

  };
  // const StyledTableRow = styled(TableRow)(({ theme }) => ({
  //   [`&.${tableRowClasses.root}`]: {
  //     height: "200px"
  //   },}))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    [`&.${tableRowClasses.root}`]: {
      height: "2px",
      minHeight: "2px",
      padding: `4px 10px !important`,
    },
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
      height: 2,
      // padding:0
    },
  }));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
      padding: `4px 10px !important`,
      fontSize: "11px",
      // height:1
    },
    [`&.${tableCellClasses.head}`]: {
      padding: `4px 10px !important`,
      fontSize: "11px",
      // height:1
    },
  }));

  React.useEffect(() => {
    getvalidationdata();
    getSpearman();
  }, []);

  const getvalidationdata = () => {
    setGetLoader(true);
    const ProjectID = localStorage.getItem("projectID");
    fetch(`${baseURL}/getvalidationdata?projectID=${ProjectID}`)
      .then((res) => res.json())
      .then((data) => {
        if (data.message === 0) {
          setMessage(true);
          setGetLoader(false);
        } else {
          setValidationdata(data.allmodel[0]);
          setAllmodel(data.allmodel);
          initializestatus(data);
          setlabels(data.labels);
          setallr2(data.r2);
          setallq2(data.q2);
          setr2(data.r2[0]);
          setq2(data.q2[0]);
          setcat(data.cat);
          setTimeseries(data.timeseries);
          setGetLoader(false);
          if (data.cat.includes(false) === false) {
            setLogistic(true);
            setParity(false);
          }
        }
      })
      .catch((err) => {
        setGetLoader(false);
        console.log("Err", err);
      });
  };
  const handleClick = (idx) => {
    setIndex(idx);
    let index = idx;
    const dataArray = Array.from(status);
    dataArray[idx] = !status[idx];
    setStatus(dataArray);

    const linedataArray = Array.from(linestatus);
    linedataArray[idx] = false;
    setlineStatus(linedataArray);
  };

  const handleLinePlot = (idx) => {
    //setIndex(idx);
    //let index = idx;
    const dataArray = Array.from(status);
    dataArray[idx] = false;
    setStatus(dataArray);
   
    const linedataArray = Array.from(linestatus);
    linedataArray[idx] = !linestatus[idx];
    setlineStatus(linedataArray);
  };

  const deleteSnap = (idx) => {
    const ProjectID = localStorage.getItem("projectID");
    setGetLoader(true);
    try {
      fetch(`${baseURL}/deleteSnap`, {
        method: "POST",
        body: JSON.stringify({
          idx: idx,
          ProjectID,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((result) => {
          setAllmodel(result.allmodel);
          setGetLoader(false);
        })
        .catch((error) => {
          console.log("error", error);
          setGetLoader(false);
        });
    } catch (error) {
      console.log("error", error);
      setGetLoader(false);
    }
  };

  const NextStep = () => {
    if (selectedsnapshot === null) {
      setOpen(true);
    } else {
      setGetLoader(true);
      const projectID = localStorage.getItem("projectID");
      const body = {
        selectedsnapshot,
        projectID,
      };
      fetch(`${baseURL}/finalmodel`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((result) => {
          props.handleNextStep();
          setGetLoader(false);
        })
        .catch((error) => {
          setGetLoader(false);
          console.error("Error:", error);
        });
    }
  };
  const changeparity = () => {
    setParity(true);
    setr2q2(false);
    setLogistic(false);
    setRanking(false);
  };
  const showlogistic = () => {
    setParity(false);
    setr2q2(false);
    setLogistic(true);
    setRanking(false);
  };

  const getSpearman = () => {
    //setGetLoader(true);
    const ProjectID = localStorage.getItem("projectID");
    fetch(`${baseURL}/getSpearman?projectID=${ProjectID}`)
      .then((res) => res.json())
      .then((myresponse) => {
        setRanks(myresponse.allranks);
        setIndependent(myresponse.independent);
        setdependent(myresponse.dependent);
        //setGetLoader(false);
      })
      .catch((err) => {
        //setGetLoader(false);
        console.log("error", err);
      });
  };

  const showranking = () => {
    setParity(false);
    setr2q2(false);
    setLogistic(false);
    setRanking(true);
  };
  const changer2q2 = () => {
    setParity(false);
    setr2q2(true);
    setLogistic(false);
    setRanking(false);
  };

  function changeBackground(e) {
    e.target.style.background = "#ececec";
    e.target.style.textDecoration = "none";
  }

  function changeAgain(e) {
    e.target.style.background = "white";
  }
  const updatevalidationdata = (i) => {
    setValidationdata(allmodel[i]);
    setr2(allr2[i]);
    setq2(allq2[i]);
  };

  const snapshotchange = (e, i) => {
    setselectedsnapshot(i);

    updatevalidationdata(i);
    let newArr = initialState;
    newArr[i] = !newArr[i];
    setCheckedState(newArr);
    let newArray = [];
    allmodel[i].map((item, i) => {
      newArray.push(true);
    });

    setStatus(newArray);
  };
  function downloadCSV(name, y_test, y_pred, times) {
    var arr = [];

    for (var i = 0; i < y_test.length; i++) {
      arr.push([y_test[i].toFixed(3), y_pred[i].toFixed(3)]);
    }

    if (times === 1) {
      var csv = "Observed,Forecasted\n";
    } else {
      var csv = "Observed,Predicted\n";
    }

    arr.forEach(function (row) {
      csv += row.join(",");
      csv += "\n";
    });
    var hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
    hiddenElement.target = "_blank";

    //provide the name for the CSV file to be downloaded
    hiddenElement.download = name + ".csv";
    hiddenElement.click();
  }
  function downloadlogisticCSV(name, y_test, y_pred) {
    var arr = [];
    for (var i = 0; i < y_test.length; i++) {
      arr.push([y_test[i], y_pred[i]]);
    }
    var csv = "Observed,Predicted\n";
    arr.forEach(function (row) {
      csv += row.join(",");
      csv += "\n";
    });
    var hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
    hiddenElement.target = "_blank";

    //provide the name for the CSV file to be downloaded
    hiddenElement.download = name + ".csv";
    hiddenElement.click();
  }

  return (
    <div>
      <Dialog
        open={lineopengraph}
        onClose={handlelineClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            width: "100%",
            height: "400px",
            maxWidth: "900px!important",
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ color: Projectcolors.primary, fontSize: "16px" }}
        >
          {lineplottitle}
        </DialogTitle>

        <Plot
          data={[
            {
              x: Array.from(Array(lineobserved.length).keys()),
              y: lineobserved,
              name: "Observed",
              mode: "lines",
              line: { color: "#17BECF" },
            },
            {
              x: Array.from(Array(lineobserved.length).keys()),
              y: linepredicted,
              name: "Predicted",
              mode: "lines",
              line: { color: "darkblue" },
            },
          ]}
          layout={{
            margin: {
              b: 55,
              l: 100,
              r: 10,
              t: 20,
            },
            width: 820,
            height: 350,
            title: {
              font: {
                size: 24,
              },
              xref: "paper",
              x: 0.05,
            },
            xaxis: {
              title: {
                text: "Count",
                font: {
                  size: 12,
                  color: "#000",
                },
              },
              linewidth: 1,
              tickangle: -90,
              //type:'date',
              //tickformat :"%Y-%m-%d-%H-%M-%S.%2f"
              //ticklen: 1,
              tickfont: {
                size: 8,
              },
              rangeslider: {
                visible: true,
                bgcolor: "lightgrey",
                thickness: 0.08,
              },
            },
            yaxis: {
              title: {
                text: "Results",
                font: {
                  size: 12,
                  color: "#000",
                },
              },
              linewidth: 1,
              ticklen: 10,
            },
          }}
          config={{
            displayModeBar: true,
            displaylogo: false,
          }}
        />
      </Dialog>
      <Dialog
        open={opengraph}
        onClose={handlegraphClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            width: "100%",
            height: "400px",
            maxWidth: "900px!important",
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ color: Projectcolors.primary, fontSize: "16px" }}
        >
          {title}
        </DialogTitle>

        <Plot
          data={[
            {
              x: date_train,
              y: ytrain,
              name: "Real Data",
              mode: "lines",
              line: { color: "#17BECF" },
            },
            {
              x: date_train,
              y: ypred,
              name: "Predicted Data",
              mode: "lines",
              line: { color: "darkblue" },
            },
            {
              x: date_test,
              y: forecasting,
              name: "Forecasting Data",
              mode: "lines",
              line: { color: "red" },
            },
          ]}
          layout={{
            margin: {
              b: 55,
              l: 100,
              r: 10,
              t: 20,
            },
            width: 820,
            height: 350,
            title: {
              font: {
                size: 24,
              },
              xref: "paper",
              x: 0.05,
            },
            xaxis: {
              title: {
                text: "TimeStamp",
                font: {
                  size: 12,
                  color: "#000",
                },
              },
              linewidth: 1,
              tickangle: -90,
              //type:'date',
              //tickformat :"%Y-%m-%d-%H-%M-%S.%2f"
              //ticklen: 1,
              tickfont: {
                size: 8,
              },
              rangeslider: {
                visible: true,
                bgcolor: "lightgrey",
                thickness: 0.08,
              },
            },
            yaxis: {
              title: {
                text: "Forecasting",
                font: {
                  size: 12,
                  color: "#000",
                },
              },
              linewidth: 1,
              ticklen: 10,
            },
          }}
          config={{
            displayModeBar: true,
            displaylogo: false,
          }}
        />
      </Dialog>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ color: "red", fontSize: "13px" }}
        >
          {"Select any one Snapshot"}
        </DialogTitle>
      </Dialog>
      {message ? (
        <div>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              //   justifyContent: "space-between",
              marginTop: "150px",
            }}
          >
            {" "}
            <Typography
              sx={{
                fontSize: 14,
                color: Projectcolors.primary,
                marginTop: 2,
                margin: "auto",
                padding: 20,
              }}
            >
              Please Complete Previous Steps to Validate Model
            </Typography>{" "}
          </Box>
        </div>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            //   justifyContent: "space-between",
            marginTop: "60px",
          }}
        >
          {/* LEFT DIV */}
          <Box
            component={Paper}
            elevation={5}
            sx={{
              width: "107px",
              border: 1,
              marginTop: 3,
              // height: "300px",
              // position: "fixed",
              marginLeft: "0%",
            }}
            className="resizeable-left-div"
          >
            <div className="transform-left-content">
              <div className="content-new" style={{ height: "100%" }}>
                <div
                  style={{
                    backgroundColor: "#e2e2e2",
                    color: "black",
                    padding: 10,
                  }}
                >
                  <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
                    Plots
                  </Typography>
                </div>
                <div style={{ color: "black", display: "block" }}>
                  <Link
                    onClick={changeparity}
                    underline="none"
                    sx={{
                      fontSize: 11,
                      padding: 2,
                      color: "black",
                      backgroundColor: "white",
                      textDecoration: "none",
                      //width: "127px",
                      display: "block",
                      fontWeight: "bold",
                    }}
                    onMouseOver={changeBackground}
                    onMouseOut={changeAgain}
                    variant="contained"
                    size="large"
                  >
                    Parity
                  </Link>
                </div>
                {timeseries ? null : (
                  <div style={{ color: "black", display: "block" }}>
                    <Link
                      onClick={changer2q2}
                      underline="none"
                      sx={{
                        fontSize: 11,
                        display: "block",
                        padding: 2,
                        color: "black",
                        textDecoration: "none",
                        fontWeight: "bold",
                        //width: "127px",
                      }}
                      variant="contained"
                      size="large"
                      onMouseOver={changeBackground}
                      onMouseOut={changeAgain}
                    >
                      R2/Q2
                    </Link>
                  </div>
                )}

                <div style={{ color: "black", display: "block" }}>
                  <Link
                    onClick={showranking}
                    underline="none"
                    sx={{
                      fontSize: 11,
                      display: "block",
                      padding: 2,
                      color: "black",
                      textDecoration: "none",
                      fontWeight: "bold",
                      //width: "127px",
                    }}
                    variant="contained"
                    size="large"
                    onMouseOver={changeBackground}
                    onMouseOut={changeAgain}
                  >
                    MB Ranking
                  </Link>
                </div>

                <div style={{ color: "black", display: "block" }}>
                  <Link
                    onClick={showlogistic}
                    underline="none"
                    sx={{
                      fontSize: 11,
                      display: "block",
                      padding: 2,
                      color: "black",
                      textDecoration: "none",
                      fontWeight: "bold",
                      //width: "127px",
                    }}
                    variant="contained"
                    size="large"
                    onMouseOver={changeBackground}
                    onMouseOut={changeAgain}
                  >
                    Logistic Regression
                  </Link>
                </div>
              </div>
            </div>
          </Box>

          {/* CENTER DIV */}
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                p: 0,
                //   bgcolor: "background.paper",
                // bgColor:"red",
                borderRadius: 1,
                //   marginLeft: 17,
                flexWrap: "wrap",
                width: "980px",
                border: 1,
                marginRight: 0,
                height: "410px",
                overflowX: "scroll",
                overflowY: "scroll",
                marginTop: 3,
              }}
              className="resize-center-div"
              id="pane"
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
                className="transform-center-div"
              >
                {parity ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {parity && cat.includes(false) ? (
                      validationdata.map((item, i) => {
                        if (cat[i] === false) {
                          return (
                            <Box
                              component={Paper}
                              elevation={5}
                              sx={{
                                width: "319px",
                                height: "265px",
                                border: 1,
                                borderColor: Projectcolors.primary,
                                marginLeft: 3,
                                marginTop: 1,
                                overFlow: "hidden",
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: Projectcolors.primary,
                                  padding: "4px 0",
                                }}
                              >
                                <Typography
                                  sx={{
                                    display: "inline",
                                    flex: 1,
                                    fontSize: 13,
                                    padding: 2,
                                    color: "white",
                                  }}
                                >
                                  {item[0].length > 26
                                    ? item[0].substring(0, 25) + "..."
                                    : item[0]}
                                </Typography>
                                <TableViewIcon
                                  sx={{
                                    display: "inline",
                                    float: "right",
                                    fontSize: 22,
                                    marginRight: 1,
                                    color: "white",
                                  }}
                                  onClick={() => handleClick(i)}
                                />
                                
                               
                                {(typeof(item[1]) === "object") || (timeseries) ? (
                              
                                  <SsidChartOutlinedIcon
                                    sx={{
                                      display: "inline",
                                      float: "right",
                                      fontSize: 22,
                                      marginRight: 1,
                                      color: "white",
                                    }}
                                    onClick={() => handleClick(i)}
                                  />
                                ) : (
                                  <ScatterPlotIcon
                                    sx={{
                                      display: "inline",
                                      float: "right",
                                      fontSize: 22,
                                      // marginRight: ,
                                      color: "white",
                                    }}
                                    onClick={() => handleClick(i)}
                                  />
                                  
                                  
                                )}
                                {timeseries ? (null):(
                                   <ShowChartIcon
                                   sx={{
                                     display: "inline",
                                     float: "right",
                                     fontSize: 22,
                                     marginRight: 1,
                                     color: "white",
                                   }}
                                   onClick={() => handleLinePlot(i)}
                                 />
                                )}

                                 

                                 

                               
                              </div>
                              <div style={{ marginTop: 5 }}>
                                <Typography
                                  sx={{
                                    display: "inline",
                                    flex: 1,
                                    fontSize: 11,
                                    padding: 1,
                                    marginTop: 2,
                                    marginLeft: 1,
                                    color: "black",
                                  }}
                                >
                                  Accuracy
                                </Typography>
                                <Typography
                                  sx={{
                                    display: "inline",
                                    flex: 1,
                                    fontSize: 11,
                                    padding: 1,
                                    marginTop: 2,
                                    marginLeft: 1,
                                    color: "black",
                                  }}
                                >
                                  R2 = {item[5]}
                                </Typography>
                                <Typography
                                  sx={{
                                    display: "inline",
                                    flex: 1,
                                    fontSize: 11,
                                    padding: 1,
                                    marginTop: 2,
                                    marginLeft: 1,
                                    color: "black",
                                  }}
                                >
                                  RMSE = {item[6]}
                                </Typography>
                                
                                {timeseries ? (
                                  <ZoomInIcon
                                    sx={{
                                      display: "inline",
                                      float: "right",
                                      fontSize: 20,
                                      marginRight: 1,
                                      color: "black",
                                      border: 1,
                                    }}
                                    onClick={() =>
                                      handlegraphOpen(item[0], item[3], item[4])
                                    }
                                  />
                                ) : null}
                              </div>

                              {timeseries ? null : (
                                <div style={{ marginTop: 14 }}>
                                  <Typography
                                    sx={{
                                      display: "inline",
                                      flex: 1,
                                      fontSize: 11,
                                      padding: 0.5,
                                      marginTop: 1,
                                      marginLeft: 1,
                                      color: "black",
                                    }}
                                  >
                                    Predictability
                                  </Typography>
                                  <Typography
                                    sx={{
                                      display: "inline",
                                      flex: 1,
                                      fontSize: 11,
                                      padding: 0.5,
                                      marginTop: 1,
                                      marginLeft: 1,
                                      color: "black",
                                    }}
                                  >
                                    Q2 = {item[7]}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      display: "inline",
                                      flex: 1,
                                      fontSize: 11,
                                      padding: 0.5,
                                      marginTop: 1,
                                      marginLeft: 1,
                                      color: "black",
                                    }}
                                  >
                                    RMSE = {item[8]}
                                  </Typography>
                                </div>
                              )}

                              <div>
                                
                                     

                                {status[i] ? (
                                  typeof item[1] === "string" ? (
                                    <div>
                                      {timeseries ? (
                                        <Plot
                                          data={[
                                            {
                                              x: item[3][0],
                                              y: item[4][0],
                                              name: "Real Data",
                                              mode: "lines",
                                              line: { color: "#17BECF" },
                                            },
                                            {
                                              x: item[3][0],
                                              y: item[4][1],
                                              name: "Predicted Data",
                                              mode: "lines",
                                              line: { color: "#7F7F7F" },
                                            },
                                            {
                                              x: item[3][1],
                                              y: item[4][2],
                                              name: "Forecasting Data",
                                              mode: "lines",
                                              line: { color: "red" },
                                            },
                                          ]}
                                          layout={{
                                            margin: {
                                              b: 65,
                                              l: 55,
                                              r: 10,
                                              t: 20,
                                            },
                                            width: 300,
                                            height: 180,
                                            title: {
                                              font: {
                                                size: 24,
                                              },
                                              xref: "paper",
                                              x: 0.05,
                                            },
                                            xaxis: {
                                              title: {
                                                text: "TimeStamp",
                                                font: {
                                                  size: 12,
                                                  color: "#000",
                                                },
                                              },
                                              linewidth: 1,
                                              tickangle: 45,
                                              //type:'date',
                                              //tickformat :"%Y-%m-%d-%H-%M-%S.%2f"
                                              //ticklen: 1,
                                              tickfont: {
                                                size: 4,
                                              },
                                            },
                                            yaxis: {
                                              title: {
                                                text: "Forecasting",
                                                font: {
                                                  size: 12,
                                                  color: "#000",
                                                },
                                              },
                                              linewidth: 1,
                                              ticklen: 10,
                                            },
                                            showlegend: false,
                                          }}
                                          config={{
                                            displayModeBar: false,
                                            displaylogo: false,
                                          }}
                                        />
                                      ) : (
                                        <Plot
                                          data={[
                                            {
                                              x: item[3],
                                              y: item[3],
                                              type: "scatter",
                                              mode: "lines",
                                              marker: { color: "#81888f" },
                                            },
                                            {
                                              x: item[3],
                                              y: item[4],
                                              type: "scatter",
                                              mode: "markers",
                                              marker: {
                                                color: Projectcolors.primary,
                                              },
                                            },
                                          ]}
                                          layout={{
                                            margin: {
                                              b: 68,
                                              l: 73,
                                              r: 10,
                                              t: 20,
                                            },
                                            width: 300,
                                            height: 170,
                                            title: {
                                              font: {
                                                size: 24,
                                              },
                                              xref: "paper",
                                              x: 0.05,
                                            },
                                            xaxis: {
                                              title: {
                                                text: "Observed",
                                                font: {
                                                  size: 12,
                                                  color: "#000",
                                                },
                                              },
                                              linewidth: 1,
                                              ticklen: 10,
                                            },
                                            yaxis: {
                                              title: {
                                                text: "Predicted",
                                                font: {
                                                  size: 12,
                                                  color: "#000",
                                                },
                                              },
                                              linewidth: 1,
                                              ticklen: 10,
                                            },
                                          }}
                                          config={{
                                            displayModeBar: false,
                                            displaylogo: false,
                                          }}
                                        />
                                      )}
                                    </div>
                                  ) : (
                                    <div>
                                      <Plot
                                        data={[
                                          {
                                            x: item[1].length,
                                            y: item[1][0],
                                            name: "Training Loss",
                                            type: "scatter",
                                            mode: "lines",
                                            marker: { color: "green" },
                                          },
                                          {
                                            x: item[1].length,
                                            y: item[1][1],
                                            name: "Validation Loss",
                                            type: "scatter",
                                            mode: "lines",
                                            marker: {
                                              color: Projectcolors.primary,
                                            },
                                          },
                                        ]}
                                        layout={{
                                          margin: { b: 65, l: 70, r: 0, t: 20 },
                                          width: 300,
                                          height: 170,
                                          title: {
                                            font: {
                                              size: 10,
                                            },
                                            xref: "paper",
                                            x: 0.05,
                                          },
                                          xaxis: {
                                            title: {
                                              text: "Epochs",
                                              font: {
                                                size: 11,
                                                color: "#000",
                                              },
                                            },
                                            linewidth: 1,
                                            ticklen: 10,
                                          },
                                          yaxis: {
                                            title: {
                                              text: "Loss",
                                              font: {
                                                size: 11,
                                                color: "#000",
                                              },
                                            },
                                            linewidth: 1,
                                            ticklen: 10,
                                          },
                                        }}
                                        config={{
                                          displayModeBar: false,
                                          displaylogo: false,
                                        }}
                                      />
                                    </div>
                                  )
                                ) : (
                                  <div>
                                    {timeseries ? (
                                      <TableContainer
                                        component={Paper}
                                        elevation={2}
                                        sx={{
                                          width: 210,
                                          height: 175,
                                          padding: 0,
                                          display: "block",
                                          marginLeft: "auto",
                                          marginRight: "auto",
                                          marginTop: 2,
                                        }}
                                      >
                                        <Table
                                          aria-label="sticky table"
                                          stickyHeader
                                          sx={{ color: "black" }}
                                        >
                                          <TableHead
                                            sx={{
                                              backgroundColor:
                                                Projectcolors.primary,
                                              height: 10,
                                            }}
                                          >
                                            <StyledTableRow>
                                              <StyledTableCell
                                                sx={{
                                                  // padding: "7px",
                                                  fontSize: 11,
                                                  color: "white",
                                                  fontWeight: "bold",
                                                  backgroundColor:
                                                    Projectcolors.primary,
                                                }}
                                              >
                                                Observed
                                              </StyledTableCell>
                                              <StyledTableCell
                                                sx={{
                                                  // padding: "7px",
                                                  fontSize: 11,
                                                  color: "white",
                                                  fontWeight: "bold",
                                                  backgroundColor:
                                                    Projectcolors.primary,
                                                }}
                                              >
                                                Predicted{" "}
                                              </StyledTableCell>

                                              <StyledTableCell
                                                sx={{
                                                  // padding: "7px",
                                                  width: 10,
                                                  color: "white",
                                                  fontWeight: "bold",
                                                  backgroundColor:
                                                    Projectcolors.primary,
                                                }}
                                              >
                                                <DownloadIcon
                                                  sx={{
                                                    fontSize: "small",
                                                    marginTop: 1,
                                                    marginRight: 2,
                                                  }}
                                                  onClick={() =>
                                                    downloadCSV(
                                                      item[0],
                                                      item[4][3],
                                                      item[4][2],
                                                      1
                                                    )
                                                  }
                                                />
                                              </StyledTableCell>
                                            </StyledTableRow>
                                          </TableHead>

                                          <TableBody>
                                            {item[4][2].map((x, i) => {
                                              return (
                                                <StyledTableRow>
                                                  <StyledTableCell
                                                    sx={{ fontSize: 11 }}
                                                  >
                                                    {item[4][3][i]
                                                      .toString()
                                                      .startsWith("0.") ||
                                                    item[4][3][i]
                                                      .toString()
                                                      .startsWith("-0.")
                                                      ? item[4][3][
                                                          i
                                                        ].toExponential(3)
                                                      : item[4][3][i].toFixed(
                                                          3
                                                        )}
                                                  </StyledTableCell>

                                                  <StyledTableCell
                                                    sx={{ fontSize: 11 }}
                                                  >
                                                    {x
                                                      .toString()
                                                      .startsWith("0.") ||
                                                    x
                                                      .toString()
                                                      .startsWith("-0.")
                                                      ? x.toExponential(3)
                                                      : x.toFixed(3)}
                                                  </StyledTableCell>
                                                  <StyledTableCell
                                                    sx={{ fontSize: 11 }}
                                                  ></StyledTableCell>
                                                </StyledTableRow>
                                              );
                                            })}
                                          </TableBody>
                                        </Table>
                                      </TableContainer>
                                    ) : (
                                      <div>
                                      {linestatus[i]?(
                                        <div>
                                          <ZoomInIcon
                                    sx={{
                                      display: "inline",
                                      float: "right",
                                      fontSize: 20,
                                      marginRight: 1,
                                      color: "black",
                                      border: 1,
                                    }}
                                    onClick={() =>
                                      handlelineOpen(item[3], item[4],item[0])
                                       
                                    }
                                  />
                                         <Plot
                                         data={[
                                           {
                                             x: Array.from(Array(item[3].length).keys()),
                                             y: item[3],
                                             name: "Observed",
                                             mode: "lines",
                                             line: { color: "#17BECF" },
                                           },
                                           {
                                             x: Array.from(Array(item[3].length).keys()),
                                             y: item[4],
                                             name: "Predicted",
                                             mode: "lines",
                                             line: { color: "darkblue" },
                                           },
                                           
                                         ]}
                                         layout={{
                                           margin: {
                                             b: 75,
                                             l: 75,
                                             r: 20,
                                             t: 20,
                                           },
                                           width: 300,
                                           height: 150,
                                           title: {
                                             font: {
                                               size: 24,
                                             },
                                             xref: "paper",
                                             x: 0.05,
                                           },
                                           xaxis: {
                                             title: {
                                               text: "Count",
                                               font: {
                                                 size: 12,
                                                 color: "#000",
                                               },
                                             },
                                             linewidth: 1,
                                             tickangle: 45,
                                             //type:'date',
                                             //tickformat :"%Y-%m-%d-%H-%M-%S.%2f"
                                             //ticklen: 1,
                                             tickfont: {
                                               size: 4,
                                             },
                                           },
                                           yaxis: {
                                             title: {
                                               text: "Results",
                                               font: {
                                                 size: 12,
                                                 color: "#000",
                                               },
                                             },
                                             linewidth: 1,
                                             ticklen: 10,
                                           },
                                           showlegend: false,
                                         }}
                                         config={{
                                           displayModeBar: false,
                                           displaylogo: false,
                                         }}
                                       />
                                       </div>
                                      ):(
                                       <div>
                                        <TableContainer
                                        component={Paper}
                                        elevation={2}
                                        sx={{
                                          width: 210,
                                          height: 150,
                                          padding: 0,
                                          display: "block",
                                          marginLeft: "auto",
                                          marginRight: "auto",
                                          marginTop: 2,
                                          //border: 1,
                                          //borderWidth: "2px",
                                          //borderColor: "black",
                                        }}
                                      >
                                        <Table
                                          aria-label="sticky table"
                                          stickyHeader
                                          sx={{ color: "black" }}
                                        >
                                          <TableHead
                                            sx={{
                                              backgroundColor:
                                                Projectcolors.primary,
                                              height: 10,
                                            }}
                                          >
                                            <StyledTableRow>
                                              <StyledTableCell
                                                sx={{
                                                  // padding: "7px",
                                                  fontSize: 11,
                                                  color: "white",
                                                  fontWeight: "bold",
                                                  backgroundColor:
                                                    Projectcolors.primary,
                                                }}
                                              >
                                                Observed
                                              </StyledTableCell>
                                              <StyledTableCell
                                                sx={{
                                                  // padding: "7px",
                                                  fontSize: 11,
                                                  color: "white",
                                                  fontWeight: "bold",
                                                  backgroundColor:
                                                    Projectcolors.primary,
                                                }}
                                              >
                                                Predicted{" "}
                                              </StyledTableCell>

                                              <StyledTableCell
                                                sx={{
                                                  // padding: "7px",
                                                  width: 10,
                                                  color: "white",
                                                  fontWeight: "bold",
                                                  backgroundColor:
                                                    Projectcolors.primary,
                                                }}
                                              >
                                                <DownloadIcon
                                                  sx={{
                                                    fontSize: "small",
                                                    marginTop: 1,
                                                    marginRight: 2,
                                                  }}
                                                  onClick={() =>
                                                    downloadCSV(
                                                      item[0],
                                                      item[3],
                                                      item[4],
                                                      0
                                                    )
                                                  }
                                                />
                                              </StyledTableCell>
                                            </StyledTableRow>
                                          </TableHead>

                                          <TableBody>
                                            {item[3].map((x, i) => {
                                              return (
                                                <StyledTableRow>
                                                  <StyledTableCell
                                                    sx={{ fontSize: 11 }}
                                                  >
                                                    {x
                                                      .toString()
                                                      .startsWith("0.") ||
                                                    x
                                                      .toString()
                                                      .startsWith("-0.")
                                                      ? x.toExponential(3)
                                                      : x.toFixed(3)}
                                                  </StyledTableCell>

                                                  <StyledTableCell
                                                    sx={{ fontSize: 11 }}
                                                  >
                                                    {item[4][i]
                                                      .toString()
                                                      .startsWith("0.") ||
                                                    item[4][i]
                                                      .toString()
                                                      .startsWith("-0.")
                                                      ? item[4][
                                                          i
                                                        ].toExponential(3)
                                                      : item[4][i].toFixed(3)}
                                                  </StyledTableCell>
                                                  <StyledTableCell
                                                    sx={{ fontSize: 11 }}
                                                  ></StyledTableCell>
                                                </StyledTableRow>
                                              );
                                            })}
                                          </TableBody>
                                        </Table>
                                      </TableContainer>
                                      </div>
                                      )} 
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            </Box>
                          );
                        }
                      })
                    ) : (
                      <div>
                        <Typography
                          sx={{
                            fontSize: 14,
                            color: Projectcolors.primary,
                            marginTop: 2,
                            margin: "auto",
                            padding: 20,
                            marginLeft: 15,
                          }}
                        >
                          Categorical Data Trained on Logistic Regression
                        </Typography>{" "}
                      </div>
                    )}
                  </div>
                ) : (
                  <div></div>
                )}

                {ranking && cat.includes(false) ? (
                  <div>
                    <div
                      style={{
                        backgroundColor: "#e2e2e2",
                        color: "black",
                        padding: 10,
                        width: "1250px",
                      }}
                    >
                      <Typography sx={{ fontSize: 13 }}>MB Ranking</Typography>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          marginTop: 0,
                          overflowY: "scroll",
                          height: "420px",
                          width: "200px",
                        }}
                      >
                        {dependent ? (
                          dependent.map((item, i) => {
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginLeft: 10,
                                }}
                              >
                                <Box
                                  sx={{
                                    width: 260,
                                    maxWidth: 240,
                                    display: "flex",
                                    flexDirection: "row",
                                    padding: 1,
                                  }}
                                >
                                  <div>
                                    <Radio
                                      key={i}
                                      onChange={() => handleChange(item, i)}
                                      checked={selectedValue === i}
                                      value={i}
                                      size="medium"
                                    />
                                  </div>
                                  <div style={{ padding: "5.0px" }}>
                                    <Typography
                                      sx={{
                                        width: 90,
                                        display: "inline",
                                        fontSize: 12,
                                      }}
                                    >
                                      {item}
                                    </Typography>
                                  </div>
                                </Box>
                              </div>
                            );
                          })
                        ) : (
                          <div>
                            <Typography
                              sx={{
                                fontSize: 14,
                                color: Projectcolors.primary,
                                marginTop: 2,
                                margin: "auto",
                                padding: 20,
                                marginLeft: 15,
                              }}
                            >
                              Categorical Data Trained on Logistic Regression
                            </Typography>{" "}
                          </div>
                        )}
                      </Box>
                      <Box
                        sx={{
                          marginLeft: "40px",
                          marginTop: "20px",
                          height: "480px",
                          overflowY: "scroll",
                          width: "710px",
                          padding: "3px",
                        }}
                      >
                        <Plot
                          data={[
                            {
                              x: independent,
                              y: ranks[selectedValue],
                              name: "Trace1",
                              type: "bar",
                              width: 0.3,
                            },
                          ]}
                          layout={{
                            width: 500,
                            height: 370,
                            title: "MB Ranking Plot",
                            barmode: "relative",
                            name: "",
                            borderradius: 5,
                            yaxis: {
                              automargin: true,
                            },
                            xaxis: {
                              automargin: true,
                            },
                          }}
                          config={{
                            displaylogo: false,
                          }}
                        />
                      </Box>
                    </div>
                  </div>
                ) : null}

                {r2q2 ? (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    {r2q2 && cat.includes(false) ? (
                      <div>
                        <div
                          style={{
                            backgroundColor: "#e2e2e2",
                            color: "black",
                            padding: 10,
                            width: "1250px",
                          }}
                        >
                          <Typography sx={{ fontSize: 13 }}>R2/Q2</Typography>
                        </div>

                        <Box sx={{ marginTop: 3, marginLeft: 10 }}>
                          <Plot
                            data={[
                              { x: labels, y: r2, name: "R2", type: "bar" },
                              {
                                x: labels,
                                y: q2,
                                name: "Q2",
                                type: "bar",
                                marker: {
                                  color: "darkred",
                                },
                              },
                            ]}
                            layout={{
                              margin: { b: 75, l: 70, r: 0, t: 20 },
                              barmode: "group",
                              width: "950px",
                              height: 350,

                              title: {
                                font: {
                                  size: 20,
                                },
                                xref: "paper",
                                x: 0.05,
                              },
                              xaxis: {
                                title: {
                                  text: " Variables",
                                  font: {
                                    size: 13,
                                    color: "#000",
                                  },
                                },
                                linewidth: 1,
                                ticklen: 10,
                                automargin: true,
                              },
                              yaxis: {
                                title: {
                                  text: "R2/Q2 Score",
                                  font: {
                                    size: 13,
                                    color: "#000",
                                  },
                                },
                                linewidth: 1,
                                ticklen: 10,
                              },
                            }}
                            config={{
                              displaylogo: false,
                            }}
                          />
                        </Box>
                      </div>
                    ) : (
                      <div>
                        <Typography
                          sx={{
                            fontSize: 14,
                            color: Projectcolors.primary,
                            marginTop: 2,
                            margin: "auto",
                            padding: 20,
                            marginLeft: 15,
                          }}
                        >
                          Categorical Data Trained on Logistic Regression
                        </Typography>{" "}
                      </div>
                    )}
                  </div>
                ) : (
                  <div></div>
                )}

                {logistic ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {logistic && cat.includes(true) ? (
                      validationdata.map((item, i) => {
                        if (cat[i] === true) {
                          return (
                            <Box
                              component={Paper}
                              elevation={5}
                              sx={{
                                width: "319px",
                                height: "283px",
                                border: 1,
                                borderColor: Projectcolors.primary,
                                marginLeft: 3,
                                marginTop: 1,
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: Projectcolors.primary,
                                  height: 33,
                                  padding: 4,
                                }}
                              >
                                <Typography
                                  sx={{
                                    display: "inline",
                                    flex: 1,
                                    fontSize: 13,
                                    padding: 2,
                                    marginTop: 3,
                                    color: "white",
                                  }}
                                >
                                  {item[0].length > 26
                                    ? item[0].substring(0, 25) + "..."
                                    : item[0]}
                                </Typography>
                                <TableViewIcon
                                  sx={{
                                    display: "inline",
                                    float: "right",
                                    fontSize: 22,
                                    marginRight: 1,
                                    color: "white",
                                  }}
                                  onClick={() => handleClick(i)}
                                />
                                <ScatterPlotIcon
                                  sx={{
                                    display: "inline",
                                    float: "right",
                                    fontSize: 22,
                                    marginRight: 1,
                                    color: "white",
                                  }}
                                  onClick={() => handleClick(i)}
                                />
                                
                              </div>
                              <div style={{ marginTop: 14 }}>
                                <Typography
                                  sx={{
                                    display: "inline",
                                    flex: 1,
                                    fontSize: 11,
                                    padding: 1,
                                    marginTop: 5,
                                    marginLeft: 1,
                                    color: "black",
                                  }}
                                >
                                  Precision = {item[6][0]}
                                </Typography>
                                <Typography
                                  sx={{
                                    display: "inline",
                                    flex: 1,
                                    fontSize: 11,
                                    padding: 1,
                                    marginTop: 5,
                                    marginLeft: 1,
                                    color: "black",
                                  }}
                                >
                                  Recall = {item[6][1]}
                                </Typography>
                              </div>
                              <div style={{ marginTop: 14 }}>
                                <Typography
                                  sx={{
                                    display: "inline",
                                    flex: 1,
                                    fontSize: 11,
                                    padding: 1,
                                    marginTop: 1,
                                    marginLeft: 1,
                                    color: "black",
                                  }}
                                >
                                  F1 score = {item[7]}
                                </Typography>
                                <Typography
                                  sx={{
                                    display: "inline",
                                    flex: 1,
                                    fontSize: 11,
                                    padding: 1,
                                    marginTop: 1,
                                    marginLeft: 1,
                                    color: "black",
                                  }}
                                >
                                  Accuracy = {item[8]}
                                </Typography>
                              </div>
                              <div>
                                {status[i] ? (
                                  <div>
                                    <Plot
                                      data={[
                                        {
                                          x: item[5][0],
                                          y: item[5][1],
                                          type: "lines",
                                          mode: "lines",
                                          marker: {
                                            color: Projectcolors.primary,
                                          },
                                        },
                                      ]}
                                      layout={{
                                        margin: { b: 60, l: 70, r: 50, t: 20 },
                                        width: 300,
                                        height: 175,
                                        title: {
                                          font: {
                                            size: 24,
                                          },
                                          xref: "paper",
                                          x: 0.05,
                                        },
                                        xaxis: {
                                          title: {
                                            text: "FPR",
                                            font: {
                                              size: 11,
                                              color: "#000",
                                            },
                                          },
                                          linewidth: 1,
                                          ticklen: 10,
                                        },
                                        yaxis: {
                                          title: {
                                            text: "TPR",
                                            font: {
                                              size: 11,
                                              color: "#000",
                                            },
                                          },
                                          linewidth: 1,
                                          ticklen: 10,
                                        },
                                      }}
                                      config={{
                                        displayModeBar: false,
                                        displaylogo: false,
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <TableContainer
                                    component={Paper}
                                    elevation={2}
                                    sx={{
                                      width: 270,
                                      height: 150,
                                      padding: 0,
                                      display: "block",
                                      marginLeft: "auto",
                                      marginRight: "auto",
                                      marginTop: 1,
                                      //border: 1,
                                      //borderWidth: "2px",
                                      //borderColor: "black",
                                    }}
                                  >
                                    <Table
                                      aria-label="sticky table"
                                      stickyHeader
                                      sx={{ color: "black" }}
                                    >
                                      <TableHead
                                        sx={{
                                          backgroundColor:
                                            Projectcolors.primary,
                                          height: 10,
                                        }}
                                      >
                                        <StyledTableRow>
                                          <StyledTableCell
                                            sx={{
                                              padding: "7px",
                                              fontSize: 11,
                                              fontWeight: "bold",
                                              color: "white",
                                              backgroundColor:
                                                Projectcolors.primary,
                                            }}
                                          >
                                            Observed
                                          </StyledTableCell>
                                          <StyledTableCell
                                            sx={{
                                              padding: "7px",
                                              fontSize: 11,
                                              color: "white",
                                              fontWeight: "bold",
                                              backgroundColor:
                                                Projectcolors.primary,
                                            }}
                                          >
                                            Predicted{" "}
                                          </StyledTableCell>

                                          <StyledTableCell
                                            sx={{
                                              padding: "7px",
                                              width: 10,
                                              color: "white",
                                              backgroundColor:
                                                Projectcolors.primary,
                                            }}
                                          >
                                            <DownloadIcon
                                              sx={{
                                                fontSize: "small",
                                                marginTop: 1,
                                                marginRight: 2,
                                              }}
                                              onClick={() =>
                                                downloadlogisticCSV(
                                                  item[0],
                                                  item[3],
                                                  item[4]
                                                )
                                              }
                                            />
                                          </StyledTableCell>
                                        </StyledTableRow>
                                      </TableHead>

                                      <TableBody>
                                        {item[3].map((x, i) => {
                                          return (
                                            <StyledTableRow>
                                              <StyledTableCell
                                                sx={{
                                                  fontSize: 11,
                                                  padding: "7px",
                                                }}
                                              >
                                                {x}
                                              </StyledTableCell>

                                              <StyledTableCell
                                                sx={{
                                                  fontSize: 11,
                                                  padding: "7px",
                                                }}
                                              >
                                                {item[4][i]}
                                              </StyledTableCell>
                                              <StyledTableCell></StyledTableCell>
                                            </StyledTableRow>
                                          );
                                        })}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                )}
                              </div>
                            </Box>
                          );
                        }
                      })
                    ) : (
                      <div>
                        <Typography
                          sx={{
                            fontSize: 14,
                            color: Projectcolors.primary,
                            marginTop: 2,
                            margin: "auto",
                            padding: 20,
                            marginLeft: 15,
                          }}
                        >
                          No Categorical Data Trained on Logistic Regression
                        </Typography>{" "}
                      </div>
                    )}
                  </div>
                ) : (
                  <div></div>
                )}
              </Box>
            </Box>

            {logistic ? (
              <div>
                <Box
                  sx={{
                    border: 0,
                    // marginTop: "1px",
                    marginLeft: 0,
                    height: "200px",
                    //  width: "1250px",
                  }}
                >
                  <TableContainer
                    component={Paper}
                    elevation={2}
                    sx={{
                      // width: 1250,
                      height: 280,
                      padding: 1,
                      display: "block",
                      //marginLeft: 1,
                      marginRight: 1,
                      //marginTop: 1,
                      border: "1px solid black",
                      borderWidth: "1px",
                      borderColor: "black",
                    }}
                  >
                    <Table sx={{ color: "black" }}>
                      <TableHead
                        sx={{
                          backgroundColor: Projectcolors.primary,
                          position: "sticky",
                          top: 0,
                        }}
                      >
                        <TableRow sx={{ height: 10 }}>
                          <TableCell
                            sx={{
                              fontSize: 12,
                              color: "black",
                              backgroundColor: "#e2e2e2",
                            }}
                          >
                            Variables
                          </TableCell>
                          <TableCell
                            sx={{
                              fontSize: 12,
                              color: "black",
                              backgroundColor: "#e2e2e2",
                            }}
                          >
                            Precision
                          </TableCell>
                          <TableCell
                            sx={{
                              fontSize: 12,
                              color: "black",
                              backgroundColor: "#e2e2e2",
                            }}
                          >
                            Recall
                          </TableCell>

                          <TableCell
                            sx={{
                              fontSize: 12,
                              color: "black",
                              backgroundColor: "#e2e2e2",
                            }}
                          >
                            F1 Score
                          </TableCell>
                          <TableCell
                            sx={{
                              fontSize: 12,
                              color: "black",
                              backgroundColor: "#e2e2e2",
                            }}
                          >
                            Accuracy
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {validationdata.map((x, i) => {
                          if (cat[i] === true) {
                            return (
                              <TableRow>
                                <TableCell
                                  sx={{ fontSize: 10, padding: "2px" }}
                                >
                                  {x[0]}
                                </TableCell>

                                <TableCell
                                  sx={{ fontSize: 10, padding: "2px" }}
                                >
                                  {x[6][0]}
                                </TableCell>
                                <TableCell
                                  sx={{ fontSize: 10, padding: "2px" }}
                                >
                                  {x[6][1]}
                                </TableCell>
                                <TableCell
                                  sx={{ fontSize: 10, padding: "2px" }}
                                >
                                  {x[7]}
                                </TableCell>
                                <TableCell
                                  sx={{ fontSize: 10, padding: "2px" }}
                                >
                                  {x[8]}
                                </TableCell>
                              </TableRow>
                            );
                          }
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </div>
            ) : (
              // BOTTTOM TABLE CONTAINER
              <Box
                sx={{
                  border: 0,
                  //  marginTop: "1px",
                  marginLeft: 0,
                  height: 280,
                  //width: "1200px",
                }}
              >
                <TableContainer
                  component={Paper}
                  elevation={2}
                  sx={{
                    //width: 1200,
                    height: 280,
                    padding: 1,
                    display: "block",
                    //marginLeft: 1,
                    marginRight: 1,
                    //marginTop: 1,
                    border: "1px solid black",
                    borderWidth: "1px",
                    borderColor: "black",
                  }}
                >
                  <Table
                    //aria-label="sticky table"
                    sx={{ color: "black" }}
                  >
                    <TableHead
                      sx={{
                        backgroundColor: Projectcolors.primary,
                        position: "sticky",
                        top: 0,
                      }}
                    >
                      <TableRow sx={{ height: 10 }}>
                        <TableCell
                          sx={{
                            fontSize: 12,
                            color: "black",
                            backgroundColor: "#e2e2e2",
                          }}
                        >
                          Variables
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: 12,
                            color: "black",
                            backgroundColor: "#e2e2e2",
                          }}
                        >
                          Accuracy(R2)
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: 12,
                            color: "black",
                            backgroundColor: "#e2e2e2",
                          }}
                        >
                          R2 RMSE
                        </TableCell>
                        {timeseries ? null : (
                          <TableCell
                            sx={{
                              fontSize: 12,
                              color: "black",
                              backgroundColor: "#e2e2e2",
                            }}
                          >
                            Predictability(Q2)
                          </TableCell>
                        )}

                        {timeseries ? null : (
                          <TableCell
                            sx={{
                              fontSize: 12,
                              color: "black",
                              backgroundColor: "#e2e2e2",
                            }}
                          >
                            Q2 RMSE
                          </TableCell>
                        )}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {validationdata.map((x, i) => {
                        if (cat[i] === false) {
                          return (
                            <TableRow>
                              <TableCell sx={{ fontSize: 10, padding: "2px" }}>
                                {x[0]}
                              </TableCell>

                              <TableCell sx={{ fontSize: 10, padding: "2px" }}>
                                {x[5]}
                              </TableCell>
                              <TableCell sx={{ fontSize: 10, padding: "2px" }}>
                                {x[6]}
                              </TableCell>
                              {timeseries ? null : (
                                <TableCell
                                  sx={{ fontSize: 10, padding: "2px" }}
                                >
                                  {x[7]}
                                </TableCell>
                              )}

                              {timeseries ? null : (
                                <TableCell
                                  sx={{ fontSize: 10, padding: "2px" }}
                                >
                                  {x[8]}
                                </TableCell>
                              )}
                            </TableRow>
                          );
                        }
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            )}
          </Box>

          {/* RIGHT DIV  */}
          <Box
            component={Paper}
            //   elevation={5}
            sx={{
              // width: "430px",
              border: 1,
              marginTop: 3,
              // height: "800px",
              // position: "fixed",
              marginRight: 0,
              // marginLeft: "78%",
              // float:"right"
            }}
            className="resizeable-rtl"
          >
            <div className="transform-rtl">
              <div style={{ height: "700px" }}>
                <div
                  className="content"
                  style={{ backgroundColor: "#e2e2e2", padding: 10 }}
                >
                  <Typography sx={{ fontSize: 15 }}>Snapshot KPI</Typography>
                </div>
                <Box
                  component={Paper}
                  elevation={2}
                  sx={{
                    //   width: "370px",
                    //   height: "630px",
                    border: 1,
                    marginLeft: 1,
                    marginTop: 1,
                    overflowX: "scroll",
                    overflowY: "scroll",
                    height: "530px",
                  }}
                  className="content"
                >
                  <div style={{ padding: 5 }}>
                    {allmodel.map((eachmodel, j) => {
                      return (
                        <div>
                          <Box
                            component={Paper}
                            elevation={2}
                            sx={{
                              width: "310px",
                              height: "240px",
                              border: 2,
                              marginLeft: 1,
                              marginTop: 2,
                              overflowX: "scroll",
                              overflowY: "scroll",
                              padding: 0,
                            }}
                            className="resizeable"
                          >
                            <div
                              style={{
                                backgroundColor: "#e2e2e2",
                                color: "black",
                                padding: 1,
                              }}
                            >
                              <Typography
                                sx={{ fontSize: 14, display: "inline" }}
                              >
                                {" "}
                                <Radio
                                  size="large"
                                  key={j}
                                  name={j}
                                  value={j}
                                  onChange={(e) => snapshotchange(e, j)}
                                  checked={checkedState[j]}
                                />
                                Snapshot {j}
                              </Typography>
                              <DeleteIcon
                                onClick={() => deleteSnap(j)}
                                sx={{
                                  display: "inline",
                                  fontSize: 20,
                                  float: "right",
                                  marginTop: 1,
                                  marginRight: 2,
                                  color: Projectcolors.primary,
                                }}
                              />
                            </div>
                            <Typography sx={{ fontSize: 11, padding: 0.5 }}>
                              Total Variable : {eachmodel.length}
                            </Typography>
                            {typeof eachmodel[0][1] != "string" ? (
                              <div>
                                <Typography sx={{ fontSize: 11, padding: 0.5 }}>
                                  Train Data % : {eachmodel[0][9][0]}
                                </Typography>
                                <Typography sx={{ fontSize: 11, padding: 0.5 }}>
                                  No of Layers : {eachmodel[0][9][1]}
                                </Typography>
                                <Typography sx={{ fontSize: 11, padding: 0.5 }}>
                                  No of Epochs : {eachmodel[0][9][2]}
                                </Typography>
                                <Typography sx={{ fontSize: 11, padding: 0.5 }}>
                                  Batch Size : {eachmodel[0][9][3]}
                                </Typography>
                              </div>
                            ) : (
                              <div></div>
                            )}

                            <Divider />

                            {eachmodel.map((item, i) => {
                              if (cat[i] === false) {
                                return (
                                  <div>
                                    <Typography
                                      sx={{
                                        fontSize: 11,
                                        padding: 0.5,
                                        marginTop: 1.2,
                                      }}
                                    >
                                      Dependent variable : {item[0]}
                                    </Typography>
                                    <Typography
                                      sx={{ fontSize: 11, padding: 0.5 }}
                                    >
                                      Machine Learning Method :{" "}
                                      {typeof item[1] === "string"
                                        ? item[1]
                                        : "ANN"}
                                    </Typography>

                                    <Typography
                                      sx={{ fontSize: 11, padding: 0.5 }}
                                    >
                                      R2 Score : {item[5]}
                                    </Typography>

                                    <Typography
                                      sx={{ fontSize: 11, padding: 0.5 }}
                                    >
                                      R2 - RMSE : {item[6]}
                                    </Typography>
                                    {timeseries ? null : (
                                      <div>
                                        <Typography
                                          sx={{ fontSize: 11, padding: 0.5 }}
                                        >
                                          Q2 Score : {item[7]}
                                        </Typography>

                                        <Typography
                                          sx={{ fontSize: 11, padding: 0.5 }}
                                        >
                                          Q2 - RMSE : {item[8]}
                                        </Typography>
                                      </div>
                                    )}

                                    <Divider />
                                  </div>
                                );
                              } else {
                                return (
                                  <div>
                                    <Typography
                                      sx={{
                                        fontSize: 11,
                                        padding: 0.5,
                                        marginTop: 1.2,
                                      }}
                                    >
                                      Dependent variable : {item[0]}
                                    </Typography>
                                    <Typography
                                      sx={{ fontSize: 11, padding: 0.5 }}
                                    >
                                      Machine Learning Method :{" "}
                                      {typeof item[1] === "string"
                                        ? item[1]
                                        : "ANN"}
                                    </Typography>

                                    <Typography
                                      sx={{ fontSize: 11, padding: 0.5 }}
                                    >
                                      Precision : {item[6][0]}
                                    </Typography>

                                    <Typography
                                      sx={{ fontSize: 11, padding: 0.5 }}
                                    >
                                      Recall : {item[6][1]}
                                    </Typography>
                                    <Typography
                                      sx={{ fontSize: 11, padding: 0.5 }}
                                    >
                                      F1 Score : {item[7]}
                                    </Typography>

                                    <Typography
                                      sx={{ fontSize: 11, padding: 0.5 }}
                                    >
                                      Accuracy : {item[8]}
                                    </Typography>

                                    <Divider />
                                  </div>
                                );
                              }
                            })}
                          </Box>
                        </div>
                      );
                    })}
                  </div>
                </Box>
                <Button
                  onClick={NextStep}
                  disabled={!props.editAccess}
                  mt={5}
                  sx={{
                    float: "left",
                    marginRight: 8,
                    width: 10,
                    height: 30,
                    fontSize: 16,
                    marginTop: 3,
                    marginLeft: 2,
                    backgroundColor: Projectcolors.primary,
                    color: "black",
                    fontStyle: "bold",
                  }}
                  variant="contained"
                  size="large"
                >
                  {" "}
                  <ChevronRightIcon sx={{ fontSize: "25px", color: "white" }} />
                </Button>
              </div>
            </div>
          </Box>
        </Box>
      )}

      {getLoader ? <GlobalLoader /> : null}
    </div>
  );
}

export default Validate;
