import React, { useEffect } from "react";
import Plot from "react-plotly.js";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Projectcolors from "../Utils/Colors";
import { formLabelClasses } from "@mui/material";
import { Alert } from "@mui/material";
import { baseURL } from "../Config/config";
import GlobalLoader from "./CommonC/GlobalLoader";

function NoiseRemoval(props) {
  const [rawdata, setRawData] = React.useState([]);

  const [lineplotdata, setLineplotdata] = React.useState([]);

  const [lineploty1, setLineploty1] = React.useState([]);
  const [smoothdata, setSmoothData] = React.useState([]);
  const [lineploty2, setLineploty2] = React.useState([]);
  const [rownumber, setRownumber] = React.useState(0);
  const [remove_noise_data, setRemovenoise] = React.useState([]);
  const [ylabel, setYLabel] = React.useState("Y axis");
  const [remove, setremove] = React.useState(false);
  const [saved, setsaved] = React.useState(false);
  const colorslist = [
    "#3366cc",
    "#dc3912",
    "#ff9900",
    "#109618",
    "#990099",
    "#0099c6",
    "#dd4477",
    "#aaaa11",
    "#743411",
    "#e67300",
    "#22aa99",
    "#994499",
    "#22aa99",
    "#66aa00",
    "#6633cc",
    "#3366cc",
    "#8b0707",
    "#651067",
    "#329262",
    "#5574a6",
    "#3b3eac",
    "#b77322",
    "#16d620",
    "#b91383",
    "#f4359e",
    "#9c5935",
    "#a9c413",
    "#2a778d",
    "#668d1c",
    "#bea413",
    "#0c5922",
    "#b82e2e",
  ];
  const [colors, setColors] = React.useState(colorslist[0]);
  const [getLoader, setGetLoader] = React.useState(false);

  useEffect(() => {
    noiseData();
  }, []);

  const noiseData = () => {
    setGetLoader(true);
    const ProjectID = localStorage.getItem("projectID");
    fetch(`${baseURL}/noisedata?projectID=${ProjectID}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())

      .then((raw) => {
        setRawData(raw.raw_data);
        setLineplotdata(raw.lineplotdata);
        setLineploty1(raw.lineplotdata[0]);
        setSmoothData(raw.smoothdata);
        setLineploty2(raw.smoothdata[0]);
        setRemovenoise(raw.lineplotdata);
        setGetLoader(false);
      })
      .catch((err) => {
        setGetLoader(false);
        console.log("caught it!", err);
      });
  };

  function handlerowclick(number) {
    setLineploty1(lineplotdata[number]);
    setLineploty2(smoothdata[number]);
    setRownumber(number);
    setYLabel(rawdata[number][1]);
    setColors(colorslist[number]);
    setremove(false);
    setsaved(false);
  }

  function removenoise() {
    //remove_noise_data[0] = lineploty2;
    remove_noise_data.splice(rownumber, 1, lineploty2);
    setremove(true);
    setsaved(false);
  }

  const savefiltereddata = () => {
    const ProjectID = localStorage.getItem("projectID");

    const body = {
      filtereddata: remove_noise_data,
      ProjectID,
    };

    try {
      fetch(`${baseURL}/savefilterdata`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((result) => {
          setsaved(true);
          setremove(false);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div>
      <Paper>
        <Box
          sx={{
            width: "95%",
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "red",
            marginLeft: 3,
            marginTop: 1,
          }}
        >
          <TableContainer
            component={Paper}
            style={{
              width: "100%",
              height: "250px",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: 0,
            }}
          >
            <Table
              sx={{ minWidth: 150 }}
              aria-label="sticky table"
              stickyHeader
            >
              <TableHead>
                <TableRow hover={true}>
                  <TableCell
                    sx={{
                      color: "#fff",
                      fontWeight: "bold",
                      fontSize: "12px",
                      width: 400,
                      backgroundColor: Projectcolors.primary,
                    }}
                  >
                    Tag Name
                  </TableCell>

                  <TableCell
                    sx={{
                      color: "#fff",
                      fontWeight: "bold",
                      fontSize: "12px",
                      width: 400,
                      backgroundColor: Projectcolors.primary,
                    }}
                  >
                    Description
                  </TableCell>

                  <TableCell
                    sx={{
                      color: "#fff",
                      fontWeight: "bold",
                      fontSize: "12px",
                      width: 400,
                      backgroundColor: Projectcolors.primary,
                    }}
                  >
                    Unit Of Measure
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rawdata
                  ? rawdata.map((item, i) => {
                      return (
                        <TableRow
                          hover={true}
                          onClick={() => handlerowclick(i)}
                          sx={{ width: 600 }}
                          key={i}
                        >
                          {item.map((data, idx) => {
                            return (
                              <TableCell
                                align="left"
                                key={data}
                                sx={{ width: 50, fontSize: "10px" }}
                              >
                                {data}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        {/* <div style={{display:'block',marginLeft:'15%',marginRight:'auto',marginTop:15,backgroundColor:'red'}}> */}

        <Box
          sx={{
            display: "flex",
            flex: 1,
            backgroundColor: "grey",
            justifyContent: "center",
            mt: 2,
          }}
        >
          <Plot
            data={[
              {
                x: Array.from(Array(lineploty1.length).keys()),
                y: lineploty1,
                type: "line",
                mode: "lines+markers",
                marker: { color: colors },
                name: " Raw data",
              },
              {
                x: Array.from(Array(lineploty2.length).keys()),
                y: lineploty2,
                type: "line",
                mode: "lines+markers",
                marker: { color: "blue" },
                name: "Smooth data",
              },
            ]}
            layout={{
              width: 950,
              height: 290,
              xaxis: {
                title: {
                  text: "Count",
                  font: {
                    size: 14,
                    color: "#000",
                  },
                },
              },
              yaxis: {
                title: {
                  text:
                    ylabel.length > 20
                      ? ylabel.substring(0, 20) + "..."
                      : ylabel,
                  font: {
                    size: 14,
                    color: "#000",
                  },
                },
              },
            }}
            config={{
              displaylogo: false,
            }}
          />
        </Box>
        {/* </div> */}

        <Box sx={{ display: "flex", flex: 1, flexDirection: "row" }}>
          <div>
            <Button
              onClick={removenoise}
              disabled={!props.editAccess}
              mr={4}
              sx={{
                marginLeft: 2,
                //width: "60px",
                height: 40,
                fontSize: "12px",
                backgroundColor: Projectcolors.primary,
                color: "#fff",
                fontStyle: "bold",
                marginBottom: 2,
              }}
              variant="contained"
              size="large"
            >
              {" "}
              Remove Noise
            </Button>
            <Button
              onClick={savefiltereddata}
              disabled={!props.editAccess}
              mr={4}
              sx={{
                marginLeft: 2,
                width: 140,
                height: 40,
                fontSize: "12px",
                backgroundColor: Projectcolors.primary,
                color: "#fff",
                fontStyle: "bold",
                marginBottom: 2,
              }}
              variant="contained"
              size="large"
            >
              {" "}
              Apply
            </Button>
          </div>
          <div>
            {remove ? (
              <div>
                <Alert
                  sx={{
                    width: "80%",
                    fontSize: "14px",
                    color: Projectcolors.primary,
                    marginBottom: 2,
                    marginLeft: 10,
                    marginRight: 10,
                  }}
                  variant="outlined"
                  severity="success"
                >
                  Noise Removed for {ylabel} Variable
                </Alert>
              </div>
            ) : (
              ""
            )}
            {saved ? (
              <div sx={{ marginLeft: 10, marginRight: 10 }}>
                <Alert
                  sx={{
                    width: "100%",
                    fontSize: "14px",
                    color: Projectcolors.primary,
                    marginBottom: 2,
                    marginLeft: 10,
                    marginRight: 10,
                  }}
                  variant="outlined"
                  severity="success"
                >
                  Filtered Data Saved Successfully
                </Alert>
              </div>
            ) : (
              ""
            )}
          </div>
        </Box>
      </Paper>
      {getLoader ? <GlobalLoader /> : null}
    </div>
  );
}

export default NoiseRemoval;
