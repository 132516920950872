import React from 'react';
import "./css/GettingStarted.css";
import image1 from "../images/14fpnew.JPG";
import image2 from "../images/15sc.JPG";
import image3 from "../images/16mcnew.JPG";
import image4 from "../images/17desktop.png";

function ModelDeploymentContent() {
  return (
    <div className='projectDetails'>
      <h2>Model Deployment</h2>
      <p>
        Model deployment is a critical phase in the machine learning and modeling process, where you take your trained model and make it available for practical use and predictions. In our case, you have described three scenarios: First Principles, Sensitivity Case, and Multicase. Here's an overview of each scenario:
      </p>

      <h4>1. First Principles:</h4>
      <p>
        In the "First Principles" scenario, you provide users with the capability to define new independent and dependent variables and their corresponding formulas.
        This allows users to create custom models based on their domain knowledge and hypotheses, even if these variables and formulas were not part of the initial model training.
        Users can input values for the independent variables and use their defined formulas to predict values for the dependent variables.
        This approach is useful when you want to enable users to experiment with different variables and hypotheses and generate predictions based on their specific needs.
      </p>
      <img src={image1} className='imagestyling' style={{width:'70%'}}/>

      <h4>2. Sensitivity Case:</h4>
      <p>
        In the "Sensitivity Case" scenario, users can input values for independent variables into the model.
        The model then predicts values for the dependent variables based on these input values.
        This feature allows users to perform sensitivity analysis, where they can explore how changes in independent variables affect the predictions of dependent variables.
      </p>
      <img src={image2} className='imagestyling' style={{width:'70%'}}/>

      <h4>3. Multicase:</h4>
      <p>
        In the "Multicase" scenario, users can input multiple sets of independent variables.
        The model processes each set of inputs and provides predictions for the corresponding dependent variables.
        This feature is useful when users want to obtain predictions for multiple scenarios or cases simultaneously, saving time and effort.
        Users can explore different combinations of input values and assess the model's predictions for various scenarios.
      </p>
      <img src={image3} className='imagestyling' style={{width:'70%'}}/>
      <h4>Deployment Methods</h4>
      <p>
        When it comes to deploying machine learning models, various methods can be used to make the model accessible to users. Here are some common deployment methods:
      </p>

      <h4>1. Web-Based Deployment:</h4>
      <ul>
        <li>
          In a web-based deployment, users can access and use the model through a web application hosted on a website.
        </li>
        <li>
          Users typically interact with the model by inputting their data and receiving predictions through a user-friendly web interface.
        </li>
        <li>
          This deployment method offers accessibility from any device with internet access, making it convenient for a wide range of users.
        </li>
      </ul>

      <h4>2. Desktop Insights Deployment:</h4>
      <ul>
        <li>
          Desktop insights deployment involves providing users with a downloadable and installable GUI (Graphical User Interface) application that runs on their local desktop or computer.
        </li>
        <li>
          Users can install the application on their desktop and use it offline to make predictions.
        </li>
      </ul>
      <img src={image4} className='imagestyling' style={{width:'70%'}}/>

      <h4>3. HYSYS Deployment:</h4>
      <ul>
        <li>
          HYSYS deployment involves creating a zip file that can be integrated with the HYSYS software.
        </li>
        <li>
          Users can unzip the file and integrate the model within the HYSYS environment, allowing them to use the model seamlessly with HYSYS.
        </li>
        <li>
          This deployment method is useful when the model needs to be tightly integrated with existing processes or simulations in the HYSYS software.
        </li>
      </ul>
    </div>
  );
}

export default ModelDeploymentContent;
