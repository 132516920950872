import React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import BuildModel from "../BuildModel";
import ANN from "../ANN";
import Forecasting from "../Forecasting";
import ScatterPlotMethod from "../ScatterPlotMethod";

function AllBuildModel(props) {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const NextStep = () => {
    props.handleNextStep();
  };
  return (
    <div>
      <Box sx={{ width: "100%", typography: "body1", marginTop: 9 }}>
        <TabContext value={value}>
          <Box
            sx={{ borderBottom: 1, borderColor: "divider" }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab
                label="MLOps"
                value="1"
                sx={{
                  fontSize: "17px",
                  textTransform: "none",
                  fontWeight: "bold",
                }}
              />
              <Tab
                label="Deep Learning"
                value="2"
                sx={{
                  fontSize: "17px",
                  textTransform: "none",
                  fontWeight: "bold",
                }}
              />
              <Tab
                label="Forecasting"
                value="3"
                sx={{
                  fontSize: "17px",
                  textTransform: "none",
                  fontWeight: "bold",
                }}
              />
              <Tab
                label="Scatter Plot Method"
                value="4"
                sx={{
                  fontSize: "17px",
                  textTransform: "none",
                  fontWeight: "bold",
                }}
              />
            </TabList>
          </Box>
          <TabPanel value="1">
            <BuildModel
              editAccess={props.editAccess}
              handleNextStep={props.handleNextStep}
              loaded={props.loaded}
              ProjectID={props.ProjectID}
            />
          </TabPanel>
          <TabPanel value="2">
            <ANN
              editAccess={props.editAccess}
              handleNextStep={props.handleNextStep}
              loaded={props.loaded}
              ProjectID={props.ProjectID}
            />
          </TabPanel>
          <TabPanel value="3">
            <Forecasting
              editAccess={props.editAccess}
              handleNextStep={props.handleNextStep}
              loaded={props.loaded}
              ProjectID={props.ProjectID}
            />
            
          </TabPanel>

          <TabPanel value="4">
          <ScatterPlotMethod
            editAccess={props.editAccess}
            handleNextStep={props.handleNextStep}
            loaded={props.loaded}
            ProjectID={props.ProjectID}
            />
            </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
}

export default AllBuildModel;
