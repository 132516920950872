import React, { useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Plot from "react-plotly.js";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import Heatmap from "./Heatmap";
import Projectcolors from "../Utils/Colors";
import { ListItemAvatar } from "@mui/material";
import { baseURL } from "../Config/config";
import GlobalLoader from "./CommonC/GlobalLoader";

const tableCellName = [
  "Tag Name",
  "Description",
  "Unit Of Measure",
  "Minimum",
  "Maximum",
  "Average",
  "Variation(%)",
  "Standard Deviation",
  "Coefficient of Variation",
];
function LinePlotRaw() {
  const [rawdata, setRawData] = React.useState([]);

  const [lineplotdata, setLineplotdata] = React.useState([]);

  const [lineplotx, setLineplotx] = React.useState([]);
  const [x, setX] = React.useState("");
  const [y, setY] = React.useState("");
  const [ylabel, setYLabel] = React.useState("Y axis");
  const [xlabelscatter, setXscatter] = React.useState("");
  const [ylabelscatter, setYscatter] = React.useState("");
  const [alltagname, setTagname] = React.useState([]);
  const colorslist = [
    "#3366cc",
    "#dc3912",
    "#ff9900",
    "#109618",
    "#990099",
    "#0099c6",
    "#dd4477",
    "#aaaa11",
    "#743411",
    "#e67300",
    "#22aa99",
    "#994499",
    "#22aa99",
    "#66aa00",
    "#6633cc",
    "#3366cc",
    "#8b0707",
    "#651067",
    "#329262",
    "#5574a6",
    "#3b3eac",
    "#b77322",
    "#16d620",
    "#b91383",
    "#f4359e",
    "#9c5935",
    "#a9c413",
    "#2a778d",
    "#668d1c",
    "#bea413",
    "#0c5922",
    "#b82e2e",
  ];
  const [colors, setColors] = React.useState(colorslist[0]);
  const [getLoader, setGetLoader] = React.useState(false);

  useEffect(() => {
    getRawData();
  }, []);

  const getRawData = () => {
    setGetLoader(true);
    const ProjectID = localStorage.getItem("projectID");
    fetch(`${baseURL}/getrawdata?projectID=${ProjectID}&scatterplot=${0}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())

      .then((raw) => {
        setRawData(raw.raw_data);
        setLineplotdata(raw.lineplotdata);
        setLineplotx(raw.lineplotdata[0]);
        setTagname(raw.tag_name);
        setX(0);
        setY(1);
        setXscatter(alltagname[0]);
        setYscatter(alltagname[1]);
        setGetLoader(false);
      })
      .catch((err) => {
        setGetLoader(false);
        console.log("caught it!", err);
      });
  };

  function handlerowclick(number) {
    setLineplotx(lineplotdata[number]);
    setYLabel(rawdata[number][1]);
    setXscatter(alltagname[0]);
    setYscatter(alltagname[1]);
    setColors(colorslist[number]);
  }

  return (
    <div>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "red",
          marginLeft: 0,
        }}
      >
        <TableContainer
          component={Paper}
          style={{
            width: "100%",
            height: "250px",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: 0,
          }}
        >
          <Table aria-label="sticky table" stickyHeader>
            <TableHead sx={{ height: 10 }}>
              <TableRow hover={true} sx={{ height: 2 }}>
                {tableCellName.map((item, i) => {
                  return (
                    <TableCell
                      sx={{
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "11px",
                        width: 400,
                        backgroundColor: Projectcolors.primary,
                      }}
                    >
                      {item}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {rawdata
                ? rawdata.map((item, i) => {
                    return (
                      <TableRow
                        hover={true}
                        onClick={() => handlerowclick(i)}
                        sx={{ width: "100%", height: 2, padding: 2 }}
                        key={i}
                      >
                        {item.map((data, idx) => {
                          return (
                            <TableCell
                              align="left"
                              key={data}
                              sx={{
                                width: 50,
                                fontSize: "10px",
                                height: 4,
                                padding: 5,
                              }}
                            >
                              {data}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
                : null}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          height: 280,
          marginTop: 1,
        }}
      >
        <Plot
          data={[
            {
              x: Array.from(Array(lineplotx.length).keys()),
              y: lineplotx,
              type: "line",
              mode: "lines+markers",
              marker: { color: colors },
            },
          ]}
          layout={{
            width: 900,
            height: 290,
            marginTop: 0,
            title: {
              font: {
                family: "Courier New, monospace",
                size: 24,
              },
              xref: "paper",
              x: 0.05,
            },
            xaxis: {
              title: {
                text: "Count",
                font: {
                  size: 14,
                  color: "#000",
                },
              },
            },
            yaxis: {
              title: {
                text:ylabel.length > 20 ? ylabel.substring(0, 20) + "..." : ylabel,
                font: {
                  size: 14,
                  color: "#000",
                },
              },
            },
          }}
          config={{
            displaylogo: false,
          }}
        />
      </Box>
      {getLoader ? <GlobalLoader /> : null}
    </div>
  );
}

export default LinePlotRaw;
