import React from "react";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { baseURL } from "../../Config/config";
import EditIcon from "@mui/icons-material/Edit";
import { makeStyles } from "@mui/styles";
import Projectcolors from "../../Utils/Colors";

const useStyles = makeStyles(() => ({
  disableRipple: {
    "&:hover": {
      backgroundColor: "transparent",
    },
    borderRadius: "3px",
  },
}));

const ShareProjectModal = (props) => {



  const navigate = useNavigate();

  const [isLoading, setIsLoading] = React.useState(false);

  

  const buttonStatus = !props.email ? true : false;
  const classes = useStyles();

  return (
    <Modal
      open={props.isModal}
      onClose={props.toggleModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Box sx={{ bgcolor: "#fff", width: "330px", padding: "10px 20px" }}>
        <Typography sx={{ fontSize: "12px" }}>
          Enter user details to share with
        </Typography>
        <TextField
          fullWidth
          id="outlined-basic"
          variant="outlined"
          placeholder="Email"
          onChange={(e) => props.setEmail(e.target.value)}
          sx={{ fontSize: "30px", margin: "8px 0 0 0 " }}
          InputLabelProps={{ style: { fontSize: 12 } }}
          inputProps={{ style: { fontSize: 12, height: "4px" } }}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            marginTop: "10px",
          }}
        >
          <Button
            disabled={buttonStatus}
            onClick={async() =>props.handleShareProject(false)}
            sx={{
              fontSize: "10px",
              textTransform: "none",
            }}
            variant="contained"
          >
            Read Only
          </Button>
          <Button
            disabled={buttonStatus}
            onClick={() => props.handleShareProject(true)}
            sx={{
              fontSize: "10px",
              marginLeft: "7px",
              textTransform: "none",
            }}
            variant="contained"
          >
            Edit Access
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ShareProjectModal;
