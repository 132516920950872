import React from "react";
import Box from "@mui/material/Box";
import Projectcolors from "../Utils/Colors";
import { baseURL } from "../Config/config";
import { Typography, Link, DialogContent } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import OutlinedInput from "@mui/material/OutlinedInput";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Alert from "@mui/material/Alert";
import { makeStyles } from "@mui/styles";
import GlobalLoader from "./CommonC/GlobalLoader";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { Checkbox, CircularProgress } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from "@mui/icons-material/Delete";
import model from "../images/model.png";
import SearchIcon from "@mui/icons-material/Search";
import { AppBar, Tab, Tabs } from '@mui/material';



export default function FirstPrinciples(props) {
  const [fp, setfp] = React.useState([
    [
      ["123445", "Reflux Ratio()", "", ""],
      ["12348798", "Reflux Ratio()", "", ""],
      ["12348798", "Reflux Ratio()", "", ""],
    ],
    [
      ["123445", "Reflux Ratio()", "", "", null],
      ["123445", "Reflux Ratio()", "", "", null],
    ],
  ]);

  const [addfprow, setaddfprow] = React.useState([
    [false, false, false],
    [false, false],
  ]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [getvalues, setgetValues] = React.useState([]);
  const [falsewarning, setfalsewarning] = React.useState(false);

  const [getLoader, setGetLoader] = React.useState(false);
  const [isChecked, setIsChecked] = React.useState(false);
  const [saved, setSaved] = React.useState(false);
  const [isPopupOpen, setPopupOpen] = React.useState(false);
  const [info, setinfo] = React.useState(false);
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [formula, setFormula] = React.useState("");
  const [formind, setFormind] = React.useState(0);


  function addfpin() {
    const fpcopy = Array.from(fp);
    const addfprowcopy = Array.from(addfprow);
    fpcopy[0].push(["", "", "", ""]);
    addfprowcopy[0].push(true);
    setaddfprow(addfprowcopy);
    setfp(fpcopy);
  }

  const handleMagnifyClick = (formulaa,i) => {
    setFormula(formulaa)
    setFormind(i)
    setPopupOpen(true);
  };

  const handleInfoClick = () => {
    setinfo(true);
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
  };
  const handleCloseinfo = () => {
    setinfo(false);
  };
  

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  function savefp() {
    const ProjectID = localStorage.getItem("projectID");
    var save = false;
    for (var i = 0; i < fp[0].length; i++) {
      if (fp[0][i][2] == "") {
        setfalsewarning(true);
        save = true;
      }
    }
    for (var i = 0; i < fp[1].length; i++) {
      if (fp[1][i][2] == "") {
        setfalsewarning(true);
        save = true;
      }
    }

    if (save == false) {
      setIsLoading(true);
      try {
        fetch(`${baseURL}/savefp`, {
          method: "POST",
          body: JSON.stringify({
            fp: fp,
            fprow: addfprow,
            ProjectID: ProjectID,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())
          .then((result) => {
            setIsLoading(false);
            setSaved(true);
            //props.handleNextStep();
          })
          .catch((error) => {
            setIsLoading(false);
            console.error("Error:", error);
          });
      } catch (error) {
        setIsLoading(false);
        console.log("Error", error);
      }
    }
  }
  function addfpde() {
    const fpcopy = Array.from(fp);
    fpcopy[1].push(["", "", "", "", ""]);
    setfp(fpcopy);
    const addfprowcopy = Array.from(addfprow);
    addfprowcopy[1].push(true);
    setaddfprow(addfprowcopy);
  }

  const handleDeclaInp = (event, idx, j) => {
    if (j == "ind_kpi") {
      const fpcopy = Array.from(fp);
      fpcopy[0][idx][0] = event.target.value;
      setfp(fpcopy);
    }
    if (j == "ind_desc") {
      const fpcopy = Array.from(fp);
      fpcopy[0][idx][1] = event.target.value;
      setfp(fpcopy);
    }
    if (j == "ind_declaration") {
      const fpcopy = Array.from(fp);
      fpcopy[0][idx][2] = event.target.value;
      setfp(fpcopy);
    }
    if (j == "ind_remark") {
      const fpcopy = Array.from(fp);
      fpcopy[0][idx][3] = event.target.value;
      setfp(fpcopy);
    }
    if (j == "dep_kpi") {
      const fpcopy = Array.from(fp);
      fpcopy[1][idx][0] = event.target.value;
      setfp(fpcopy);
    }
    if (j == "dep_desc") {
      const fpcopy = Array.from(fp);
      fpcopy[1][idx][1] = event.target.value;
      setfp(fpcopy);
    }
    if (j == "dep_declaration") {
      const fpcopy = Array.from(fp);
      fpcopy[1][idx][2] = event.target.value;
      setfp(fpcopy);
    }
    if (j == "dep_remark") {
      const fpcopy = Array.from(fp);
      fpcopy[1][idx][3] = event.target.value;
      setfp(fpcopy);
    }
    if (j == "dep_calculations") {
      const fpcopy = Array.from(fp);
      fpcopy[1][idx][4] = event.target.value;
      setfp(fpcopy);
      setFormula(event.target.value);
    }
  };
  console.log("fp[0]", fp[0]);
  console.log("fp[1]", fp[1]);
  console.log("addfprow", addfprow);

  React.useEffect(() => {
    getfpData();
  }, []);

  const getfpData = () => {
    setGetLoader(true);
    const projectID = localStorage.getItem("projectID");
    fetch(`${baseURL}/getfpData?projectID=${projectID}&loaded=${props.loaded}`)
      .then((res) => res.json())
      .then((data) => {
        console.log("response reveived");
        console.log("data", data);
        setfp(data.fp);
        setaddfprow(data.fprow);
        setGetLoader(false);
        if (data.saved == 1) {
          setgetValues(data.fp);
        }
        if (data.usefp == true) {
          setIsChecked(true);
        } else {
          setIsChecked(false);
        }
      })
      .catch((err) => {
        setGetLoader(false);
      });
  };

  function deleteSnap(x, idx) {
    console.log("deleteSnap", idx);
    const fpcopy = Array.from(fp);
    const addfprowcopy = Array.from(addfprow);
    fpcopy[x].splice(idx, 1);
    addfprowcopy[x].splice(idx, 1);
    setfp(fpcopy);
    setaddfprow(addfprowcopy);
  }
  const handlefalsewarningClose = () => {
    setfalsewarning(false);
  };

  const handlefpChange = (e) => {
    setIsChecked(e.target.checked);
    const ProjectID = localStorage.getItem("projectID");
    try {
      fetch(`${baseURL}/usefp`, {
        method: "POST",
        body: JSON.stringify({
          usefp: e.target.checked,
          ProjectID: ProjectID,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((result) => {
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          console.error("Error:", error);
        });
    } catch (error) {
      setIsLoading(false);
      console.log("Error", error);
    }
  };

  console.log("addfprow", addfprow);
  console.log("data", fp);
  const operatorsData = [
    { operator: '()', name: 'Brackets', example: '(a + b)/2' },
    { operator: '+', name: 'Addition', example: 'a + b' },
    { operator: '-', name: 'Subtraction', example: 'a - b' },
    { operator: '*', name: 'Multiplication', example: 'a * b' },
    { operator: '/', name: 'Division', example: 'a / b' },
    { operator: '%', name: 'Modulus', example: 'a % b' },
    { operator: '**', name: 'Exponentiation', example: 'a ** b' },
    { operator: '//', name: 'Floor Division', example: 'a // b' },
    // Add more operators as needed
  ];
  
  const functionsData = [
    { fnname: 'abs', name: 'Absolute Value', example: 'abs(x)' },
    { fnname: 'sqrt', name: 'Square Root', example: 'sqrt(x)' },
    { fnname: 'pow', name: 'Exponentiation', example: 'pow(x, y)' },
    { fnname: 'exp', name: 'Exponential', example: 'exp(x)' },
    { fnname: 'log', name: 'Logarithm', example: 'log(x, base)' },
    { fnname: 'sin', name: 'Sine', example: 'sin(x)' },
    { fnname: 'cos', name: 'Cosine', example: 'cos(x)' },
    { fnname: 'tan', name: 'Tangent', example: 'tan(x)' },
    { fnname: 'asin', name: 'Arcsine', example: 'asin(x)' },
    { fnname: 'acos', name: 'Arccosine', example: 'acos(x)' },
    { fnname: 'atan', name: 'Arctangent', example: 'atan(x)' },
    { fnname: 'floor', name: 'Floor', example: 'floor(x)' },
    { fnname: 'ceil', name: 'Ceiling', example: 'ceil(x)' },
    // Add more functions as needed
  ];
 

  return (
    <div style={{ marginTop: "0px" }}>

    <div className="popup">
      <Dialog
        open={info}
        onClose={handleCloseinfo}
        
      >
        <DialogTitle sx={{fontSize:"14px", color: Projectcolors.primary}}>Available Expressions and Functions</DialogTitle>
<DialogContent sx={{padding:'20px'}}>
<AppBar position="static"  >
        <Tabs  onChange={handleTabChange}>
          <Tab label="Mathematical Operators" value={0} style={{color:'white',fontSize:"13px" , textTransform: 'capitalize'}}/>
          <Tab label="Mathematical Functions" value={1} style={{color:'white',fontSize:"13px" , textTransform: 'capitalize'}} />
        </Tabs>
      </AppBar>
      <Paper elevation={3} style={{ padding: '10px' }}>
        {selectedTab === 0 && (
          <div>
            <table>
              <tbody>
                {operatorsData.map((item, index) => (
                  <tr key={index}>
                    <td style={{padding:'10px',fontSize: "13PX"}}>{item.operator}</td>
                    <td style={{padding:'10px',fontSize: "13PX"}}>{item.name}</td>
                    <td style={{padding:'10px',fontSize: "13PX"}}>{item.example}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <hr />
            <p style={{fontSize:'13px',fontWeight:'bold'}}>Example :  </p>
            <p style={{fontSize:'13px'}}>x1 = b + c</p>
            <p style={{fontSize:'13px'}}>x2 = ( b // c ) * x1</p>
            <p style={{fontSize:'13px'}}>x3= x1 + x2</p>
          </div>
        )}
        {selectedTab === 1 && (
          <div>
            <table>
              <tbody>
                {functionsData.map((item, index) => (
                  <tr key={index}>
                    <td style={{padding:'10px',fontSize: "13PX"}}>{item.fnname}</td>
                    <td style={{padding:'10px',fontSize: "13PX"}}>{item.name}</td>
                    <td style={{padding:'10px',fontSize: "13PX"}}>{item.example}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <hr/>
            <p style={{fontSize:'13px',fontWeight:'bold'}}>Example :</p>
            <p style={{fontSize:'13px'}}> Y = log(x) + sin(x)</p>

          </div>
        )}
      </Paper>
      </DialogContent>
        
        
      </Dialog>
    </div>
                                  
      <Dialog
        open={falsewarning}
        onClose={handlefalsewarningClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ color: "red", fontSize: "13px" }}
        >
          {"Declarations cannot be empty"}
        </DialogTitle>
      </Dialog>
      <Typography sx={{ fontSize: "14PX", mt: 1 }}>
        <Checkbox
          color="primary"
          inputProps={{
            "aria-label": "select all desserts",
          }}
          checked={isChecked}
          size="medium"
          onChange={handlefpChange}
        />
        <span style={{ marginTop: "20px" }}>Active First Principles</span>
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          border: 0,
          marginTop: 1,
        }}
      >
        <Box
          component={Paper}
          elevation={10}
          //m="auto"
          sx={{
            height: 555,
            width: 1790,
            padding: 2,
            display: "flex",
            flexDirection: "row",
            border: 0,
          }}
        >
          <Box
            sx={{
              border: 1,
              marginTop: 1,
              mr: 2,
              width: 690,
              height: 480,
              borderColor: Projectcolors.primary,
              padding: 2,
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                //border:"4px solid black",
                position: "relative",
                top: -26,
                left: 15,
                width: 160,
                height: 12,
              }}
            >
              <Typography sx={{ fontSize: "13PX", fontWeight: "bold" }}>
                &nbsp;&nbsp;Independent Variables
              </Typography>
            </div>
            <Button
              onClick={() => addfpin()}
              disabled={!props.editAccess}
              sx={{
                width: 180,
                height: 35,
                fontSize: "12px",
                backgroundColor: Projectcolors.primary,
                color: "#fff",
                fontStyle: "bold",
                float: "left",
                ml: 0,
                marginTop: 0,
                marginBottom: 2,
                textTransform: "none",
              }}
              variant="contained"
              size="small"
              textTransform="none"
            >
              Add New Independent
            </Button>
            <TableContainer component={Paper} sx={{ height: 360 }}>
              <Table
                sx={{ minWidth: 495 }}
                aria-label="sticky table"
                stickyHeader
             >
                <TableHead
                  sx={{
                    backgroundColor: Projectcolors.primary,
                  }}
                >
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: "12px",
                        color: "white",
                        backgroundColor: Projectcolors.primary,
                      }}
                    >
                      KPI
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: "12px",
                        color: "white",
                        backgroundColor: Projectcolors.primary,
                      }}
                    >
                      Description
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: "12px",
                        color: "white",
                        backgroundColor: Projectcolors.primary,
                      }}
                    >
                      Declaration
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: "12px",
                        color: "white",
                        backgroundColor: Projectcolors.primary,
                      }}
                    >
                      Remark
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: "12px",
                        color: "white",
                        backgroundColor: Projectcolors.primary,
                      }}
                    >
                      Delete
                    </TableCell>
                  </TableRow>
                </TableHead>
                {getvalues.length
                  ? getvalues[0].map((row, i) => (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        {addfprow[0][i] == true ? (
                          <TableCell
                            sx={{ fontSize: "10px", height: 1, width: 50 }}
                            align="center"
                          >
                            <FormControl
                              sx={{
                                float: "right",
                                marginRight: 0,
                                marginTop: 0,
                                marginLeft: 0,
                                width: 100,
                              }}
                              size="small"
                            >
                              {""}
                              <OutlinedInput
                                id="outlined-basic"
                                variant="outlined"
                                placeholder=""
                                defaultValue={row[0]}
                                onChange={(e) => {
                                  handleDeclaInp(e, i, "ind_kpi");
                                }}
                                sx={{
                                  fontSize: "11px",
                                  height: "30px",
                                }}
                                size="small"
                              />
                            </FormControl>
                          </TableCell>
                        ) : (
                          <TableCell
                            align="center"
                            sx={{ fontSize: "10px", height: 1 }}
                          >
                            {row[0]}
                          </TableCell>
                        )}

                        {addfprow[0][i] == true ? (
                          <TableCell
                            sx={{ fontSize: "10px", height: 1 }}
                            align="center"
                          >
                            <FormControl
                              sx={{
                                float: "right",
                                marginRight: 0,
                                marginTop: 0,
                                marginLeft: 0,
                                width: 100,
                              }}
                              size="small"
                            >
                              {""}
                              <OutlinedInput
                                id="outlined-basic"
                                variant="outlined"
                                placeholder=""
                                defaultValue={row[1]}
                                onChange={(e) => {
                                  handleDeclaInp(e, i, "ind_desc");
                                }}
                                sx={{
                                  fontSize: "11px",
                                  height: "30px",
                                }}
                                size="small"
                              />
                            </FormControl>
                          </TableCell>
                        ) : (
                          <TableCell
                            sx={{ fontSize: "10px", height: 1 }}
                            align="center"
                          >
                            {row[1]}
                          </TableCell>
                        )}

                        <TableCell
                          align="center"
                          sx={{ fontSize: "10px", height: 1 }}
                        >
                          <FormControl
                            sx={{
                              float: "right",
                              marginRight: 0,
                              marginTop: 0,
                              marginLeft: 0,
                              width: 160,
                            }}
                            size="small"
                          >
                            {""}
                            <OutlinedInput
                              id="outlined-basic"
                              variant="outlined"
                              placeholder=""
                              defaultValue={row[2]}
                              onChange={(e) => {
                                handleDeclaInp(e, i, "ind_declaration");
                              }}
                              sx={{
                                width: "135px",
                                fontSize: "11px",
                                height: "30px",
                              }}
                              size="small"
                            />
                          </FormControl>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ fontSize: "10px", height: 1 }}
                        >
                          <FormControl
                            sx={{
                              float: "right",
                              marginRight: 0,
                              marginTop: 0,
                              marginLeft: 0,
                              width: 160,
                            }}
                            size="small"
                          >
                            {""}
                            <TextField
                              id="outlined-basic"
                              variant="outlined"
                              multiline
                              onChange={(e) => {
                                handleDeclaInp(e, i, "ind_remark");
                              }}
                              defaultValue={row[3]}
                              rows={2}
                              maxRows={4}
                              placeholder=""
                              sx={{
                                fontSize: "11px",
                              }}
                              size="small"
                            />
                          </FormControl>
                        </TableCell>

                        {addfprow[0][i] == true ? (
                          <TableCell>
                            <DeleteIcon
                              onClick={() => deleteSnap(0, i)}
                              sx={{
                                display: "inline",
                                fontSize: 15,
                                float: "right",
                                marginTop: 1,
                                marginRight: 2,
                                color: Projectcolors.primary,
                              }}
                            />
                          </TableCell>
                        ) : (
                          <TableCell>
                            <DeleteIcon
                              disabled={true}
                              sx={{
                                display: "inline",
                                fontSize: 15,
                                float: "right",
                                marginTop: 1,
                                marginRight: 2,
                                color: "lightgrey",
                              }}
                            />
                          </TableCell>
                        )}
                      </TableRow>
                    ))
                  : fp[0].map((row, i) => (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        {addfprow[0][i] == true ? (
                          <TableCell
                            sx={{ fontSize: "10px", height: 1, width: 50 }}
                            align="center"
                          >
                            <FormControl
                              sx={{
                                float: "right",
                                marginRight: 0,
                                marginTop: 0,
                                marginLeft: 0,
                                width: 100,
                              }}
                              size="small"
                            >
                              <OutlinedInput
                                id="outlined-basic"
                                variant="outlined"
                                placeholder=""
                                onChange={(e) => {
                                  handleDeclaInp(e, i, "ind_kpi");
                                }}
                                sx={{
                                  fontSize: "11px",
                                  height: "30px",
                                }}
                                size="small"
                              />
                            </FormControl>
                          </TableCell>
                        ) : (
                          <TableCell
                            align="center"
                            sx={{ fontSize: "10px", height: 1 }}
                          >
                            {row[0]}
                          </TableCell>
                        )}

                        {addfprow[0][i] == true ? (
                          <TableCell
                            sx={{ fontSize: "10px", height: 1 }}
                            align="center"
                          >
                            <FormControl
                              sx={{
                                float: "right",
                                marginRight: 0,
                                marginTop: 0,
                                marginLeft: 0,
                                width: 100,
                              }}
                              size="small"
                            >
                              <OutlinedInput
                                id="outlined-basic"
                                variant="outlined"
                                placeholder=""
                                onChange={(e) => {
                                  handleDeclaInp(e, i, "ind_desc");
                                }}
                                sx={{
                                  fontSize: "11px",
                                  height: "30px",
                                }}
                                size="small"
                              />
                            </FormControl>
                          </TableCell>
                        ) : (
                          <TableCell
                            sx={{ fontSize: "10px", height: 1 }}
                            align="center"
                          >
                            {row[1]}
                          </TableCell>
                        )}

                        <TableCell
                          align="center"
                          sx={{ fontSize: "10px", height: 1 }}
                        >
                          <FormControl
                            sx={{
                              float: "right",
                              marginRight: 0,
                              marginTop: 0,
                              marginLeft: 0,
                              width: 160,
                            }}
                            size="small"
                          >
                            <OutlinedInput
                              id="outlined-basic"
                              variant="outlined"
                              placeholder=""
                              onChange={(e) => {
                                handleDeclaInp(e, i, "ind_declaration");
                              }}
                              sx={{
                                width: "135px",
                                fontSize: "11px",
                                height: "30px",
                              }}
                              size="small"
                            />
                          </FormControl>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ fontSize: "10px", height: 1 }}
                        >
                          <FormControl
                            sx={{
                              float: "right",
                              marginRight: 0,
                              marginTop: 0,
                              marginLeft: 0,
                              width: 160,
                            }}
                            size="small"
                          >
                            <TextField
                              id="outlined-basic"
                              variant="outlined"
                              multiline
                              onChange={(e) => {
                                handleDeclaInp(e, i, "ind_remark");
                              }}
                              rows={2}
                              maxRows={4}
                              placeholder=""
                              sx={{
                                fontSize: "11px",
                              }}
                              size="small"
                            />
                          </FormControl>
                        </TableCell>

                        {addfprow[0][i] == true ? (
                          <TableCell>
                            <DeleteIcon
                              onClick={() => deleteSnap(0, i)}
                              sx={{
                                display: "inline",
                                fontSize: 15,
                                float: "right",
                                marginTop: 1,
                                marginRight: 2,
                                color: Projectcolors.primary,
                              }}
                            />
                          </TableCell>
                        ) : (
                          <TableCell>
                            <DeleteIcon
                              disabled={true}
                              sx={{
                                display: "inline",
                                fontSize: 15,
                                float: "right",
                                marginTop: 1,
                                marginRight: 2,
                                color: "lightgrey",
                              }}
                            />
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
              </Table>
            </TableContainer>
          </Box>
          <Box sx={{ height: 390 }}>
            <Box
              sx={{
                width: 355,
                height: 310,
                padding: 5,
                boxShadow: 9,
                border: 1,
                borderWidth: "4px",
                borderColor: Projectcolors.primary,
                borderRadius: 3,
                fontSize: 20,
                textAlign: "center",
                marginTop: 12,
                marginRight: 1,
              }}
            >
              <Typography sx={{ fontSize: "13px" }}>
                SelexMB Trained Model
              </Typography>

              <img
                alt="model"
                src={model}
                style={{
                  marginTop: 40,
                  objectFit: "contain",
                  height: "130px",
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              border: 1,
              marginTop: 1,
              mr: 2,
              width: 660,
              height: 480,
              borderColor: Projectcolors.primary,
              padding: 2,
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                //border:"4px solid black",
                position: "relative",
                top: -28,
                left: 15,
                width: 150,
                height: 12,
              }}
            >
              <Typography sx={{ fontSize: "13PX", fontWeight: "bold" }}>
                &nbsp;&nbsp;Dependent Variables
              </Typography>
            </div>
            <Button
              onClick={() => addfpde()}
              disabled={!props.editAccess}
              sx={{
                width: 180,
                height: 35,
                fontSize: "12px",
                backgroundColor: Projectcolors.primary,
                color: "#fff",
                fontStyle: "bold",
                float: "left",
                ml: 0,
                marginTop: 0,
                marginBottom: 2,
                textTransform: "none",
              }}
              variant="contained"
              size="small"
              textTransform="none"
            >
              Add New Dependent
            </Button>
            <TableContainer component={Paper} sx={{ height: 300 }}>
              <Table sx={{ minWidth: 495 }} aria-label="sticky table" stickyHeader>
                <TableHead
                  sx={{
                    backgroundColor: Projectcolors.primary,
                  }}
                >
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{ fontSize: "12px", color: "white",backgroundColor: Projectcolors.primary }}
                    >
                      KPI
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ fontSize: "12px", color: "white",backgroundColor: Projectcolors.primary }}
                    >
                      Description
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ fontSize: "12px", color: "white" ,backgroundColor: Projectcolors.primary}}
                    >
                      Declaration
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ fontSize: "12px", color: "white",backgroundColor: Projectcolors.primary }}
                    >
                      Remark
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ fontSize: "12px", color: "white" ,backgroundColor: Projectcolors.primary}}
                    >
                      Calculations
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ fontSize: "12px", color: "white" , backgroundColor: Projectcolors.primary }}
                    >
                      Delete
                    </TableCell>
                  </TableRow>
                </TableHead>
                {getvalues.length
                  ? getvalues[1].map((row, i) => (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        {addfprow[1][i] == true ? (
                          <TableCell
                            sx={{ fontSize: "10px", height: 1 }}
                            align="center"
                          >
                            <FormControl
                              sx={{
                                float: "right",
                                marginRight: 0,
                                marginTop: 0,
                                marginLeft: 0,
                                width: 100,
                              }}
                              size="small"
                            >
                              {""}
                              <OutlinedInput
                                id="outlined-basic"
                                variant="outlined"
                                placeholder=""
                                defaultValue={row[0]}
                                onChange={(e) => {
                                  handleDeclaInp(e, i, "dep_kpi");
                                }}
                                sx={{
                                  fontSize: "11px",
                                  height: "30px",
                                }}
                                size="small"
                              />
                            </FormControl>
                          </TableCell>
                        ) : (
                          <TableCell
                            align="center"
                            sx={{ fontSize: "10px", height: 1 }}
                          >
                            {row[0]}
                          </TableCell>
                        )}

                        {addfprow[1][i] == true ? (
                          <TableCell
                            sx={{ fontSize: "10px", height: 1 }}
                            align="center"
                          >
                            <FormControl
                              sx={{
                                float: "right",
                                marginRight: 0,
                                marginTop: 0,
                                marginLeft: 0,
                                width: 100,
                              }}
                              size="small"
                            >
                              {""}
                              <OutlinedInput
                                id="outlined-basic"
                                variant="outlined"
                                placeholder=""
                                defaultValue={row[1]}
                                onChange={(e) => {
                                  handleDeclaInp(e, i, "dep_desc");
                                }}
                                sx={{
                                  fontSize: "11px",
                                  height: "30px",
                                }}
                                size="small"
                              />
                            </FormControl>
                          </TableCell>
                        ) : (
                          <TableCell
                            sx={{ fontSize: "10px", height: 1 }}
                            align="center"
                          >
                            {row[1]}
                          </TableCell>
                        )}
                        <TableCell
                          align="center"
                          sx={{ fontSize: "10px", height: 1 }}
                        >
                          <FormControl
                            sx={{
                              float: "right",
                              marginRight: 0,
                              marginTop: 0,
                              marginLeft: 0,
                              width: 100,
                            }}
                            size="small"
                          >
                            {""}
                            <OutlinedInput
                              id="outlined-basic"
                              variant="outlined"
                              defaultValue={row[2]}
                              onChange={(e) => {
                                handleDeclaInp(e, i, "dep_declaration");
                              }}
                              placeholder=""
                              sx={{
                                fontSize: "11px",
                                height: "30px",
                              }}
                              size="small"
                            />
                          </FormControl>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ fontSize: "10px", height: 1 }}
                        >
                          <FormControl
                            sx={{
                              float: "right",
                              marginRight: 0,
                              marginTop: 0,
                              marginLeft: 0,
                              width: 100,
                            }}
                            size="small"
                          >
                            <TextField
                              id="outlined-basic"
                              variant="outlined"
                              defaultValue={row[3]}
                              onChange={(e) => {
                                handleDeclaInp(e, i, "dep_remark");
                              }}
                              multiline
                              rows={2}
                              maxRows={4}
                              placeholder=""
                              sx={{
                                fontSize: "11px",
                              }}
                              size="small"
                            />
                          </FormControl>
                        </TableCell>

                        {addfprow[1][i] == true ? (
                          <TableCell align="center" sx={{ height: 1 }}>
                            <Box sx={{ display: "flex", flexDirection: "row" }}>
                              <Box>
                                <FormControl
                                  sx={{
                                    float: "right",
                                    marginRight: 0,
                                    marginTop: 0,
                                    marginLeft: 0,
                                    width: 100,
                                  }}
                                  size="small"
                                >
                                  {""}
                                  <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    defaultValue={row[4]}
                                    onChange={(e) => {
                                      handleDeclaInp(e, i, "dep_calculations");
                                    }}
                                    multiline
                                    rows={3}
                                    maxRows={30}
                                    placeholder=""
                                    sx={{
                                      fontSize: "11px",
                                    }}
                                    value={row[4]}
                                    size="small"
                                  />

                                      
                                </FormControl>
                              </Box>
                              <Box>
                                <IconButton onClick={handleInfoClick}>
                                    <InfoIcon fontSize="small" />
                                </IconButton>
                                
                                <IconButton onClick={()=>handleMagnifyClick(row[4],i)}>
                                  <SearchIcon />
                                </IconButton>
                                <Dialog
                                        open={isPopupOpen}
                                        onClose={handleClosePopup}
                                        
                                      >
                                        
                                        <DialogTitle sx={{fontSize:"14px", color: Projectcolors.primary}}>First Principle Coding</DialogTitle>
                                        <TextField
                                          id="outlined-basic"
                                          variant="outlined"
                                          defaultValue={formula}
                                          onChange={(e) => {
                                            handleDeclaInp(
                                              e,
                                              formind,
                                              "dep_calculations"
                                            );
                                          }}
                                          multiline
                                          rows={10}
                                          maxRows={30}
                                          placeholder=""
                                          sx={{
                                            width: "400px",
                                            fontSize: "11px",
                                            margin:"10px"
                                          }}
                                        />
                                      </Dialog>
                              </Box>
                            </Box>
                          </TableCell>
                        ) : (
                          <TableCell
                            align="center"
                            sx={{ fontSize: "10px", height: 1 }}
                          >
                            None
                          </TableCell>
                        )}

                        {addfprow[1][i] == true ? (
                          <TableCell>
                            <DeleteIcon
                              onClick={() => deleteSnap(1, i)}
                              sx={{
                                display: "inline",
                                fontSize: 15,
                                float: "right",
                                marginTop: 1,
                                marginRight: 2,
                                color: Projectcolors.primary,
                              }}
                            />
                          </TableCell>
                        ) : (
                          <TableCell>
                            <DeleteIcon
                              disabled={true}
                              sx={{
                                display: "inline",
                                fontSize: 15,
                                float: "right",
                                marginTop: 1,
                                marginRight: 2,
                                color: "lightgrey",
                              }}
                            />
                          </TableCell>
                        )}
                      </TableRow>
                    ))
                  : fp[1].map((row, i) => (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        
                        {addfprow[1][i] == true ? (
                          <TableCell
                            sx={{ fontSize: "10px", height: 1 }}
                            align="center"
                          >
                            <FormControl
                              sx={{
                                float: "right",
                                marginRight: 0,
                                marginTop: 0,
                                marginLeft: 0,
                                width: 100,
                              }}
                              size="small"
                            >
                              <OutlinedInput
                                id="outlined-basic"
                                variant="outlined"
                                placeholder=""
                                onChange={(e) => {
                                  handleDeclaInp(e, i, "dep_kpi");
                                }}
                                sx={{
                                  fontSize: "11px",
                                  height: "30px",
                                }}
                                size="small"
                              />
                            </FormControl>
                          </TableCell>
                        ) : (
                          <TableCell
                            align="center"
                            sx={{ fontSize: "10px", height: 1 }}
                          >
                            {row[0]}
                          </TableCell>
                        )}

                        {addfprow[1][i] == true ? (
                          <TableCell
                            sx={{ fontSize: "10px", height: 1 }}
                            align="center"
                          >
                            <FormControl
                              sx={{
                                float: "right",
                                marginRight: 0,
                                marginTop: 0,
                                marginLeft: 0,
                                width: 100,
                              }}
                              size="small"
                            >
                              <OutlinedInput
                                id="outlined-basic"
                                variant="outlined"
                                placeholder=""
                                onChange={(e) => {
                                  handleDeclaInp(e, i, "dep_desc");
                                }}
                                sx={{
                                  fontSize: "11px",
                                  height: "30px",
                                }}
                                size="small"
                              />
                            </FormControl>
                          </TableCell>
                        ) : (
                          <TableCell
                            sx={{ fontSize: "10px", height: 1 }}
                            align="center"
                          >
                            {row[1]}
                          </TableCell>
                        )}
                        <TableCell
                          align="center"
                          sx={{ fontSize: "10px", height: 1 }}
                        >
                          <FormControl
                            sx={{
                              float: "right",
                              marginRight: 0,
                              marginTop: 0,
                              marginLeft: 0,
                              width: 100,
                            }}
                            size="small"
                          >
                            <OutlinedInput
                              id="outlined-basic"
                              variant="outlined"
                              onChange={(e) => {
                                handleDeclaInp(e, i, "dep_declaration");
                              }}
                              placeholder=""
                              sx={{
                                fontSize: "11px",
                                height: "30px",
                              }}
                              size="small"
                            />
                          </FormControl>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ fontSize: "10px", height: 1 }}
                        >
                          <FormControl
                            sx={{
                              float: "right",
                              marginRight: 0,
                              marginTop: 0,
                              marginLeft: 0,
                              width: 100,
                            }}
                            size="small"
                          >
                            <TextField
                              id="outlined-basic"
                              variant="outlined"
                              onChange={(e) => {
                                handleDeclaInp(e, i, "dep_remark");
                              }}
                              multiline
                              rows={2}
                              maxRows={4}
                              placeholder=""
                              sx={{
                                fontSize: "11px",
                              }}
                              size="small"
                            />
                          </FormControl>
                        </TableCell>

                        {addfprow[1][i] == true ? (
                          <TableCell
                            align="center"
                            sx={{ fontSize: "10px", height: 1 }}
                          >
                            <Box sx={{ display: "flex", flexDirection: "row" }}>
                              <Box>
                                <FormControl
                                  sx={{
                                    float: "right",
                                    marginRight: 0,
                                    marginTop: 0,
                                    marginLeft: 0,
                                    width: 100,
                                  }}
                                  size="small"
                                >
                                  <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    onChange={(e) => {
                                      handleDeclaInp(e, i, "dep_calculations");
                                    }}
                                    multiline
                                    rows={3}
                                    maxRows={30}
                                    placeholder=""
                                    sx={{
                                      fontSize: "11px",
                                    }}
                                    size="small"
                                    value={row[4]}
                                  />


                                     
                                </FormControl>
                              </Box>

                              <Box>
                                
                                  <IconButton onClick={handleInfoClick}>
                                    <InfoIcon fontSize="small" />
                                  </IconButton>
                                

                                <IconButton onClick={()=>handleMagnifyClick(row[4],i)}>
                                  <SearchIcon />
                                </IconButton>
                                <Dialog
                                        open={isPopupOpen}
                                        onClose={handleClosePopup}
                                        
                                      >
                                       <DialogTitle sx={{fontSize:"14px", color: Projectcolors.primary}}>First Principle Coding</DialogTitle>
                                        <TextField
                                          id="outlined-basic"
                                          variant="outlined"
                                          defaultValue={formula}
                                          
                                          onChange={(e) => {
                                            handleDeclaInp(
                                              e,
                                              formind,
                                              "dep_calculations"
                                            );
                                          }}
                                          multiline
                                          rows={10}
                                          maxRows={30}
                                          //placeholder=""
                                          sx={{
                                            width: "400px",
                                            fontSize: "11px",
                                            margin:"10px"
                                          }}
                                        />
                                      </Dialog>
                              </Box>
                            </Box>
                          </TableCell>
                        ) : (
                          <TableCell
                            align="center"
                            sx={{ fontSize: "10px", height: 1 }}
                          >
                            None
                          </TableCell>
                        )}

                        {addfprow[1][i] == true ? (
                          <TableCell>
                            <DeleteIcon
                              onClick={() => deleteSnap(1, i)}
                              sx={{
                                display: "inline",
                                fontSize: 15,
                                float: "right",
                                marginTop: 1,
                                marginRight: 2,
                                color: Projectcolors.primary,
                              }}
                            />
                          </TableCell>
                        ) : (
                          <TableCell>
                            <DeleteIcon
                              sx={{
                                display: "inline",
                                fontSize: 15,
                                float: "right",
                                marginTop: 1,
                                marginRight: 2,
                                color: "lightgrey",
                              }}
                            />
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
              </Table>
            </TableContainer>

            {isLoading ? (
              <Box
                sx={{
                  width: "121px",
                  height: 40,
                  fontSize: "12px",
                  // backgroundColor: Projectcolors.primary,
                  color: "#fff",
                  fontStyle: "bold",
                  marginBottom: 2,
                  marginTop: 1,
                  float: "left",
                  //marginLeft: 90,
                  border: 1,
                  marginRight: 1.5,
                }}
              >
                <CircularProgress size={28} sx={{ marginLeft: "50px" }} />
              </Box>
            ) : (
              <Button
                onClick={() => savefp()}
                disabled={!props.editAccess}
                sx={{
                  width: 140,
                  height: 35,
                  fontSize: "12px",
                  backgroundColor: Projectcolors.primary,
                  color: "#fff",
                  fontStyle: "bold",
                  float: "left",
                  mt: 2,
                  marginTop: 2,
                  marginRight: 2,
                  marginBottom: 2,
                  textTransform: "none",
                }}
                variant="contained"
                size="small"
                textTransform="none"
              >
                Save
              </Button>
            )}

            {saved ? (
              <div>
                <Alert
                  sx={{
                    fontSize: "12px",
                    color: Projectcolors.primary,
                    height: "45px",
                    width: "250px",
                    marginTop: 2,
                    marginLeft: 5,
                  }}
                  variant="outlined"
                  severity="success"
                >
                  First Principles saved
                </Alert>
              </div>
            ) : (
              <p></p>
            )}
          </Box>
        </Box>
      </Box>
      {getLoader ? <GlobalLoader /> : null}
    </div>
  );
}
