import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MathModal from "./CommonC/MathModal";
import Projectcolors from "../Utils/Colors";
import SelexMathNavbar from "./CommonC/SelexMathNavbar";
import { makeStyles, withStyles } from "@mui/styles";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { baseURL } from "../Config/config";
import { useNavigate } from "react-router-dom";
import ShareProjectModal from "./CommonC/ShareProjectModal";

import GlobalLoader from "./CommonC/GlobalLoader";
import BgContainer2 from "./CommonC/BgContainer2";
import { useSelector, useDispatch } from "react-redux";
import ShareIcon from "@mui/icons-material/Share";



const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableRow: {
    height: 1,
  },
  tableCell: {
    padding: "0px 16px",
  },
  root: {
    // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    minHeight: "1px",
    padding: "0",
  },
});

const tableHeaders = [
  "Project Name",
  "Last Modified",
  "Edit",
  "Delete",
  "Share"
];

function SelexMath() {
  const [isModal, setIsModal] = React.useState(false);
  const [shareModal, setShareModal] = React.useState(false);
  const [shareProjectName, setShareProjectName] = React.useState("");
  const [shareProjectID, setShareProjecID] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [edit, setEdit] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [timer, setTimer] = React.useState(null);
  const [message, setmessage] = React.useState("");

  const navigate = useNavigate();


  const [projectDetails, setProjectDetails] = React.useState();
  const [getLoader, setGetLoader] = React.useState(false);

  const dispatch = useDispatch();
  const classes = useStyles();
  const handleClickOpen = () => {
    setOpen(true);
    setTimer(
      setTimeout(() => {
          setOpen(false);
      }, 2500)
  );

  };

  const handleClose = () => {
    setOpen(false);
  };




  React.useEffect(() => {
    getAllProjects();
  }, []);


  const getProjectDetails = async () => {
    // setGetLoader(true);
    const token = localStorage.getItem("usertoken");
    const options = {
      method: "GET",
      headers: { "Content-Type": "application/json", token: token },
    };
 
    try {
      const result = await fetch(`${baseURL}/projectaccessdetails`, options);
      let res = await result.json();
      if (!res.code) {
        // setGetLoader(false);
        return;
      }
      // setGetLoader(false);
      // dispatch(create(res.projectDetails));
    } catch (error) {
      // setGetLoader(false);
      console.log("error", error);
    }
  };

  const getAllProjects = async (id) => {
    setGetLoader(true);
    const token = localStorage.getItem("usertoken");
    const options = {
      method: "GET",
      headers: { "Content-Type": "application/json", token: token },
    };

    try {
      const result = await fetch(`${baseURL}/allmathprojects`, options);
      let res = await result.json();
      if (!res.code) {
        setGetLoader(false);
        return;
      }
      // getProjectDetails();
      setGetLoader(false);
      setProjectDetails([...res.data]);
    } catch (error) {
      setGetLoader(false);
      console.log("error", error);
    }
  };

  const StyledTableRow = withStyles((theme) => ({
    root: {
      height: 5,
    },
  }))(TableRow);

  const StyledTableCell = withStyles((theme) => ({
    root: {
      padding: "0px 16px",
    },
  }))(TableCell);

  const deleteProject = async (id) => {
    let body = {
      projectID: id,
    };

    const token = localStorage.getItem("usertoken");

    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json", Token: token },
      body: JSON.stringify(body),
    };
    try {
      let result = await fetch(`${baseURL}/deletemathproject`, options);
      result = await result.json();
      getAllProjects();
    } catch (error) {
      console.log("error", error);
    }
  };

  const toggleModal = () => {
    return setIsModal(!isModal);
  };
  const toggleShareModal = (projectName, projectID) => {
    setShareModal(!shareModal);
    setShareProjecID(projectID);
    setShareProjectName(projectName);
  };

  const handleShareProject = async (value) => {
    const token = localStorage.getItem("usertoken");
    setEdit(value)

    const body = {
      email,
      shareProjectName,
      shareProjectID,
      editAccess: value,
    };

    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json", token: token },
      body: JSON.stringify(body),
    };

    try {
      let res = await fetch(`${baseURL}/mathshareproject`, options);
      res = await res.json();
      if (res.code === 1) {
        toggleShareModal();
        handleClickOpen();
        setmessage("Project Shared Successfully")
        return;
      }
      else if(res.code === 0){
        toggleShareModal();
        handleClickOpen();
        setmessage(res.message);
      }
      else if (res.code === 2){
        toggleShareModal();
        handleClickOpen();
        setmessage(res.message);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

 
  
  const handleNavigation = (projectID,projectName) => {
    navigate("/newmathproject", {
      state: {
        ProjectID: projectID,
        projectName:projectName,
        editAccess:edit,
        loaded: true,
      },
    });
  };

  //  onClick={()=>navigate("/newproject",{ state : {ProjectID:item.projectID,loaded:true}})}

  return (
    <div
      style={{
        height: window.screen.height,
      }}
    >
      <SelexMathNavbar />
      <BgContainer2>
        <Box
          m="auto"
          mt={10}
          sx={{
            width: 620,
            height: 400,
            backgroundColor: Projectcolors.primary,
            padding: 2,
            boxShadow: 6,
          }}
        >
          <Typography
            sx={{ align: "left", color: "#fff", fontSize: 14 }}
            variant="h4"
          >
            SelexMath Projects List
          </Typography>

          <TableContainer
            component={Paper}
            style={{
              width: "100%",
              height: 286,
              padding: 0,
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: 17,
            }}
          >
            <Table
              className={classes.root}
              sx={{ minWidth: 150 }}
              aria-label="simple table"
              stickyHeader
            >
              <TableHead>
                <TableRow className={classes.root}>
                  {tableHeaders.map((item, idx) => {
                    return (
                      <TableCell
                        className={classes.root}
                        sx={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          width: 50,
                          color: Projectcolors.primary,
                        }}
                      >
                        {item}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {projectDetails &&
                  projectDetails.length &&
                  projectDetails.map((item, i) => {
                    const newDate = item.time;
                    const dateStr = new Date(item.time)
                      .toDateString()
                      .substr(4); // 'Fri Apr 10 2020'

                    const projectName = item.projectName;
                    const projectID = item.projectID;

                    return (
                      <StyledTableRow sx={{ width: 100 }}>
                        <StyledTableCell
                          className={classes.tableCell}
                          sx={{
                            fontSize: 11,
                            cursor: "pointer",
                            padding: "0 16px",
                          }}
                          padding="checkbox"
                          onClick={() => handleNavigation(item.projectID,item.projectName)}
                        >
                          {item.projectName}
                        </StyledTableCell>

                        <StyledTableCell sx={{ fontSize: 11 }}>
                          {dateStr}
                        </StyledTableCell>

                        <StyledTableCell sx={{ fontSize: 5 }}>
                          <EditIcon
                            sx={{ fontSize: 11 }}
                            onClick={() => handleNavigation(item.projectID,item.projectName)}
                          />
                        </StyledTableCell>

                        <StyledTableCell sx={{ fontSize: 5 }}>
                          <DeleteIcon
                            sx={{ fontSize: 11, cursor: "pointer" }}
                            onClick={() => deleteProject(item.projectID)}
                          />
                        </StyledTableCell>
                        <StyledTableCell
                          onClick={() =>
                            toggleShareModal(item.projectName, item.projectID)
                          }
                          sx={{ fontSize: 5, cursor: "pointer" }}
                        >
                          <ShareIcon sx={{ fontSize: 11 }} />
                        </StyledTableCell>
                       
                      </StyledTableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>

          <Button
            mt={3}
            onClick={toggleModal}
            sx={{
              // width: 170,
              // height: 50,
              fontSize: 10.5,
              marginTop: 2,
              backgroundColor: "#fff",
              fontStyle: "bold",
              color: "black",
            }}
            // color="black"
            variant="contained"
            size="large"
          >
            Create Project
          </Button>
        </Box>

        <MathModal isModal={isModal} toggleModal={toggleModal} />
        <ShareProjectModal
          isModal={shareModal}
          handleShareProject={handleShareProject}
          setEmail={setEmail}
          email={email}
          toggleModal={toggleShareModal}
        />
            

        {getLoader ? <GlobalLoader /> : null}
      </BgContainer2>
    </div>
  );
}

export default SelexMath;
