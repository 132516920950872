import React from "react";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { baseURL } from "../../Config/config";

const MathModal = (props) => {
  const navigate = useNavigate();

  const [projectName, setProjectName] = React.useState("");
  const [clientname, setClientName] = React.useState("");
  const [objective, setObjective] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);

  // const createNewProject = async () => {
  //   navigate("/newproject");
  // }
  const createNewMathProject = async () => {
    const projectID = uuidv4(); // ⇨ '9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d'
    setIsLoading(true);
    const body = {
      projectName,
      clientname,
      objective,
      time: new Date(),
      // projectID,
      de : []
    };
    localStorage.setItem("projectName", projectName);
    localStorage.setItem("projectID", projectID);
    localStorage.setItem("loaded", false);
   const token =  localStorage.getItem("usertoken");

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json","token":token },
      body: JSON.stringify(body), 
    };
    try {
      let res = await fetch(`${baseURL}/newmathproject`, requestOptions);
      res = await res.json();
      if (res.code) {
        localStorage.setItem("projectID",res.message)
        setIsLoading(false);
        navigate("/newmathproject", { state: { loaded: false, ProjectID: false } });
        return;
      }
      alert("Some Error");
      setIsLoading(false);
    } catch (error) {
      alert("Some Error", error);
      setIsLoading(false);
      console.log("err", error);
    }
  };

  return (
    <div style={{ backgroundColor: "red" }}>
      <Modal
        open={props.isModal}
        onClose={props.toggleModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid
          style={{
            backgroundColor: "#fff",
            height: "300px",
            width: "330px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            boxShadow: 24,
            flex: 1,
          }}
        >

          {" "}
          <Grid
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              height: "100%",
              flexDirection: "column",
              paddingTop: "30px",
            }}
          >

            {/* <input
              onChange={(e) => setProjectName(e.target.value)}
              style={{
                width: "80%",
                height: "1%",
                marginTop: "20px",
                padding: "20px 10px",
              }}
              inputProps={{ style: { fontSize: 12,height:8 } }}
              placeholder="Projects name"
            /> */}
            <Typography style={{fontSize: "12px" }}>Enter Project Name  </Typography>

            <TextField
            //   required
            id="outlined-basic"
            variant="outlined"
            placeholder=""
            //   defaultValue="Hello World"
            onChange={(e) => setProjectName(e.target.value)}
            sx={{ width: 300, fontSize: "30px",width:"80%"}}
            InputLabelProps={{ style: { fontSize: 12 } }}
            inputProps={{ style: { fontSize: 12,height:"4px" } }}
            // InputLabelProps={{ shrink: true }}
          />
          <Typography style={{fontSize: "12px",marginTop:"12px" }}>Client Name (Optional) </Typography>
          <TextField
            //   required
            id="outlined-basic"
            variant="outlined"
            placeholder=""
            //   defaultValue="Hello World"
            onChange={(e) => setClientName(e.target.value)}
            sx={{ width: 300, fontSize: "30px",width:"80%"}}
            InputLabelProps={{ style: { fontSize: 12 } }}
            inputProps={{ style: { fontSize: 12,height:"4px" } }}
            // InputLabelProps={{ shrink: true }}
          />
          <Typography style={{fontSize: "12px",marginTop:"12px" }}>Objective (Optional) </Typography>
          <TextField
            //   required
            id="outlined-basic"
            variant="outlined"
            placeholder=""
            //   defaultValue="Hello World"
            onChange={(e) => setObjective(e.target.value)}
            sx={{ width: 300, fontSize: "30px",width:"80%"}}
            InputLabelProps={{ style: { fontSize: 12 } }}
            inputProps={{ style: { fontSize: 12,height:"4px" } }}
            // InputLabelProps={{ shrink: true }}
          />

            {isLoading ? (
              <Box
                sx={{
                  position: "absolute",
                  right: "35px",
                  bottom: "30px",
                  height: "30px",
                  fontSize:"10px"
                }}
              >
                <CircularProgress  size={28}/>
              </Box>
            ) : (
              <Button
                disabled={!projectName ? true : false}
                onClick={() => createNewMathProject()}
                sx={{
                  position: "absolute",
                  right: "35px",
                  bottom: "30px",
                  height: "30px",
                  fontSize:"10px"
                }}
                variant="contained"
              >
                Continue
              </Button>
            )}
          </Grid>
        </Grid>
      </Modal>
    </div>
  );
};

export default MathModal;
