import React, { useState ,useEffect} from "react";
import SelexMathNavbar from "./CommonC/SelexMathNavbar";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { baseURL } from "../Config/config";
import Plot from "react-plotly.js";
import DownloadIcon from "@mui/icons-material/Download";
import {  useDispatch } from "react-redux";
import { renderMatches, useLocation } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import GlobalLoader from "./CommonC/GlobalLoader";
import LinearProgress from "@mui/material/LinearProgress";
import PropTypes from "prop-types";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { ZoomIn } from '@material-ui/icons';
import IconButton from "@mui/material/IconButton";
import { DialogContent } from "@mui/material";

import InfoIcon from "@mui/icons-material/Info";
import { AppBar, Tabs } from '@mui/material';
import { Checkbox } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  Table,
} from "@mui/material";

import Projectcolors from "../Utils/Colors";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";




const menuprops = {
  PaperProps: {
    style: {
      maxHeight: 150,
      width:50
    },
  },
};


function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          sx={{ fontSize: 14 }}
          color="text.secondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

function MathLandingPage() {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalUOMOpen, setModalUOMOpen] = useState(false);
  const [isModalExpUOMOpen, setModalExpUOMOpen] = useState(false);
  const [isModalExpEqns, setModalExpEqns] = useState(false);
  const [isModalExplicitOpen, setModalExplicitOpen] = useState(false);
  const [isModalExplicitOpenRange, setModalExplicitOpenRange] = useState(false);
  const [isModalExpOpenRange, setModalExpOpenRange] = useState(false);

  const [equation, setEquation] = useState([]);
  const [dependent, setDependent] = useState([]);
  const [selectedTab, setSelectedTab] = React.useState(0);

  const [uom, setUOM] = useState([' ']);
  const [inuom, setinuom] = useState(" ");
  const [remark, setRemark] = useState([' ']);
  const [inremark, setinRemark] = useState(" ");
  const [explicit, setExplicit] = useState([]);
  const [deinitial, setDeinitial] = useState([]);
  const [result, setResult] = useState("");
  const [independent, setIndependent] = useState("");
  const [xlabel, setXlabel] = useState("X axis");
  const [indeinitial, setIndiinitial] = useState(0);
  const [indestep, setIndiStep] = useState(0);
  const [indefinal, setIndifinal] = useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [tabledisplay, setTabledisplay] = React.useState([]);
  const [ydata, setYdata] = React.useState([]);
  const [graphdata, setGraphdata] = React.useState([]);
  const [initialgraph, setInitialGraph] = React.useState([]);
  const [eachequation, seteachEquation] = useState("");
  const [eachdependent, seteachDependent] = useState("");
  const [eachexplicit, seteachExplicit] = useState("");
  const [expvar, setexpvar] = useState([]);
  const [expvarrange, setexpvarrange] = useState([]);
  const [eachdeinitial, seteachDeinitial] = useState(0);
  const [eachuom, seteachUom] = useState("");
  const [eachremark, seteachRemark] = useState("");
  const [errmessage, seterrmessage] = React.useState(false);
  const [edit, setEdit] = React.useState(true);
  const [errormessage, seterrormessage] = React.useState('Something went wrong');
  const [calculate, setcal] = useState(false);
  const [eachmin, seteachmin] = useState("");
  const [eachmax, seteachmax] = useState("");
  const colorslist = [
    "#3366cc",
    "#dc3912",
    "#ff9900",
    "#109618",
    "#990099",
    "#0099c6",
    "#dd4477",
    "#aaaa11",
    "#743411",
    "#e67300",
    "#22aa99",
    "#994499",
    "#22aa99",
    "#66aa00",
    "#6633cc",
    "#3366cc",
    "#8b0707",
    "#651067",
    "#329262",
    "#5574a6",
    "#3b3eac",
    "#b77322",
    "#16d620",
    "#b91383",
    "#f4359e",
    "#9c5935",
    "#a9c413",
    "#2a778d",
    "#668d1c",
    "#bea413",
    "#0c5922",
    "#b82e2e",
  ];
  const [editableindex, seteditableindex] = useState(-1);
  const [expedit, setexpedit] = useState(-1);
  const [expeditrange, setexpeditrange] = useState(-1);
  const [calculatestart, setCalculatestart] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [value, setValue] = React.useState("1");
  const [loadednew, setLoadedNew] = React.useState(false);
  const [pid, setPid] = React.useState(false);
  const [info, setinfo] = React.useState(false);
  const [limits, setlimits] = React.useState([]);
  const [limitseqn, setlimitseqn] = React.useState([]);
  const [eachlimitequation, seteachlimitEquation] = useState("");
  const [totalvar, settotalvar] = useState([]);
  const [allvalues, setallvalues] = useState({});
  const [checkedState, setCheckedState] = useState([]);
  const [keys, setkeys] = useState([]);
  const [xgraph, setX] = useState([]);
  const [ygraph, setY] = useState([]);
  const [records, setRecords] = useState([]);
  const [alluoms, setalluoms] = useState([]);






  const [pname, setPname] = React.useState(false);  
  let loaded = false;
  let ProjectID = false;
  let projectName = false;
  let editAccess = true;
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    if (location.state.loaded) {
      setLoadedNew(location.state.loaded);
    }
  }, [location.state.loaded]);
  
  useEffect(() => {
    if (location.state.ProjectID) {
      ProjectID = location.state.ProjectID;
      setPid(ProjectID)
    }
  }, [location.state.ProjectID]);
  
  useEffect(() => {
    if (location.state.projectName) {
      projectName = location.state.projectName;
      setPname(projectName)
    }
  }, [location.state.projectName]);
  
  useEffect(() => {
    if (location.state.editAccess) {
      setEdit(location.state.editAccess);
    }
  }, [location.state.editAccess]);
  
  useEffect(() => {
    if (loadednew) {
      getDeData();
    }
  }, [loadednew]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleInfoClick = () => {
    setinfo(true);
  };
  const handleCloseinfo = () => {
    setinfo(false);
  };
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };


  function addCondition(){
    var limitscopy = [...limits];
    limitscopy.push([])
    setlimits(limitscopy);
    console.log(limitscopy)


  }

  //console.log("graphdata",graphdata)
  //console.log("initialGraph",initialgraph)
  const handleOnChange = (value, check,i) => {
    //console.log(value,check)
    //console.log("graphdata",graphdata)
    var randnum = Math.floor(Math.random() * (30 - 0 + 1)) + 0
    if (check=== false) {
      var checkedprev = [...checkedState]
      //console.log(checkedprev)
      checkedprev[i] = true;
      setCheckedState(checkedprev);
      console.log("xgraph",xgraph)

      const updateGraph = [
        ...graphdata,
        {
          //x: Array.from(Array(allvalues[value].length).keys()),
          x:xgraph,
          y: allvalues[value],
          type: "line",
          mode: "lines+markers",
          marker: { color: colorslist[randnum] },
          name: value,
         
        },
      ];
      setGraphdata(updateGraph);
      //console.log(updateGraph)
      //console.log("records",records)
      setRecords([...records,value]);

    }
    else{
      //console.log("i=",i)
      var checkedprev = [...checkedState]
      //console.log(checkedprev)
      checkedprev[i] = false; // Problem: mutates list[0]
      setCheckedState(checkedprev);
      let graphdatacopyy = [...graphdata]
      let recordscopy = [...records]
      var ind = recordscopy.indexOf(value)
      graphdatacopyy.splice(ind,1)
      setGraphdata(graphdatacopyy)
      recordscopy.splice(ind,1)
      setRecords(recordscopy)
      //console.log("records",records)


    }


  }
  

  const handleSelect = () => {
    if (xlabel) {
      return <span style={{ color: "black" }}>{xlabel}</span>;
    }
    return <span style={{ color: "#888888" }}>Select</span>;
  };

  const handledropdownx = (e,i) => {
    console.log(i)
    console.log(e.target)
    setX(allvalues[e.target.value])

    var labelform = e.target.value +" ("+alluoms[i]+")"
    setXlabel(labelform)
    let graphdatacopyy = [...graphdata]
    graphdatacopyy.map((each)=>{
      each.x = allvalues[e.target.value]
    })
    setGraphdata(graphdatacopyy)


  }


  function addDeExplicitRange(){
    if(expeditrange>-1){
      console.log('editable')
      var limitseqncopy = [...limitseqn]
      var limitscopy =[...limits]
      var expvarrangecopy = []
      var oldexpvarange = expvarrange
      var uomcopy = uom
      var remarkcopy = remark

      if(eachlimitequation!= ''){
        limitseqncopy[expeditrange] = eachlimitequation
        limitscopy[expeditrange][0] =eachmin
        limitscopy[expeditrange][1]=eachmax
      }
      setlimitseqn(limitseqncopy)
      setlimits(limitscopy)
      let varr = []
      for(var i = 0 ; i < (limitseqncopy.length);i++){
        var regex = /\b([a-zA-Z_]\w*)\b/g;
        var match;
        while ((match = regex.exec(limitseqncopy[i])) !== null) {
          if (!varr.includes(match[1])) {
          varr.push(match[1])
          }
        }
      }
      expvarrangecopy = expvarrangecopy.concat(varr);
      setexpvarrange(expvarrangecopy)
      let combined = expvar.concat(expvarrangecopy)
      settotalvar(combined)

      var uniqueIndices = [];
      expvarrangecopy.forEach(function(element, index) {
        // If the element is not in arr2, add its index to uniqueIndices
        if (!oldexpvarange.includes(element)) {
            uniqueIndices.push(index);
        }
    });
    uniqueIndices.forEach(function(index) {
      if (expvarrangecopy.length > oldexpvarange.length){
      uomcopy.splice(dependent.length + index,0,' ');
      remarkcopy.splice(dependent.length + index,0,' ')
      }
      });
      setUOM(uomcopy)
      setRemark(remarkcopy)
      seteachlimitEquation("");
      seteachmin("");
      seteachmax("");
      setexpeditrange(-1);
      handleCloseExplicitModalRange();

    }
    else{
      setlimits(prevLimits=>[...prevLimits,[eachmin,eachmax]])
      setlimitseqn(prevLimitsEqn=>[...prevLimitsEqn,eachlimitequation]);
      var regex = /\b([a-zA-Z_]\w*)\b/g;
      var match;
      let varr = []
      let expuomremark =[]
      while ((match = regex.exec(eachlimitequation)) !== null) {
        if (!varr.includes(match[1])) {
        varr.push(match[1])
        
        }
      }
    //console.log("varr",varr)
      let expvarcopy = expvarrange
      //console.log("copy",expvarcopy)
      for(var i = 0 ; i < (varr.length);i++){
        if (!expvarcopy.includes(varr[i])) {
          expvarcopy.push(varr[i])
          expuomremark.push(' ')
      }
    }
    setexpvarrange(expvarcopy)
    let combined = expvar.concat(expvarcopy)
      settotalvar(combined)

      setUOM(prevExpVar => [...prevExpVar, ...expuomremark]);
      setRemark(prevExpVar => [...prevExpVar, ...expuomremark])
      seteachmin("");
      seteachmax("");
      seteachlimitEquation("");
      handleCloseExplicitModalRange();
    }
    
  }
  /**console.log("expvar",expvar)
  console.log("expvarrange",expvarrange)
  console.log(uom)
  console.log(remark)
  console.log("limitseqn",limitseqn)
  console.log("limits",limits)**/

  const getDeData = () => {
    setIsLoading(true);
    var graphdatacopy = [];
    localStorage.setItem("projectID", pid);
    localStorage.setItem("projectName", pname);
    const token = localStorage.getItem("usertoken");
    fetch(`${baseURL}/getdedata?projectID=${pid}&token=${token}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())

      .then((data) => {
        if (data.de) {
          setEquation(data.de[0])
          setDependent(data.de[1])
          setDeinitial(data.de[2])
          setExplicit(data.de[3])
          setIndependent(data.de[4])
          setIndiinitial(data.de[5])
          setIndifinal(data.de[6])
          setTabledisplay(data.de[7])
          setYdata(data.de[8])
          setUOM(data.de[9])
          setRemark(data.de[10])
          setexpvar(data.de[11])
          setlimits(data.de[12])
          setlimitseqn(data.de[13])
          setexpvarrange(data.de[14])
          settotalvar(data.de[15]);
          setallvalues(data.allvalues);
          setkeys(data.de[16]);
          setIndiStep(data.de[17])

          setX(data.allvalues[data.de[16][(data.de[16].length)-1]])
          var totalallvars=data.de[1].concat(data.de[15])
          totalallvars=totalallvars.concat(data.de[4])
          console.log("totalallvars",totalallvars)
          var alluomscopy = []
          for (var k=0;k<data.de[16].length;k++){
            var indexxx = totalallvars.indexOf(data.de[16][k])
            alluomscopy.push(data.de[9][indexxx])
          }
          setalluoms(alluomscopy)
          console.log("alluomscopy",alluomscopy)

          var labelform = data.de[4] +" ("+data.de[9][(data.de[9].length)-1]+")"
          console.log("labelform",labelform)
          setXlabel(labelform)




          if (!(checkedState.length>0)){
          var falseArray = new Array(data.de[16].length).fill(false);
          setCheckedState(falseArray);
          }

          setIsLoading(false);
          console.log("data.de[8][-1]",data.de[8][-1])
          console.log("data.de[8][-1]",data.de[8][(data.de[8].length)-1])
          setEdit(data.editAccess)
          data.de[8].map((each, i) => {
            var randnum = Math.floor(Math.random() * (30 - 0 + 1)) + 0


            if (i != data.de[8].length - 1) {
              graphdatacopy.push({
                x: data.de[8][(data.de[8].length)-1],
                y: each,
                type: "line",
                mode: "lines+markers",
                marker: { color: colorslist[randnum] },
                name: data.de[1][i] + "(" + data.de[9][i] + ")",
              });
            }
          });
          setInitialGraph(graphdatacopy);
          setGraphdata([])
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("caught it!", err);
        seterrmessage(true)
        setIsLoading(false);
      });
  };
  console.log("xgraphhh",xgraph)


  const handleOpenModal = (val) => {
    if(val === "no"){
      seteditableindex(-1)

    }
    setModalOpen(true);
  };

  const handleOpenUOMModal = (val) => {
    if(val === "dependentuom"){
      setModalUOMOpen(true);

    }
    else if(val === "explicituom"){
      setModalExpUOMOpen(true);
    }
    else if(val === "expliciteqns"){
      setModalExpEqns(true);
    }
    else{
      setModalExpOpenRange(true);
    }
    
  };

  const handleOpenExplicitModal = () => {
    setModalExplicitOpen(true);
  };
  const handleOpenExplicitModalRange = () => {
    setModalExplicitOpenRange(true);
  };
  const handleCloseExplicitModal = () => {
    setModalExplicitOpen(false);
    setexpedit(-1)
  };
  const handleCloseExplicitModalRange = () => {
    setModalExplicitOpenRange(false);
    setexpeditrange(-1)
  };

  // Function to handle closing the modal
  const handleCloseUOMModal = () => {
    setModalUOMOpen(false);

  };
  const handleCloseModal = () => {
    setModalOpen(false);

  };

  const handleCloseExpUOMModal = () => {
    setModalExpUOMOpen(false);

  };
  const handleCloseExpEqnsModal = () => {
    setModalExpEqns(false);

  };
  const handleCloseExpRange = () => {
    setModalExpOpenRange(false);

  };

  function EditEquation(i){
    seteditableindex(i)
    handleOpenModal("yes")


  }
  function EditExplicit(i){
    setexpedit(i)
    handleOpenExplicitModal()


  }
  function EditExplicitRange(i){
    setexpeditrange(i)
    seteachlimitEquation(limitseqn[i])
    seteachmin(limits[i][0])
    seteachmax(limits[i][1])
    handleOpenExplicitModalRange()


  }

  function deleteEquation(i){

    var copyarr = [...equation];
  var depcopy = [...dependent];
  var deinitialcopy =[...deinitial];
  var uomcopy = [...uom];
  var remarkcopy = [...remark];
    depcopy.splice(i,1)
    copyarr.splice(i,1)
    deinitialcopy.splice(i,1)
    uomcopy.splice(i,1)
    remarkcopy.splice(i,1)


    setEquation(copyarr)
    setDependent(depcopy)
    setDeinitial(deinitialcopy)
    setUOM(uomcopy)
    setRemark(remarkcopy)

  };

  function deleteExplicit(i){
    var copyarr = [...explicit];
    copyarr.splice(i,1)
    var expvarcopy = []
    var newarr =[]
    var newremark = []

    let varr = []
      for(var i = 0 ; i < (copyarr.length);i++){
        var regex = /\b([a-zA-Z_]\w*)\b/g;
        var match;
        while ((match = regex.exec(copyarr[i])) !== null) {
          if (!varr.includes(match[1])) {
          varr.push(match[1])
          }
        }
      }

      expvarcopy = expvarcopy.concat(varr);
      setexpvar(expvarcopy)
      setExplicit(copyarr)

      newarr = uom.slice(0,(dependent.length+expvarcopy.length+expvarrange.length))
      newarr = newarr.concat(uom[uom.length-1])
      setUOM(newarr)

      newremark = remark.slice(0,(dependent.length+expvarcopy.length+expvarrange.length))
      newremark = newremark.concat(remark[remark.length-1])
      setRemark(newremark)
      let combined = expvarcopy.concat(expvarrange)
      settotalvar(combined)

  };

  function deleteExplicitRange(i){
    var limitseqncopy = [...limitseqn];
    var limitscopy=[...limits];
    limitseqncopy.splice(i,1)
    limitscopy.splice(i,1)
    var expvarrangecopy = []
    var newarr =[]
    var newremark = []
    let varr = []
    console.log("=====",limitseqncopy)
      for(var i = 0 ; i < (limitseqncopy.length);i++){
        var regex = /\b([a-zA-Z_]\w*)\b/g;
        var match;
        while ((match = regex.exec(limitseqncopy[i])) !== null) {
          if (!varr.includes(match[1])) {
          varr.push(match[1])
          }
        }
      }

      expvarrangecopy = expvarrangecopy.concat(varr);
      console.log("000000",expvarrangecopy)
      setexpvarrange(expvarrangecopy)
      let combined = expvar.concat(expvarrangecopy)
      settotalvar(combined)
      setlimitseqn(limitseqncopy)
      setlimits(limitscopy)

      newarr = uom.slice(0,(dependent.length+expvar.length+expvarrangecopy.length))
      newarr = newarr.concat(uom[uom.length-1])
      setUOM(newarr)
      newremark = remark.slice(0,(dependent.length+expvar.length+expvarrangecopy.length))
      newremark = newremark.concat(remark[remark.length-1])
      setRemark(newremark)


    /**
    let varr1 = []
      for(var l = 0 ; l < (limitseqncopy.length);l++){
        var regex = /\b([a-zA-Z_]\w*)\b/g;
        var match;
        while ((match = regex.exec(limitseqn[i])) !== null) {
          if (!varr1.includes(match[1])) {
          varr1.push(match[1])
          }
        }
      }
      console.log("varr1",varr1)
      varr1.forEach(element => {

      const index = expvarrangecopy.indexOf(element);
      expvarcopy.splice(index, 1);
      console.log("expvarcopynew",expvarcopy)
    });

    let varr2 = []
      for(var i = 0 ; i < (copyarr.length);i++){
        var regex = /\b([a-zA-Z_]\w*)\b/g;
        var match;
        while ((match = regex.exec(copyarr[i])) !== null) {
          if (!varr2.includes(match[1])) {
            varr2.push(match[1])
          }
        }
      }
      console.log("varr2",varr2)

      expvarcopy = expvarcopy.concat(varr2);
      console.log("expvar",expvarcopy)

      setexpvar(expvarcopy)
      setlimitseqn(copyarr)
      setlimits(limitscopy)

      newarr = uom.slice(0,(dependent.length+expvarcopy.length))
      newarr = newarr.concat(uom[uom.length-1])
      setUOM(newarr)
      console.log("uomm",newarr)


      newremark = remark.slice(0,(dependent.length+expvarcopy.length))
      newremark = newremark.concat(remark[remark.length-1])
      setRemark(newremark)
      console.log("remark",newremark) */


  };

  function addDe() {

    if(editableindex>-1){
      var eqcopy = [...equation]
      var dependentcopy=[...dependent]
      var deinitialcopy=[...deinitial]

      if(eachequation!= ''){
      eqcopy[editableindex] = eachequation
      }
      if(eachdependent!= ''){
      dependentcopy[editableindex] = eachdependent
      }
      if(eachdeinitial != ''){
      deinitialcopy[editableindex] = eachdeinitial
      }
      setEquation(eqcopy)
      setDependent(dependentcopy)
      setDeinitial(deinitialcopy)
      seteachDependent("");
      seteachEquation("");
      seteachDeinitial("");
      seteditableindex(-1);
      handleCloseModal();


      
    }
    else{
    setEquation([...equation, eachequation]);
    setDependent([...dependent, eachdependent]);
    setDeinitial([...deinitial, eachdeinitial]);
    setXlabel(independent)
    seteachDependent("");
    seteachEquation("");
    seteachDeinitial("");
    setUOM([...uom,' '])
    setRemark([...remark,' '])

    handleCloseModal();
    }
  }
  function savelimits(){
    console.log('yes')
  }

  function addDeExplicit() {
    if(expedit>-1){
      var expcopy = [...explicit]
      var expvarcopy = []
      var oldexpvar = expvar
      var uomcopy = uom
      var remarkcopy = remark
      if(eachexplicit!= ''){
        expcopy[expedit] = eachexplicit
      }
      setExplicit(expcopy)
      let varr = []
      for(var i = 0 ; i < (expcopy.length);i++){
        var regex = /\b([a-zA-Z_]\w*)\b/g;
        var match;
        while ((match = regex.exec(expcopy[i])) !== null) {
          if (!varr.includes(match[1])) {
          varr.push(match[1])
          }
        }
      }
      expvarcopy = expvarcopy.concat(varr);
      setexpvar(expvarcopy)
      let combined = expvarcopy.concat(expvarrange)
      settotalvar(combined)
      
      
      
      var uniqueIndices = [];
      expvarcopy.forEach(function(element, index) {
        // If the element is not in arr2, add its index to uniqueIndices
        if (!oldexpvar.includes(element)) {
            uniqueIndices.push(index);
        }
    });
    uniqueIndices.forEach(function(index) {
      if (expvarcopy.length > oldexpvar.length){
      uomcopy.splice(dependent.length + index,0,' ');
      remarkcopy.splice(dependent.length + index,0,' ')
      }
      });
      setUOM(uomcopy)
      setRemark(remarkcopy)


      seteachExplicit("");
      setexpedit(-1);
      
      handleCloseExplicitModal();


      
    }
    else{
    setExplicit([...explicit,eachexplicit]);
    seteachExplicit("");
    var regex = /\b([a-zA-Z_]\w*)\b/g;
    var match;
    let varr = []
    let expuomremark =[]  
    while ((match = regex.exec(eachexplicit)) !== null) {
      if (!varr.includes(match[1])) {
      varr.push(match[1])
      expuomremark.push(' ')
      }
    }
    setexpvar(prevExpVar => [...prevExpVar, ...varr]);
    setUOM(prevExpVar => [...prevExpVar, ...expuomremark]);
    setRemark(prevExpVar => [...prevExpVar, ...expuomremark])
    console.log("totalcopy",varr+expvarrange)
    let combined = varr.concat(expvarrange)
    settotalvar(combined)




    handleCloseExplicitModal();
    }
  }
  //console.log("total",totalvar)


  const handleermessageOpen = () => {
    seterrmessage(true);
  };
  const handleMessage = () => {
    seterrmessage(false);
  };
  
  function saveuom(i,value,id){
    
    if (id == "uom"){
      var uomcopy = uom;
    uomcopy[i] = value.trimStart()
    setUOM(uomcopy);
    seteachUom(value)

    }
    if(id ==="remark"){
      var remarkcopy = remark
      remarkcopy[i] = value.trimStart()
      seteachRemark(value)
      setRemark(remarkcopy);
    }
    if(id === "inuom"){
      var uomcopy = uom;

      uomcopy[uomcopy.length-1] = value.trimStart()
      setinuom(value);
      seteachUom(value)
      setUOM(uomcopy);
    }
    if(id ==="inremark"){
      var remarkcopy = remark
      remarkcopy[remarkcopy.length-1] = value.trimStart()

      setinRemark(value)
      seteachRemark(value)
      setRemark(remarkcopy);
    }
    handleCloseModal();
    
  }

  function downloaddeq() {
    const projectID = localStorage.getItem("projectID");
    const projectName = localStorage.getItem("projectName");
    setIsLoading(true);
    fetch(`${baseURL}/getdeqreportdata?projectID=${projectID}`)
      .then((res) => res.blob())
      .then((blob) => {
        var file = new Blob([blob], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        var url = URL.createObjectURL(file);
        var link = document.createElement("a");
        link.download = projectName + ".xlsx";
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        fetch(`${baseURL}/deletefile?projectName=${projectName}`, {
          method: "POST",
          body: JSON.stringify(projectName),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())
          .then((result) => {
            setIsLoading(false);
          })
          .catch((error) => {
            console.error("Error:", error);
            setIsLoading(false);
          });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        seterrormessage("Can't Download File")
        seterrmessage(true);
        console.log("Error", err);
      });

    /**
    const projectName = localStorage.getItem("projectName");
    console.log(ydata)
    console.log("called")
    var arr = [];

    for (var i = 0; i < ydata[0].length; i++) {
      var miniarr = []
      console.log("ydata",ydata)
      miniarr.push((ydata[(ydata.length) - 1][i]).toFixed(6));

      for (var j = 0; j < dependent.length; j++) {
          console.log("inside else")
          miniarr.push((ydata[j][i]).toFixed(6));        
      }

      arr.push(miniarr)
    }
    console.log(arr);
    
    var csv = "";
    csv += independent.toString() + ",";
    csv += dependent.toString();
    csv += "\n"
    console.log(csv);

    
    arr.forEach(function (row) {
      csv += row.join(",");
      csv += "\n";
    });
    console.log(csv);
    var hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
    hiddenElement.target = "_blank";

    //provide the name for the CSV file to be downloaded
    hiddenElement.download = projectName+".csv";
    hiddenElement.click();
     */
     

  }
  let timer;

  const calculateode = () => {
    const ProjectID = localStorage.getItem("projectID");
    setCalculatestart(true);
    setProgress(0);
    //setIsLoading(true);
    var graphdatacopy = [];

    const config = {
      onUploadProgress: (progressEvent) => {
        let seconds = 100;
        timer = setInterval(() => {
          setProgress((prevProgress) =>
            prevProgress >= 99 ? 99 : prevProgress + 1
          );
        }, seconds);
      },
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({
      equations: equation,
      dependent: dependent,
      explicit: explicit,
      explicitvar: expvar,
      deinitial: deinitial,
      indeinitial: indeinitial,
      indefinal: indefinal,
      indestep:indestep,
      independent: independent,
      uom:uom,
      remark:remark,
      limits:limits,
      limitseqn:limitseqn,
      explicitvarrange:expvarrange,
      totalvar:totalvar,

      ProjectID: ProjectID,
    })

      axios
      .post(`${baseURL}/calculateode`, body, config)
        .then((res) => {
          const result = res.data;
          setIsLoading(false);
          if (result.myerror === 0){ 
          setTabledisplay(result.tabledisplaynew);
          setYdata(result.ydata);
          setallvalues(result.allvalues);
          setkeys(result.keysofgraph);
          setX(result.allvalues[result.keysofgraph[(result.keysofgraph.length)-1]])

          var totalallvars=dependent.concat(totalvar)
          totalallvars=totalallvars.concat(independent)
          console.log("totalallvars",totalallvars)
          var alluomscopy = []
          for (var k=0;k<result.keysofgraph.length;k++){
            var indexxx = totalallvars.indexOf(result.keysofgraph[k])
            alluomscopy.push(uom[indexxx])
          }
          setalluoms(alluomscopy)
          console.log("alluomscopy",alluomscopy)
          var labelform = independent +" ("+uom[(uom.length)-1]+")"
          setXlabel(labelform)




          console.log("length",result.keysofgraph.length)
          if (!(checkedState.length>0)){
            var falseArray = new Array(result.keysofgraph.length).fill(false);
          setCheckedState(falseArray);
          console.log("checked",falseArray)
          }
          
          result.ydata.map((each, i) => {
            var randnum = Math.floor(Math.random() * (30 - 0 + 1)) + 0

            if (i != result.ydata.length - 1) {
              graphdatacopy.push({
                x: result.ydata[(result.ydata.length)-1],
                y: each,
                type: "line",
                mode: "lines+markers",
                marker: { color: colorslist[randnum] },
                name: dependent[i] + "(" + uom[i] + ")",
              });
            }
          });
          setInitialGraph(graphdatacopy);
          clearInterval(timer);

          setProgress(100);
        }
        else{
          setIsLoading(false);
          clearInterval(timer);
          setProgress(0);

          seterrormessage(result.message)
          seterrmessage(true)
        }
        })
        .catch((error) => {
          clearInterval(timer);
          seterrormessage("Something went wrong")
          seterrmessage(true);
          console.error("Error:", error);
        });
       
  };
  const operatorsData = [
    { operator: '()', name: 'Brackets', example: '(a + b)/2' },
    { operator: '+', name: 'Addition', example: 'a + b' },
    { operator: '-', name: 'Subtraction', example: 'a - b' },
    { operator: '*', name: 'Multiplication', example: 'a * b' },
    { operator: '/', name: 'Division', example: 'a / b' },
    { operator: '%', name: 'Modulus', example: 'a % b' },
    { operator: '**', name: 'Exponentiation', example: 'a ** b' },
    { operator: '//', name: 'Floor Division', example: 'a // b' },
    // Add more operators as needed
  ];
  
  const functionsData = [
    { fnname: 'abs', name: 'Absolute Value', example: 'abs(x)' },
    { fnname: 'sqrt', name: 'Square Root', example: 'sqrt(x)' },
    { fnname: 'pow', name: 'Exponentiation', example: 'pow(x, y)' },
    { fnname: 'exp', name: 'Exponential', example: 'exp(x)' },
    { fnname: 'ln', name: 'Natural logarithm', example: 'log(x)' },
    { fnname: 'log', name: 'Logarithm', example: 'log(x, base)' },
    { fnname: 'sin', name: 'Sine', example: 'sin(x)' },
    { fnname: 'cos', name: 'Cosine', example: 'cos(x)' },
    { fnname: 'tan', name: 'Tangent', example: 'tan(x)' },
    { fnname: 'asin', name: 'Arcsine', example: 'asin(x)' },
    { fnname: 'acos', name: 'Arccosine', example: 'acos(x)' },
    { fnname: 'atan', name: 'Arctangent', example: 'atan(x)' },
    { fnname: 'floor', name: 'Floor', example: 'floor(x)' },
    { fnname: 'ceil', name: 'Ceiling', example: 'ceil(x)' },

    // Add more functions as needed
  ];
  return (
    <div>
      <div className="popup">
      <Dialog
        open={info}
        onClose={handleCloseinfo}
        
      >
        <DialogTitle sx={{fontSize:"14px", color: Projectcolors.primary}}>Available Expressions and Functions</DialogTitle>
<DialogContent sx={{padding:'20px'}}>
<AppBar position="static"  >
        <Tabs  onChange={handleTabChange}>
          <Tab label="Mathematical Operators" value={0} style={{color:'white',fontSize:"13px" , textTransform: 'capitalize'}}/>
          <Tab label="Mathematical Functions" value={1} style={{color:'white',fontSize:"13px" , textTransform: 'capitalize'}} />
        </Tabs>
      </AppBar>
      <Paper elevation={3} style={{ padding: '10px' }}>
        {selectedTab === 0 && (
          <div>
            <table>
              <tbody>
                {operatorsData.map((item, index) => (
                  <tr key={index}>
                    <td style={{padding:'10px',fontSize: "13PX"}}>{item.operator}</td>
                    <td style={{padding:'10px',fontSize: "13PX"}}>{item.name}</td>
                    <td style={{padding:'10px',fontSize: "13PX"}}>{item.example}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <hr />
            <p style={{fontSize:'13px',fontWeight:'bold'}}>Example :  </p>
            <p style={{fontSize:'13px'}}>x1 = b + c</p>
            <p style={{fontSize:'13px'}}>x2 = ( b // c ) * x1</p>
            <p style={{fontSize:'13px'}}>x3= x1 + x2</p>
          </div>
        )}
        {selectedTab === 1 && (
          <div>
            <table>
              <tbody>
                {functionsData.map((item, index) => (
                  <tr key={index}>
                    <td style={{padding:'10px',fontSize: "13PX"}}>{item.fnname}</td>
                    <td style={{padding:'10px',fontSize: "13PX"}}>{item.name}</td>
                    <td style={{padding:'10px',fontSize: "13PX"}}>{item.example}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <hr/>
            <p style={{fontSize:'13px',fontWeight:'bold'}}>Example :</p>
            <p style={{fontSize:'13px'}}> Y = log(x) + sin(x)</p>

          </div>
        )}
      </Paper>
      </DialogContent>
        
        
      </Dialog>
    </div>
      <Dialog
        open={errmessage}
        onClose={handleMessage}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{padding:5}}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{  fontSize: "14px" }}
        >
          <Typography sx={{color: Projectcolors.primary,fontSize: "16px",fontWeight:'bold'}}>Selex Debugger</Typography>
          <Typography sx={{color: "red" , fontSize: "14px",marginTop:2}}>Error !! {errormessage}</Typography>
        </DialogTitle>
      </Dialog>

      <SelexMathNavbar />

      <Box
        bgcolor="white"
        p={2}
        boxShadow={3}
        borderRadius={2}
        m="auto"
        sx={{
          width: "1300px",
          marginTop: "20px",
          height: "850px",
          marginBottom: "50px",
        }}
      >
        <Typography sx={{ fontSize: 16 }}>
          Differential Equations Solver
        </Typography>

        <Box display="flex" justifyContent="space-between">
          {/* First box */}
          <Box
            width="48%"
            height={780}
            padding={1}
            elevation={10}
            marginTop={2}
            boxShadow={3}
            border={0}
          >
            {/* Content of the first box */}
            
            <Box border={0}>
            <TabContext value={value}>
          <Box
            sx={{ borderBottom: 1, borderColor: "divider" }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab
                  label="DE Initialization"
                  value="1"
                  sx={{
                    fontSize: 14,
                    textTransform: "none",
                    fontWeight: "bold",
                  }}
                />
                
                <Tab
                  label="DE Events"
                  value="2"
                  sx={{
                    fontSize: 14,
                    textTransform: "none",
                    fontWeight: "bold",
                  }}
                />
                <Tab
                  label="DE Declaration"
                  value="3"
                  sx={{
                    fontSize: 14,
                    textTransform: "none",
                    fontWeight: "bold",
                  }}
                />
              </TabList>
             
          </Box>
          <TabPanel value="1">
          <Button
              variant="contained"
              color="primary"
              onClick={()=>handleOpenModal("no")}
              disabled = {edit?false:true}
            >
              Add Differential Equation
            </Button>
              <Box
                sx={{
                  elevation: 3,
                  border: 1,
                  padding: 1,
                  fontSize: 14,
                  marginTop: 2,
                }}
              >
                <Typography sx={{ fontSize: 12 }}>
                  Differential Equations
                </Typography>
                <Box sx={{ height: "200px", overflowY: "auto" ,overflowX:"hidden"}}>
                  <ol style={{width:"520px"}}>
                  {equation.length > 0?
                    equation.map((each, i) => {
                      return (
                        <li style={{width:"500px"}}>
                          <Box display="flex" width="500px">
                          <Box sx={{display:"inline" ,width:"300px",textAlign: 'left'}} >
                          d({dependent[i]}) / d({independent}) = {each}
                          </Box>
                          <Box sx={{display:"inline",width:"165px",textAlign: 'right'}}>
                          <EditIcon
                            sx={{ fontSize: 15 , cursor: "pointer",marginLeft:10  }}
                            onClick={()=>EditEquation(i)}
                          />
                          <DeleteIcon
                            sx={{ fontSize: 15, cursor: "pointer" , marginLeft:2  }}
                            onClick={() => deleteEquation(i)}
                          />
                          </Box>
                          </Box>
                        </li>
                        
                      );
                    }):null}
                  </ol>
                </Box>
              </Box>

              
              <Box
                sx={{
                  elevation: 3,
                  border: 1,
                  padding: 1,
                  fontSize: 14,
                  marginTop: 2,
                }}
              >
                <Typography sx={{ fontSize: 12 }}>
                  Independent Variable : {independent}
                </Typography>
                <Typography sx={{ fontSize: 12 }}>
                  {independent}(0) = {indeinitial}
                </Typography>
                <Typography sx={{ fontSize: 12 }}>
                  {independent}(f) = {indefinal}
                </Typography>
                <Typography sx={{ fontSize: 12 }}>
                  {independent}(step) = {indestep}
                </Typography>
              </Box>
             
          </TabPanel>
          
          <TabPanel value="3">
          <Box
                sx={{
                  elevation: 3,
                  border: 0,
                  padding: 0,
                  fontSize: 14,
                  marginTop: 0,
                  height:380,
                  width:550
                }}
              >
                <Box sx={{border:1,padding:2}}>
                <Typography sx={{ fontSize: 12,display:'inline',border:0,marginRight:1 }}>
                  Dependent Variables
                  </Typography>

                  <ZoomIn
                            sx={{ fontSize: 15 , cursor: "pointer",marginLeft:12,marginTop:2,display:'inline',border:1 , strokeWidth: 1   }}
                            onClick={()=>handleOpenUOMModal("dependentuom")}
                          />
            <Box sx={{ height: "60px", overflowY: "auto" ,overflowX:"hidden",border: 0,marginTop:1}}>
                  <ol style={{width:"520px"}}>
                  {equation.length > 0?
                    equation.map((each, k) => {
                      return (
                        <li style={{width:"500px",padding:'6px'}}>
                          <Box display="flex" width="500px">
                          <Box sx={{display:"inline" ,width:"50px",textAlign: 'left'}} >
                          {dependent[k]} 
                          </Box>
                          <Box sx={{display:"inline",width:"430px",textAlign: 'left',border:'0px solid black'}}>
                          <TextField
                              label="UOM"
                              variant="outlined"
                              value={uom[k] != "" ? uom[k] : eachuom}
                              //defaultValue={dependent[editableindex]}
                              onChange={(e) => saveuom(k,e.target.value,"uom")}
                              style={{ width: "100px", marginBottom: "5px" }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                style: {
                                  fontSize: "14px",
                                  height: "10px", // Set the height here
                                  padding: "10px",
                                },
                              }}
                            />
                          <TextField
                              label="Remark"
                              variant="outlined"
                              value={remark[k] != ""?remark[k]:eachremark}
                              //defaultValue={dependent[editableindex]}
                              onChange={(e) => saveuom(k,e.target.value,"remark")}
                              style={{ width: "270px", marginBottom: "5px",marginLeft:"25px" }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                style: {
                                  fontSize: "14px",
                                  height: "10px", // Set the height here
                                  padding: "10px",
                                },
                              }}
                            />
                          </Box>
                          </Box>
                        </li>
                        
                      );
                    }):null}
                  </ol>
                </Box>
                </Box>

                <Box sx={{border:1,padding:2,marginTop:2}}>
                <Typography sx={{ fontSize: 12 ,display:'inline',border:0,marginRight:1}}>
                  Explicit Variables
                
                </Typography>
                <ZoomIn
                            sx={{ fontSize: 18 , cursor: "pointer",marginLeft:12,marginTop:20,display:'inline',border:1 , strokeWidth: 1 }}
                            onClick={()=>handleOpenUOMModal("explicituom")}
                          />
            <Box sx={{ height: "60px", overflowY: "auto" ,overflowX:"hidden",border: 0,marginTop:2}}>
                  <ol style={{width:"520px"}}>
                  {totalvar.length > 0?
                    totalvar.map((each, k) => {
                      return (
                        <li style={{width:"500px",padding:'6px'}}>
                          <Box display="flex" width="500px">
                          <Box sx={{display:"inline" ,width:"50px",textAlign: 'left'}} >
                          {totalvar[k].length > 5 ? totalvar[k].slice(0,5)+'...' : totalvar[k]}
                          </Box>
                          <Box sx={{display:"inline",width:"430px",textAlign: 'left',border:'0px solid black'}}>
                          <TextField
                              label="UOM"
                              variant="outlined"
                              value={uom[k+(dependent.length)] != "" ? uom[k+(dependent.length)] : eachuom}
                              //defaultValue={dependent[editableindex]}
                              onChange={(e) => saveuom(k+dependent.length,e.target.value,"uom")}
                              style={{ width: "100px", marginBottom: "5px" }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                style: {
                                  fontSize: "14px",
                                  height: "10px", // Set the height here
                                  padding: "10px",
                                },
                              }}
                            />
                          <TextField
                              label="Remark"
                              variant="outlined"
                              value={remark[k+dependent.length] != ""?remark[k+dependent.length]:eachremark}
                              //defaultValue={dependent[editableindex]}
                              onChange={(e) => saveuom(k+dependent.length,e.target.value,"remark")}
                              style={{ width: "270px", marginBottom: "5px",marginLeft:"25px" }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                style: {
                                  fontSize: "14px",
                                  height: "10px", // Set the height here
                                  padding: "10px",
                                },
                              }}
                            />
                          </Box>
                          </Box>
                        </li>
                        
                      );
                    }):null}
                  </ol>
                </Box>
                </Box>

                <Box sx={{border:1,padding:2,marginTop:2}}>
                <Typography sx={{ fontSize: 12 }}>
                  Independent Variables
                </Typography>
            <Box sx={{ height: "38px",border: 0,marginTop:2}}>
                          <Box display="flex" width="500px">
                          <Box sx={{display:"inline" ,width:"50px",textAlign: 'left',border:'0px solid black'}} >
                          {independent}  ({uom[uom.length-1]})
                          </Box>
                          <Box sx={{display:"inline",width:"430px",textAlign: 'left',border:'0px solid black',marginLeft:6}}>
                          <TextField
                              label="UOM"
                              variant="outlined"
                              value={uom[uom.length-1] != "" ? uom[uom.length-1] : eachuom}
                              //defaultValue={dependent[editableindex]}
                              onChange={(e) => saveuom(0,e.target.value,"inuom")}
                              style={{ width: "100px", marginBottom: "5px" }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                style: {
                                  fontSize: "14px",
                                  height: "10px", // Set the height here
                                  padding: "10px",
                                },
                              }}
                            />
                          <TextField
                              label="Remark"
                              variant="outlined"
                              value={remark[remark.length-1] != ""?remark[remark.length-1]:eachremark}
                              //defaultValue={dependent[editableindex]}
                              onChange={(e) => saveuom(0,e.target.value,"inremark")}
                              style={{ width: "270px", marginBottom: "5px",marginLeft:"25px" }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                style: {
                                  fontSize: "14px",
                                  height: "10px", // Set the height here
                                  padding: "10px",
                                },
                              }}
                            />
                          </Box>
                          </Box>
                       
                </Box>
                </Box>
                

            </Box>
          </TabPanel>
          


            <TabPanel value="2">
            <Box
                sx={{
                  elevation: 3,
                  border: 0,
                  padding: 0,
                  fontSize: 14,
                  marginTop: 0,
                  height:400,
                  width:550
                }}
              >
                 <Button
              variant="contained"
              color="primary"
              onClick={handleOpenExplicitModal}
              disabled = {edit?false:true}
            >
              Add Explicit Equation
            </Button>

              <Box
                sx={{
                  elevation: 3,
                  border: 1,
                  padding: 1,
                  fontSize: 14,
                  marginTop: 2,
                }}
              >
                <Typography sx={{ fontSize: 12 , display:'inline',marginRight:1 }}>
                  Explicit Equations
                </Typography>
                <ZoomIn
                            sx={{ fontSize: 15 , cursor: "pointer",marginLeft:12,marginTop:2,display:'inline',border:1 , strokeWidth: 1   }}
                            onClick={()=>handleOpenUOMModal("expliciteqns")}
                          />
                <Box sx={{ height: "60px", overflowY: "auto" ,overflowX:"hidden" }}>
                  <ol style={{width:"500px"}}>
                  {explicit.length > 0?
                    explicit.map((each, i) => {
                      return (<li style={{width:"500px"}}>
                         <Box display="flex" width="500px">
                         <Box sx={{display:"inline" ,width:"300px",textAlign: 'left'}} >

                        {each}
                        </Box>
                        <Box sx={{display:"inline",width:"165px",textAlign: 'right'}}>

                       <EditIcon
                            sx={{ fontSize: 15 , cursor: "pointer",marginLeft:10 }}
                            onClick={()=>EditExplicit(i)}
                          />
                          <DeleteIcon
                            sx={{ fontSize: 15 , cursor: "pointer",marginLeft:2 }}
                            onClick={() => deleteExplicit(i)}
                          />
                          </Box>
                          </Box>

                          </li>);
                    }):null}
                  </ol>
                </Box>
              </Box>
              <Button
              variant="contained"
              color="primary"
              onClick={handleOpenExplicitModalRange}
              sx={{mt:2,mr:2}}
              disabled = {edit?false:true}
            >
              Add Explicit Equation with Range
            </Button>
            <Box sx={{display:"inline",mt:2.5,border:0,position:"absolute"}}>
            <ZoomIn
                            sx={{ fontSize: 15 , cursor: "pointer",marginLeft:15,marginRight:2,marginTop:-5.5,display:'inline',border:1 , strokeWidth: 1  }}
                            onClick={()=>handleOpenUOMModal("exprange")}
                          />
                          </Box>
                <Box sx={{border:0,padding:0,height:210,marginTop:2}}>
                
            <Box sx={{ height: "220px", overflowY: "auto" ,overflowX:"hidden",border: 0,marginTop:0,marginLeft:-5}}>
                  <ol style={{width:"570px"}}>
                  <TableContainer
                component={Paper}
                sx={{ height: 185, marginTop: 1 }}
              >
                <Table sx={{ minWidth: 195 }}>
                  <TableHead
                    sx={{
                      position: "sticky",
                      top: 0,
                      backgroundColor: Projectcolors.primary,
                    }}
                  >
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{ fontSize: "12px", color: "white" }}
                      >
                        Independent Variable
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ fontSize: "12px", color: "white" }}
                      >
                        Minimum
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ fontSize: "12px", color: "white" }}
                      >
                        Maximum
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ fontSize: "12px", color: "white" }}
                      >
                        Equation
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ fontSize: "12px", color: "white" }}
                      >
                        Action
                      </TableCell>

                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {limits.length > 0
                      ? limits.map((each,k) => (
                          <TableRow>
                            <TableCell
                              align="center"
                              sx={{ fontSize: "10px", height: 1 }}
                            >
                              {independent} ({uom[uom.length-1]})
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{ fontSize: "10px", height: 1 }}
                            >
                              {k==0?(limits[k][0]):(limits[k][0] != "" ? limits[k][0] : eachmin)} 
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{ fontSize: "10px", height: 1 }}
                            >
                              {limits[k][1] != "" ? limits[k][1] : eachmax} 
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{ fontSize: "10px", height: 1 }}
                            >
                              {limitseqn[k]} 
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{ fontSize: "10px", height: 1 }}
                            >
                               <EditIcon
                            sx={{ fontSize: 12 , cursor: "pointer",marginLeft:1 }}
                            onClick={()=>EditExplicitRange(k)}
                          />
                            <DeleteIcon
                            sx={{ fontSize: 12 , cursor: "pointer",marginLeft:2 ,marginTop:1}}
                            onClick={() => deleteExplicitRange(k)}
                          />
                            </TableCell>
                            
                          </TableRow>
                        ))
                      : null}
                  </TableBody>

                  
                  </Table>
                  </TableContainer>
                  
                  
                  </ol>
                </Box>
                </Box>
                </Box>
            </TabPanel>
            <Button
              variant="contained"
              color="primary"
              sx={{ marginTop: 0,marginLeft:3 }}
              onClick={calculateode}
              disabled = {edit?false:true}
            >
              Calculate
            </Button>
          
        </TabContext>
        

            
            </Box>
            

            <Box sx={{ marginTop: 2 ,border:0,width:550,marginLeft:2}}>
              <Typography sx={{ fontSize: 14 }}>
                Calculated Values of DE variables
              </Typography>
              <TableContainer
                component={Paper}
                sx={{ height: 185, marginTop: 1 }}
              >
                <Table sx={{ minWidth: 195 }}>
                  <TableHead
                    sx={{
                      position: "sticky",
                      top: 0,
                      backgroundColor: Projectcolors.primary,
                    }}
                  >
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{ fontSize: "12px", color: "white" }}
                      >
                        Variable
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ fontSize: "12px", color: "white" }}
                      >
                        Initial Value
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ fontSize: "12px", color: "white" }}
                      >
                        Final Value
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tabledisplay.length > 0
                      ? tabledisplay.map((each,k) => (
                          <TableRow>
                            <TableCell
                              align="center"
                              sx={{ fontSize: "10px", height: 1 }}
                            >
                              {each[0]}
                              ({k===(tabledisplay.length-1)?uom[uom.length-1]:uom[k]})
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{ fontSize: "10px", height: 1 }}
                            >
                              {each[1]} 
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{ fontSize: "10px", height: 1 }}
                            >
                              {each[2]} 
                            </TableCell>
                          </TableRow>
                        ))
                      : null}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>

          {/* Second box */}
          <Box
            width="51%"
            height={780}
            padding={3}
            elevation={10}
            marginTop={2}
            boxShadow={3}
          >
            <Typography sx={{ fontSize: 14 }}>
              Result of DE Variables
            </Typography>

            <Typography sx={{ fontSize: 14 }}>
              Graphical Representation
            </Typography>
            <Box display="flex" width="150px" sx={{border:0,mt:2}}>
            {keys.length>0?(
              <Box sx={{display:"inline" , padding:2,border:0,width:"150px"}} elevation ={3} component={Paper}>

              <FormControl
              sx={{ m: 0, mt: 0.7, minWidth: 100, height: 40 }}
            >
              <Typography sx={{ fontSize: 12 }}>X axis</Typography>
              <Select
                defaultValue="AAAA"
                // labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                // label="Age"
                label=""
                placeholder="Placeholder"
                onChange={(e)=>{
                  const selectedIndex = keys.findIndex((key) => key === e.target.value);
                  handledropdownx(e, selectedIndex);
                }}
                sx={{ fontSize: 11, color: "black" }}
                MenuProps={menuprops}
                renderValue={handleSelect}
              >
                {keys.map((each, i) => {
                      return (
                        <MenuItem
                          value={each}
                          name={i}
                          key={i}
                          sx={{ fontSize: "10px", color: "black" }}
                        >
                          {each} ({alluoms[i]})
                        </MenuItem>
                      );
                    })}
              </Select>
            </FormControl>
            <Typography sx={{ fontSize: 12 , mt:6 }}>Y axis</Typography>

           <Box sx={{ height: "190px", overflowY: "auto" ,overflowX:"hidden",border:0,width:"130px" }}>         
      {keys.map((each,i)=>(

          <Box sx={{ mt: 2 }}>
           
          <Checkbox
            color="primary"
            inputProps={{
              "aria-label": "select all desserts",
            }}
            sx={{ display: "inline" }}
            size="medium"
            key={0}
            value={"linear"}
            checked={checkedState[i]}
            onChange={() => handleOnChange(each, checkedState[i],i)}
          />
          <Typography sx={{ fontSize: "12px", display: "inline" }}>
            {each} ({alluoms[i]})
          </Typography>
          </Box>
      
      )

      )}
      </Box>
       </Box>
            ):null}
            
           
            <Box sx={{display:"inline",border:0,ml:1}} elevation ={3} component={Paper} >
            
            
            
            {ydata.length > 0 ? (
              <Plot
                data={graphdata.length>0?graphdata:initialgraph}
                layout={{
                  width: 440,
                  height: 320,
                  marginTop: 0,
                  title: {
                    font: {
                      family: "Courier New, monospace",
                      size: 24,
                    },
                    xref: "paper",
                    x: 0.05,
                  },
                  xaxis: {
                    title: {
                      text: xlabel,
                      font: {
                        size: 14,
                        color: "#000",
                      },
                    },
                  },
                  yaxis: {
                    title: {
                      text: graphdata.length === 1 ? records[0] : "1D Variable",
                      font: {
                        size: 14,
                        color: "#000",
                      },
                    },
                  },
                }}
                config={{
                  displaylogo: false,
                }}
              />
            ) : null}
            </Box>
            </Box>

{calculatestart ? (
                  <Box
                    sx={{
                      width: "98%",
                      float: "left",
                      marginBottom: 0,
                      height: "10px",
                      marginTop: "5px",
                    }}
                  >
                    <LinearProgressWithLabel
                      value={progress}
                      sx={{ height: "6px" }}
                    />
                  </Box>
                ) : null}

            <Box sx={{ marginTop: 4 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Typography sx={{ fontSize: 14 }}>
                Tabular Representation
              </Typography>
              <DownloadIcon
                sx={{
                  fontSize: "medium",
                  marginTop: 0.3,
                  marginRight: 2,
                  marginLeft:1,
                  color: Projectcolors.primary,
                }}
                onClick={() => downloaddeq()}
              />
              </Box>

              <TableContainer
                component={Paper}
                sx={{ height: 290, marginTop: 1 }}
              >
                <Table sx={{ minWidth: 195 }}>
                  <TableHead
                    sx={{
                      position: "sticky",
                      top: 0,
                      backgroundColor: Projectcolors.primary,
                    }}
                  >
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{ fontSize: "12px", color: "white" }}
                      >
                        {independent} ({uom[uom.length-1]})
                      </TableCell>
                      {dependent.length > 0?
                      dependent.map((each,k) => (
                        <TableCell
                          align="center"
                          sx={{ fontSize: "12px", color: "white" }}
                        >
                          {each} ({uom[k]})
                        </TableCell>
                      )):null}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ydata.length > 0
                      ? ydata[0].map((each, i) => (
                          <TableRow>
                            <TableCell
                              align="center"
                              sx={{ fontSize: "10px", height: 1 }}
                            >
                              {((ydata[ydata.length - 1][i]).toString()).includes('e') ? ydata[ydata.length - 1][i].toExponential(6) : ydata[ydata.length - 1][i].toFixed(6) }
                            </TableCell>

                            {dependent.map((each2, j) => (
                              <TableCell
                                align="center"
                                sx={{ fontSize: "10px", height: 1 }}
                              >
                                {((ydata[j][i]).toString()).includes('e') ? ydata[j][i].toExponential(6) : ydata[j][i].toFixed(6)}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))
                      : null}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Box>
      </Box>
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 900,
            height:550,
            bgcolor: "white",
            border: "2px solid #000",
            borderRadius: "8px",
            p: 3,
          }}
        >
          <Typography variant="h5" sx={{ marginBottom: 2 }}>
            Differential Equations Solver
          </Typography>

          <Box display="flex" justifyContent="space-between">
            {/* First box with two input boxes in column format */}
            <Box
              marginLeft="0%"
              marginTop="1%"
              width="25%"
              border={0}
              borderRadius={4}
              p={1}
            >
              {/* Input box for differential equation */}
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Typography sx={{ fontSize: 20 }}>d(&nbsp; </Typography>
                {editableindex>-1?(
                  <TextField
                  //label="Enter differential equation"
                  variant="outlined"
                  //value={eachdependent}
                  defaultValue={dependent[editableindex]}
                  onChange={(e) => seteachDependent(e.target.value)}
                  style={{ width: "100px", marginBottom: "5px" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    style: {
                      fontSize: "14px",
                      height: "15px", // Set the height here
                      padding: "10px",
                    },
                  }}
                />
                ):(
                  <TextField
                  //label="Enter differential equation"
                  variant="outlined"
                  value={eachdependent}
                  //defaultValue={dependent[editableindex]}
                  onChange={(e) => seteachDependent(e.target.value)}
                  style={{ width: "100px", marginBottom: "5px" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    style: {
                      fontSize: "14px",
                      height: "15px", // Set the height here
                      padding: "10px",
                    },
                  }}
                />
                )}
                
                <Typography sx={{ fontSize: 20 }}> &nbsp;) </Typography>
              </div>
              <Typography  sx={{ fontSize: 16 }}>
                -------------------------- &nbsp;=
              </Typography>

              <div
                style={{ display: "flex", flexDirection: "row", marginTop: 5 }}
              >
                <Typography sx={{ fontSize: 20 }}>d(&nbsp; </Typography>
                
                <TextField
                  //label="Enter differential equation"
                  variant="outlined"
                  value={independent}
                  onChange={(e) => setIndependent(e.target.value)}
                  style={{ width: "100px", marginBottom: "5px" }}
                  inputProps={{
                    style: {
                      fontSize: "14px",
                      height: "15px", // Set the height here
                      padding: "10px",
                    },
                  }}
                />
                <Typography sx={{ fontSize: 20 }}> &nbsp;) </Typography>
              </div>

              {/* Another input box if needed */}
              {/* <TextField
                label="Another Input"
                variant="outlined"
                fullWidth
              /> */}
            </Box>
            <Box
              width="750px"
              border={0}
              borderRadius={4}
              p={0}
              sx={{ marginTop: "1.5%", height: "50%", marginRight: "1%" }}
            >
              {editableindex>-1?(
                <TextField
                //label="Enter equation"
                variant="outlined"
                defaultValue={equation[editableindex]}
                onChange={(e) => seteachEquation(e.target.value)}
                multiline
                rows={2}
  
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  style: {
                    fontSize: "15px",
                    width:"560px",
                    height: "60px", // Set the height here
                    padding: "10px"
                  },
                }}
                sx={{
                  marginLeft: 0,
                  display:'inline'
                }}
              />
              ):(
                <TextField
               //label="Result"
               variant="outlined"
               value={eachequation}
               onChange={(e) => seteachEquation(e.target.value)}
               InputLabelProps={{
                 shrink: true,
               }}
               multiline
              rows={2}
               inputProps={{
                 style: {
                   fontSize: "15px",
                   width:"560px",
                   height: "60px", // Set the height here
                   padding: "10px",
                 },
               }}
               sx={{
                 marginLeft: 0,
                 display:'inline'
               }}
             />
             

              )}
              <IconButton onClick={handleInfoClick} sx={{'display':"inline"}}>
             <InfoIcon fontSize="small" />
         </IconButton>
              
            </Box>
          </Box>

          <Typography sx={{ fontSize: 15, marginTop: 4 }}>
            Enter Initial Value for Dependent Variable
          </Typography>
          <div
            style={{ display: "flex", flexDirection: "row", marginTop: "10px" }}
          >
            {/* Input box for differential equation */}
            {editableindex>-1?(
               <Typography sx={{ fontSize: 16 }}>{dependent[editableindex]}(0) = </Typography>
            ):(
               <Typography sx={{ fontSize: 16 }}>{eachdependent}(0) = </Typography>
            )}
           
            {editableindex>-1?(
               <TextField
               //label="Result"
               variant="outlined"
               defaultValue={deinitial[editableindex]}
               onChange={(e) => seteachDeinitial(e.target.value)}
               InputLabelProps={{
                 shrink: true,
               }}
               inputProps={{
                 style: {
                   fontSize: "14px",
                   height: "15px", // Set the height here
                   padding: "10px",
                 },
               }}
               sx={{
                 marginLeft: 2,
               }}
             />
            ):(
               <TextField
               //label="Result"
               variant="outlined"
               value={eachdeinitial}
               onChange={(e) => seteachDeinitial(e.target.value)}
               InputLabelProps={{
                 shrink: true,
               }}
               inputProps={{
                 style: {
                   fontSize: "14px",
                   height: "15px", // Set the height here
                   padding: "10px",
                 },
               }}
               sx={{
                 marginLeft: 2,
               }}
             />
            )}
           
          </div>

          <Typography sx={{ fontSize: 15, marginTop: 4 }}>
            Enter Initial Value for Independent Variable
          </Typography>
          <div style={{ display: "flex", flexDirection: "row", marginTop: "10px" }}>
            <div>
            <Typography sx={{ fontSize: 16  }}>{independent}(0) = </Typography>

            <Typography sx={{ fontSize: 16,marginTop:1.5 }}>{independent}(f) = </Typography>

            <Typography sx={{ fontSize: 16,marginTop:1.5 }}>{independent}(step) = </Typography>

            </div>
            <div>
            <TextField
              //label="Result"
              variant="outlined"
              value={indeinitial}
              onChange={(e) => setIndiinitial(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                style: {
                  fontSize: "14px",
                  height: "15px", // Set the height here
                  padding: "10px",
                },
              }}
              sx={{
                marginLeft: 2,
              }}
            />
              <br/>
            <TextField
              //label="Result"
              variant="outlined"
              value={indefinal}
              onChange={(e) => setIndifinal(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                style: {
                  fontSize: "14px",
                  height: "15px", // Set the height here
                  padding: "10px",
                },
              }}
              sx={{
                marginLeft: 2,
              }}
            />
            <br/>
            <TextField
              //label="Result"
              variant="outlined"
              value={indestep}
              onChange={(e) => setIndiStep(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                style: {
                  fontSize: "14px",
                  height: "15px", // Set the height here
                  padding: "10px",
                },
              }}
              sx={{
                marginLeft: 2,
              }}
            />
            
            </div>
            
          </div>

          {/* Calculate button */}
          {editableindex>-1?(<Button
            variant="contained"
            color="primary"
            style={{ marginTop: "30px",width:"100px"}}
            onClick={addDe}
          >
            Update
          </Button>):(<Button
            variant="contained"
            color="primary"
            style={{ marginTop: "30px",width:"100px" }}
            onClick={addDe}
          >
            Add
          </Button>)}
          
        </Box>
      </Modal>

      <Modal
        open={isModalExplicitOpen}
        onClose={handleCloseExplicitModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 550,
            height:300,
            bgcolor: "white",
            border: "2px solid #000",
            borderRadius: "8px",
            p: 2,
          }}
        >
         


          

          <Typography sx={{ fontSize: 15, marginTop: 2 }}>
            Enter Explicit Equation
          </Typography>
          <div
            style={{ display: "flex", flexDirection: "row", marginTop: "10px" }}
          >
            {expedit>-1?(
               <TextField
               //label="Result"
               variant="outlined"
               defaultValue={explicit[expedit]}
               multiline
               rows={5}
               onChange={(e) => seteachExplicit(e.target.value)}
               InputLabelProps={{
                 shrink: true,
               }}
               inputProps={{
                 style: {
                   fontSize: "14px",
                   height: "95px", // Set the height here
                   padding: "7px",
                   overflowY: "auto",

                   
                 },
               }}
               sx={{
                 width: "500px",
                 overflowY: "auto",
               }}
             />
            ):(
              <TextField
              //label="Result"
              variant="outlined"
              value={eachexplicit}
              multiline
              rows={5}
              onChange={(e) => seteachExplicit(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                style: {
                  fontSize: "14px",
                  height: "95px", // Set the height here
                  padding: "7px",
                  overflowY: "auto",

                },
              }}
              sx={{
                width: "500px",
                overflowY: "auto",
              }}

            />
            )}
            <IconButton onClick={handleInfoClick}>
                                    <InfoIcon fontSize="small" />
                                </IconButton>
           
          </div>

          {/* Calculate button */}
          {expedit>-1?(
            <Button
            variant="contained"
            color="primary"
            style={{ marginTop: "20px" }}
            onClick={addDeExplicit}
          >
            Update
          </Button>
          ):(
            <Button
            variant="contained"
            color="primary"
            style={{ marginTop: "20px" }}
            onClick={addDeExplicit}
          >
            Add
          </Button>
          )}
          
        </Box>
      </Modal>

      <Modal
        open={isModalUOMOpen}
        onClose={handleCloseUOMModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 700,
            height:500,
            bgcolor: "white",
            border: "2px solid #000",
            borderRadius: "8px",
            p: 3,
          }}
        >
          <Typography sx={{ fontSize: 12 }}>
                  Dependent Variables
                  </Typography>
                  <Box sx={{ height: "420px", overflowY: "auto" ,overflowX:"hidden",border: 1,marginTop:1,padding:2}}>

           <ol style={{width:"520px"}}>
                  {equation.length > 0?
                    equation.map((each, k) => {
                      return (
                        <li style={{width:"500px",padding:'6px'}}>
                          <Box display="flex" width="500px">
                          <Box sx={{display:"inline" ,width:"50px",textAlign: 'left'}} >
                          {dependent[k]} 
                          </Box>
                          <Box sx={{display:"inline",width:"430px",textAlign: 'left',border:'0px solid black'}}>
                          <TextField
                              label="UOM"
                              variant="outlined"
                              value={uom[k] != "" ? uom[k] : eachuom}
                              //defaultValue={dependent[editableindex]}
                              onChange={(e) => saveuom(k,e.target.value,"uom")}
                              style={{ width: "100px", marginBottom: "5px" }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                style: {
                                  fontSize: "14px",
                                  height: "10px", // Set the height here
                                  padding: "10px",
                                },
                              }}
                            />
                          <TextField
                              label="Remark"
                              variant="outlined"
                              value={remark[k] != ""?remark[k]:eachremark}
                              //defaultValue={dependent[editableindex]}
                              onChange={(e) => saveuom(k,e.target.value,"remark")}
                              style={{ width: "300px", marginBottom: "5px",marginLeft:"25px" }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                style: {
                                  fontSize: "14px",
                                  height: "10px", // Set the height here
                                  padding: "10px",
                                },
                              }}
                            />
                          </Box>
                          </Box>
                        </li>
                        
                      );
                    }):null}
                  </ol>
                  </Box>
        </Box>
        </Modal>

        <Modal
        open={isModalExpUOMOpen}
        onClose={handleCloseExpUOMModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 700,
            height:500,
            bgcolor: "white",
            border: "2px solid #000",
            borderRadius: "8px",
            p: 3,
          }}
        >
          <Box sx={{border:0,padding:0,marginTop:0}}>
                <Typography sx={{ fontSize: 12 }}>
                  Explicit Variables
                 
                </Typography>
            <Box sx={{ height: "420px", overflowY: "auto" ,overflowX:"hidden",border: 1,marginTop:1,padding:2}}>
                  <ol style={{width:"520px"}}>
                  {totalvar.length > 0?
                    totalvar.map((each, k) => {
                      return (
                        <li style={{width:"500px",padding:'6px'}}>
                          <Box display="flex" width="500px">
                          <Box sx={{display:"inline" ,width:"100px",textAlign: 'left'}} >
                          {totalvar[k].length > 10 ? totalvar[k].slice(0,8)+'...' : totalvar[k]} 
                          </Box>
                          <Box sx={{display:"inline",width:"430px",textAlign: 'left',border:'0px solid black'}}>
                          <TextField
                              label="UOM"
                              variant="outlined"
                              value={uom[k+(dependent.length)] != "" ? uom[k+(dependent.length)] : eachuom}
                              //defaultValue={dependent[editableindex]}
                              onChange={(e) => saveuom(k+dependent.length,e.target.value,"uom")}
                              style={{ width: "100px", marginBottom: "5px" }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                style: {
                                  fontSize: "14px",
                                  height: "10px", // Set the height here
                                  padding: "10px",
                                },
                              }}
                            />
                          <TextField
                              label="Remark"
                              variant="outlined"
                              value={remark[k+dependent.length] != ""?remark[k+dependent.length]:eachremark}
                              //defaultValue={dependent[editableindex]}
                              onChange={(e) => saveuom(k+dependent.length,e.target.value,"remark")}
                              style={{ width: "270px", marginBottom: "5px",marginLeft:"25px" }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                style: {
                                  fontSize: "14px",
                                  height: "10px", // Set the height here
                                  padding: "10px",
                                },
                              }}
                            />
                          </Box>
                          </Box>
                        </li>
                        
                      );
                    }):null}
                  </ol>
                </Box>
                </Box>
        </Box>
        </Modal>

        <Modal
        open={isModalExplicitOpenRange}
        onClose={handleCloseExplicitModalRange}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            height:250,
            bgcolor: "white",
            border: "2px solid #000",
            borderRadius: "8px",
            p: 2,
          }}
        >
         


          

          <Typography sx={{ fontSize: 15, marginTop: 2 }}>
          Add Explicit Equation with Range
          </Typography>
          <div
            style={{ display: "flex", flexDirection: "row", marginTop: "30px" }}
          >
            {expeditrange>-1?(
               <Box display="flex" width="500px">
               <Box sx={{display:"inline" ,width:"30px",textAlign: 'center'}} >
               {independent} ({uom[uom.length-1]})
               </Box>
               <Box sx={{display:"inline",width:"400px",textAlign: 'left',border:'0px solid black'}}>
               <TextField
                   label="Min"
                   variant="outlined"
                   defaultValue={limits[expeditrange][0]}
                   //defaultValue={dependent[editableindex]}
                   onChange={(e) => seteachmin(e.target.value)}
                   style={{ width: "60px", marginBottom: "5px" , marginLeft:"25px" }}
                   InputLabelProps={{
                     shrink: true,
                   }}
                   inputProps={{
                     style: {
                       fontSize: "14px",
                       height: "10px", // Set the height here
                       padding: "10px",
                     },
                   }}
                 />
                 <TextField
                   label="Max"
                   variant="outlined"
                   defaultValue={limits[expeditrange][1]}
                   //defaultValue={dependent[editableindex]}
                   onChange={(e) => seteachmax(e.target.value)}
                   style={{ width: "60px", marginBottom: "5px",marginLeft:"25px" }}
                   InputLabelProps={{
                     shrink: true,
                   }}
                   inputProps={{
                     style: {
                       fontSize: "14px",
                       height: "10px", // Set the height here
                       padding: "10px",
                     },
                   }}
                 />
               <TextField
                   label="Equations"
                   variant="outlined"
                   multiline
                   rows={5}
                   defaultValue={limitseqn[expeditrange]}
                   //defaultValue={dependent[editableindex]}
                   onChange={(e) => seteachlimitEquation(e.target.value)}
                   style={{ width: "200px", marginBottom: "5px",marginLeft:"25px" }}
                   InputLabelProps={{
                     shrink: true,
                   }}
                   inputProps={{
                     style: {
                       fontSize: "14px",
                       height: "30px", // Set the height here
                       padding: "2px"
                     },
                   }}

                 />
                 
               </Box>
               </Box>
            ):(
              <Box display="flex" width="500px">
              <Box sx={{display:"inline" ,width:"30px",textAlign: 'center'}} >
              {independent} ({uom[uom.length-1]})
              </Box>
              <Box sx={{display:"inline",width:"400px",textAlign: 'left',border:'0px solid black'}}>
              <TextField
                  label="Min"
                  variant="outlined"
                  value={eachmin}
                  //defaultValue={dependent[editableindex]}
                  onChange={(e) => seteachmin(e.target.value)}
                  style={{ width: "60px", marginBottom: "5px" , marginLeft:"25px" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    style: {
                      fontSize: "14px",
                      height: "10px", // Set the height here
                      padding: "10px",
                    },
                  }}
                />
                <TextField
                  label="Max"
                  variant="outlined"
                  value={eachmax}
                  //defaultValue={dependent[editableindex]}
                  onChange={(e) => seteachmax(e.target.value)}
                  style={{ width: "60px", marginBottom: "5px",marginLeft:"25px" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    style: {
                      fontSize: "14px",
                      height: "10px", // Set the height here
                      padding: "10px",
                    },
                  }}
                />
              <TextField
                  label="Equations"
                  variant="outlined"
                  multiline
                  rows={5}
                  value={eachlimitequation}
                  //defaultValue={dependent[editableindex]}
                  onChange={(e) => seteachlimitEquation(e.target.value)}
                  style={{ width: "200px", marginBottom: "5px",marginLeft:"25px" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    style: {
                      fontSize: "14px",
                      height: "30px", // Set the height here
                      padding: "2px",
                    },
                  }}
                  
                />
               
              </Box>
              </Box>
            )}
            <IconButton onClick={handleInfoClick}>
                                    <InfoIcon fontSize="small" />
                                </IconButton>
           
          </div>

          {/* Calculate button */}
          {expeditrange>-1?(
            <Button
            variant="contained"
            color="primary"
            style={{ marginTop: "20px" }}
            onClick={addDeExplicitRange}
          >
            Update
          </Button>
          ):(
            <Button
            variant="contained"
            color="primary"
            style={{ marginTop: "20px" }}
            onClick={addDeExplicitRange}
          >
            Add
          </Button>
          )}
          
        </Box>
      </Modal>

      <Modal
        open={isModalExpEqns}
        onClose={handleCloseExpEqnsModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
<Box
sx={{
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  height:500,
  bgcolor: "white",
  border: "2px solid #000",
  borderRadius: "8px",
  p: 3,
}}>

<Typography sx={{ fontSize: 12  }}>
                  Explicit Equations
                </Typography>
<Box
                sx={{
                  elevation: 3,
                  border: 1,
                  padding: 1,
                  fontSize: 14,
                  marginTop: 2,
                  height:"420px"
                }}
              >
                <Box sx={{ height: "420px", overflowY: "auto" ,overflowX:"hidden" }}>
                  <ol style={{width:"500px"}}>
                  {explicit.length > 0?
                    explicit.map((each, i) => {
                      return (<li style={{width:"500px"}}>
                         <Box display="flex" width="500px">
                         <Box sx={{display:"inline" ,width:"300px",textAlign: 'left'}} >

                        {each}
                        </Box>
                        <Box sx={{display:"inline",width:"165px",textAlign: 'right'}}>

                       <EditIcon
                            sx={{ fontSize: 15 , cursor: "pointer",marginLeft:10 }}
                            onClick={()=>EditExplicit(i)}
                          />
                          <DeleteIcon
                            sx={{ fontSize: 15 , cursor: "pointer",marginLeft:2 }}
                            onClick={() => deleteExplicit(i)}
                          />
                          </Box>
                          </Box>

                          </li>);
                    }):null}
                  </ol>
                </Box>
              </Box>
              </Box>




      </Modal>

      <Modal
        open={isModalExpOpenRange}
        onClose={handleCloseExpRange}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
<Box
sx={{
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height:450,
  bgcolor: "white",
  border: "2px solid #000",
  borderRadius: "8px",
  p: 1,
}}>
  <Typography sx={{fontSize:12}}>
               Explicit Equation with Range
              </Typography>
   <Box sx={{border:0,padding:0,height:410,marginTop:1}}>
                
                <Box sx={{ height: "420px", overflowY: "auto" ,overflowX:"hidden",border: 0,marginTop:0,marginLeft:-4}}>
                      <ol style={{width:"600px"}}>
                      <TableContainer
                    component={Paper}
                    sx={{ height: 385, marginTop: 1 }}
                  >
                    <Table sx={{ minWidth: 195 }}>
                      <TableHead
                        sx={{
                          position: "sticky",
                          top: 0,
                          backgroundColor: Projectcolors.primary,
                        }}
                      >
                        <TableRow>
                          <TableCell
                            align="center"
                            sx={{ fontSize: "12px", color: "white" }}
                          >
                            Independent Variable
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ fontSize: "12px", color: "white" }}
                          >
                            Minimum
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ fontSize: "12px", color: "white" }}
                          >
                            Maximum
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ fontSize: "12px", color: "white" }}
                          >
                            Equation
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ fontSize: "12px", color: "white" }}
                          >
                            Action
                          </TableCell>
    
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {limits.length > 0
                          ? limits.map((each,k) => (
                              <TableRow>
                                <TableCell
                                  align="center"
                                  sx={{ fontSize: "10px", height: 1 }}
                                >
                                  {independent} ({uom[uom.length-1]})
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{ fontSize: "10px", height: 1 }}
                                >
                                  {k==0?(limits[k][0]):(limits[k][0] != "" ? limits[k][0] : eachmin)} 
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{ fontSize: "10px", height: 1 }}
                                >
                                  {limits[k][1] != "" ? limits[k][1] : eachmax} 
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{ fontSize: "10px", height: 1 }}
                                >
                                  {limitseqn[k]} 
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{ fontSize: "10px", height: 1 }}
                                >
                                   <EditIcon
                                sx={{ fontSize: 12 , cursor: "pointer",marginLeft:1 }}
                                onClick={()=>EditExplicitRange(k)}
                              />
                                <DeleteIcon
                                sx={{ fontSize: 12 , cursor: "pointer",marginLeft:2 ,marginTop:1}}
                                onClick={() => deleteExplicitRange(k)}
                              />
                                </TableCell>
                                
                              </TableRow>
                            ))
                          : null}
                      </TableBody>
    
                      
                      </Table>
                      </TableContainer>
                      
                      
                      </ol>
                    </Box>
                    </Box>



</Box>
</Modal>
      {isLoading ? <GlobalLoader /> : null}
    </div>
  );
}

export default MathLandingPage;
