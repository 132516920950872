import React from "react";
import Box from "@mui/material/Box";
import { Card, Paper } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import { Typography } from "@mui/material";
import { Button } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Alert } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import GlobalLoader from "./CommonC/GlobalLoader";
import axios from "axios";

import { baseURL } from "../Config/config";
import Projectcolors from "../Utils/Colors";

import Arrow from "../images/arrow.png";
import model from "../images/model.png";
import neural from "../images/neural1.png";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import LinearProgress from "@mui/material/LinearProgress";
import PropTypes from "prop-types";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          sx={{ fontSize: 14 }}
          color="text.secondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

function ANN(props) {
  const [test, setTest] = React.useState(null);
  const [layers, setLayers] = React.useState(null);
  const [epochs, setepochs] = React.useState(null);
  const [ann, setAnn] = React.useState([]);
  const [batch, setbatch] = React.useState(null);
  const [independent, setIndependent] = React.useState([]);
  const [dependent, setDependent] = React.useState([]);
  const [count, setCount] = React.useState([]);
  const [index, setIndex] = React.useState();
  const [unitsin, setunitsin] = React.useState([]);
  const [unitsde, setunitsde] = React.useState([]);
  const [trained, setTrained] = React.useState(false);
  const [modalTraining, setmodalTraining] = React.useState(false);
  const [getLoader, setGetLoader] = React.useState(false);
  const [catestatus, setcatestatus] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openwarning, setopenwarning] = React.useState(false);
  const [openwarning2, setopenwarning2] = React.useState(false);
  const [opennumbers, setopennumbers] = React.useState(false);
  const [choosenalgo, setChoosenalgo] = React.useState([]);
  const [showinput, setshowinput] = React.useState([]);
  const [timeseries, settimeseries] = React.useState([]);
  const [message, setMessage] = React.useState("Model Training Failed !");
  const [progress, setProgress] = React.useState(0);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClosewarning = () => {
    setopenwarning(false);
  };

  const handleClosewarning2 = () => {
    setopenwarning(false);
  };

  const handleClosenumbers = () => {
    setopennumbers(false);
  };

  React.useEffect(() => {
    getBuildModel();
  }, []);

  const getBuildModel = () => {
    setGetLoader(true);
    const ProjectID = localStorage.getItem("projectID");
    fetch(
      `${baseURL}/getbuildmodel?projectID=${ProjectID}&loaded=${props.loaded}`
    )
      .then((res) => res.json())
      .then((data) => {
        setAnn(data.ann);
        //setcatestatus(data.catestatus);
        setIndependent(data.independent);
        setDependent(data.dependent);
        setunitsin(data.unitsin);
        setunitsde(data.unitsde);
        setCount(data.len_all);
        setshowinput(data.showinput);
        setChoosenalgo(data.hyperparameters);
        if (data.ann.length > 0) {
          setTest(data.ann[0]);
          setLayers(data.ann[1]);
          setepochs(data.ann[2]);
          setbatch(data.ann[3]);
        }
        setGetLoader(false);
        settimeseries(data.timeseries);
      })
      .catch((err) => {
        setGetLoader(false);
        console.log("err", err);
      });
  };

  const NextStep = () => {
    props.handleNextStep();
  };

  const handleSize = (e) => {
    setTest(e.target.value);
  };
  const handleLayers = (e) => {
    setLayers(e.target.value);
  };
  const handleepochs = (e) => {
    setepochs(e.target.value);
  };
  const handlebatch = (e) => {
    setbatch(e.target.value);
  };

  const aFunctions = [handleSize, handleLayers, handleepochs, handlebatch];
  const aLabels = [
    "Train Data %",
    "No of Layers",
    "No of Epochs",
    "Batch Size",
  ];

  const annData = [
    {
      name: "Train Data %",
      //label:"Size",
      placeholder: "80",
    },
    {
      name: "No of Layers",
      //label:"Layers",
      placeholder: "5",
    },
    {
      name: "No of Epochs",
      //label:"Epochs ",
      placeholder: "25",
    },
    {
      name: "Batch Size",
      //label:"Batches",
      placeholder: "50",
    },
  ];

  const tooltip = [
    "Data use to train ANN",
    "A neural network is made up of vertically stacked components called Layers.",
    "The number of passes of the entire training dataset the ANN has completed.",
    "The number of samples that will be passed through to the network at one time",
  ];

  const aPlaceholder = ["Size", "Layers", "Epochs", "Batches"];
  let timer;

  const trainmodel = () => {
    setProgress(0);
    setTrained(false);
    const ProjectID = localStorage.getItem("projectID");
    if (
      test === null ||
      layers === null ||
      epochs === null ||
      batch === null ||
      test === "" ||
      layers === "" ||
      epochs === "" ||
      batch === ""
    ) {
      setOpen(true);
    } else if (
      /[a-zA-Z]/.test(test) ||
      /[a-zA-Z]/.test(layers) ||
      /[a-zA-Z]/.test(epochs) ||
      /[a-zA-Z]/.test(batch)
    ) {
      setopennumbers(true);
    } else {
      setmodalTraining(true);

      const body = JSON.stringify({
        trainsize: test,
        layers: layers,
        epochs: epochs,
        batch: batch,
        ann: 1,
        ProjectID,
        choosenalgo,
        showinput,
        timeseries: 0,
      });

      const config = {
        onUploadProgress: (progressEvent) => {
          let seconds = 1500;
          timer = setInterval(() => {
            setProgress((prevProgress) =>
              prevProgress >= 99 ? 99 : prevProgress + 1
            );
          }, seconds);
        },
        headers: {
          "Content-Type": "application/json",
        },
      };
      axios
        .post(`${baseURL}/trainmodel`, body, config)
        .then((response) => {
          if (response.data.message === "error") {
            setMessage("Model Training Failed ! " + response.data.valueerror);
            setopenwarning(true);
            setmodalTraining(false);
          } else {
            setTrained(true);
            setmodalTraining(false);
            clearInterval(timer);
            setProgress(100);
          }
        })
        .catch((error) => {
          setMessage(
            "Model Training Failed ! Try to minimize Epochs or Maximize Batch Size."
          );
          setopenwarning(true);
          setmodalTraining(false);
        });
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ color: "red", fontSize: "13px" }}
        >
          {"All Inputs require to train ANN"}
        </DialogTitle>
      </Dialog>

      <Dialog
        open={openwarning}
        onClose={handleClosewarning}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ color: "red", fontSize: "13px" }}
        >
          {message}
        </DialogTitle>
      </Dialog>

      <Dialog
        open={openwarning2}
        onClose={handleClosewarning2}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ color: "red", fontSize: "13px" }}
        >
          {"Model Training Failed!"}
        </DialogTitle>
      </Dialog>

      <Dialog
        open={opennumbers}
        onClose={handleClosenumbers}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ color: "red", fontSize: "13px" }}
        >
          {"Only Numerical Values accepted."}
        </DialogTitle>
      </Dialog>

      {catestatus || timeseries ? (
        <div>
          <Box
            component={Paper}
            elevation={10}
            m="auto"
            sx={{
              height: 600,
              width: 1350,
              marginTop: "20px",
              padding: 4,
            }}
          >
            <div style={{ margin: "auto", width: "100%", textAlign: "center" }}>
              {timeseries ? (
                <Typography
                  sx={{
                    fontSize: 17,
                    color: Projectcolors.primary,
                    marginTop: 2,
                  }}
                >
                  Data Includes Time Series . Try Forecasting Algorithms.
                </Typography>
              ) : (
                <Typography sx={{ fontSize: 17, color: "red", marginTop: 2 }}>
                  Make sure Step 2 (KPI) does not include categorical data to
                  train model with ANN.
                </Typography>
              )}
            </div>
          </Box>
        </div>
      ) : (
        <div>
          <Box
            component={Paper}
            elevation={10}
            m="auto"
            sx={{
              // height: "620px",
              height: "575px",
              width: 1350,
              // marginTop: "20px",
              padding: "12px 16px",
              // marginRight
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Box
                // m="auto"
                sx={{
                  width: 946,
                  height: 289,
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "60px",
                  marginLeft: "2px",
                  // backgroundColor:"red",
                  marginRight: "-28px",
                  // paddingRight:-10
                }}
              >
                <Box
                  sx={{
                    width: 256,
                    height: "289px",
                    marginTop: "0px",
                    // marginRight: "20px",
                  }}
                >
                  <div
                    style={{
                      //position: "fixed",
                      backgroundColor: "white",
                      position: "relative",
                      top: 8,
                      left: 15,
                      width: 165,
                    }}
                  >
                    <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                      &nbsp;&nbsp;Independent Variables
                    </Typography>
                  </div>
                  <Box
                    sx={{
                      width: 240,
                      height: "289px",
                      marginTop: "0px",
                      border: 1,
                      borderColor: Projectcolors.primary,
                      padding: 1,
                      overflowY: "scroll",
                      marginLeft: "1px",
                    }}
                  >
                    {independent.map((item, i) => {
                      return (
                        <Typography
                          sx={{
                            float: "right",
                            marginRight: 0,
                            marginTop: 1,
                            marginLeft: 3,
                            fontSize: "11px",
                          }}
                        >
                          {" "}
                          {item} ({unitsin[i]})
                          {/* <div style={{display:"flex",flexDirection:"row",alignItems:"center"}}>
               <Divider
                 sx={{
                   width: 240,
                   height: 1,
                   color: Projectcolors.primary,
                   backgroundColor: Projectcolors.primary,
                 }}
               />
               </div> */}
                          <img
                          alt ="arrow"
                            src={Arrow}
                            style={{
                              width: "180px",
                              height: "28px",
                              marginTop: 1,
                              // backgroundColor:"red"
                            }}
                          />
                        </Typography>
                      );
                    })}
                  </Box>
                </Box>

                <Box
                  sx={{
                    width: 400,
                    height: 290,
                    border: 1,
                    // padding: 3,
                    boxShadow: 9,
                    border: 1,
                    borderWidth: "4px",
                    borderColor: Projectcolors.primary,
                    borderRadius: 3,
                    fontSize: 20,
                    textAlign: "center",
                    marginTop: 2.5,
                  }}
                >
                  <Typography sx={{ fontSize: "15px", marginTop: 3 }}>
                    SelexMB Training Model
                  </Typography>
                  <img
                  alt ="model"
                    src={model}
                    style={{ height: "120px", width: "270px" }}
                  />
                  <img
                  alt ="nn"
                    style={{ border: 1, height: "98px", width: "235px" }}
                    src={neural}
                  />
                </Box>
                <Box
                  sx={{
                    width: 256,
                    height: 370,
                    // marginRight: "20px",
                    marginLeft: "10px",
                  }}
                >
                  <div
                    style={{
                     //position: "fixed",
                      backgroundColor: "white",
                      position: "relative",
                      top: 8,
                      left: 15,
                      width: 138,
                    }}
                  >
                    <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                      &nbsp;&nbsp;Dependent Variables
                    </Typography>
                  </div>
                  <Box
                    sx={{
                      width: 240,
                      height: "289px",
                      marginTop: "0px",
                      border: 1,
                      borderColor: Projectcolors.primary,
                      padding: 1,
                      overflowY: "scroll",
                      marginLeft: "1px",
                    }}
                  >
                    {dependent.map((item, i) => {
                      return (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginTop: 1,
                            padding: "0 20px",
                          }}
                        >
                          <Box>
                            <Typography sx={{ fontSize: "11px" }}>
                              {item} ({unitsde[i]})
                              {/* <ArrowRightAltIcon sx={{fontSize:27,marginTop:0,marginleft:30,position:"absolute"}}/> */}
                            </Typography>

                            <img
                            alt ="arrow"
                              src={Arrow}
                              style={{
                                width: "100%",
                                height: "28px",
                                marginTop: 1,
                                // marginLeft: "10px",
                                // marginRight: "10px",
                                // backgroundColor:"red"
                              }}
                            />
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              </Box>

              <Box sx={{ marginTop: 4 }}>
                <div>
                  <fieldset>
                    <legend
                      style={{
                        fontSize: "16px",
                        marginLeft: 14,
                        fontWeight: "bold",
                      }}
                    >
                      ANN Parameters
                    </legend>
                  </fieldset>
                  {ann.length && ann ? (
                    <Box
                      componenet={Paper}
                      elevation={10}
                      sx={{
                        // border: 1,
                        padding: "20px 20px",
                        boxShadow: 3,
                        height: "310px",
                        ml: 2,
                      }}
                    >
                      {ann.map((item, i) => {
                        //let na = aFunctions[i];
                        return (
                          <Box
                            sx={{
                              width: "120%",
                              // height:"289px",
                              border: 0,
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-even",
                              // backgroundColor:"red"
                            }}
                          >
                            <Box sx={{ width: "110px" }}>
                              <Typography
                                component="div"
                                sx={{ fontSize: "13px", mr: "2px", mt: 3 }}
                              >
                                {aLabels[i]}
                              </Typography>
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                flex: 0.8,
                                justifyContent: "flex-end",
                                alignItems: "center",
                                marginTop: 2,
                              }}
                            >
                              <FormControl
                                component="div"
                                size="small"
                                sx={{
                                  // float: "right",

                                  padding: 0,
                                  // marginLeft: 3,
                                }}
                              >
                                <OutlinedInput
                                  //label="size"
                                  placeholder="80"
                                  //variant="outlined"
                                  sx={{
                                    width: "150px",
                                    fontSize: "13px",
                                    height: "40px",
                                  }}
                                  inputProps={{
                                    fontSize: "13px",
                                    height: "40px",
                                  }}
                                  onChange={aFunctions[i]}
                                  size="small"
                                  defaultValue={item}
                                />
                              </FormControl>
                              <Tooltip
                                title={
                                  <Typography fontSize={15}>
                                    {tooltip[i]}
                                  </Typography>
                                }
                                sx={{ fontSize: 15 }}
                              >
                                <IconButton>
                                  <InfoIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                  ) : (
                    <div>
                      {/* <fieldset>
                    <legend>ANN Parameters</legend>
                  </fieldset> */}
                      <Box
                        componenet={Card}
                        elevation={10}
                        sx={{
                          // border: 1,
                          padding: "20px 20px",
                          boxShadow: 3,
                          height: "310px",
                          ml: 2,
                        }}
                      >
                        {annData.map((item, i) => {
                          return (
                            <Box
                              sx={{
                                width: "120%",
                                border: 0,
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-even",
                                // bgcolor:"green"
                              }}
                            >
                              <Box sx={{ width: "110px" }}>
                                <Typography
                                  component="div"
                                  sx={{
                                    fontSize: "13px",
                                    mr: "2px",
                                    marginTop: 3,
                                  }}
                                >
                                  {item.name}
                                </Typography>
                              </Box>

                              <Box
                                sx={{
                                  display: "flex",
                                  flex: 0.8,
                                  justifyContent: "flex-end",
                                  alignItems: "center",
                                  marginTop: 2,
                                }}
                              >
                                <FormControl
                                  component="div"
                                  size="small"
                                  sx={{
                                    // float: "right",

                                    padding: 0,
                                    // marginLeft: 3,
                                  }}
                                >
                                  <OutlinedInput
                                    // classes={{ root: classes.customTextField }}
                                    // label="layers"
                                    placeholder={item.placeholder}
                                    sx={{
                                      width: "150px",
                                      fontSize: "13px",
                                      height: "40px",
                                    }}
                                    onChange={aFunctions[i]}
                                    size="small"
                                    inputProps={{
                                      fontSize: "13px",
                                      height: "40px",
                                    }}
                                  />
                                </FormControl>
                                <Tooltip
                                  title={
                                    <Typography fontSize={15}>
                                      {tooltip[i]}
                                    </Typography>
                                  }
                                  sx={{ fontSize: 15 }}
                                >
                                  <IconButton>
                                    <InfoIcon sx={{ fontSize: "large" }} />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Box>
                          );
                        })}
                      </Box>
                    </div>
                  )}
                </div>
              </Box>
            </Box>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                margin: "auto",
                width: "30%",
                marginTop: 8,
                marginLeft: "20%",
              }}
            >
              <h5>Count : {count} </h5>
              <h5>Independent : {independent.length}</h5>
              <h5>Dependent : {dependent.length}</h5>
            </div>

            <Box sx={{ height: "40px" }}>
              <Button
                onClick={trainmodel}
                disabled={!props.editAccess}
                mr={4}
                sx={{
                  width: "95px",
                  height: "32px",
                  fontSize: "12px",
                  backgroundColor: Projectcolors.primary,
                  color: "#fff",
                  fontStyle: "bold",
                  // marginBottom: 2,
                  float: "left",
                  marginRight: 3,
                  marginLeft: "270px",
                  mt: 3,
                  fontWeight: "bold",
                  // marginBottom:40
                  // marginTop: 2,
                }}
                variant="contained"
                size="medium"
              >
                {" "}
                Train
              </Button>

              <Button
                onClick={NextStep}
                mt={5}
                sx={{
                  float: "left",
                  width: 10,
                  minWidth: 38,
                  height: "32px",
                  fontSize: "19px",
                  // marginTop: 2,
                  backgroundColor: Projectcolors.primary,
                  color: "black",
                  fontStyle: "bold",
                  padding: 0,
                  mt: 3,
                  marginRight: 3,
                }}
                variant="contained"
                size="large"
              >
                {" "}
                <ChevronRightIcon sx={{ fontSize: "25px", color: "white" }} />
              </Button>

              <br />

              {trained ? (
                <Alert
                  sx={{
                    width: "20%",
                    fontSize: "12px",
                    color: Projectcolors.primary,
                    marginLeft: "40px",
                    marginTop: "9px",
                  }}
                  variant="outlined"
                  severity="success"
                >
                  Model Trained Successfully
                </Alert>
              ) : null}
            </Box>
            <br />
            <br />
            {modalTraining ? (
              <Box
                sx={{
                  width: "100%",
                  marginBottom: 0,
                  height: "20px",
                  marginTop: "10px",
                }}
              >
                <LinearProgressWithLabel
                  value={progress}
                  sx={{ height: "6px" }}
                />
              </Box>
            ) : null}
            {trained ? (
              <Box
                sx={{
                  width: "100%",
                  marginBottom: 0,
                  height: "20px",
                  marginTop: "10px",
                }}
              >
                <LinearProgressWithLabel
                  value={progress}
                  sx={{ height: "6px" }}
                />
              </Box>
            ) : null}
          </Box>
        </div>
      )}

      {getLoader ? <GlobalLoader /> : null}
    </div>
  );
}

export default ANN;
