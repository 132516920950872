import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import BgContainer2 from "./CommonC/BgContainer2";
import Navbar from "./CommonC/Navbar";
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import GettingStarted from './SelexHelp/GettingStarted';
import Deinitialize from './SelexHelp/Deintialize';
import Results from './SelexHelp/Results';
import Tutorial from './SelexHelp/Tutorial';

import SelexMathNavbar from './CommonC/SelexMathNavbar';


const drawerWidth = 320;

function SelexHelp(){
    
    const location = useLocation();
    const [activeLink, setActiveLink] = useState('');
  
    const links = [
      { text: 'Getting Started', to: '#gettingstarted' },
      { text: 'Differential Equations Solver', to: '#initialize' },
      { text: 'Differential Equations Results', to: '#results' },
      { text: 'Step by Step Tutorial', to: '#tutorial' },
    ];

    useEffect(() => {
        const hash = location.hash;
        setActiveLink(hash);
      }, [location.hash]);

return(
    <div> 
    <SelexMathNavbar />

    <BgContainer2 />

    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            marginTop:8
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Divider />
        <List>
          {links.map((link, index) => (
            <ListItem
              button
              component={Link}
              to={link.to}
              key={link.text}
              selected={activeLink === link.to}
            >
              <ListItemIcon>
                {/* Add an icon here if needed */}
              </ListItemIcon>
              <Typography sx={{fontSize:14}}>{link.text}</Typography>
            </ListItem>
          ))}
        </List>
        
      </Drawer>
      <Box
        component="main"
        sx={{ bgcolor: 'background.default', p: 3}}
      >
         {activeLink === '' && (
        <GettingStarted/>
         )}
        {activeLink === '#gettingstarted' && (
          <div>
          

<GettingStarted/>

          </div>
        )}
        {activeLink === '#initialize' && (
          <div>
          <Deinitialize />
          </div>
        )}
         {activeLink === '#results' && (
        < Results />
        )}
         {activeLink === '#tutorial' && (
        < Tutorial />
        )}
        
        
        {/* Add your content components here */}
      </Box>
    </Box>

    </div>
)
}

export default SelexHelp;
