import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useEffect } from "react";
import { baseURL } from "../../Config/config";
import { Box } from "@mui/system";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Button, formLabelClasses, TextField } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import Projectcolors from "../../Utils/Colors";
import AddUserModal from "./AddUserModal";
import GlobalLoader from "./GlobalLoader";

const tableHead = ["Username", "Password", "Edit", "Delete"];

export default function AdminTable(props) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [usersArray, setUsersArray] = React.useState([]);
  const [isEdit, setIsEdit] = React.useState();
  const [username, setUsername] = React.useState();
  const [password, setPassword] = React.useState();
  const [modalState, setModalState] = React.useState(false);
  const [pageUpdater, setPageUpdater] = React.useState(0);
  const [loader, setLoader] = React.useState(false);


  const toggleModal = (value) => {
    setModalState(value);
  };

  useEffect(async () => {
    await getAllusers();
  }, [pageUpdater]);

  const refreshList = async(value) => {  

    if(value){
      await getAllusers();
      return;
    }
    return;

  }


  const deleteUser = async (id) => {
    const body = {
      userID: id,
    };

    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    };

    try {
      setLoader(true)
      let result = await fetch(`${baseURL}/deleteuser`, options);
      result = await result.json();
      if (result.code) {
        // setPageUpdater(pageUpdater + 1)
        // window.location.reload();
        await getAllusers()
        setLoader(false)
        return;
      }
      alert("There was some error");
      setLoader(false)
    } catch (error) {
      setLoader(false)
      console.log("error", error);
    }
  };

  const getAllusers = async () => {
    try {
      setLoader(true)
      let result = await fetch(`${baseURL}/getallusers`);
      result = await result.json();
      if (result.code) {
        const data = [];
        setIsEdit([])
        result.data.map((item, idx) => {
          setLoader(false)
          return data.push({
            id: item.user_id,
            username: item.username,
            password: item.password,
            editState: false,
          });
        });
        setLoader(false)
        setIsEdit(data);
        setLoader(false)
        setUsersArray(result.data);

        return;
      }
      alert("There was some error");
      setLoader(false)
    } catch (error) {
      setLoader(false)
      console.log("error", error);
    }
  };

  const handleUpdateUserApi = async (id) => {
    const body = {
      username,
      password,
      userID: id,
    };

    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    };
    try {
      setLoader(true)

      let result = await fetch(`${baseURL}/updateuser`, options);
      result = await result.json();
      if (result.code) {
        await getAllusers()
        setLoader(false)
        return;

      }
      alert("There was some error");

    } catch (error) {
      console.log("error", error);
    }
  };

  const handleUpdate = async (i, user, pass, id) => {
    await handleUpdateUserApi(id);
  };

  const editFunc = (u, i, value) => {
    const data = [...isEdit];

    setIsEdit(
      data.map((d, idx) => {
        if (d.id === u) {
          setUsername(d.username);
          setPassword(d.password);
          return { ...d, editState: value };
        }
        return d;
      })
    );
  };



  return (
    <Box sx={{ backgroundColor: "#fff", padding: "0 20px 20px 20px"}}>
      <h3 style={{ textAlign: "center", fontSize: "17px", fontWeight: "bold",marginTop:"5px",padding:"20px" }}>
        User Control
      </h3>

      <Button
        onClick={() => toggleModal(true)}
        sx={{
          color: Projectcolors.white,
          cursor: "pointer",
          fontSize:"10px",
          width: 20,
          minWidth:95,
          height:30

        }}
        variant="contained"
        size="medium"

      >
        Add User
      </Button>

      <TableContainer sx={{ maxHeight: 440,marginTop:1 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {tableHead.map((item) => (
                <TableCell
                  //   key={column.id}
                  //   align={column.align}
                  style={{
                    minWidth: "30px",
                    fontSize: "13px",
                    padding: "20px",
                    fontWeight: "bold",
                  }}
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {usersArray &&
              isEdit &&
              usersArray.map((item, idx) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
                    {isEdit && isEdit.length && isEdit[idx] && isEdit[idx].editState ? (
                      <TableCell
                        style={{
                          minWidth: "200px",
                          backgroundColor: "#fff",
                          fontSize: "11px",
                        }}
                      >
                        <TextField
                          id="outlined-basic"
                          // label="Username"
                          variant="outlined"
                          InputProps={{ style: { fontSize: "11px" } }}
                          InputLabelProps={{ style: { fontSize: "11px" } }}
                          // value={username}
                          defaultValue={item.username}
                          onChange={(e) => setUsername(e.target.value)}
                          sx={{
                            minWidth: "200px",
                            backgroundColor: "#fff",
                            fontSize: "11px",
                          }}
                          size="small"
                        />
                      </TableCell>
                    ) : (
                      <TableCell
                        style={{
                          minWidth: "30px",
                          backgroundColor: "#fff",
                          fontSize: "11px",
                        }}
                      >
                        {item.username}
                      </TableCell>
                    )}

{isEdit && isEdit.length && isEdit[idx] && isEdit[idx].editState ? (
                      <TableCell
                        style={{
                          minWidth: "200px",
                          backgroundColor: "#fff",
                          fontSize: "11px",
                        }}
                      >
                        <TextField
                          type="password"
                          id="outlined-basic"
                          // label="Password"
                          variant="outlined"
                          InputProps={{ style: { fontSize: "11px" } }}
                          InputLabelProps={{ style: { fontSize: "11px" } }}
                          defaultValue={item.password}
                          // value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          sx={{
                            minWidth: "200px",
                            backgroundColor: "#fff",
                            // fontSize: "16px",
                          }}
                          size="small"
                        />
                      </TableCell>
                    ) : (
                      <TableCell
                        style={{
                          minWidth: "30px",
                          backgroundColor: "#fff",
                          fontSize: "11px",
                        }}
                      >
                        {item.password}
                      </TableCell>
                    )}

                    <TableCell
                      sx={{
                        minWidth: "30px",
                        backgroundColor: "#fff",
                        fontSize: "16px",
                      }}
                    >
                    {isEdit && isEdit.length && isEdit[idx] && isEdit[idx].editState ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            onClick={() =>
                              handleUpdate(
                                idx,
                                item.username,
                                item.password,
                                item.user_id
                              )
                            }
                            variant="contained"
                            
                          >
                            Update
                          </Button>

                          <CancelIcon
                            onClick={() => editFunc(item.user_id, idx, false)}
                            sx={{ marginLeft: "5px", cursor: "pointer" }}
                          />
                        </div>
                      ) : (
                        <EditIcon
                          onClick={() => editFunc(item.user_id, idx, true)}
                        />
                      )}
                    </TableCell>

                    <TableCell
                      sx={{
                        minWidth: "10px",
                        width:"10px",
                        backgroundColor: "#fff",
                        fontSize: "16px",
                      }}
                    >
                      <DeleteIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() => deleteUser(item.user_id)}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
      <div style={{ display: "flex", flex: 1, backgroundColor: "red" }}>
        <AddUserModal modalState={modalState} refreshList={refreshList} toggleModal={toggleModal} />
      </div>

      {loader ? <GlobalLoader/> : null}


    </Box>
  );
}
