import { CircularProgress, Modal } from '@mui/material'
import React from 'react'

const GlobalLoader = () => {

  return (
    <div style={{display:"flex",flex:1}}>
        <Modal
        open={true}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{display:"flex",justifyContent:"center",alignItems:"Center"}}
      > 
      {/* <div style={{backgroundColor:"#fff",width:"280px",height:"170px",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"Center"}}> */}
              {/* <h1 style={{marginBottom:"18px"}}>Please Wait</h1> */}
              <CircularProgress />
      {/* </div> */}
      </Modal>
    </div>
  )
}

export default GlobalLoader