import React from "react";
import Box from "@mui/material/Box";
import Projectcolors from "../Utils/Colors";
import { baseURL } from "../Config/config";
import { Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Button } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Alert } from "@mui/material";
import { Paper } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import GlobalLoader from "./CommonC/GlobalLoader";
import Tooltip from "@mui/material/Tooltip";
import model from "../images/model.png";
import Arrow from "../images/arrow.png";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import LinearProgress from "@mui/material/LinearProgress";
import PropTypes from "prop-types";
import axios from "axios";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          sx={{ fontSize: 14 }}
          color="text.secondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

function BuildModel(props) {
  const [independent, setIndependent] = React.useState([]);
  const [dependent, setDependent] = React.useState([]);
  const [count, setCount] = React.useState([]);
  const [dependentalgo, setDependentAlgo] = React.useState([]);
  const [algorithm, setAlgorithm] = React.useState([
    "lasso",
    "linear",
    "polynomial",
    "randomforestregressor",
    "knregressor",
    "decisiontreeregressor",
    "multilasso",
    "logistic",
    "xgboost",
    "svm",
    "mlp",
  ]);

  const [algorithmshort, setAlgorithmshort] = React.useState([
    "MBCV",
    "MBLR",
    "MBPR",
    "MBRF",
    "MBKN",
    "MBDT",
    "MBML",
    "MBKNN",
    "MBX",
    "MBSVM",
    "MBMLP",
  ]);

  const [index, setIndex] = React.useState();
  const [unitsin, setunitsin] = React.useState([]);
  const [unitsde, setunitsde] = React.useState([]);
  const [trained, setTrained] = React.useState(false);
  const [test, setTest] = React.useState(false);
  const [modalTraining, setmodalTraining] = React.useState(false);
  const [allmodel, setallmodel] = React.useState([]);
  const [prevalgo, setprevalgo] = React.useState([]);
  const [cat, setcat] = React.useState([]);
  const [getLoader, setGetLoader] = React.useState(false);
  const [ann, setAnn] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [openn, setOpenn] = React.useState(false);
  const [selected, setselected] = React.useState(0);

  const [parameters, setParameters] = React.useState([
    [
      0.001,
      100,
      null,
      true,
      "auto",
      1000,
      0.0001,
      true,
      null,
      false,
      null,
      false,
      null,
      "cyclic",
    ],
    [true, true, null, false],
    [2,false,true,'C'],

    [
      100,
      "squared_error",
      null,
      2,
      1,
      0.0,
      1.0,
      null,
      0.0,
      true,
      false,
      null,
      null,
      0,
      false,
      0.0,
      null,
    ],
    [6, "uniform", "auto", 30, 2, "minkowski", null, null],
    ["squared_error", "best", null, 2, 1, 0.0, null, null, null, 0.0, 0.0],
    [
      0.001,
      100,
      null,
      true,
      1000,
      0.0001,
      true,
      null,
      false,
      null,
      null,
      "cyclic",
    ],
    [
      "l2",
      false,
      0.0001,
      1.0,
      true,
      1,
      null,
      null,
      "lbfgs",
      100,
      "auto",
      0,
      false,
      null,
      null,
    ],
    [
      "gbtree",
      0,
      0.3,
      0,
      6,
      1,
      0,
      1,
      "uniform",
      1,
      1,
      1,
      0,
      "auto",
      0.03,
      1,
      1,
      "default",
      "depthwise",
      0,
      256,
      "auto",
      1,
    ],
    ["rbf", 3, "scale", 0.0, 0.001, 1.0, 0.1, true, 200, false, -1],
    [
      "(100,100)",
      "relu",
      "adam",
      0.0001,
      "auto",
      "constant",
      0.001,
      0.5,
      200,
      true,
      null,
      0.0001,
      false,
      false,
      0.9,
      true,
      false,
      0.1,
      0.9,
      0.999,
      1e-8,
      10,
      15000,
    ],
  ]);
  const [parameterslabels, setParameterslabels] = React.useState([
    [
      "Eps",
      "N alphas",
      "Alphas",
      "Fit intercept",
      "Precompute",
      "Max iter",
      "Tol",
      "Copy X",
      "CV",
      "Verbose",
      "N jobs",
      "Positive",
      "Random state",
      "Selection",
    ],
    ["Fit intercept", "Copy X", "N jobs", "Positive"],
    ["Degree","Interaction Only","Include Bias","Order"],
    [
      "N estimators",
      "Criterion",
      "Max depth",
      "Min samples split",
      "Min samples leaf",
      "Min weight fraction leaf",
      "Max features",
      "Max leaf nodes",
      "Min impurity decrease",
      "Bootstrap",
      "oob score",
      "N jobs",
      "Random state",
      "Verbose",
      "Warm start",
      "Ccp alpha",
      "Max samples",
    ],
    [
      "N neighbors",
      "Weights",
      "Algorithm",
      "Leaf size",
      "P",
      "Metric",
      "Metric params",
      "N jobs",
    ],
    [
      "Criterion",
      "Splitter",
      "Max depth",
      "Min samples split",
      "Min samples leaf",
      "Min weight fraction leaf",
      "Max features",
      "Random state",
      "Max leaf nodes",
      "Min impurity decrease",
      "Ccp alpha",
    ],
    [
      "Eps",
      "N alphas",
      "Alphas",
      "Fit intercept",
      "Max iter",
      "Tol",
      "Copy X",
      "CV",
      "Verbose",
      "N jobs",
      "Random state",
      "Selection",
    ],
    [
      "Penalty",
      "Dual",
      "Tol",
      "C",
      "Fit intercept",
      "Intercept scaling",
      "Class weight",
      "Random state",
      "Solver",
      "Max iter",
      "Multi class",
      "Verbose",
      "Warm start",
      "N jobs",
      "l1 ratio",
    ],
    [
      "Booster",
      "Silent",
      "Eta",
      "Gamma",
      "Max depth",
      "Min child weight",
      "Max delta step",
      "Subsample",
      "Sampling Method",
      "Colsample bytree",
      "Colsample bylevel",
      "Colsample bynode",
      "Alpha",
      "Tree method",
      "Sketch eps",
      "Scale pos weight",
      "Refresh leaf",
      "Process type",
      "Grow policy",
      "Max leaves",
      "Max bin",
      "Predictor",
      "Num parallel tree",
    ],
    [
      "Kernel",
      "Degree",
      "Gamma",
      "Coef0",
      "Tol",
      "C",
      "Epsilon",
      "Shrinking",
      "Cache size",
      "Verbose",
      "Max iter",
    ],
    [
      "Hidden layer sizes",
      "Activation",
      "Solver",
      "Alpha",
      "Batch size",
      "Learning rate",
      "Learning rate init",
      "Power t",
      "Max iter",
      "Shuffle",
      "Random state",
      "Tol",
      "Verbose",
      "Warm start",
      "Momentum",
      "Nesterovs momentum",
      "Early stopping",
      "Validation fraction",
      "Beta 1",
      "Beta 2",
      "Epsilon",
      "N iter no change",
      "Max fun",
    ],
  ]);
  const [links, setlinks] = React.useState([
    "https://scikit-learn.org/stable/modules/generated/sklearn.linear_model.LassoCV.html",
    "https://scikit-learn.org/stable/modules/generated/sklearn.linear_model.LinearRegression.html",
    "https://scikit-learn.org/stable/modules/generated/sklearn.preprocessing.PolynomialFeatures.html",
    "https://scikit-learn.org/stable/modules/generated/sklearn.ensemble.RandomForestRegressor.html",
    "https://scikit-learn.org/stable/modules/generated/sklearn.neighbors.KNeighborsRegressor.html",
    "https://scikit-learn.org/stable/modules/generated/sklearn.tree.DecisionTreeRegressor.html",
    "https://scikit-learn.org/stable/modules/generated/sklearn.linear_model.MultiTaskLassoCV.html",
    "https://scikit-learn.org/stable/modules/generated/sklearn.linear_model.LogisticRegression.html",
    "https://xgboost.readthedocs.io/en/stable/parameter.html",
    "https://scikit-learn.org/stable/modules/generated/sklearn.svm.SVR.html",
    "https://scikit-learn.org/stable/modules/generated/sklearn.neural_network.MLPRegressor.html",
  ]);
  const [choosenalgo, setChoosenalgo] = React.useState([]);
  const [showinput, setshowinput] = React.useState([]);
  const [algochoosen, setalgochoosen] = React.useState(false);
  const [valueerror, setvalueerror] = React.useState("");
  const [timeseries, settimeseries] = React.useState(0);
  const [progress, setProgress] = React.useState(0);
  const tooltip = [
    [
      "float",
      "integer",
      "array",
      "boolean",
      "auto, bool or array-like of shape (n_features, n_features)",
      "integer",
      "float",
      "boolean",
      "int, cross-validation generator or iterable",
      "boolean",
      "integer",
      "boolean",
      "integer",
      '{"cyclic", "random"}',
    ],
    ["boolean", "boolean", "integer", "boolean"],
    ["int or tuple (min_degree, max_degree)","boolean","boolean","{'C', 'F'}"],
    [
      "integer",
      "{“squared_error”, “absolute_error”, “poisson”}",
      "integer",
      "integer or float",
      "integer or float",
      "float",
      "{“sqrt”, “log2”, None}, int or float",
      "integer",
      "float",
      "boolean",
      "boolean",
      "integer",
      "integer",
      "integer",
      "boolean",
      "non-negative float",
      "integer or float",
    ],
    [
      "integer",
      '{"uniform", "distance"} or callable',
      '{"auto", "ball_tree", "kd_tree", "brute"}',
      "integer",
      "integer",
      "string",
      "dictionary",
      "integer",
    ],
    [
      "{“squared_error”, “friedman_mse”, “absolute_error”, “poisson”}",
      "{“best”, “random”}",
      "integer",
      "integer or float",
      "integer or float",
      "float",
      "int, float or {“auto”, “sqrt”, “log2”}",
      "integer",
      "integer",
      "float",
      "non-negative float",
    ],
    [
      "float",
      "integer",
      "array",
      "boolean",
      "integer",
      "float",
      "boolean",
      "int, cross-validation generator or iterable",
      "boolean or integer",
      "integer",
      "integer",
      '{"cyclic", "random"}',
    ],
    [
      '{"l1", "l2", "elasticnet", "none"}',
      "boolean",
      "float",
      "float",
      "boolean",
      "float",
      'dict or "balanced"',
      "integer",
      '{"newton-cg", "lbfgs", "liblinear", "sag", "saga"}',
      "integer",
      '{"auto", "ovr", "multinomial"}',
      "integer",
      "boolean",
      "integer",
      "float",
    ],
    [
      "gbtree, gblinear or dart",
      "integer",
      "range[0,1]",
      "range[0,]",
      "positive integer",
      "positive integer",
      "positive integer",
      "range[0,1]",
      "uniform,gradient_based",
      "range[0,1]",
      "range[0,1]",
      "range[0,1]",
      "postive integer",
      "auto, exact, approx, hist, gpu_hist",
      "range[0,1]",
      "integer",
      "0 or 1",
      "default, update",
      "depthwise, lossguide",
      "positive integer",
      "positive integer",
      "cpu_predictor,gpu_predictor,",
      "positive integer",
    ],
    [
      '{"linear", "poly", "rbf", "sigmoid", "precomputed"} ',
      "integer",
      '{"scale", "auto"} or float',
      "float",
      "float",
      "float",
      "float",
      "boolean",
      "float",
      "boolean",
      "integer",
    ],
    [
      "tuple",
      '{"identity", "logistic", "tanh", "relu"}',
      '{"lbfgs", "sgd", "adam"}',
      "float",
      "integer",
      '{"constant", "invscaling", "adaptive"}',
      "float",
      "float",
      "integer",
      "boolean",
      "integer",
      "float",
      "boolean",
      "boolean",
      "float",
      "boolean",
      "boolean",
      "float",
      "float",
      "float",
      "float",
      "integer",
      "integer",
    ],
  ];

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpenn = () => {
    setOpenn(true);
  };

  const handleClosee = () => {
    setOpenn(false);
  };

  let newArray = [];
  let newparameters = [];
  let change = [];
  //dependentalgo =['lasso','lasso']
  //dependentalgo = ['linear','lasso']

  const handleChange = (event, idx) => {
    //setalgochoosen(true);
    setIndex(event.target.value);
    let index = event.target.value;
    const dataArray = Array.from(dependentalgo);
    const chosenalgoarray = Array.from(choosenalgo);
    const show = Array.from(showinput);
    dataArray[idx] = algorithm[index];
    chosenalgoarray[idx] = parameters[index];
    show[idx] = index;
    setDependentAlgo(dataArray);
    setChoosenalgo(chosenalgoarray);
    setshowinput(show);

    // let dataArray =
    //index - e.target.value
    //item to update on index- algorithm[e.target.value]
    // setDependentAlgo(dependentalgo=>[...dependentalgo,algorithm[event.target.value]]);
  };
  let timer;
  const trainmodel = () => {
    const ProjectID = localStorage.getItem("projectID");
    setProgress(0);
    setTrained(false);
    if (test === "" || test === null || dependentalgo.length === 0) {
      setOpen(true);
    } else {
      const body = JSON.stringify({
        independent,
        dependent,
        dependentalgo,
        trainsize: test,
        ProjectID,
        allmodel,
        ann: 0,
        choosenalgo,
        showinput,
        timeseries: 0,
      });

      setmodalTraining(true);
      const config = {
        onUploadProgress: (progressEvent) => {
          let seconds = 800;
          timer = setInterval(() => {
            setProgress((prevProgress) =>
              prevProgress >= 99 ? 99 : prevProgress + 1
            );
          }, seconds);
        },
        headers: {
          "Content-Type": "application/json",
        },
      };

      try {
        axios
          .post(`${baseURL}/trainmodel`, body, config)
          .then((response) => response)
          .then((result) => {
            if (result.data.message === "error") {
              setselected(result.data.algo);
              setTrained(false);
              setmodalTraining(false);
              setvalueerror(result.data.valueerror);
              setProgress(0);
              setOpenn(true);
            } else {
              setTrained(true);
              setmodalTraining(false);
              setallmodel(result.data.allmodel);
              clearInterval(timer);
              setProgress(100);
            }
          })
          .catch((error) => {
            setmodalTraining(false);
            console.error("Error:", error);
          });
      } catch (error) {
        setmodalTraining(false);
        console.log("error", error);
      }
    }
  };

  const initializeDependentAlgo = (data) => {
    if (data.prevalgo.length > 0) {
      data.dependent.map((item, i) => {
        newArray.push(algorithm[data.prevalgo[i]]);
        setDependentAlgo(newArray);
      });
    } else {
      data.dependent.map((item, i) => {
        newArray.push("lasso");
        setDependentAlgo(newArray);
      });
    }
  };

  React.useEffect(() => {
    getBuildModel();
  }, []);

  const getBuildModel = () => {
    setGetLoader(true);
    const ProjectID = localStorage.getItem("projectID");
    fetch(
      `${baseURL}/getbuildmodel?projectID=${ProjectID}&loaded=${props.loaded}`
    )
      .then((res) => res.json())
      .then((data) => {
        setTest(data.traindata);
        setprevalgo(data.prevalgo);
        initializeDependentAlgo(data);
        setIndependent(data.independent);
        setDependent(data.dependent);
        setunitsin(data.unitsin);
        setunitsde(data.unitsde);
        setCount(data.len_all);
        setcat(data.cat);
        setGetLoader(false);
        setAnn(data.ann);
        setshowinput(data.showinput);
        setChoosenalgo(data.hyperparameters);
        settimeseries(data.timeseries);

        //setIndex(0);
      })
      .catch((err) => {
        setGetLoader(false);
        console.log("err", err);
      });
  };

  const NextStep = () => {
    props.handleNextStep();
  };
  const handleSize = (e) => {
    setTest(e.target.value);
  };

  const handleInput = (event, i, j) => {
    let change = choosenalgo.slice();
    let arrayy = change[i].slice();
    arrayy[j] = event.target.value;
    change[i] = arrayy.slice();
    setChoosenalgo(change);

    //change[i][j] = event.target.value;
    //setchoosenalgo(chosenalgoarray);
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ color: "red", fontSize: "13px" }}
        >
          {"Train Data % Required"}
        </DialogTitle>
      </Dialog>

      <Dialog
        open={openn}
        onClose={handleClosee}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ color: "red", fontSize: "13px" }}
        >
          <Typography sx={{ fontSize: 15 }}>
            Parameter Input Wrong for Algorithm {algorithmshort[selected]}{" "}
          </Typography>
          <Typography sx={{ fontSize: 13, color: "black", marginTop: 2 }}>
            {valueerror}
          </Typography>
          <Typography
            sx={{ fontSize: 13, color: Projectcolors.primary, marginTop: 2 }}
          >
            Know more about {algorithmshort[selected]} Parameters:{" "}
            <a target="_blank" href={links[selected]}>
              Click Here
            </a>
          </Typography>
        </DialogTitle>
      </Dialog>
      {timeseries ? (
        <div>
          <Box
            component={Paper}
            elevation={10}
            m="auto"
            sx={{
              height: 600,
              width: 1350,
              marginTop: "20px",
              padding: 4,
            }}
          >
            <div style={{ margin: "auto", width: "100%", textAlign: "center" }}>
              <Typography
                sx={{
                  fontSize: 17,
                  color: Projectcolors.primary,
                  marginTop: 2,
                }}
              >
                Data Includes Time Series . Try Forecasting Algorithms.
              </Typography>{" "}
            </div>
          </Box>
        </div>
      ) : (
        <Box
          component={Paper}
          elevation={10}
          m="auto"
          sx={{
            height: "575px",
            width: 1380,
            marginTop: "7px",
            padding: "28px 32px",
            // paddingBottom:10,
            marginBottom: 10,
          }}
        >
          <Box
            sx={{
              float: "right",
              marginRight: 8,
              width: "300px",
              border: 0,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Typography
              component="div"
              sx={{
                fontSize: "13px",
                ml: "70px",
                mr: "8px  ",
                fontWeight: "bold",
              }}
            >
              Train Data %
            </Typography>
            {test > 0 && ann.length === 0 ? (
              <FormControl
                sx={{ float: "right", width: 140, marginTop: 0, padding: 0 }}
              >
                {" "}
                <OutlinedInput
                  sx={{ width: "147px", fontSize: "12px", height: "40px" }}
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="Size"
                  onChange={handleSize}
                  size="small"
                  defaultValue={test}
                />
              </FormControl>
            ) : (
              <FormControl
                sx={{ float: "right", width: 140, marginTop: 0, padding: 0 }}
              >
                <OutlinedInput
                  id="outlined-basic"
                  variant="outlined"
                  sx={{ width: "147px", fontSize: "12px", height: "40px" }}
                  placeholder="Size"
                  onChange={handleSize}
                  size="small"
                  //  inputProps={:}
                />
              </FormControl>
            )}
          </Box>

          <Box
            //  m="auto"
            sx={{
              width: 1350,
              height: 340,
              display: "flex",
              flexDirection: "row",
              marginTop: "55px",
              boder: 1,
              //  bgcolor:"green"
              //  overflowX:"hidden"
              //  marginLeft:"50px"
            }}
          >
            <Box
              sx={{
                width: 250,
                height: "300px",
                marginTop: "0px",
                marginRight: "10px",
              }}
            >
              <div
                style={{
                  //position: "fixed",
                  backgroundColor: "white",
                  position: "relative",
                  top: 8,
                  left: 15,
                  width: 155,
                }}
              >
                <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                  &nbsp;&nbsp;Independent Variables
                </Typography>
              </div>

              <Box
                sx={{
                  width: 250,
                  height: "300px",
                  marginTop: "0px",
                  border: 1,
                  borderColor: Projectcolors.primary,
                  padding: "10px 30px",
                  overflowY: "scroll",
                  // marginLeft: "1px",
                }}
              >
                {independent.map((item, i) => {
                  return (
                    <Typography
                      sx={{
                        // float: "right",
                        // marginRight: 0,
                        fontSize: "11px",
                        marginTop: 1,
                        // marginLeft: 5,
                      }}
                    >
                      {" "}
                      {item} ({unitsin[i]})
                      {/* <div style={{display:"flex",flexDirection:"row",alignItems:"center"}}>
         <Divider
           sx={{
             width: 240,
             height: 1,
             color: Projectcolors.primary,
             backgroundColor: Projectcolors.primary,
           }}
         />
         </div> */}
                      <img
                        src={Arrow}
                        style={{
                          width: "100%",
                          height: "28px",
                          marginTop: 1,
                          // backgroundColor:"red"
                        }}
                      />
                    </Typography>
                  );
                })}
              </Box>
            </Box>

            <Box
              sx={{
                width: 350,
                height: "300px",
                border: 1,
                padding: 5,
                boxShadow: 9,
                border: 1,
                borderWidth: "4px",
                borderColor: Projectcolors.primary,
                borderRadius: 3,
                fontSize: 20,
                textAlign: "center",
                marginTop: 2,
              }}
            >
              <Typography sx={{ fontSize: "15px" }}>
                SelexMB Training Model
              </Typography>
              <img
                src={model}
                style={{ marginTop: 40, objectFit: "contain", height: "140px" }}
              />
            </Box>

            <Box sx={{ width: 390, height: "300px", marginLeft: "10px" }}>
              <div
                style={{
                  //position: "fixed",
                  backgroundColor: "white",
                  position: "relative",
                  top: 8,
                  left: 15,
                  width: 139,
                }}
              >
                <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                  &nbsp;&nbsp;Dependent Variables
                </Typography>
              </div>
              <Box
                sx={{
                  width: 370,
                  height: "300px",
                  marginTop: "0px",
                  border: 1,
                  borderColor: Projectcolors.primary,
                  padding: 1,
                  overflowY: "scroll",
                  marginLeft: "1px",
                }}
              >
                {dependent.map((item, i) => {
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: 0,
                        width: 180,
                      }}
                    >
                      <Box>
                        <Typography sx={{ fontSize: "11px", marginLeft: 3 }}>
                          {item} ({unitsde[i]})
                          {/* <ArrowRightAltIcon sx={{fontSize:27,marginTop:0,marginleft:30,position:"absolute"}}/> */}
                        </Typography>
                        <Typography>
                          <img
                            src={Arrow}
                            style={{
                              width: "165px",
                              height: "28px",
                              marginTop: 1,
                              marginLeft: "10px",
                              marginRight: "10px",
                              // backgroundColor:"red"
                            }}
                          />
                        </Typography>
                      </Box>
                      <Box>
                        {cat[i] ? (
                          <div>
                            <FormControl
                              size="large"
                              sx={{ width: 150, marginTop: "20px" }}
                            >
                              <Typography sx={{ fontSize: "10px" }}>
                                {" "}
                                Select Algorithm
                              </Typography>
                              <Select
                                id="demo-simple-select-helper"
                                // label="Age"
                                //label=""
                                placeholder="Placeholder"
                                //labelId={item}
                                //id={i}
                                //value={algorithm[index]}
                                //label={item}
                                sx={{ fontSize: "12px", height: "43px" }}
                                onChange={(e) => {
                                  handleChange(e, i);
                                }}
                                defaultValue={6}
                                //  inputProps={{height:"20px"}}
                                //defaultValue={1}
                              >
                                <MenuItem value={7} sx={{ fontSize: "11px" }}>
                                  MBKNN
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                        ) : (
                          <div>
                            <FormControl
                              size="large"
                              sx={{ width: 150, marginTop: "20px" }}
                            >
                              <Typography sx={{ fontSize: "10px" }}>
                                {" "}
                                Select Algorithm
                              </Typography>
                              <Select
                                id="demo-simple-select-helper"
                                // label="Age"
                                //label=""
                                placeholder="Placeholder"
                                // labelId={item}
                                // id={i}
                                //value={algorithm[index]}
                                //label={item}
                                sx={{ fontSize: "12px", height: "43px" }}
                                onChange={(e) => {
                                  handleChange(e, i);
                                }}
                                defaultValue={prevalgo[i]}
                                //  inputProps={{height:"20px"}}
                                //defaultValue={1}
                              >
                                <MenuItem value={0} sx={{ fontSize: "11px" }}>
                                  MBCV
                                </MenuItem>
                                <MenuItem value={1} sx={{ fontSize: "11px" }}>
                                  MBLR
                                </MenuItem>
                                <MenuItem value={2} sx={{ fontSize: "11px" }}>
                                  MBPR
                                </MenuItem>
                                <MenuItem value={3} sx={{ fontSize: "11px" }}>
                                  MBRF
                                </MenuItem>
                                <MenuItem value={4} sx={{ fontSize: "11px" }}>
                                  MBKN
                                </MenuItem>
                                <MenuItem value={5} sx={{ fontSize: "11px" }}>
                                  MBDT
                                </MenuItem>
                                <MenuItem value={6} sx={{ fontSize: "11px" }}>
                                  MBML
                                </MenuItem>
                                <MenuItem value={8} sx={{ fontSize: "11px" }}>
                                  MBX
                                </MenuItem>
                                <MenuItem value={9} sx={{ fontSize: "11px" }}>
                                  MBSVM
                                </MenuItem>

                                <MenuItem value={10} sx={{ fontSize: "11px" }}>
                                  MBMLP
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                        )}
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>

            <Box>
              <div
                style={{
                  backgroundColor: "white",
                  position: "relative",
                  top: 10,
                  left: 15,
                  width: 160,
                }}
              >
                <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                  &nbsp;&nbsp;Hyperparameter Tuning
                </Typography>
              </div>

              <Box
                sx={{
                  border: 1,
                  borderColor: Projectcolors.primary,
                  width: 340,
                  marginTop: 0,
                  marginLeft: 0,
                  marginRight: 1,
                  height: "420px",
                  padding: 1,
                  overflowY: "scroll",
                }}
              >
                {choosenalgo.map((item, i) => {
                  return (
                    <div style={{ marginTop: "8px" }}>
                      <Typography
                        sx={{
                          fontSize: "13px",
                          marginTop: 10,
                          display: "inline",
                        }}
                      >
                        {dependent[i]} {algorithmshort[showinput[i]]} Parameters
                      </Typography>
                      <Divider sx={{ marginBottom: 2 }} />
                      {item.map((value, j) => {
                        return (
                          <div>
                            <Box sx={{ display: "flex", flexDirection: "row" }}>
                              <Box sx={{ width: 155, padding: 1 }}>
                                <Typography sx={{ fontSize: "12px" }}>
                                  {parameterslabels[showinput[i]][j]}
                                </Typography>
                              </Box>
                              <Box>
                                <FormControl>
                                  {" "}
                                  <OutlinedInput
                                    id="outlined-basic"
                                    variant="outlined"
                                    placeholder="None"
                                    sx={{ fontSize: "11px", width: "130px" }}
                                    onChange={(e) => {
                                      handleInput(e, i, j);
                                    }}
                                    size="small"
                                    value={value}
                                    defaultValue={value}
                                  />
                                </FormControl>
                                <Tooltip
                                  title={
                                    <Typography fontSize={11}>
                                      {tooltip[showinput[i]][j]}
                                    </Typography>
                                  }
                                  sx={{ fontSize: 15 }}
                                >
                                  <IconButton>
                                    <InfoIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Box>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </Box>
            </Box>
          </Box>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              margin: "auto",
              width: "30%",
              // marginTop: 6,
              marginLeft: "18%",
            }}
          >
            <h5 style={{ fontSize: "13px" }}>Count : {count} </h5>
            <h5 style={{ fontSize: "13px" }}>
              Independent : {independent.length}
            </h5>
            <h5 style={{ fontSize: "13px" }}>Dependent : {dependent.length}</h5>
          </div>
          <Button
            onClick={trainmodel}
            disabled={!props.editAccess}
            mr={4}
            sx={{
              width: "95px",
              height: "32px",
              fontSize: "12px",
              backgroundColor: Projectcolors.primary,
              color: "#fff",
              fontStyle: "bold",
              // marginBottom: 2,
              float: "left",
              marginRight: 3,
              marginLeft: "270px",
              mt: 3,
              fontWeight: "bold",
              // marginBottom:40
              // marginTop: 2,
            }}
            variant="contained"
            size="medium"
          >
            {" "}
            Train
          </Button>

          <Button
            onClick={NextStep}
            mt={5}
            sx={{
              float: "left",
              width: 10,
              minWidth: 38,
              height: "32px",
              fontSize: "19px",
              // marginTop: 2,
              backgroundColor: Projectcolors.primary,
              color: "black",
              fontStyle: "bold",
              padding: 0,
              mt: 3,
              marginRight: 3,
            }}
            variant="contained"
            size="large"
          >
            {" "}
            <ChevronRightIcon sx={{ fontSize: "19px", color: "white" }} />
          </Button>

          <br />

          {trained ? (
            <Alert
              sx={{
                width: "20%",
                fontSize: "12px",
                color: Projectcolors.primary,
                marginLeft: "340px",
                marginTop: "7px",
              }}
              variant="outlined"
              severity="success"
            >
              Model Trained Successfully
            </Alert>
          ) : null}
          <br />
          {trained ? (
            <Box
              sx={{
                width: "100%",
                marginBottom: 0,
                height: "20px",
                marginTop: "0px",
              }}
            >
              <LinearProgressWithLabel value={100} sx={{ height: "6px" }} />
            </Box>
          ) : null}

          {modalTraining ? (
            <Box
              sx={{
                width: "100%",
                marginBottom: 0,
                height: "10px",
                marginTop: "55px",
              }}
            >
              <LinearProgressWithLabel
                value={progress}
                sx={{ height: "6px" }}
              />
            </Box>
          ) : null}
        </Box>
      )}

      {getLoader ? <GlobalLoader /> : null}
    </div>
  );
}

export default BuildModel;
