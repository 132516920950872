import React from "react";
import { Route, Navigate, Outlet } from "react-router-dom";

// to handle redirection from public route
// Ex.
// // An authenticated user tries to load public pages like Login, Register, etc.
// // it will redirect to defined path
// export const PublicRoute = (props) => {
//   const { redirectTo = "/", auth, ...restProps } = props;

//   if (auth) {
//     return <Navigate to={redirectTo} />;
//   }

//   return <Route {...restProps} />;
// };

// // to handle redirection from private/restricted route
// // Ex.
// // A non authenticated user tries to load restricted pages like Profile, Dashboard, etc.
// // it will redirect to defined path
// export const PrivateRoute = (props) => {
//   const { redirectTo = "/", auth, component, ...restProps } = props;

//   if (!auth) {
//     return <Navigate to={redirectTo} />;
//   }

//   return <component />;
// };


export const ProtectedRoute = ({ user, redirectPath = "/" }) => {
  if (!user) {
    return <Navigate to={redirectPath} replace />;
  }
  return <Outlet />;
};

export const AdminRoute = ({ user,admin, redirectPath = "/admin", children }) => {
  if (!admin) {
    return <Navigate to={redirectPath} replace />;
  }

  return children;
};
