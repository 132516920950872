import React from 'react'
import image10 from "../../images/10SelexHelp.png";
import image11 from "../../images/11SelexHelp.png";
import image12 from "../../images/12SelexHelp.png";
import image13 from "../../images/13SelexHelp.png";
import image14 from "../../images/14SelexHelp.png";
import image15 from "../../images/15SelexHelp.png";
import image16 from "../../images/16SelexHelp.png";
import '../css/GettingStarted.css';


function Tutorial() {
  return (
    <div className="project-details">
              <h2>Differential Equation Tutorial</h2>
              <br/>
              <img src={image10} className='imagestyling'/>
              <br/>
              <img src={image11} className='imagestyling'/>
              <br/>
              <img src={image12} className='imagestyling'/><br/>
              <img src={image13} className='imagestyling'/><br/>
              <img src={image14} className='imagestyling'/><br/>
              <img src={image15} className='imagestyling'/><br/>
              <img src={image16} className='imagestyling'/><br/>
              <p>
              SelexMath stands as a robust mathematical engine that boasts the remarkable capability to tackle a wide range of complex challenges within the oil and gas industry. Its proficiency extends to resolving both ordinary and partial differential equations, particularly in scenarios involving heat transfer, mass transfer, and reactor applications.
              </p>
              <p>The power of SelexMath truly shines when it comes to 1D modeling (One Dimensional Modeling), a domain where it excels in addressing many industrial problems effectively and efficiently.</p>
              <p>For those who wish to witness the capabilities of SelexMath in action, we unequivocally invite you to reach out to us for a demonstration copy. Simply drop us an email at the following address: <b>safiya.shaikh@optimizegs.com</b></p>
              <p>We'd be delighted to showcase how SelexMath can empower your operations and help you overcome your mathematical modeling challenges.
 </p>
</div>
  )
}

export default Tutorial