import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { baseURL } from "../../Config/config";
import Projectcolors from "../../Utils/Colors";
//   import GlobalLoader from "./CommonC/GlobalLoader";

//   import bg from "../images/AI.jpg";
import Selexlogo from "../../images/SelexMB Final.png";
import optimizelogo from "../../images/logooptimizegs.png";

//   import BackgroundContainer from "./CommonC/BackgroundContainer";
import SubNavbar from "../CommonC/SubNavbar";
import { Alert } from "@mui/material";
import BgContainer2 from "../CommonC/BgContainer2";
import AboutModal from "../CommonC/AboutModal";
import ContactModal from "../CommonC/ContactModal";

const ForgotPasswordAdmin = () => {
  const navigate = useNavigate();
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openc, setOpenc] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");

  const [state, setState] = React.useState({
    isOpen: false,
    vertical: "top",
    horizontal: "center",
  });
  const { id } = useParams();
  const { vertical, horizontal, isOpen } = state;
  const handleOpenc = () => setOpenc(true);
  const handleCloseContact = () => setOpenc(false);

  const handleSubmit = async () => {
    setLoading(true);
    const body = {
      username,
      reset: "false",
      userType: "admins",
    };

    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    };
    try {
      let result = await fetch(`${baseURL}/resetpassword`, options);
      result = await result.json();
      if (result.token) {
        setLoading(false);
        setSnackbarMessage(result.message);
        handleSnackbar(true);
        return;
      }
      setLoading(false);
      setSnackbarMessage(result.message);
      handleSnackbar(true);
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }

    return;
  };

  const handlePasswordSubmit = async () => {
    if (password !== confirmPassword) {
      setSnackbarMessage("Password and confirm password are not same");
      handleSnackbar(true);
      return;
    }

    setLoading(true);
    const body = {
      password,
      id,
      reset: "true",
      userType: "admins",
    };

    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    };
    try {
      let result = await fetch(`${baseURL}/resetpassword`, options);
      result = await result.json();
      if (result.code) {
        setLoading(false);
        setSnackbarMessage(result.message);
        handleSnackbar(true);
        setTimeout(() => {
          navigate("/admin");
        }, 3000);
        return;
      }
      setLoading(false);
      setSnackbarMessage(result.message);
      handleSnackbar(true);
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }

    return;
  };

  const handleSnackbar = (value) => {
    setState({ ...state, isOpen: value });
  };

  return (
    <div
      style={{
        height: window.screen.height,
      }}
    >
      <BgContainer2>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          key={vertical + horizontal}
          open={isOpen}
          autoHideDuration={4000}
          onClose={() => handleSnackbar(false)}
        >
          <Alert
            severity="info"
            sx={{
              width: "100%",
              color: Projectcolors.primary,
              fontSize: "12px",
              backgroundColor: "#fff",
            }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <SubNavbar
          handleOpenc={handleOpenc}
          handleOpen={handleOpen}
          home={true}
        />

        <br />

        {!id ? (
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              alignItems: "center",
              marginTop: "8%",
              marginLeft: "-0.5%",
            }}
          >
            <div
              style={{
                backgroundColor: "#fff",
                display: "flex",
                padding: "2px 70px 2px 70px",
                marginTop: "48px",
                flexDirection: "column",
                borderRadius: "16px",
                border: "1px solid black",
                marginBottom: "10px",
                position: "relative",
              }}
            >
              <div style={{ position: "absolute", left: "10px" }}>
                <img
                  style={{
                    height: "70px",
                    width: "130px",
                    marginTop: "8px",
                    objectFit: "contain",
                  }}
                  src={Selexlogo}
                />
              </div>

              <Typography
                sx={{
                  fontSize: "15px",
                  marginBottom: "16px",
                  marginTop: "100px",
                }}
              >
                Forgot Password
              </Typography>
              <TextField
                //   required
                id="outlined-basic"
                variant="outlined"
                placeholder="Email or username"
                //   defaultValue="Hello World"
                onChange={(e) => setUsername(e.target.value)}
                sx={{ width: 220, fontSize: "30px" }}
                InputLabelProps={{ style: { fontSize: 12 } }}
                inputProps={{ style: { fontSize: 12, height: 8 },autoComplete: 'new-password' }}
                // InputLabelProps={{ shrink: true }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "12px",
                }}
              >
                <Button
                  onClick={handleSubmit}
                  sx={{
                    bgcolor: Projectcolors.primary,
                    fontSize: "10px",
                    width: 100,
                    marginBottom: 6,
                    marginTop: 2,
                    color: "#fff",
                    ":hover": {
                      bgcolor: Projectcolors.primary, // theme.palette.primary.main
                      color: "white",
                    },
                  }}
                >
                  {loading ? (
                    <CircularProgress sx={{ color: "#fff" }} size={18} />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              alignItems: "center",
              marginTop: "8%",
              marginLeft: "-0.5%",
            }}
          >
            <div
              style={{
                backgroundColor: "#fff",
                display: "flex",
                padding: "2px 70px 2px 70px",
                marginTop: "48px",
                flexDirection: "column",
                borderRadius: "16px",
                border: "1px solid black",
                marginBottom: "10px",
                position: "relative",
              }}
            >
              <div style={{ position: "absolute", left: "10px" }}>
                <img
                  style={{
                    height: "70px",
                    width: "130px",
                    marginTop: "8px",
                    objectFit: "contain",
                  }}
                  src={Selexlogo}
                />
              </div>

              <Typography
                sx={{
                  fontSize: "15px",
                  marginBottom: "16px",
                  marginTop: "100px",
                }}
              >
                Reset Password
              </Typography>
              <TextField
                //   required
                id="outlined-basic"
                variant="outlined"
                placeholder="Password"
                //   defaultValue="Hello World"
                onChange={(e) => setPassword(e.target.value)}
                sx={{ width: 220, fontSize: "30px" }}
                InputLabelProps={{ style: { fontSize: 12 } }}
                inputProps={{ style: { fontSize: 12, height: 8 },autoComplete: 'new-password' }}
                // InputLabelProps={{ shrink: true }}
              />

              <TextField
                //   required
                fullWidth
                id="outlined-basic"
                variant="outlined"
                placeholder="Confirm Password"
                type="password"
                //   defaultValue="Hello World"
                onChange={(e) => setConfirmPassword(e.target.value)}
                sx={{ width: 220, marginTop: 2 }}
                inputProps={{ style: { fontSize: 12, height: 8 },autoComplete: 'new-password' }}
                InputLabelProps={{ style: { fontSize: 12 } }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handlePasswordSubmit()
                    // write your functionality here
                  }
                }}
              />

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "12px",
                }}
              >
                <Button
                  onClick={handlePasswordSubmit}
                  sx={{
                    bgcolor: Projectcolors.primary,
                    fontSize: "10px",
                    width: 100,
                    marginBottom: 6,
                    marginTop: 2,
                    color: "#fff",
                    ":hover": {
                      bgcolor: Projectcolors.primary, // theme.palette.primary.main
                      color: "white",
                    },
                  }}
                >
                  {loading ? (
                    <CircularProgress sx={{ color: "#fff" }} size={18} />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </div>
            </div>
          </div>
        )}

        <AboutModal open={open} handleClose={handleClose} />
        <ContactModal open={openc} handleClose={handleCloseContact} />
      </BgContainer2>
    </div>
  );
};

export default ForgotPasswordAdmin;
