import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Checkbox, CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import Projectcolors from "../../Utils/Colors";
import { baseURL } from "../../Config/config";
import GlobalLoader from "../CommonC/GlobalLoader";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Typography } from "@mui/material";
import Radio from "@mui/material/Radio";
import Plot from "react-plotly.js";

export default function SelectKpi(props) {
  const [dataArray, setdataArray] = React.useState(null);
  const [independent, setIndependent] = React.useState([]);
  const [dependent, setDependent] = React.useState([]);
  const [checkedindependent, setcheckedin] = React.useState(
    new Array(100).fill(false)
  );
  const [checkeddependent, setcheckedde] = React.useState(
    new Array(100).fill(false)
  );
  const [indb, setindb] = React.useState([]);
  const [dedb, setdedb] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [getLoader, setGetLoader] = React.useState(false);
  const [kpierrr, setkpierr] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [timeseries, setTimeseries] = React.useState(0);
  const [value, setValue] = React.useState("1");
  const [selectedValue, setSelectedValue] = React.useState(0);
  const [ranks, setRanks] = React.useState([]);
  const [spearman, setSpearman] = React.useState(0);
  const [save, setSave] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === "2") {
      if (save) {
        setSpearman(1);
        getSpearman();
      } else {
        setSpearman(0);
      }
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeRadio = (item, position) => {
    setSelectedValue(position);
  };

  const getSpearman = () => {
    setGetLoader(true);
    const ProjectID = localStorage.getItem("projectID");
    fetch(`${baseURL}/getSpearman?projectID=${ProjectID}`)
      .then((res) => res.json())
      .then((myresponse) => {
        setRanks(myresponse.allranks);
        setIndependent(myresponse.independent);
        setDependent(myresponse.dependent);
        setGetLoader(false);
      })
      .catch((err) => {
        setGetLoader(false);
        console.log("error", err);
      });
  };

  React.useEffect(() => {
    getSelectKpi();
    getSpearman();
  }, []);

  function getcheckstatus(data) {
    setIndependent(data.independent);
    setDependent(data.dependent);
    let newinArray = checkedindependent;
    let newdeArray = checkeddependent;
    if (data.independent.length > 0) {
      data.independent.map((indbitem, indbindex) => {
        data.allkpi.map((item, i) => {
          if (indbitem === item[1]) {
            newinArray[i] = true;
            setcheckedin(newinArray);
          }
        });
      });
    }

    if (data.dependent.length > 0) {
      data.dependent.map((dedbitem, dedbindex) => {
        data.allkpi.map((item, i) => {
          if (dedbitem === item[1]) {
            newdeArray[i] = true;
            setcheckedde(newdeArray);
          }
        });
      });
    }
  }

  const getSelectKpi = () => {
    setGetLoader(true);
    if (props.loaded) {
      localStorage.setItem("projectID", props.ProjectID);
    }

    const ProjectID = localStorage.getItem("projectID");
    const body = {
      ProjectID,
    };

    const options = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      // body: JSON.stringify(body),
    };
    fetch(
      `${baseURL}/selectkpi?projectID=${ProjectID}&loaded=${props.loaded}`,
      options
    )
      .then((res) => res.json())
      .then((kpidata) => {
        setdataArray(kpidata.allkpi);
        setTimeseries(kpidata.timeseries);
        //initializecheckstatus(kpidata.allkpi);
        if (props.loaded || kpidata.independent) {
          getcheckstatus(kpidata);
          if (kpidata.independent.length > 0 && kpidata.dependent.length > 0) {
            setSave(1);
          }
        }
        setGetLoader(false);
      })
      .catch((err) => {
        setGetLoader(false);
        console.log("There was an error", err);
      });
  };

  const handleSubmit = () => {
    const ProjectID = localStorage.getItem("projectID");
    setIsLoading(true);
    try {
      fetch(`${baseURL}/savekpi`, {
        method: "POST",
        body: JSON.stringify({
          independent: independent,
          dependent: dependent,
          ProjectID: ProjectID,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((result) => {
          setIsLoading(false);
          setSave(1);
          //props.handleNextStep();
        })
        .catch((error) => {
          setIsLoading(false);
          console.error("Error:", error);
        });
    } catch (error) {
      setIsLoading(false);
      console.log("Error", error);
    }
  };

  const handleIndependentChange = (e, i) => {
    if (checkedindependent) {
      if (dependent.includes(e.target.value)) {
        setOpen(true);
      } else {
        let newinArray = checkedindependent;
        newinArray[i] = !newinArray[i];
        setcheckedin(newinArray);

        if (e.target.checked) {
          setIndependent((independent) => [...independent, e.target.value]);
          return;
        } else {
        }

        const data = [...independent];
        const newData = data.filter((x) => x !== e.target.value);
        setIndependent(newData);
      }
    }
  };

  const handledependentChange = (e, i) => {
    if (checkeddependent) {
      if (independent.includes(e.target.value)) {
        setOpen(true);
      } else {
        let newdeArray = checkeddependent;
        newdeArray[i] = !newdeArray[i];
        setcheckedde(newdeArray);
        if (e.target.checked) {
          setDependent((dependent) => [...dependent, e.target.value]);
          return;
        }

        const data = [...dependent];
        const newData = data.filter((x) => x !== e.target.value);
        setDependent(newData);
      }
    }
  };
  const handleNext = () => {
    props.handleNextStep();
  };

  const disabled = !independent.length || !dependent.length;

  return (
    <Box
      style={{
        width: "980px",
        //display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: 80,
        height: "600px",
      }}
    >
      <Box sx={{ width: "100%", typography: "body1", marginTop: 9 }}>
        <TabContext value={value}>
          <Box
            sx={{ borderBottom: 1, borderColor: "divider" }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab
                label="Define Indicators"
                value="1"
                sx={{
                  fontSize: "17px",
                  textTransform: "none",
                  fontWeight: "bold",
                }}
              />
              <Tab
                label="Evaluate Indicators"
                value="2"
                sx={{
                  fontSize: "17px",
                  textTransform: "none",
                  fontWeight: "bold",
                }}
              />
            </TabList>
          </Box>
          <TabPanel value="1">
            <TableContainer
              component={Paper}
              elevation={10}
              style={{
                width: "940px",
                marginTop: 0,
                border: 1,
                //borderWidth: "10px",
                //borderColor: "black",
                height: "550px",
              }}
            >
              <Table
                sx={{ minWidth: 130 }}
                aria-label="simple table"
                stickyHeader
              >
                <TableHead sx={{ width: "100%" }}>
                  <TableRow>
                    {dataArray
                      ? dataArray[0].map((item, i) => {
                          return (
                            <TableCell
                              sx={{
                                fontWeight: "bold",
                                fontSize: "12px",
                                width: 350,
                                backgroundColor: Projectcolors.primary,
                                color: Projectcolors.white,
                              }}
                            >
                              {item}
                            </TableCell>
                          );
                        })
                      : null}
                    <TableCell
                      align="left"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "12px",
                        backgroundColor: Projectcolors.primary,
                        color: Projectcolors.white,
                      }}
                    >
                      Independent
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "12px",
                        backgroundColor: Projectcolors.primary,
                        color: Projectcolors.white,
                      }}
                    >
                      Dependent
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataArray
                    ? dataArray.map((item, i) => {
                        if (i > 0) {
                          return (
                            <TableRow sx={{ width: 600 }}>
                              {item.map((data, idx) => {
                                return (
                                  <TableCell
                                    align="left"
                                    sx={{ width: 50, fontSize: "10px" }}
                                  >
                                    {data}
                                  </TableCell>
                                );
                              })}
                              {timeseries === 1 && i === 1 ? (
                                <TableCell padding="checkbox">
                                  {
                                    <Checkbox
                                      color="primary"
                                      inputProps={{
                                        "aria-label": "select all desserts",
                                      }}
                                      size="medium"
                                      checked={true}
                                      onChange={(e) =>
                                        handleIndependentChange(e, i)
                                      }
                                      value={item[1]}
                                      disabled={true}
                                    />
                                  }
                                </TableCell>
                              ) : (
                                <TableCell padding="checkbox">
                                  {
                                    <Checkbox
                                      color="primary"
                                      inputProps={{
                                        "aria-label": "select all desserts",
                                      }}
                                      size="medium"
                                      checked={checkedindependent[i]}
                                      onChange={(e) =>
                                        handleIndependentChange(e, i)
                                      }
                                      value={item[1]}
                                      disabled={
                                        timeseries ? true : !props.editAccess
                                      }
                                    />
                                  }
                                </TableCell>
                              )}

                              <TableCell padding="checkbox">
                                <Checkbox
                                  color="primary"
                                  inputProps={{
                                    "aria-label": "select all desserts",
                                  }}
                                  size="medium"
                                  checked={checkeddependent[i]}
                                  onChange={(e) => handledependentChange(e, i)}
                                  value={item[1]}
                                  disabled={!props.editAccess}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        }
                      })
                    : null}
                </TableBody>
              </Table>

              {getLoader ? <GlobalLoader /> : null}
            </TableContainer>
            <Button
              disabled={disabled}
              onClick={handleNext}
              sx={{
                color: "white",
                width: "121px",
                height: 40,
                fontSize: "12px",
                marginTop: 1.5,
                backgroundColor: Projectcolors.primary,
                fontStyle: "bold",
                float: "right",
              }}
              variant="contained"
              size="large"
            >
              Next Step
            </Button>
            {isLoading ? (
              <Box
                sx={{
                  width: "121px",
                  height: 40,
                  fontSize: "12px",
                  // backgroundColor: Projectcolors.primary,
                  color: "#fff",
                  fontStyle: "bold",
                  marginBottom: 2,
                  marginTop: 1,
                  float: "right",
                  //marginLeft: 90,
                  border: 1,
                  marginRight: 1.5,
                }}
              >
                <CircularProgress size={28} sx={{ marginLeft: "50px" }} />
              </Box>
            ) : (
              <Button
                disabled={disabled}
                onClick={handleSubmit}
                sx={{
                  color: "white",
                  width: "121px",
                  height: 40,
                  fontSize: "12px",
                  marginTop: 1.5,
                  backgroundColor: Projectcolors.primary,
                  fontStyle: "bold",
                  float: "right",
                  marginRight: 1.5,
                }}
                variant="contained"
                size="large"
              >
                Save
              </Button>
            )}
          </TabPanel>
          <TabPanel value="2" sx={{ height: "500px" }}>
            <Box
              component={Paper}
              elevation={4}
              sx={{ marginLeft: 1, height: "430px" }}
            >
              {spearman ? (
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Box
                    component={Paper}
                    elevation={4}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: 3,
                      overflowY: "scroll",
                      height: "380px",
                      width: "220px",
                      marginLeft: 2,
                    }}
                  >
                    {dependent ? (
                      dependent.map((item, i) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft: 10,
                            }}
                          >
                            <Box
                              sx={{
                                width: 260,
                                maxWidth: 240,
                                display: "flex",
                                flexDirection: "row",
                                padding: 1,
                              }}
                            >
                              <div>
                                <Radio
                                  key={i}
                                  onChange={() => handleChangeRadio(item, i)}
                                  checked={selectedValue === i}
                                  value={i}
                                  size="medium"
                                />
                              </div>
                              <div style={{ padding: "5.0px" }}>
                                <Typography
                                  sx={{
                                    width: 90,
                                    display: "inline",
                                    fontSize: 12,
                                  }}
                                >
                                  {item}
                                </Typography>
                              </div>
                            </Box>
                          </div>
                        );
                      })
                    ) : (
                      <div>
                        <Typography
                          sx={{
                            fontSize: 14,
                            color: Projectcolors.primary,
                            marginTop: 2,
                            margin: "auto",
                            padding: 20,
                            marginLeft: 15,
                          }}
                        >
                          Categorical Data Trained on Logistic Regression
                        </Typography>{" "}
                      </div>
                    )}
                  </Box>

                  {getLoader ? (
                    <div>
                      <Box
                        sx={{
                          marginTop: 2,
                          margin: "auto",
                          padding: 20,
                          marginLeft: 15,
                          margin: "auto",
                        }}
                      >
                        <CircularProgress size={28} />
                      </Box>
                    </div>
                  ) : (
                    <div>
                      <Box
                        component={Paper}
                        elevation={4}
                        sx={{
                          marginLeft: "40px",
                          marginTop: "20px",
                          height: "380px",
                          overflowY: "scroll",
                          width: "610px",
                          padding: "28px",
                        }}
                      >
                        <Plot
                          data={[
                            {
                              x: independent,
                              y: ranks[selectedValue],
                              name: "Trace1",
                              type: "bar",
                              width: 0.3,
                            },
                          ]}
                          layout={{
                            width: 500,
                            height: 350,
                            title: "MB Ranking Plot",
                            barmode: "relative",
                            name: "",
                            borderradius: 5,
                            yaxis: {
                              automargin: true,
                            },
                            xaxis: {
                              automargin: true,
                            },
                          }}
                          config={{
                            displaylogo: false,
                          }}
                        />
                      </Box>
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  <Typography
                    sx={{
                      fontSize: 14,
                      color: Projectcolors.primary,
                      marginTop: 2,
                      margin: "auto",
                      padding: 20,
                      marginLeft: 15,
                    }}
                  >
                    Please Select and Save Independent and Dependent Variable
                  </Typography>{" "}
                </div>
              )}
            </Box>

            <Button
              disabled={disabled}
              onClick={handleNext}
              sx={{
                color: "white",
                width: "121px",
                height: 40,
                fontSize: "12px",
                backgroundColor: Projectcolors.primary,
                fontStyle: "bold",
                float: "right",
                marginTop: 2,
              }}
              variant="contained"
              size="large"
            >
              Next Step
            </Button>
          </TabPanel>
        </TabContext>
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ color: "red", fontSize: "13px" }}
        >
          {"One Variable cannot be selected as Independent and Dependent Both."}
        </DialogTitle>
      </Dialog>
    </Box>
  );
}
