import React, { useState, useEffect } from "react";
import CleanData from "./Pages/CleanData";
import Importcsv from "./Pages/Importcsv";
import StepperC from "./StepperC";
import Navbar from "./CommonC/Navbar";
import { baseURL } from "../Config/config";
import AllBuildModel from "./Pages/AllBuildModel";
import AllDeploy from "./Pages/AllDeploy";
import { useLocation } from "react-router-dom";
import SelectKpi from "./Pages/SelectKpi";
import Validate from "./Pages/Validate";
import { useSelector, useDispatch } from "react-redux";
import { create } from "../Store/projectPermission/permssionReducer";

function LandingPage() {
  const [currentTime, setCurrentTime] = useState(0);
  const [selectedFile, setSelectedFile] = useState();
  const [isSelected, setIsSelected] = useState(false);
  const [dataArray, setdataArray] = useState(null);
  const [finaldata, setFinalData] = useState([]);

  const [kpi, setKpi] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const steps = [
    "Import Data",
    "Key Indicators",
    "Data Reconditioning",
    "Build MB",
    "Validate MB",
    "Deploy MB",
  ];

  let loaded = false;
  let ProjectID = false;
  let projectName = false;
  const location = useLocation();
  const dispatch = useDispatch();
  if (location.state.loaded) {
    loaded = location.state.loaded;
  }
  if (location.state.ProjectID) {
    ProjectID = location.state.ProjectID;
  }
  if (location.state.projectName) {
    projectName = location.state.projectName;
  }

  const availableProjects = useSelector((state) => state.cart);

  useEffect(() => {
    getProjectDetails();

    fetch(`${baseURL}/time`)
      .then((res) => res.json())
      .then((data) => {
        setCurrentTime(data.time);
      });
  }, []);

  const getProjectDetails = async () => {
    // setGetLoader(true);
    const token = localStorage.getItem("usertoken");
    const options = {
      method: "GET",
      headers: { "Content-Type": "application/json", token: token },
    };

    try {
      const result = await fetch(`${baseURL}/projectaccessdetails`, options);
      let res = await result.json();
      if (!res.code) {
        // setGetLoader(false);
        //agar code zero hai to bas function stop kardo
        return;
      }
      //agr code zero nahi yaane 1 hai to baaki ka function run kro
      // setGetLoader(false);
      dispatch(create(res.projectDetails));
      // setProjectDetails([...res.data]);
    } catch (error) {
      // setGetLoader(false);
      console.log("error", error);
    }
  };
  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };

  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };
  const handlePrevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const setCurrentStepFunc = (value) => {
    return setCurrentStep(value);
  };

  const checkProjectAccess = () => {
    const projectID = localStorage.getItem("projectID");
    const currentProject = availableProjects.filter(
      (item) => item.projectId == projectID
    );
    if (availableProjects.includes(...currentProject)) {
      if (currentProject.length && currentProject[0].editAccess == true) {
        return true;
      }
      return false;
    }
    return true;
  };

  const editAccess = checkProjectAccess();

  return (
    <div>
      <Navbar />
      <StepperC
        setCurrentStepFunc={setCurrentStepFunc}
        allSteps={steps}
        currentStep={currentStep}
      />
      {currentStep === 0 ? (
        <Importcsv
          handleNextStep={handleNextStep}
          loaded={loaded}
          ProjectID={ProjectID}
          projectName={projectName}
          editAccess={editAccess}
        />
      ) : currentStep === 1 ? (
        <SelectKpi
          handleNextStep={handleNextStep}
          loaded={loaded}
          ProjectID={ProjectID}
          editAccess={editAccess}
        />
      ) : currentStep === 2 ? (
        <CleanData
          handleNextStep={handleNextStep}
          loaded={loaded}
          ProjectID={ProjectID}
          editAccess={editAccess}
        />
      ) : currentStep === 3 ? (
        <AllBuildModel
          handleNextStep={handleNextStep}
          loaded={loaded}
          ProjectID={ProjectID}
          editAccess={editAccess}
        />
      ) : currentStep === 4 ? (
        <Validate
          handleNextStep={handleNextStep}
          loaded={loaded}
          ProjectID={ProjectID}
          editAccess={editAccess}
        />
      ) : currentStep === 5 ? (
        <AllDeploy
          handleNextStep={handleNextStep}
          loaded={loaded}
          ProjectID={ProjectID}
          editAccess={editAccess}
        />
      ) : null}

      {/* <div>
        <p> button for Stepper which is outside the stepper</p>
      <button  onClick={handleNextStep}>Submit</button>
      </div> */}
      {/* <TableC /> */}
    </div>
  );
}

export default LandingPage;
