import React, { useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Plot from "react-plotly.js";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import Projectcolors from "../Utils/Colors";
import { Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import { baseURL } from "../Config/config";
import GlobalLoader from "./CommonC/GlobalLoader";

function SliceData(props) {
  const [rawdata, setRawData] = React.useState([]);

  const [lineplotdata, setLineplotdata] = React.useState([]);

  const [lineploty, setLineploty] = React.useState([]);
  const [xrange1, setXrange1] = React.useState(0);
  const [xrange2, setXrange2] = React.useState(0);

  const [slice, setslice] = React.useState(false);
  const [saved, setsaved] = React.useState(false);

  const [ylabel, setYLabel] = React.useState("Y axis");
  const colorslist = [
    "#3366cc",
    "#dc3912",
    "#ff9900",
    "#109618",
    "#990099",
    "#0099c6",
    "#dd4477",
    "#aaaa11",
    "#743411",
    "#e67300",
    "#22aa99",
    "#994499",
    "#22aa99",
    "#66aa00",
    "#6633cc",
    "#3366cc",
    "#8b0707",
    "#651067",
    "#329262",
    "#5574a6",
    "#3b3eac",
    "#b77322",
    "#16d620",
    "#b91383",
    "#f4359e",
    "#9c5935",
    "#a9c413",
    "#2a778d",
    "#668d1c",
    "#bea413",
    "#0c5922",
    "#b82e2e",
  ];
  const [colors, setColors] = React.useState(colorslist[0]);
  const [getLoader, setGetLoader] = React.useState(false);

  useEffect(() => {
    getSlicedData();
  }, [slice]);

  const getSlicedData = () => {
    setGetLoader(true);
    const ProjectID = localStorage.getItem("projectID");
    fetch(`${baseURL}/getsliceddata?projectID=${ProjectID}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())

      .then((raw) => {
        setRawData(raw.raw_data);
        setLineplotdata(raw.lineplotdata);
        setLineploty(raw.lineplotdata[0]);
        setGetLoader(false);
      })
      .catch((err) => {
        setGetLoader(false);
        console.log("caught it!", err);
      });
  };

  function handlerowclick(number) {
    setLineploty(lineplotdata[number]);
    setYLabel(rawdata[number][1]);
    setColors(colorslist[number]);

  }

  const getsliderdata = (eventdata) => {
    
    setslice(false);

    setXrange1(Math.round(eventdata["xaxis.range[0]"]));
    setXrange2(Math.round(eventdata["xaxis.range[1]"]));
  };


  const slicedata = () => {
    setGetLoader(true);
    const ProjectID = localStorage.getItem("projectID");

    const body = {
      x1: xrange1,
      x2: xrange2,
      ProjectID: ProjectID,
    };

    try {
      fetch(`${baseURL}/saveslicedata`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((result) => {
          setslice(true);
          setsaved(false);
          setGetLoader(false);
        })
        .catch((error) => {
          console.error("Error:", error);
          setGetLoader(false);
        });
    } catch (error) {
      console.log("error", error);
      setGetLoader(false);
    }
  };

  const undodata = () => {
    const ProjectID = localStorage.getItem("projectID");
    setGetLoader(true);
    try {
      fetch(`${baseURL}/undodata?projectID=${ProjectID}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((result) => {
          setslice(false);
          setGetLoader(false);
          getSlicedData();

        })
        .catch((error) => {
          console.error("Error:", error);
          setGetLoader(false);
        });
    } catch (error) {
      console.log("error", error);
      setGetLoader(false);
    }
  };

  const savedata = () => {
    setsaved(true);
    setslice(false);
  };

  const selectorOptions = {
    buttons: [
      {
        step: "month",
        stepmode: "backward",
        count: 1,
        label: "1m",
      },
      {
        step: "month",
        stepmode: "backward",
        count: 6,
        label: "6m",
      },
      {
        step: "year",
        stepmode: "todate",
        count: 1,
        label: "YTD",
      },
      {
        step: "year",
        stepmode: "backward",
        count: 1,
        label: "1y",
      },
      {
        step: "all",
      },
    ],
  };

  return (
    <div>
      {" "}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "white",
          marginLeft: 0,
          marginTop: 2,
        }}
      >
        <TableContainer
          component={Paper}
          elevation={3}
          style={{
            width: "95%",
            height: "250px",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: 0,
          }}
        >
          <Table aria-label="sticky table" stickyHeader>
            <TableHead>
              <TableRow hover={true}>
                <TableCell
                  sx={{
                    color: "white",
                    fontWeight: "bold",
                    fontSize: "11px",
                    width: 400,
                    backgroundColor: Projectcolors.primary,
                    height: 10,
                  }}
                >
                  Tag Name
                </TableCell>

                <TableCell
                  sx={{
                    color: "white",
                    fontWeight: "bold",
                    fontSize: "11px",
                    width: 400,
                    backgroundColor: Projectcolors.primary,
                  }}
                >
                  Description
                </TableCell>

                <TableCell
                  sx={{
                    color: "white",
                    fontWeight: "bold",
                    fontSize: "11px",
                    width: 400,
                    backgroundColor: Projectcolors.primary,
                  }}
                >
                  Unit Of Measure
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rawdata
                ? rawdata.map((item, i) => {
                    return (
                      <TableRow
                        hover={true}
                        onClick={() => handlerowclick(i)}
                        sx={{ width: "100%", height: 1 }}
                        key={i}
                      >
                        {item.map((data, idx) => {
                          return (
                            <TableCell
                              align="left"
                              key={data}
                              sx={{ width: 50, fontSize: "10px", height: 1 }}
                            >
                              {data}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
                : null}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {/* <div style={{ backgroundColor: "grey" }}> */}
      <Box
        sx={{
          display: "flex",
          flex: 1,
          backgroundColor: "grey",
          justifyContent: "center",
          marginTop: 2,
        }}
      >
        <Plot
          data={[
            {
              x: Array.from(Array(lineploty.length).keys()),
              y: lineploty,
              type: "line",
              mode: "lines+markers",
              marker: { color: colors },
            },
          ]}
          layout={{
            width: 900,
            height: 290,
            title: {
              font: {
                family: "Courier New, monospace",
                size: 24,
              },
              xref: "paper",
              x: 0.05,
            },
            xaxis: {
              title: {
                text: "Count",
                font: {
                  size: 14,
                  color: "#000",
                },
              },
              rangeselector: {},
              rangeslider: {
                visible: true,
                bgcolor: "lightgrey",
                thickness: 0.08,
              },
            },
            yaxis: {
              title: {
                text: ylabel,
                font: {
                  size: 14,
                  color: "#000",
                },
                rangeselector: selectorOptions,
                rangeslider: {},
              },
            },
          }}
          onRelayout={(eventdata) => getsliderdata(eventdata)}
          config = {{
            displaylogo:false
          }}
        />
      </Box>
      {/* BUTTON    */}
      <Box
        sx={{
          padding: 0,
          marginLeft: 12,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div>
          <Typography variant="h1" sx={{ fontSize: "14px" }}>
            Start Slice Out: {xrange1} &nbsp;&nbsp;&nbsp; End Slice Out:{" "}
            {xrange2}
          </Typography>
          <Typography
            variant="h1"
            sx={{ fontSize: 15, marginTop: 3 }}
          ></Typography>
        </div>
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "space-between",
            marginRight: 1,
            marginLeft: 100,
            marginBottom: 2,
          }}
        >
          {slice ? (
            <div>
              <Alert
                sx={{
                  fontSize: "12px",
                  color: Projectcolors.primary,
                  marginLeft: 0,
                  height: "45px",
                  width: "250px",
                  marginBottom: 2,
                }}
                variant="outlined"
                severity="success"
              >
                Data Sliced Successfully
              </Alert>
            </div>
          ) : (
            " "
          )}
          {saved ? (
            <div>
              <Alert
                sx={{
                  fontSize: "12px",
                  color: Projectcolors.primary,
                  height: "45px",
                  width: "250px",
                }}
                variant="outlined"
                severity="success"
              >
                Data saved
              </Alert>
            </div>
          ) : (
            <p></p>
          )}
        </div>

        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "flex-end",
            marginRight: 16,
            marginBottom: 20,
          }}
        >
          <Button
            onClick={slicedata}
            disabled={!props.editAccess}
            mr={4}
            sx={{
              marginLeft: 2,
              width: 30,
              height: 40,
              fontSize: "14px",
              padding: 2,
              backgroundColor: Projectcolors.primary,
              color: "white",
              fontStyle: "bold",
            }}
            variant="contained"
            size="large"
          >
            {" "}
            Apply
          </Button>
          <Button
            onClick={undodata}
            disabled={!props.editAccess}
            mt={3}
            mr={4}
            sx={{
              marginLeft: 2,
              width: 30,
              height: 40,
              fontSize: "14px",
              backgroundColor: Projectcolors.primary,
              color: "white",
              fontStyle: "bold",
            }}
            variant="contained"
            size="large"
          >
            {" "}
            Undo
          </Button>
          <Button
            onClick={savedata}
            disabled={!props.editAccess}
            mt={3}
            mr={4}
            sx={{
              marginLeft: 2,
              width: 30,
              height: 40,
              fontSize: "14px",
              backgroundColor: Projectcolors.primary,
              color: "white",
              fontStyle: "bold",
            }}
            variant="contained"
            size="large"
          >
            {" "}
            Save
          </Button>
        </div>
      </Box>
      {getLoader ? <GlobalLoader /> : null}
      {/* </div> */}
    </div>
  );
}

export default SliceData;
