import React from 'react';
import './css/GettingStarted.css'; // Import your CSS file for styling
import image1 from "../images/5raw.png";
import image2 from "../images/6dataslicing.png";
import image3 from "../images/7limits.png";
import image4 from "../images/8noise.png";
import image5 from "../images/5.2correlation.JPG";
import image6 from "../images/5.3plot.JPG";
import image7 from "../images/5.4mbrank.JPG";

function DataReconditioningStep() {
  return (
    <div className="project-details">
      <h2>Data Reconditioning Step</h2>
      <p>
        In the "Data Reconditioning" step, you can refine and prepare your data for machine learning analysis. This step is crucial for ensuring the quality and reliability of your data.
      </p>

      <h4>1. Raw Data Visualization</h4>
      <p>
        <strong>Visualizing Your Data</strong>
      </p>
      <p>
        <strong>Click "Raw Data Visualization":</strong> To begin, select the "Raw Data Visualization" option. This feature allows you to explore your data visually.
      </p>
      <p>
        <strong>Explore Data Plots:</strong> Utilize various data visualization tools to gain insights into your dataset. Visualizations can help you identify patterns, outliers, and potential issues within your data.
      </p>
      <p>
        <strong>Analyze Data Distribution:</strong> Assess the distribution of your variables, detect anomalies, and identify trends that may impact your model.
      </p>

      <p>
        In the "Raw Data Visualization" step of your software, you have four options to visualize and analyze your data. Here's an overview of each of these options:
      </p>

      <h5>1.1 - Data Statistics</h5>
      <p>
        This option allows users to view basic statistics of the data, including the minimum, maximum, and average values of different variables in a tabular format.
        Users can quickly assess the data's central tendency and range, which can provide insights into the distribution and characteristics of the data.
        It's a useful tool for understanding the general properties of the dataset.
      </p>
      <img alt="image1" src={image1} className='imagestyling'/>

      <h5>1.2 - Correlation (Heatmap):</h5>
      <p>
        Correlation analysis is a crucial step in understanding relationships between variables in the dataset.
        The "Correlation" option provides a heatmap that visually represents the correlation coefficients between pairs of variables.
        Positive correlations are typically shown in one color (e.g., shades of blue), while negative correlations are shown in another color (e.g., shades of red). The intensity of the color represents the strength of the correlation.
        Users can quickly identify which variables are positively or negatively related and to what extent.
      </p>
      <img alt="image2" src={image5} className='imagestyling'/>

      <h5>1.3 - Plot Controls (X and Y Plot):</h5>
      <p>
        The "Plot Controls" option allows users to create scatter plots or other types of graphical representations.
        Users can select two variables, one for the x-axis and another for the y-axis, and create a custom scatter plot. This is particularly useful for visually exploring relationships between two variables.
        Customization options may include specifying the type of plot (e.g., scatter plot, line plot) and adding labels and titles.
      </p>
      <img alt="image3" src={image6} className='imagestyling'/>

      <h5>1.4 - MB Ranking (Independent and Dependent Variable Ranking):</h5>
      <p>
        In the "MB Ranking" option, Plot shows ranking variables in terms of their importance, which can help identify which variables have the most significant impact on the dependent variable.
        Ranking can be based on various criteria, such as correlation strength, feature importance scores, or statistical tests.
        Users can understand the relative importance of different variables, aiding in feature selection or variable prioritization.
      </p>
      <img alt="image4" src={image7} className='imagestyling'/>

      <p>
        These data visualization options provide valuable tools for data exploration, analysis, and initial insights before moving on to more advanced modeling and decision-making steps. Users can use these tools to gain a better understanding of their data and to make informed decisions about variable selection and feature engineering.
      </p>


      <h4>2. Data Slicing</h4>
      <p>
        <strong>Selecting Data Subsets</strong>
      </p>
      <p>
        <strong>Click "Data Slicing":</strong> Choose the "Data Slicing" option to segment your data into subsets.
      </p>
      <p>
        <strong>Slicing Progress:</strong> A progress bar will display the percentage of data you have processed after each slicing operation, helping you keep track of your progress.
      </p>
      <img alt="image4" src={image2} className='imagestyling'/>


      <h4>3. Engineering Constraints</h4>
      <p>
        <strong>Applying Engineering Rules</strong>
      </p>
      <p>
        <strong>Click "Engineering Constraints":</strong> Select the "Engineering Constraints" option to enforce rules and constraints on your data. You can set lower and upper limits for your predictions.
      </p>
      <img alt="image5" src={image3} className='imagestyling'/>


      <h4>4. Data Noise Removal</h4>
      <p>
        <strong>Cleaning Noisy Data</strong>
      </p>
      <p>
        <strong>Click "Data Noise Removal":</strong> Choose the "Data Noise Removal" option to identify and eliminate noise or irrelevant information from your dataset.
      </p>
      
      <p>
        <strong>Noise Removal Process:</strong> Follow the steps to apply noise reduction techniques, such as smoothing or filtering, to improve data quality.
      </p>

      <img alt="image6" src={image4} className='imagestyling'/>


      <h4>Progress Tracking</h4>
      <p>
        Throughout the data reconditioning process, you can monitor your progress using the progress bar displayed on the screen. The progress bar shows the percentage of data you have worked on after slicing and processing.
      </p>
    </div>
  );
}

export default DataReconditioningStep;
