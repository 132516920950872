import { Box, Button, Modal, Paper, Typography } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import optimizelogo from "../../images/logooptimizegs.png";

const ContactModal = ({ open, handleClose }) => {
  const styles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    height: 450,
    bgcolor: "white",
    border: "2px solid #000",
    p: 4,
    display: "block",
  };

  return (
    <div
      style={{
        height: window.screen.height,
      }}
    >
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles} component={Paper} elevation={10}>
          <div>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                backgroundColor: "white",
                justifyContent: "space-between",
              }}
            >
              <Box
                component="div"
                sx={{ display: "flex", marginLeft: "1%", marginTop: 0 }}
              >
                <img
                  style={{ height: "65px", width: "210px", marginTop: "0px" }}
                  src={optimizelogo}
                />
              </Box>

              <Button
                onClick={handleClose}
                sx={{
                  display: "inline-flex",
                  color: "black",
                  fontSize: "16px",
                }}
              >
                <CloseIcon sx={{ fontSize: "20px" }} />
              </Button>
            </Box>
          </div>
          <Box sx={{ padding: 5 }}>
            <Typography sx={{ fontSize: 18 }}>
              {" "}
              Submit an inquiry form to have an Optimize Solutions
              representative quickly respond to you to selexmb@optimizegs.com
            </Typography>
            <br />
            <Typography variant="h4" sx={{ marginTop: 2 }}>
              <b>Worldwide Headquarter</b>
            </Typography>
            <br />
            <Typography sx={{ fontSize: 16 }}>
              UK Office: 85 Great Portland st. First floor, London, W1W7LT, UK.
            </Typography>
            <Typography sx={{ fontSize: 16 }}>
              Middle East Office: Zone 68, Street 935, Building 5, Doha, Qatar.{" "}
            </Typography>
            <Typography sx={{ fontSize: 16 }}>
              Tel (London): 020 31375226
            </Typography>
            <Typography sx={{ fontSize: 16 }}>Fax: 020 7183 3073</Typography>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default ContactModal;
