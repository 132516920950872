import React from 'react'
// import selexbg from '../../images/selexmbbg.png"'
import selexbg from "../../images/AI.jpg";


const BgContainer2 = (props) => {
  return (
    <div
      style={{
        backgroundImage: `url(${selexbg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        // border:"10px solid black",
        height:"100%",
        width:"100%",
        //minHeight:"100%",
        // border:"10px solid black",
        overflow:"hidden"
        // background
        // backgroundColor:"red",
        // height: "1050px",
        // display:"flex",
        /* Full height */
        /* Center and scale the image nicely */
        // background-position: center;
        // background-repeat: no-repeat;
        // background-size: cover;
      }}
    > 
    {props.children}
    </div>
  )
}

export default BgContainer2