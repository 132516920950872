import React, { useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Plot from "react-plotly.js";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import Heatmap from "./Heatmap";
import Projectcolors from "../Utils/Colors";
import { ListItemAvatar, Typography } from "@mui/material";
import LinePlotRaw from "./LinePlotRaw";
import { baseURL } from "../Config/config";
import GlobalLoader from "./CommonC/GlobalLoader";

const menuprops = {
  PaperProps: {
    style: {
      maxHeight: 250,

    },
  },
};

function Scatterplot() {
  const [rawdata, setRawData] = React.useState([]);

  const [lineplotdata, setLineplotdata] = React.useState([]);

  const [lineplotx, setLineplotx] = React.useState([]);
  const [x, setX] = React.useState("");
  const [y, setY] = React.useState("");
  const [ylabel, setYLabel] = React.useState("Y axis");
  const [xlabelscatter, setXscatter] = React.useState();
  const [ylabelscatter, setYscatter] = React.useState();
  const [alltagname, setTagname] = React.useState([]);
  const [getLoader, setGetLoader] = React.useState(false);
  const [timeseries, setTimeseries] = React.useState(0);

  const handledropdownx = (e) => {
    setX(e.target.value);
    setXscatter(rawdata[e.target.value][1]);
  };
  const handledropdowny = (e) => {
    setY(e.target.value);
    setYscatter(rawdata[e.target.value][1]);
  };

  useEffect(() => {
    getRawData();
  }, []);

  const getRawData = () => {
    setGetLoader(true);

    const ProjectID = localStorage.getItem("projectID");
    fetch(`${baseURL}/getrawdata?projectID=${ProjectID}&scatterplot=${1}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())

      .then((raw) => {
        setRawData(raw.raw_data);
        setLineplotdata(raw.lineplotdata);
        setLineplotx(raw.lineplotdata[0]);
        setTagname(raw.tag_name);
        setX(0);
        setY(1);
        setXscatter(alltagname[0]);
        setYscatter(alltagname[1]);
        setGetLoader(false);
        setTimeseries(raw.timeseries);
      })
      .catch((err) => {
        setGetLoader(false);
        console.log("caught it!", err);
      });
  };




  const handleSelect = () =>{
    if(xlabelscatter){

      return <span style={{color:"black"}}>{xlabelscatter}</span>
    } 
    return <span style={{color:"#888888"}}>Select</span>
  }

  const handleSelectY = () =>{
    if(ylabelscatter){

      return <span style={{color:"black"}}>{ylabelscatter}</span>
    } 
    return <span style={{color:"#888888"}}>Select</span>
  }


  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box
          component={Paper}
          elevation={4}
          sx={{ marginLeft: 1, border: "1px solid #0078C9" }}
        >
          <Plot
            data={[
              {
                x: lineplotdata[x],
                y: lineplotdata[y],
                type: "scatter",
                mode: "markers",
                marker: { color: Projectcolors.primary },
              },
            ]}
            layout={{
              margin: { b: 200 },
              width: 700,
              height: 500,
              title: {
                font: {
                  size: 18,
                },
                xref: "paper",
                x: 0.05,
              },
              xaxis: {
                title: {
                  text: xlabelscatter,
                  font: {
                    size: 14,
                    color: "#000",
                  },
                },
                linewidth: 1,
                ticklen: 10,
              },
              yaxis: {
                title: {
                  text: ylabelscatter,
                  font: {
                    size: 14,
                    color: "#000",
                  },
                },
                linewidth: 1,
                ticklen: 10,
              },
            }}
            config = {{
              displaylogo:false
            }}
          />
        </Box>
        <Box
          sx={{ display: "flex", flexDirection: "column", height: 200,width:170}}
          component={Paper}
          elevation={0}
        >
           <Typography sx={{ml:1.2,fontSize:"13px",fontWeight:500}}>X axis</Typography>
          <FormControl 
          sx={{ m: 1,mt:0.7, minWidth: 150, height: 40 }}>
            
            <Select
              defaultValue="AAAA"
              // labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              // label="Age"
              label=""
              placeholder="Placeholder"
              onChange={handledropdownx}
              sx={{ fontSize: 11 ,color:"black"}}
              MenuProps={menuprops}
              renderValue={handleSelect}
            >
              {rawdata
                ? rawdata.map((item, i) => {
                    return (
                      <MenuItem value={i} key={i} sx={{ fontSize: "10px" ,color:"black"}}>
                        {item[1]}
                      </MenuItem>
                    );
                  })
                : null}
            </Select>
          </FormControl>
          <Typography sx={{ml:1.2,mt:1.5,fontSize:"13px",fontWeight:500}}>Y axis</Typography>
          <FormControl sx={{ m: 1,mt:0.7, minWidth: 150, maxHeight: 40 }}>
            {/* <InputLabel
              sx={{ fontSize: 12 }}
              id="demo-simple-select-helper-label"
            >
              Y axis
            </InputLabel> */}
            <Select
              defaultValue="AAA"
              id="demo-simple-select-helper"
              onChange={handledropdowny}
              sx={{ fontSize: 11 }}
              MenuProps={menuprops}
              renderValue={handleSelectY}
            >
              {rawdata
                ? rawdata.map((item, i) => {
                    return (
                     <MenuItem value={i} key={i} sx={{ fontSize: "10px" }}>

                        {item[1]}
                      </MenuItem>
                    );
                  })
                : null}
            </Select>
          </FormControl>
        </Box>
      </Box>

      <Box sx={{ display: "flex", flex: 1, justifyContent: "center" }}></Box>

      {getLoader ? <GlobalLoader /> : null}
    </div>
  );
}

export default Scatterplot;
