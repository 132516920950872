import { Button, Modal, TextField, Typography } from "@mui/material";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import { baseURL } from "../../Config/config";

const AddUserModal = (props) => {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");

  const addUser = async () => {
    const userID = uuidv4();
    const body = {
      username,
      password,
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    };
    try {
      let res = await fetch(`${baseURL}/adduser`, requestOptions);
      res = await res.json();
      if (res.code) {
        props.toggleModal(false);
        props.refreshList(true)
        return;
      }
      alert("Some Error");
    } catch (error) {
      alert("Some Error", error);
      console.log("err", error);
      props.toggleModal(false);
    }
  };

  return (
    <div>
      <Modal
        open={props.modalState}
        onClose={() => props.toggleModal(false)}
        sx={{ display: "flex", flex: 1, justifyContent: "center", padding: 0 }}
        // elevation={15}
      >
        <div
          style={{
            backgroundColor: "#fff",
            display: "flex",
            // flex: 1,
            // justifyContent: "center",
            flexDirection: "column",
            width: "300px",
            height: "26%",
            marginTop: "15%",
            padding: "30px 20px",
            borderRadius: "6px",
          }}
        >
          <Typography sx={{fontSize:"13px",marginBottom:1}}>Add New User</Typography>
          <TextField
            id="outlined-basic"
            label="Username"
            variant="outlined"
            InputProps={{ style: { fontSize: "12px" } }}
            InputLabelProps={{ style: { fontSize: "12px" } }}
            onChange={(e) => setUsername(e.target.value)}
            sx={{
              // minWidth: "200px",
              backgroundColor: "#fff",
              fontSize: "12px",
              marginBottom: "10px",
            }}
            size="small"
          />

          <TextField
            id="outlined-basic"
            label="Password"
            variant="outlined"
            InputProps={{ style: { fontSize: "12px" } }}
            InputLabelProps={{ style: { fontSize: "12px" } }}
            onChange={(e) => setPassword(e.target.value)}
            sx={{
              // minWidth: "200px",
              backgroundColor: "#fff",
              fontSize: "16px",
              marginBottom: "10px",
            }}
            size="small"
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "-1000px",
            }}
          >
            <Button
              onClick={() => addUser()}
              variant="contained"
              sx={{
                color: 'white',
                cursor: "pointer",
                fontSize:"13px",
                textDecoration:"none"
      
              }}
              size="small"
            >
              Add
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddUserModal;
