import React from 'react';
import '../css/GettingStarted.css'; // Import your CSS file for styling
import image2 from "../../images/2SelexHelp.png";
import image3 from "../../images/3SelexHelp.png";
import image4 from "../../images/4SelexHelp.png";
import image5 from "../../images/5SelexHelp.png";
import image6 from "../../images/6SelexHelp.png";
import image7 from "../../images/7SelexHelp.png";
import imageselex from "../../images/62SelexHelp.png";




function Deinitialize() {
  return (
    <div className="project-details">
      <h2>Differential Equation Solver</h2>
      <h4>1 . DE Initialization</h4>
      <p>
      In this step, you can initialize a differential equation (DE) by defining the initial conditions. The Independent variable, The Dependent variable and Explicit equations
      </p>
      <br/>
      <img src={image4} className='imagestyling'/>



      <h4>Add Differential Equation</h4>
      <br/>
      <img src={image2} className='imagestyling'/>

      <ol>
        <li>
          Click on the "Add Differential Equation" button, a dialog box will open to facilitate the initialization of a new differential equation (DE)      </li>
        <li>
        In the first input box, you can enter the name or symbol representing the first dependent variable.
          </li>
        <li>
        Below that, you can define name or symbol representing Independent variables.
        </li>
        <li>On the right side, you can specify the equation itself, expressing the relationships between the variables.</li>
        <li>Finally, you will need to provide initial values for both the dependent and independent variables. These initial values serve as the starting conditions for solving the equation and predicting the behavior of the system over time or space.</li>
        
      </ol>
      <p>Properly inputting this information ensures accurate and meaningful solutions to the DE.</p>
      <p>Additionally, a box will display where all entered equations are listed, providing options to edit or delete them as necessary. This feature allows for easy management and modification of previously defined equations. By clicking on the "Edit" option, you can update the equation, while the "Delete" option removes it from the list. This functionality streamlines the process of organizing and adjusting equations, enhancing user experience and efficiency.</p>

      <h4>Add Explicit Equations</h4>
      <br/>
      <img src={image3} className='imagestyling'/>

      <p>
      Users can define other variables and equations that may influence the behavior of the system. These additional equations can represent external factors, boundary conditions, or constraints, and they can be integrated into the differential equation solution process to provide a more comprehensive understanding of the system dynamics. By incorporating explicit equations and defining relevant variables, users can effectively model and analyze complex systems in a more manageable and intuitive manner.
      </p>
      <ol>
        <li>
          Click on the "Add Explicit Equation" button, a dialog box containing input box will open to facilitate the initialization of a new explicit equation</li>
        <li>
        In the input box, you can define explicit equations using a simple syntax, such as "k = 5". This format allows you to directly assign a value to a variable, in this case, setting the value of "k" to 5. 
        </li>
        <li>
        In a single input box, you can define equations like "a = 10" and "k = a * 6" using a new-line format, such as "a = 10" then press enter and type "k = a * 6". This allows you to specify multiple equations in a single line, making it more convenient to input and manage various parameters and dependencies within the system.
        </li>
        </ol>
        <p>A box will display where all entered equations are listed, providing options to edit or delete them as necessary. This feature allows for easy management and modification of previously defined explicit equations. By clicking on the "Edit" option, you can update the equation, while the "Delete" option removes it from the list. This functionality streamlines the process of organizing and adjusting equations, enhancing user experience and efficiency.
</p>

<p><strong>"Please note: When typing any mathematical equation, remember to use Python's math operators syntax for proper interpretation and processing by the system."
<br/>
<img src={image7} className='imagestyling'/>    




</strong></p>
<h4>2 . DE Events</h4>
<p>In the "DE Events" step, you'll find two distinct boxes dedicated to adding Explicit Equations and adding Explicit Equations with range.The Graphical Representation in the image below shows "delta" variable as explicit variable with range. 
<br />
<img src={imageselex} className='imagestyling'/> 

</p>
<br/>


<h4>3 . DE Declaration</h4>
<p>In the "DE Declaration" step, you'll find three distinct boxes dedicated to declaring dependent, independent, and explicit variables along with their respective units of measurement (UOM) and remarks. 
<br />
<img src={image6} className='imagestyling'/> 

</p>
<p>By incorporating units of measurement and remarks for each variable declaration, you ensure clarity and precision in defining the components of your differential equations. This structured approach facilitates comprehensive documentation and understanding of the variables involved, enhancing the usability and effectiveness of the DE declaration process.</p>
<br/>
<img src={image5} className='imagestyling'/>    
    </div>
  );
}

export default Deinitialize;
