import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Projectcolors from '../Utils/Colors';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/material';
import AddTaskIcon from '@mui/icons-material/AddTask';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import { Link } from '@mui/material';
export default function StepperC(props) {

  const {setCurrentStepFunc} = props

  // const newHelper = props.setCurrentStepFunc()

  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#eaeaf0',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#eaeaf0',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderTopWidth: 3,
      borderRadius: 1,
    },
  }));




  const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 18,
    height: 18,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
      // backgroundImage:
      //   'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
      backgroundColor:'#d61002',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
      // backgroundImage:
      //   'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
      backgroundColor:'#d61002',
    }),
  }));

  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;
  
    const icons = {
      1: <div onClick={()=>setCurrentStepFunc(0)} style={{padding:"15px", fontSize:"10px  ",cursor:"pointer"}}>1</div>,
      2: <div onClick={()=>setCurrentStepFunc(1)} style={{padding:"15px", fontSize:"10px  ",cursor:"pointer"}}>2</div>,
      3: <div onClick={()=>setCurrentStepFunc(2)} style={{padding:"15px", fontSize:"10px  ",cursor:"pointer"}}>3</div>,
      4: <div onClick={()=>setCurrentStepFunc(3)} style={{padding:"15px", fontSize:"10px  ",cursor:"pointer"}}>4</div>,
      5: <div onClick={()=>setCurrentStepFunc(4)} style={{padding:"15px", fontSize:"10px  ",cursor:"pointer"}}>5</div>,
      6: <div onClick={()=>setCurrentStepFunc(5)} style={{padding:"15px", fontSize:"10px  ",cursor:"pointer"}}>6</div>,
      

    };
  
    return (
      <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }




  return (
     <Box  sx={{ width: '100%',height:'70px',marginTop:0,marginLeft:0,marginRight:5,zIndex:1000,position:"fixed",backgroundColor:"#fff"}}>
      <Box sx={{marginTop:1,width: '98%',height:'20px',zIndex:11000,elevation:100,backgroundColor:"#fff"}}>
      <Stepper connector={<QontoConnector />} activeStep={props.currentStep} sx={{padding:"15px",border:1,borderColor:Projectcolors.primary,margin:"10px 10px",position:"fixed",width:"98%",zIndex:1000,bgcolor:"#fff"}}>
        {props.allSteps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps} sx={{fontSize:20}}>
              <StepLabel StepIconComponent={ColorlibStepIcon}><Typography sx={{fontSize:"11px",color:Projectcolors.primary,fontWeight: 'bold'}}>{label}</Typography></StepLabel>
            </Step>
          );
        })}
      </Stepper>
     </Box>

    </Box> 
   
  );
}
