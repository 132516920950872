

import { configureStore } from '@reduxjs/toolkit'
// import cartReducer from "../features/cart/cartSlice";
import permssionReducer from '../Store/projectPermission/permssionReducer'

const store = configureStore({
    reducer: {
        cart: permssionReducer
    }
})

export default store