import React from 'react';
import { Box} from '@mui/system';
import { Paper } from '@mui/material';
import { Typography } from '@mui/material';
import first from '../images/1.png';
import second from '../images/2.png';
import third from '../images/3.png';
import fourth from '../images/4.png';
import { Link } from '@mui/material';
import bg from "../images/background.jpeg";
import selexbg from "../images/selexmbbg.png";
import Selexlogo from '../images/SelexMB Final.png';



function About() {
  return (
    <div style={{
      backgroundImage: `url(${bg})`,
      backgroundRepeat: "repeat",
      backgroundSize: "contain",
      height: "100%",
    }}>
         <Box component="div" sx={{ display: 'inline',marginLeft:14,marginTop:5}} >       
             <img style = {{height:'135px',width:'170px',marginTop:'10px'}} src = {Selexlogo}/>
          </Box>
      <Box component="div" sx={{ display: 'inline',float:'right', padding:5}}  >
<Typography sx={{display: 'inline' , padding:1,fontSize:25,marginRight:7}}><Link href="/" sx={{textDecoration:'none',color:'#80e5ff'}}><b>HOME</b></Link></Typography>
    <Typography sx={{display: 'inline' , padding:1,fontSize:25,marginRight:7}}><Link href="/about" sx={{textDecoration:'none',color:'#80e5ff'}}><b>ABOUT</b></Link></Typography>
    <Typography sx={{display: 'inline' , padding:1,fontSize:25,marginRight:7}}><Link sx={{textDecoration:'none',color:'#80e5ff'}}><b>CONTACT</b></Link></Typography>
    <Typography sx={{display: 'inline' ,padding:1,fontSize:25,backgroundColor:'#ff3399',borderRadius:'10%',marginRight:7}}><Link href="/login" sx={{textDecoration:'none',color:'#80e5ff'}}><b>SIGN IN </b></Link></Typography>
</Box>
<br />

        <Box component = {Paper} elevation={3} sx={{height:"4000px",width:"1500px",margin:"auto",padding:10,marginTop:8}} >
         <Typography variant="h2" sx={{textAlign:'center',fontStyle:'bold'}}>SelexMB™</Typography>
         <br />
         <Typography variant="h2" sx={{textAlign:'center',fontStyle:'bold'}}>
Industrial AI & Domain Expertise Combine to Optimize Assets
</Typography> 
<br />
        <Typography variant="h3" >Introduction</Typography>
        <br />
        <Typography sx={{fontSize:18}}>
Optimize Global Solutions has created a method
for integrating the MLOps (Machine Learning
Operations) and ANN concept with domain
knowledge resulting in a SaaS (Software as a
Service) application with capabilities to:</Typography>
<br />
<ol>
  <li style={{fontSize:18}}>Import the field data.</li>
  <li style={{fontSize:18}}>Define the KPI (Key Performance Indicator).</li>
  <li style={{fontSize:18}}>Clean the Data.</li>
  <li style={{fontSize:18}}>Build AI Model.</li>
  <li style={{fontSize:18}}>Validate Machine Learning Model.</li>
  <li style={{fontSize:18}}>Deploy the Model for Industrial applications
and usage.</li>
</ol>
<br />
<br />

<Typography variant="h3" >Industrial Maturity Overview</Typography>
<br />
<Typography sx={{fontSize:18}}>
Industrial Machine Learning Solution can have
hundreds of functions and the most prominent use
for the energy sector is applying artificial
intelligence to collate and progress data for
managers to make efficient and informed
decisions. To make right full-scale transition
towards industrial ML application, it is very
important to understand the maturity level of the
entity domain to this challenge.
Applications with embedded industrial Machine
Learning will have the capabilities to deliver the
value of data science methods in existing
workflows and industrial operation. This enables
companies to:
</Typography>
<br />
<br />
<ul>
<li style={{fontSize:18}}>Lower the barriers to ML adoption
through targeted applications.</li>
<li style={{fontSize:18}}>Overcome the lack of data science and
ML skills.
</li>
</ul>
<br />

<Typography variant="h3" >SelexMB™ Business Case</Typography>
<br />
<Typography sx={{color:'blue',fontSize:18}}>Business Case: Crude Distillation Column Performance Assessment</Typography>
<br />
<img src={first} style={{height:'500px',width:'1200px',display:'block',marginLeft:'auto',marginRight:'auto'}}/>
<br />
<br />
<Typography sx={{fontSize:18}}>Defining the KPIs (Key Performance Indicators) is a key cornerstone to develop a powerful ML model to which all dependent variables to be featured and predicted.
</Typography>
<br />
<br />
<img src={second} style={{height:'500px',width:'1200px',display:'block',marginLeft:'auto',marginRight:'auto'}}/>
<br />
<br />
<Typography sx={{fontSize:18}}>Following the completion of data cleaning, the user can identify the training/testing split using the recommended practices of 70/30, 60/40 and 80/20 along with the suitable algorithm to complete the prediction process.
</Typography>
<br />
<br />
<img src={third} style={{height:'500px',width:'1200px',display:'block',marginLeft:'auto',marginRight:'auto'}}/>
<br />
<br />
<Typography sx={{fontSize:18}}>Model validation is an assurance stage where user can simply adjudge the adequacy of his model based upon the results of R² (measurement of accuracy) and Q² (measurement of predictability).
</Typography>
<br />
<br />
<img src={fourth} style={{height:'500px',width:'1200px',display:'block',marginLeft:'auto',marginRight:'auto'}}/>
<br />
<br />
<Typography sx={{fontSize:18}}>Once the R² and Q² values are accepted, the ML model can be deployed to support business decisions
</Typography>
<br />
<br />
<Typography variant="h3" >SelexMB™ Business Advantages</Typography>

<br />
<Typography sx={{color:'blue',fontSize:18}}>Solve More Complex Problems:</Typography><Typography sx={{fontSize:18}}> Address problems that cannot be easily solved with first principles models alone.
</Typography>
<br />
<Typography sx={{color:'blue',fontSize:18}}>Build Better Models Faster:</Typography><Typography sx={{fontSize:18}}>  Empower engineers of all skill levels with Industrial AI to quickly build and deploy accurate models for digital twins, refinery planning using SelexMB™.

</Typography>
<br />
<Typography sx={{color:'blue',fontSize:18}}>Sustain Accurate Models Longer:</Typography> <Typography sx={{fontSize:18}}>AI models provide a better representation of the plant, keeping the model more relevant over a longer period, reducing the effort required to sustain the model.


</Typography>



</Box>
    </div>
  )
}

export default About