import React from 'react';
import "./css/GettingStarted.css";
import image1 from "../images/13validation.JPG";

function ModelValidationContent() {
  return (
    <div className="project-details">
      <h2>Model Validation</h2>

      <img src={image1} className='imagestyling' style={{width:'70%'}}/>
      <p>
        "R²" (R-squared) and "Q²" (Q-squared) are metrics used for model validation and assessment. These metrics provide insights into the accuracy and predictability of a model. Let's discuss R² and Q² and how they are used in model validation:
      </p>

      <h4>R² (R-squared):</h4>
      <p>
        R-squared, also known as the coefficient of determination, is a metric that quantifies the proportion of the variance in the dependent variable that is explained by the independent variables in a regression model. It measures the goodness of fit of the model to the data.
        R² values range from 0 to 1, where:
        <br />
        R² = 0: The model explains none of the variance in the dependent variable, indicating a poor fit.
        <br />
        R² = 1: The model explains all of the variance in the dependent variable, indicating a perfect fit.
        In model validation, R² is often used to assess how well the model fits the training data. Higher R² values suggest that the model captures a larger proportion of the variance in the data, indicating a better fit.
      </p>

      <h4>Q² (Q-squared):</h4>
      <p>
        Q-squared is a metric used in predictive modeling, particularly in the context of cross-validation. It assesses how well the model predicts new, unseen data.
        Q² measures the proportion of the variance in the dependent variable that is predicted by the model when it is applied to a validation dataset (not the training data).
        Like R², Q² values range from 0 to 1, with higher values indicating better predictive performance.
        A Q² value close to 1 suggests that the model can accurately predict new data, while a value close to 0 indicates poor predictive performance.
      </p>
    </div>
  );
}

export default ModelValidationContent;
