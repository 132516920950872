import React from 'react';
import '../css/GettingStarted.css'; // Import your CSS file for styling
import image1 from "../../images/1SelexHelp.png";

function GettingStarted() {
  return (
    <div className="project-details">

<h2>
            SelexMath™ Help Center
          </h2>
          <p>Welcome to SelexMath™ Help Center</p>
          <p>At SelexMath™, we specialize in providing cutting-edge math solutions tailored to tackle the complexities of 
            differential equations. Whether you're grappling with ordinary or partial differentials, 
            our innovative techniques and dedicated team are here to guide you through every step. With SelexMath™, 
            you'll harness the power of advanced mathematical tools to streamline your problem-solving process and 
            unlock new insights. Explore our range of solutions and let us empower you to conquer the most challenging 
            mathematical landscapes with confidence.</p>

          <h2>
          Getting Started
          </h2>

          <p >
          Step-by-step instructions on how to initialize variables for differential equation , declaration for differential equation and get results.
          </p>

          <h5>Managing Projects on the Home Page
</h5>

<h5>Viewing Existing Projects
</h5>

<p>The project list on the home page provides a summary of your existing projects. Each project entry includes the following elements:
</p>
<br/>
<img src={image1} className='imagestyling'/>

      <h5>Project Name:</h5>
      <p>
        This is the unique name you've given to each project. The project name should be descriptive and easily recognizable to help you quickly identify the project.
      </p>

      <h5>Date Modified:</h5>
      <p>
        This indicates the date and time when the project was last modified. This information helps you track recent changes to the project.
      </p>

      <h5>Edit Button:</h5>
      <p>
        Clicking the "Edit" button allows you to make changes or updates to the project details.
      </p>

      <h5>Delete Button:</h5>
      <p>
        The "Delete" button lets you permanently remove a project from your project list. Use this button with caution, as it will delete all associated data and cannot be undone.
      </p>

      <h5>Share Button:</h5>
      <p>
        The "Share" button allows you to share the project with other team members or collaborators. Sharing can be a helpful feature for collaborative projects, enabling others to access and contribute to the project.
      </p>

      <h5>Creating a New Project</h5>
      <p>To create a new project, follow these steps:</p>

      <ol>
        <li>
          <strong>Click on the "Create New Project" button:</strong> You'll find this button on the home page, usually located near the project list.
        </li>
        <li>
          <strong>Enter Project Details:</strong>
          <ul>
            <li>
              <strong>Project Name (Required):</strong> Provide a unique and descriptive name for your new project.
            </li>
            <li>
              <strong>Client Name (Optional):</strong> If this project is associated with a client, enter their name here.
            </li>
            <li>
              <strong>Objective (Optional):</strong> Describe the goal or objective of the project. This step is optional but can be helpful for keeping track of project purpose.
            </li>
          </ul>
        </li>
        <li>
          <strong>Click "Create Project":</strong> Once you've entered the project details, click the "Create Project" button to create your new project.
        </li>
      </ol>
    </div>
  );
}

export default GettingStarted;
