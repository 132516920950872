import React, { useState } from "react";
import { Checkbox, Typography } from "@mui/material";
import Plot from "react-plotly.js";
import { baseURL } from "../Config/config";
import GlobalLoader from "./CommonC/GlobalLoader";
import Box from "@mui/material/Box";
import Projectcolors from "../Utils/Colors";
import { Grid, Paper, TextField } from "@mui/material";

function Heatmap() {
  const [alltagname, setTagname] = React.useState([]);

  const [allcheckboxtagname, setChecboxtagname] = React.useState([]);
  const [allcorrdata, setAllCorrData] = React.useState([]);

  const [corrdata, setCorrData] = React.useState([]);
  const [checkedState, setCheckedState] = useState(new Array(50).fill(true));
  const [updatebox, setUpdateBox] = useState(false);
  const [strcorrdata, setstrCorrData] = React.useState([]);
  const [getLoader , setGetLoader] = React.useState(false);


  React.useEffect(() => {
    getAllTagName()
  }, []);


  const getAllTagName = () => { 
    const ProjectID = localStorage.getItem("projectID")
    setGetLoader(true)
    fetch(`${baseURL}/getalltagname?projectID=${ProjectID}`)
      .then((res) => res.json())
      .then((myresponse) => {
        setTagname(myresponse.tag_name);
        setCorrData(myresponse.rowlist);
        setstrCorrData(myresponse.corrdata);
        setChecboxtagname(myresponse.tag_name);
        setAllCorrData(myresponse.rowlist);
        setGetLoader(false)
      })
      .catch((err)=> {
        setGetLoader(false)
        console.log("error",err)})
   }


  const handleOnChange = (item, position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );

    setCheckedState(updatedCheckedState);
    if (alltagname.includes(item)) {
      setTagname(alltagname.filter((x, i) => x !== item));
      setCorrData(corrdata.filter((x, i) => x !== allcorrdata[position]));
    } 
    if(!alltagname.includes(item)) {
      let newData = allcorrdata[position]
      const insert = (arr, index, newItem) => [
        // part of the array before the specified index
        ...arr.slice(0, index),
        // inserted item
        newItem,
        // part of the array after the specified index
        ...arr.slice(index)
      ]
      const result = insert(corrdata,position ,newData)
      setTagname(alltagname.splice(position, 0, item));
      setCorrData(result);
      setTagname(alltagname);
      
    }
    //setTagname(alltagname.filter((item,i)=>(item !== removeitem)))
    //setCorrData(corrdata.filter((item,i)=>(i !== position)))
  };

  const changeupdatebox = (alltagname,corrdata) =>{
    setUpdateBox(!updatebox);
    setTagname(alltagname);
    setCorrData(corrdata);
  }


  return (

    <div style={{display:"flex", flexDirection:"row",paddingRight:"10px",justifyContent:"space-between"}}>
    <Box component ={Paper} elevation={5} sx={{display:"flex", flexDirection:"column",marginTop:0,border:"1px solid #0078C9",overflowY:"scroll",height:"500px",width:"240px"}}>
      {allcheckboxtagname
        ? allcheckboxtagname.map((item, i) => {
            return (
              <div style={{display:"flex",alignItems:"center",marginLeft:10}}>
                <Checkbox
                  color="primary"
                  inputProps={{
                    "aria-label": "select all desserts",
                  }}
                  size="medium"
                  key={i}
                  value={item}
                  onChange={() => handleOnChange(item, i)}
                  checked={checkedState[i]}
                  
                />
                <Typography key={item} sx={{fontSize:"12px"}}>{item}</Typography>
              </div>
            );
          })
        : null}
 </Box>
 <Box component ={Paper} elevation={5} sx={{marginLeft:"30px",border:"1px solid #0078C9",height:"500px",overflowY:"scroll",width:"710px",padding:"3px"}}>
      <Plot
        data={[
          {
            z: corrdata,
            x: alltagname,
            y: alltagname,
            type: "heatmap",
            colorscale: [
              [0, "#e83131"],
              [1, "#0602d9"],
            ],
            xgap: 3,
            ygap: 3,
          },
        ]}
        layout={{
          width: 600,
          height: 500,
          title: "Correlation Plot",
          name: "",
          borderradius: 5,
          yaxis: {
            automargin: true
          },
          xaxis: {
            automargin: true
          }
                }}
                config = {{
                  displaylogo:false
                }}
      />
      </Box>
   {
            getLoader ? <GlobalLoader /> : null
          }
    </div>
  );
}

export default Heatmap;
