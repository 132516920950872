import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

import Projectcolors from "../../Utils/Colors";
import { InputLabel } from "@mui/material";
import { baseURL } from "../../Config/config";
import SelectKpi from "../Pages/SelectKpi";
import AdminTable from "../CommonC/AdminTable";
import AddUserModal from "../CommonC/AddUserModal";
import { Link } from "@mui/material";

import Selexlogo from "../../images/SelexMB Final.png";
import bg from "../../images/AI.jpg";
import Navbar from "../CommonC/Navbar";
import BgContainer2 from "../CommonC/BgContainer2";
import GlobalLoader from "../CommonC/GlobalLoader";

const AdminHome = () => {
  const navigate = useNavigate();
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [modalState, setModalState] = React.useState(false);

  const toggleModal = (value) => {
    setModalState(value);
  };

  return (
    <div
    style={{
      height:window.screen.height
    }}
  >
   
    <Navbar admin={true}/>
    <BgContainer2>
       <div
         style={{
           justifyContent: "center",
           alignItems: "center",
           marginLeft: "23%",
           marginRight: "28%",
           marginTop:120
         }}
       >
         <AdminTable modalState={modalState} toggleModal={toggleModal} />
       </div>
    {/* {
          getLoader ? <GlobalLoader  /> : null
        } */}
        </BgContainer2>
  </div>

  );
};

export default AdminHome;
