import { createSlice } from "@reduxjs/toolkit";
let nextItemId = 0;
const cartSlice = createSlice({
  name: "cart",
  // in case cart was saved items, will be here:
  initialState: [],
  // reducers actions
  reducers: {
    // create action
    create: {
      reducer(state, { payload }) {
        const { projectId, projectName, editAccess } = payload;
        if(payload[0].empty){
          state = []
        return;
        }
        return [state, ...payload]


      },
    //   prepare(payload) {
    //     return {
    //        payload
    //     };
    //   },
    },
  },
});
const { actions, reducer } = cartSlice;
export const { create } = actions;
export default reducer;
