import { Box, Button, Modal, Paper, Typography } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import first from "../../images/1.png";
import second from "../../images/2.png";
import third from "../../images/3.png";
import fourth from "../../images/4.png";
import Selexlogo from "../../images/SelexMB Final.png";
import optimizelogo from "../../images/logooptimizegs.png";
const AboutModal = ({ open, handleClose }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    height: 700,
    bgcolor: "white",
    border: "2px solid #000",
    p: 4,
    overflow: "scroll",
    display: "block",
  };

  return (
    <div
      style={{
        height: window.screen.height,
      }}
    >
      {" "}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} component={Paper} elevation={10}>
          <div>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                backgroundColor: "white",
                justifyContent: "space-between",
              }}
            >
              <Box
                component="div"
                sx={{ display: "flex", marginLeft: "1%", marginTop: 0 }}
              >
                <img
                  style={{ height: "75px", width: "230px", marginTop: "0px" }}
                  src={optimizelogo}
                />
              </Box>

              <Button
                onClick={handleClose}
                sx={{
                  display: "inline-flex",
                  color: "black",
                  fontSize: "16px",
                }}
              >
                <CloseIcon sx={{ fontSize: "18px" }} />
              </Button>
            </Box>
          </div>

          <div>
            <br />
            <Typography
              variant="h3"
              sx={{
                textAlign: "center",
                fontStyle: "bold",
                marginTop: "0px",
              }}
            >
              SelexMB™
            </Typography>
            <br />
            <Typography
              variant="h3"
              sx={{ textAlign: "center", fontStyle: "bold" }}
            >
              Industrial AI & Domain Expertise Combine to Optimize Assets
            </Typography>
            <br />
            <Typography variant="h4" sx={{ marginTop: "15px" }}>
              <b>Introduction</b>
            </Typography>
            <br />
            <Typography sx={{ fontSize: 16 }}>
              Optimize Global Solutions has created a method for integrating the
              MLOps (Machine Learning Operations) and ANN concept with domain
              knowledge resulting in a SaaS (Software as a Service) application
              with capabilities to:
            </Typography>
            <br />
            <ol>
              <li style={{ fontSize: 16 }}>Import the field data.</li>
              <li style={{ fontSize: 16 }}>
                Define the KPI (Key Performance Indicator).
              </li>
              <li style={{ fontSize: 16 }}>Clean the Data.</li>
              <li style={{ fontSize: 16 }}>Build AI Model.</li>
              <li style={{ fontSize: 16 }}>Validate Machine Learning Model.</li>
              <li style={{ fontSize: 16 }}>
                Deploy the Model for Industrial applications and usage.
              </li>
            </ol>
            <br />
            <br />
            <Typography variant="h4">
              <b>Industrial Maturity Overview</b>
            </Typography>
            <br />
            <Typography sx={{ fontSize: 16 }}>
              Industrial Machine Learning Solution can have hundreds of
              functions and the most prominent use for the energy sector is
              applying artificial intelligence to collate and progress data for
              managers to make efficient and informed decisions. To make right
              full-scale transition towards industrial ML application, it is
              very important to understand the maturity level of the entity
              domain to this challenge. Applications with embedded industrial
              Machine Learning will have the capabilities to deliver the value
              of data science methods in existing workflows and industrial
              operation. This enables companies to:
            </Typography>
            <br />
            <br />
            <ul>
              <li style={{ fontSize: 16 }}>
                Lower the barriers to ML adoption through targeted applications.
              </li>
              <li style={{ fontSize: 16 }}>
                Overcome the lack of data science and ML skills.
              </li>
            </ul>
            <br />
            <Typography variant="h4">
              <b>SelexMB™ Business Case</b>
            </Typography>
            <br />
            <Typography sx={{ color: "blue", fontSize: 16 }}>
              Business Case: Crude Distillation Column Performance Assessment
            </Typography>
            <br />
            <img
              src={first}
              style={{
                height: "380px",
                width: "900px",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
            <br />
            <br />
            <Typography sx={{ fontSize: 16 }}>
              Defining the KPIs (Key Performance Indicators) is a key
              cornerstone to develop a powerful ML model to which all dependent
              variables to be featured and predicted.
            </Typography>
            <br />
            <br />
            <img
              src={second}
              style={{
                height: "380px",
                width: "900px",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
            <br />
            <br />
            <Typography sx={{ fontSize: 16 }}>
              Following the completion of data cleaning, the user can identify
              the training/testing split using the recommended practices of
              70/30, 60/40 and 80/20 along with the suitable algorithm to
              complete the prediction process.
            </Typography>
            <br />
            <br />
            <img
              src={third}
              style={{
                height: "380px",
                width: "900px",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
            <br />
            <br />
            <Typography sx={{ fontSize: 16 }}>
              Model validation is an assurance stage where user can simply
              adjudge the adequacy of his model based upon the results of R²
              (measurement of accuracy) and Q² (measurement of predictability).
            </Typography>
            <br />
            <br />
            <img
              src={fourth}
              style={{
                height: "380px",
                width: "900px",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
            <br />
            <br />
            <Typography sx={{ fontSize: 16 }}>
              Once the R² and Q² values are accepted, the ML model can be
              deployed to support business decisions
            </Typography>
            <br />
            <br />
            <Typography variant="h4">
              <b>SelexMB™ Business Advantages</b>
            </Typography>
            <br />
            <Typography sx={{ color: "blue", fontSize: 16 }}>
              Solve More Complex Problems:
            </Typography>
            <Typography sx={{ fontSize: 16 }}>
              {" "}
              Address problems that cannot be easily solved with first
              principles models alone.
            </Typography>
            <br />
            <Typography sx={{ color: "blue", fontSize: 16 }}>
              Build Better Models Faster:
            </Typography>
            <Typography sx={{ fontSize: 16 }}>
              {" "}
              Empower engineers of all skill levels with Industrial AI to
              quickly build and deploy accurate models for digital twins,
              refinery planning using SelexMB™.
            </Typography>
            <br />
            <Typography sx={{ color: "blue", fontSize: 16 }}>
              Sustain Accurate Models Longer:
            </Typography>{" "}
            <Typography sx={{ fontSize: 16 }}>
              AI models provide a better representation of the plant, keeping
              the model more relevant over a longer period, reducing the effort
              required to sustain the model.
            </Typography>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default AboutModal;
